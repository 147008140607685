import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { UserService } from './user.service';

@Injectable()
export class AuthGuardService implements CanActivate {

  constructor(
    public user: UserService, 
    public router: Router,
    public authService: AuthService

  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.user.isAuthenticated()) {
      if (this.authService.isAdmin()) {
        return true
      } else {
        this.router.navigate(['login'])
                location.reload();
        return false;
      }
    }
    else {
      this.router.navigate(['login'], { queryParams: { return: state.url }});
      return false;
    }
  }

}