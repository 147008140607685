<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !selectedDashboardMetric); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

        <!-- Response Filters -->
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">

            <div class="row justify-content-start">
                <div class="col-12">
                    <div class="form-group mb-4">
                        <ng-container *ngIf="!displayDashboard && !noAvailableDashboards && !skipDashboardSelect">
                            <h4 [ngClass]="{'text-primary-light': ui.darkMode}">
                                Available Dashboards
                            </h4>
                            <p class="text-secondary">
                                Select a dashboard to view/edit.
                            </p>
                            <ng-container *ngFor="let filter of prefilters">
                                <app-project-dashboard-filter 
                                    [filterName]="filter.filterName" 
                                    [filterOptions]="filter.filterOptions" 
                                    (emitSelectedItems)="updateDashboard($event.filterItems)">
                                </app-project-dashboard-filter>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!displayDashboard && noAvailableDashboards">
                            <p class="text-center h5 text-muted p-4">
                                There are no dashboards for {{ currentYear }}
                            </p>
                        </ng-container>
                        <ng-container *ngIf="displayDashboard">
                            <div class="d-block text-info c-pointer filters" (click)="clearFilter()">
                                <i class="fas fa-times mr-2"></i>Showing:
                                <span *ngFor="let filter of selectedFilters" class="font-weight-bold ml-1">{{ filter.name }}&nbsp;</span>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Table -->
            <app-dynamic-table
                *ngIf="displayDashboard || skipDashboardSelect"
                [data]="currentProject.dashboard" 
                [columns]="dashboardTableColumns" 
                [currentYear]="currentYear"
                [tableTitle]="'dashboard'"
                [primaryKey]="'metricId'"
                [onRowClick]="onDashboardMetricClick">
            </app-dynamic-table>

            <!-- Selected Dashboard Metric -->
            <app-slider-panel *ngIf="selectedDashboardMetric" [onClose]="closeDashboardMetricSlider">
                <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
                    <!-- Heading -->
                    <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                    <!-- Form -->
                    <app-dynamic-form [data]="selectedDashboardMetric" [blocks]="dashboardMetricDetailsForm"></app-dynamic-form>
                </ng-container>
            </app-slider-panel>

        </ng-container>
        
</ng-template>