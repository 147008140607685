<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
  <div class="container-fluid">
    <div class="row justify-content-center pt-5">
      <div class="col-md-11 col-12">
        <!-- List of all tools -->
        <div class="row" *ngIf="!selectedTool">
          <div class="col-md-4 mb-3 d-flex align-items-stretch" *ngFor="let tool of toolInformation">
            <div class="card" style="width: 40rem;">
              <div class="card-body d-flex flex-column">
                <div class="d-flex justify-content-between pb-2">
                  <h3 class="card-title mt-1" style="color: #003087; font-weight: 600;">{{ tool.title }}</h3>
                  <i [class]="tool.icon" class="fa-3x" style="color: #003087;"></i>
                </div>
                <p class="card-text mb-4">{{ tool.subTitle }}</p>

                <button *ngIf="tool.showButton" (click)="openTool(tool)"
                  class="btn btn-primary mt-auto align-self-start" [disabled]="tool.disableOpenButton"
                  [innerHTML]="tool.openButtonInnerHtml">
                </button>

                <a *ngIf="tool.folderLocationLink" class="btn btn-primary mt-auto align-self-start"
                  [href]="tool.folderLocationLink" target="_blank">Open sharepoint</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>