<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Info -->
    <div class="alert alert-info mb-4">
        <i class="fas fa-info-circle mr-1"></i><strong>Dynamic document links are created when the user actually clicks to download the document.</strong>  Links expire after a few hours. The intention is to ensure these documents can only be downloaded by active users, and not shared with others. Categories include reports, case studies, presentations and offline toolkits.
    </div>

    <!-- Table -->
    <app-dynamic-table
        *ngIf="currentEvent.awsDocumentsDynamic"
        [data]="currentEvent.awsDocumentsDynamic"
        [columns]="eventDocumentsTableColumns"
        [currentYear]="currentYear"
        [tableTitle]="'dynamicDocumentsList'"
        [primaryKey]="'documentId'"
        [onRowClick]="onTableRowClick">
    </app-dynamic-table>
</ng-template>

<!-- Selected Document -->
<app-slider-panel *ngIf="documentLayout && displayedDocument" [onClose]="closeQuestionSlider">
    <app-area-heading [heading]="documentLayout.heading" [subheading]="documentLayout.subheading"></app-area-heading>
    <app-dynamic-form [data]="displayedDocument" [blocks]="questionGroupForm"></app-dynamic-form>
    <button class="btn btn-primary" (click)="getAwsDocuments()">Open File</button>
    <div *ngIf="displayedDocument.downloadCount > 0">
        <hr>
        <h4 class="mt-2 mb-3">
            Download History
        </h4>
        <app-dynamic-table
            [data]="displayedDocument.downloadedBy"
            [columns]="eventDocumentsHistoryTableColumns"
            [currentYear]="currentYear"
            [tableTitle]="'dynamicDocumentsList'"
            [primaryKey]="'documentId'"
            [disableRowClick]="true"
            [hideExportButton]="true"
            [hideSearch]="true"> 
        </app-dynamic-table>
    </div>
</app-slider-panel>