import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class ToolService {
  private deApiUrl: string = environment.deAPIUrl;

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    public auth: AuthService
  ) {}

  public submitDataExtract(body: any): Observable<any> {
    body.token = this.auth.token();
    return this.http.post(this.deApiUrl, body, {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
      responseType: 'text',
    });
  }
}
