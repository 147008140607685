import moment from "moment";
import { IUserActivity } from "../models/user-activity.vm";
import { IUserRole } from "../models/user-role.vm";
import { IUserLoginAttempts } from "../models/user-login-attempts.vm";

export class Users {
  public userId: number;
  public ID: number;
  public fullName: string;
  public userLogonName: string;
  public userEmail: string;
  public userFullName: string;
  public userJobTitle: string;
  public jobTitle: string;
  public userPhone: number;
  public isActive: string;
  public isAdmin: string;
  public userDetailLastReviewed: string;
  public recentlyActive: string
  public hasActiveRoles: string
  public projectId: number
  public userLastLogon: string
  public roles: Array<IUserRole>; 
  public logonAttempts: Array<IUserLoginAttempts>
  public failedLogons: number;
  public successfulLogons: number;
  public userActivity: Array<IUserActivity>;


  constructor(data: any) {
    this.mapUsersBasicDetails(data);
  }

  private mapUsersBasicDetails(data: any): void {
    this.userId = data.userId;
    this.fullName = data.fullName;
    this.userJobTitle = data.userJobTitle;
    this.userLogonName = data.logonName;
    this.userEmail = data.userEmail
    this.userFullName = data.userFullName;
    this.jobTitle = data.jobTitle;
    this.userPhone = data.userPhone;
    this.isActive = data.isActive;
    this.isAdmin = data.isAdmin;
    this.recentlyActive = data.recentlyActive;
    this.hasActiveRoles = data.hasActiveRoles;
    this.projectId = data.projectId;
    this.userLastLogon = this.formatDate(data.lastLogon);
    this.userDetailLastReviewed = this.formatDate(data.userDetailLastReviewed);
    this.roles = this.mapUserRoles(data.roles);
    this.logonAttempts = data.logonAttempts
    this.failedLogons = data.failedLogons;
    this.successfulLogons= data.successfulLogons
    this.userActivity= data.userActivity
  }

  private mapUserRoles(roles: Array<IUserRole>): Array<IUserRole> {
    if (roles) {
      let flatRoles: Array<IUserRole> = [];
      Object.values(roles).forEach((el)=>{Object.values(el).forEach(el => flatRoles.push(el))})  
      return flatRoles
    } 
    else {
      return null
    }
  }

  private formatDate(date: string) {
    return moment(date).format('LLL');
  }
}

