<ng-container>
  <div class="container-fluid">
      <div class="row justify-content-center pb-5">
          <div class="col-md-11 col-12">

              <div class="row mt-5 mb-3">
                  <div class="col-md-7 col-12" *ngIf="layout">
                      <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                  </div>
                  <div class="col-md-5 col-12 d-flex">
                      <div class="ml-auto">
                          <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                      </div>
                  </div>
              </div>
              
              <app-area-nav [navItems]="crmNavItems"></app-area-nav>
              <app-crm-overview *ngIf="currentSection == 'overview'"></app-crm-overview>
              <app-crm-opportunities *ngIf="currentSection == 'opportunities'"></app-crm-opportunities>
              <app-crm-interactions *ngIf="currentSection == 'interactions'"></app-crm-interactions>
              <app-crm-follow-ups *ngIf="currentSection == 'follow-ups'"></app-crm-follow-ups>
          </div>
      </div>
  </div>            
</ng-container>
