import { Component, Input, OnInit } from '@angular/core';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

@Component({
  selector: 'app-input-copy',
  templateUrl: './input-copy.component.html',
  styleUrls: ['./input-copy.component.scss']
})
export class InputCopyComponent implements OnInit {

  public copiedMessage: string = null;
  @Input() public block: IDynamicFormBlock;

  constructor() { }

  ngOnInit(): void {
  }

  async copyToClipboard(id: string) {
    var range = document.createRange();  
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}
