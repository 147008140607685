import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const UsersLoginAttemptsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Date and time',
        percWidth: 50,
        dataKey: 'logonAttemptDate',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Status',
        dataKey: 'logonStatus',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
    }
]

