import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IOrganisationRole } from 'src/app/core/models/organisation-role.vm';
import { IOrganisationUser } from 'src/app/core/models/organisation-user.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { OrganisationUserTableColumns, OrganisationUserRolesTableColumns, OrganisationUserDetailsForm, OrganisationUserRolesDetailsForm, UserTableFilters } from './organisation-user-layouts';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDynamicPresetFilter } from 'src/app/core/models/dynamic-table-filter.vm';

@Component({
  selector: 'app-organisation-user',
  templateUrl: './organisation-user.component.html',
  styleUrls: ['./organisation-user.component.scss']
})
export class OrganisationUserComponent implements OnChanges {

  @Input() public currentOrganisation: Organisation;
  @Input() currentYear: number;

  public selectedUser: IOrganisationUser = null;
  public selectedUserRole: IOrganisationRole = null;
  public userLayout: ILayout;
  public roleLayout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public organisationUserTableColumns: Array<IDynamicTableColumn> = OrganisationUserTableColumns;
  public organisationUserRolesTableColumns: Array<IDynamicTableColumn> = OrganisationUserRolesTableColumns;
  public organisationUserDetailsForm: Array<IDynamicFormBlock> = OrganisationUserDetailsForm;
  public organisationUserRolesDetailsForm: Array<IDynamicFormBlock> = OrganisationUserRolesDetailsForm;
  public presetFilters: Array<IDynamicPresetFilter> = UserTableFilters;

  darkMode$: Observable<boolean>;

  constructor(
    public loaderService: LoaderService,
    private organisationService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode')
   }

  ngOnChanges(): void {
    // Check for open users
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    
    this.getDetails(this.currentOrganisation.organisationId, this.currentYear);
  }

  private getDetails(organisationId: number, currentYear: number): void {
    this.organisationService.getOrganisation(organisationId, currentYear).subscribe(
      success => {
        this.currentOrganisation.users = success.data.organisationDetails.users;

        // Open slider, if in params
        if (this.currentQueryParams.user) {
          this.onUserClick(this.currentQueryParams.user);
        }
        if (this.currentQueryParams.role) {
          this.onUserRoleClick(this.currentQueryParams.role);
        }
      },
      error => {
        console.error('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onUserClick = (primaryKey: string): void => {
    this.selectedUser = this.currentOrganisation.users.find(user => user.userId == +primaryKey);
    this.userLayout = new Layout(
      this.selectedUser.fullName,
      [ `ID: ${this.selectedUser.userId}` ],
      null
    )
    this.router.navigate([], { queryParams: { user: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeUserSlider = (): void => {
    this.selectedUser = null;
    this.router.navigate([], { queryParams: { user: null }, queryParamsHandling: 'merge' });
  }

  public onUserRoleClick = (primaryKey: string): void => {
    this.selectedUserRole = this.selectedUser.roles.find(user => user.userRoleId == +primaryKey);
    this.roleLayout = new Layout(
      this.selectedUserRole.roleName,
      [ `ID: ${this.selectedUserRole.userRoleId}` ],
      null
    )
    this.router.navigate([], { queryParams: { role: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeUserRoleSlider = (): void => {
    this.selectedUserRole = null;
    this.router.navigate([], { queryParams: { role: null }, queryParamsHandling: 'merge' });
  }


}
