import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OpportunitiesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'name',
        percWidth: 5,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation ID',
        dataKey: 'organisationId',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Type',
        dataKey: 'type',
        percWidth: 5,
        sortable: false,
        groupable: true,
        searchable: false
    },
    {
        header: 'Stage',
        dataKey: 'stage',
        percWidth: 5,
        sortable: false,
        groupable: true,
        searchable: false
    },
    {
        header: 'Initial Contact Date',
        dataKey: 'initialContactDate',
        percWidth: 7,
        sortable: false,
        groupable: true,
        searchable: false,
        format: 'date'
    },
    {
        header: 'Project Number',
        dataKey: 'projectNumber',
        percWidth: 5,
        sortable: false,
        groupable: false,
        searchable: true
    }
];

export const OpportunitiesForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Opportunity',
        blockType: 'header',
    },
    {
        label: 'Name',
        dataKey: 'name',
        blockType: 'input',  
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Type',
        dataKey: 'type',
        blockType: 'input',  
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Stage',
        dataKey: 'stage',
        blockType: 'input',  
        colWidth: 4,
        disabled: true
    },
    {
        label: 'Project Number',
        dataKey: 'projectNumber',
        blockType: 'input',  
        colWidth: 4,
        disabled: true
    },
    {
        label: 'Initial Contact Date',
        dataKey: 'initialContactDate',
        blockType: 'input',  
        colWidth: 4,
        disabled: true
    },
    {
        blockType: 'divider'
    }
]

export const InteractionsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Description',
        dataKey: 'description',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Type',
        dataKey: 'type',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Interaction ID',
        dataKey: 'interactionId',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Opportunity ID',
        dataKey: 'opportunityId',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Date',
        dataKey: 'interactionDate',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: false,
        format: 'date'
    }
];