import { Component, Input, OnChanges } from '@angular/core';
import { FollowUpsDetailsForm, FollowUpsTableColumns } from './crm-follow-ups-layouts';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Store } from '@ngrx/store';
import { CrmService } from 'src/app/core/services/crm.services';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IFollowUp } from 'src/app/core/models/crm-follow-ups.vm';
import { Layout } from 'src/app/core/classes/layout';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-crm-follow-ups',
  templateUrl: './crm-follow-ups.component.html',
  styleUrls: ['./crm-follow-ups.component.scss']
})
export class CrmFollowUpsComponent implements OnChanges {

  @Input() currentYear: number;
  
  public selectedFollowUp: IFollowUp = null;
  public layout: ILayout;
  public followupsTableColumns: Array<IDynamicTableColumn> = FollowUpsTableColumns;
  public followupsDetailsForm: Array<IDynamicFormBlock> = FollowUpsDetailsForm;
  public loadingError: string;
  public followups = [];
  darkMode$: Observable<boolean>;
  
  constructor(
    public loaderService: LoaderService,
    private store: Store<{ darkMode: boolean }>,
    private crmService: CrmService,
    private datePipe: DatePipe
  ) {
   this.darkMode$ = store.select('darkMode');
   this.getCrmFollowUps();
    }

  ngOnChanges(): void {}

  private getCrmFollowUps() {
    this.crmService.getFollowUps().subscribe(
      success => {
        if (success.data.responseList) {
          this.followups = success.data.responseList;
        }
      },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.message;
      }
    )
  };

  public onFollowUpClick = (): void => {
    this.selectedFollowUp = this.followups.find((followup) => followup.interactionId)
    this.layout = new Layout(
      this.selectedFollowUp.name,
      [null],
      null
    )
  };

  public closeFollowUpSlider = (): void => {
    this.selectedFollowUp = null;
  };
}