import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OrganisationMOUTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'mou_id',
        percWidth: 5,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Name',
        dataKey: 'organisation_name',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Notes',
        dataKey: 'notes',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Review',
        dataKey: 'review',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true,
        dateFormat: 'MMM D, h:mm A'
    },
    {
        header: 'Sent',
        dataKey: 'sent_out',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true,
        dateFormat: 'MMM D, h:mm A'
    },
    {
        header: 'Signed',
        dataKey: 'signed',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true,
        dateFormat: 'MMM D, h:mm A'
    },
    {
        header: 'Status',
        dataKey: 'state',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Options',
        percWidth: 5,
        dataKey: '',
        sortable: false,
        groupable: false,
        searchable: false,
        exportable: false,
        options: ['clickable'],
        button: 'options',
        buttonType: 'delete'
    }
];

export const MOUDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'MOU Details',
        blockType: 'header',
    },
    {
        label: 'Notes',
        dataKey: 'notes',
        blockType: 'input',
        colWidth: 12
    },
    {
        label: 'Sent',
        dataKey: 'sent_out',
        blockType: 'input',   
        inputType: 'datetime-local',         
        colWidth: 6,
    },
    {
        label: 'Signed',
        dataKey: 'signed',
        blockType: 'input',    
        inputType: 'datetime-local',        
        colWidth: 6,
    },
    {
        label: 'Review',
        dataKey: 'review',
        blockType: 'input',
        inputType: 'datetime-local',     
        colWidth: 6,
    },
    {
        label: 'Status',
        dataKey: 'state',
        blockType: 'input',       
        colWidth: 6,
    },
    {
        blockType: 'saveButton',
        dataKeys: [],
        label: 'Save',
    }
]

export const MOUCreateForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'MOU Details',
        blockType: 'header',
    },  
    {
        label: 'Lead ID',
        dataKey: 'leadId',
        helpText: "Required",
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisations'
    },
    {
        label: 'Signing Organisation ID',
        helpText: "Required",
        dataKey: 'signingOrganisationId',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisations',
    },
    {
        label: 'Status',
        dataKey: 'state',
        helpText: "Required",
        blockType: 'input',       
        colWidth: 12,
    },
    {
        label: 'Sent',
        dataKey: 'sent_out',
        blockType: 'input',   
        inputType: 'datetime-local',         
        colWidth: 6,
    },
    {
        label: 'Signed',
        dataKey: 'signed',
        blockType: 'input',    
        inputType: 'datetime-local',        
        colWidth: 6,
    },
    {
        label: 'Review',
        dataKey: 'review',
        blockType: 'input',
        inputType: 'datetime-local',     
        colWidth: 6,
    },
    {
        label: 'Notes',
        dataKey: 'notes',
        blockType: 'input',       
        colWidth: 12,
    },
    {
        blockType: 'saveButton',
        dataKeys: ['leadId', 'signingOrganisationId', 'state'],
        dataKey: 'state',
        label: 'Save',
        action: 'create'
    }
]