<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>


<ng-template #notLoading>
    <div class="row justify-content-start">
        <div class="col-md-2">
            <app-section-nav [navItems]="projectDetailNavItems"></app-section-nav>
        </div>

        <div class="col-md-10">
            <app-dynamic-form  *ngIf="currentScope == 'basics'" [data]="currentProject" (dataUpdate)="submissionDetailsUpdate($event)"
                [blocks]="projectDetailBasicsBlocks"></app-dynamic-form>
            <app-dynamic-form *ngIf="currentScope == 'roles'" [data]="currentProject"
                [blocks]="projectDetailRolesBlocks" [rules]="projectDetailRules.projectRoles"
                (dataUpdate)="dataUpdate($event, 'projectRoles')">
            </app-dynamic-form> 
             <app-dynamic-form 
            *ngIf="currentScope == 'dates' && currentProject.projectDates" 
            [data]="currentProject" 
            [blocks]="projectDetailDatesBlocks"
            [rules]="projectDetailRules.dates"
            (dataUpdate)="dataUpdate($event, 'projectDates')">
            </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'services' && currentProject.serviceItems" 
                [data]="currentProject" 
                [blocks]="projectDetailServiceItemBlocks"
                [rules]="projectDetailRules.serviceItems"
                (dataUpdate)="dataUpdate($event, 'serviceItems')">
            </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'guidance' && currentProject.guidance" 
                [data]="currentProject"
                [blocks]="projectDetailGuidanceBlocks"
                [rules]="projectDetailRules.guidance"
                (dataUpdate)="dataUpdate($event, 'guidance')">
            </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'surveys' && currentProject.surveys && useTranslatedList" 
                [data]="currentProject" 
                [blocks]="translatedProjectDetailSurveyBlock"
                [rules]="projectDetailRules.surveys"
                (dataUpdate)="dataUpdate($event, 'surveys')">
            </app-dynamic-form>
            <app-dynamic-form 
            *ngIf="currentScope == 'surveys' && currentProject.surveys && !useTranslatedList" 
            [data]="currentProject" 
            [blocks]="projectDetailSurveyBlocks"
            [rules]="projectDetailRules.surveys"
            (dataUpdate)="dataUpdate($event, 'surveys')">
        </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'peer-groups' && currentProject.peerGroups" 
                [data]="currentProject" 
                [blocks]="projectDetailPeerGroupBlocks">
            </app-dynamic-form>
        </div>
    </div>
</ng-template>