import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { IPopupModal } from 'src/app/core/models/popup-modal.vm';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class PopupModalComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  @Output() public commentText = new EventEmitter<any>();
  @Input() public modalInfo: IPopupModal;
  @ViewChild('modal') modal: ElementRef;

  public myTextarea: string = '';
  darkMode$: Observable<boolean>;


  constructor(
    config: NgbModalConfig, 
    private modalService: NgbModal,
    private store: Store<{ darkMode: boolean }>
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.modalService.open(this.modal, { centered: true });
    }, 200);
  }

  onValueChange(value: string): void {
    this.commentText.emit(value)
  }

  closeModal(event: boolean) {
    this.action.emit(event);
  }

}
