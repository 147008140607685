import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-table-pagination',
  templateUrl: './table-pagination.component.html',
  styleUrls: ['./table-pagination.component.scss'],
})
export class TablePaginationComponent implements OnInit, OnChanges {
  @Input() maxPages = 10;
  @Input() initialPage = 1;
  @Input() items: Array<any>;
  @Input() pageSize: number = 20;
  @Input() paginationReset: boolean;
  @Output() changePage = new EventEmitter<any>(true);

  public pager: any = {};
  public showAllLoading: boolean;
  public initialPageSize: number;
  public clicked: boolean = false;
  public darkMode$: Observable<boolean>;
  public showAllButton: boolean = false;
  public rePaginationButton: boolean = false;

  constructor(
    private ref: ChangeDetectorRef,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
    ref.detach();
  }

  ngOnInit() {
    if(this.items.length >= 20) {
      this.showAllButton = true
    }

    this.initialPageSize = this.pageSize;
    if (this.items && this.items.length) {
      this.setPage(this.initialPage);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.paginationReset) {
      this.pageSize = this.initialPageSize;
      this.showAllButton = true;
      this.rePaginationButton = false;
    }

    if(this.rePaginationButton) {
      this.pageSize = this.items.length;
    }

    if (changes.items.currentValue != changes.items.previousValue) {
      this.setPage(this.initialPage);
    }
  }

  public setPage(page: number) {
    // get a new pager object for the specified page
    this.pager = this.paginate(page);

    // get a new page of items for the page
    let pageOfItems = this.items.slice(
      this.pager.startIndex,
      this.pager.endIndex + 1
    );

    // call change page function
    this.changePage.emit({
      pageOfItems: pageOfItems,
      pager: this.pager,
    });

    setTimeout(() => {
      this.showAllLoading = false;
      this.ref.detectChanges();
      this.ref.reattach();
    }, 500);
  }

  private paginate(currentPage: number = 1) {
    // calculate totals
    let totalItems = this.items.length;
    let totalPages = Math.ceil(totalItems / this.pageSize);

    // clamp the requested page
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= this.maxPages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      // total pages more than max so calculate start and end pages
      let maxPagesBeforeCurrentPage = Math.floor(this.maxPages / 2);
      let maxPagesAfterCurrentPage = Math.ceil(this.maxPages / 2) - 1;
      if (currentPage <= maxPagesBeforeCurrentPage) {
        // current page near the start
        startPage = 1;
        endPage = this.maxPages;
      } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
        // current page near the end
        startPage = totalPages - this.maxPages + 1;
        endPage = totalPages;
      } else {
        // current page somewhere in the middle
        startPage = currentPage - maxPagesBeforeCurrentPage;
        endPage = currentPage + maxPagesAfterCurrentPage;
      }
    }

    let startIndex = (currentPage - 1) * this.pageSize;
    let endIndex = Math.min(startIndex + this.pageSize - 1, totalItems - 1);

    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: this.pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }

  public showAllToggle() {
    this.showAllLoading = true;
    this.ref.detectChanges();
    if (this.pageSize == this.initialPageSize) {
      this.pageSize = this.items.length;
      this.setPage(1);
      this.rePaginationButton = true;
      this.showAllButton = false;
    } else {
      this.pageSize = this.initialPageSize;
      this.setPage(1);
      this.rePaginationButton = false;
      this.showAllButton = true;
    }
  }
}
