import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { IProjectUser } from '../models/project-user.vm';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(
    private http: HttpClient,
    public jwtHelper: JwtHelperService,
    public auth: AuthService
  ) {}

  public getToken(username, password): Observable<any> {
    return this.http
      .get(this.nhsApiUrl + 'authentication', {
        headers: new HttpHeaders().append(
          'Authorization',
          'Basic ' + btoa(username + ':' + password)
        ),
      })
      .pipe(          
        tap((success: any) => {
          localStorage.setItem('adminUserToken', success.data.token);
        }),
        catchError((error) => {
          if (error.name === 'TimeoutError') {
            console.error('An error occurred:', error);
            return throwError(error.message);
          } else {
            console.error('An error occurred:', error);
            return throwError(error.error.error.message);
          }          
        })
      );
  }

  public isAuthenticated(): boolean {
    return this.auth.authenticated();
  }

  public getAdminUsers(): Observable<IProjectUser[]> {
    let token = localStorage.getItem('adminUserToken');
    return this.http
      .get<any>(this.nhsApiUrl + 'users/getUsers', {
        headers: new HttpHeaders().set('Authorization', token),
        params: new HttpParams().set('adminUsers', 'Y'),
      })
      .pipe(
        map((res) => {
          const users: IProjectUser[] = res.data.userList;
          return users;
        })
      );
  }

  public getUser(): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'users/details', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }
}
