import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IOrganisationRegistration } from 'src/app/core/models/organisation-registration.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { OrganisationRegistrationDetailsForm, OrganisationRegistrationTableColumns } from './organisation-registration-layouts';

@Component({
  selector: 'app-organisation-registration',
  templateUrl: './organisation-registration.component.html',
  styleUrls: ['./organisation-registration.component.scss'],
})
export class OrganisationRegistrationComponent implements OnChanges {

  @Input() public currentOrganisation: Organisation;
  @Input() currentYear: number;

  public selectedRegistration: IOrganisationRegistration = null;
  public layout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public organisationRegistrationTableColumns: Array<IDynamicTableColumn> = OrganisationRegistrationTableColumns;
  public organisationRegistrationDetailsForm: Array<IDynamicFormBlock> = OrganisationRegistrationDetailsForm;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private organisationService: OrganisationService
  ) {}

  ngOnChanges(): void {
    // Check for open registration
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Get registrations, if needed
    if (!this.currentOrganisation.registrations) {
      this.getOrganisationRegistrations(this.currentOrganisation.organisationId, this.currentYear);
    }
  }

  private getOrganisationRegistrations(organisationId: number, currentYear: number): void {
    this.organisationService.getOrganisationRegistration(organisationId, currentYear).subscribe(
        success => {
          if (success.data.projectRegistrations) {
            this.currentOrganisation.registrations = success.data.projectRegistrations.filter(reg => reg.registrationYear == +currentYear);
          } else {
            this.currentOrganisation.registrations = [];
          }

          // Open slider, if in params
          if (this.currentQueryParams.registration) {
            this.onRegistrationClick(this.currentQueryParams.registration);
          }
        },
        error => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public onRegistrationClick = (primaryKey: string): void => {
    this.selectedRegistration = this.currentOrganisation.registrations.find((reg) => reg.registrationId == +primaryKey);
    this.layout = new Layout(
      this.selectedRegistration.organisationName,
      [`ID: ${this.selectedRegistration.registrationId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { registration: primaryKey }, queryParamsHandling: 'merge', });
  };

  public closeRegistrationSlider = (): void => {
    this.selectedRegistration = null;
    this.router.navigate([], { queryParams: { registration: null }, queryParamsHandling: 'merge', });
  };

}
