import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

export const ValidationTableColumns: IDynamicTableColumn[] = [
  {
    header: 'Status',
    dataKey: 'status',
    percWidth: 12,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'status',
    insight: {
      title: "Possible States",
      noHeader: true,
      body: [
        {
          column1: 'Pending',
          column2: 'Validation query awaiting review',
          badge: '#F8F9FA'
        },
        {
          column1: 'Updated',
          column2: 'Value updated by member or NHSBN',
          badge: '#fef3c7'
        },
        {
          column1: 'Validated',
          column2: 'Member responded and value correct',
          badge: '#47754b',
          textColor: 'white'
        },
        {
          column1: 'Confirmed',
          column2: 'Member marked value as correct',
          badge: 'rgb(115, 207, 154)'
        },
        {
          column1: 'Accepted',
          column2: 'NHSBN accepted value without member response',
          badge: '#71b1f5'
        },
        {
          column1: 'Deleted',
          column2: 'Value deleted by member or NHSBN',
          badge: 'rgba(108, 117, 125, 0.2)'
        },
      ]
    }
  },
  {
    header: 'Report Name',
    dataKey: 'reportName',
    percWidth: 15,
    sortable: true,
    groupable: true,
    searchable: true,
    linkable: true,
  },
  {
    header: 'Organisation',
    dataKey: 'organisationName',
    percWidth: 0,
    sortable: false,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Submission',
    percWidth: 13,
    dataKey: 'submissionName',
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Code',
    dataKey: 'submissionCode',
    percWidth: 5,
    sortable: false,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Service',
    dataKey: 'serviceItemName',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: true,
  },
  {
    header: 'Option',
    dataKey: 'optionName',
    percWidth: 10,
    sortable: false,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Question',
    dataKey: 'displayQuestionText',
    percWidth: 13,
    sortable: false,
    groupable: true,
    searchable: true,
    linkKey: true,
  },
  {
    header: 'Response',
    dataKey: 'response',
    percWidth: 10,
    sortable: false,
    groupable: false,
    searchable: true,
  },
  {
    header: 'User Note',
    dataKey: 'userNotes',
    percWidth: 13,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'External Note',
    dataKey: 'validationDescription',
    percWidth: 13,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Internal Note',
    dataKey: 'notes',
    percWidth: 13,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Assignee',
    percWidth: 10,
    dataKey: 'validatorName',
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Actions',
    percWidth: 10,
    dataKey: '',
    sortable: false,
    groupable: false,
    searchable: false,
    exportable: false,
    options: ['clickable'],
    button: 'options',
    buttonType: 'validation',
    actions: [
      {
        icon: 'fas fa-lg fa-check-square mr-2',
        color: '#3c763d',
        action: 'validate',
        description: 'Toggle status: validated/pending',
      },
      {
        icon: 'fas fa-lg fa-user-check mr-2',
        color: '#71b1f5',
        action: 'accept',
        description: 'Toggle status: accepted/pending',
      },
      {
        icon: 'fas fa-lg fa-minus-square mr-2',
        color: '#a94442',
        action: 'delete',
        description: 'Delete validation',
      },
      {
        icon: 'fas fa-lg fa-pen-square mr-2',
        color: '#005eb8',
        action: 'edit',
        description: 'Edit validation',
      },
    ],
  },
  {
    header: 'Tier ID',
    dataKey: 'tierId',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Report ID',
    dataKey: 'reportId',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Submission ID',
    dataKey: 'submissionId',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Question ID',
    dataKey: 'questionId',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Question Part',
    dataKey: 'questionPart',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'External Note',
    dataKey: 'validationDescription',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Internal Note',
    dataKey: 'notes',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false
  },
  {
    header: 'Data Collection Link',
    dataKey: 'dataCollectionLink',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Data Collection Link',
    dataKey: 'outputsLink',
    percWidth: 0,
    sortable: false,
    groupable: false,
    searchable: false,
  },
];

export const ValidationDetailsFormBlocks: IDynamicFormBlock[] = [
  {
    blockType: 'divider',
  },
  {
    label: 'Metadata:',
    dataKey: 'validationMetadata',
    blockType: 'input',
    colWidth: 4,
    disabled: true
  },
  {
    label: 'Validation ID:',
    dataKey: 'validationId',
    blockType: 'input',
    colWidth: 4,
    disabled: true
  },
  {
    label: 'Status',
    dataKey: 'status',
    blockType: 'input',
    colWidth: 4,
    disabled: true
  },
  {
    blockType: 'divider',
  },
  {
    label: 'Internal note:',
    dataKey: 'notes',
    helpText: 'This is an internal note, only visible to admins and not users.',
    blockType: 'input',
    colWidth: 12,
  },
  {
    blockType: 'saveButton',
    dataKeys: [],
    label: 'Save',
  }
];

export const ValidationSliderTableColumns: IDynamicTableColumn[] = [
  {
    header: 'Question Heading',
    dataKey: 'headingText',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Question ID-Part and Text',
    dataKey: 'displayQuestionText',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Type',
    dataKey: 'parameterType',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Response',
    percWidth: 20,
    dataKey: 'response',
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Action',
    percWidth: 20,
    dataKey: '',
    sortable: false,
    groupable: false,
    searchable: false,
    options: ['clickable'],
    button: 'set',
    actions: [
      {
        key: 'responseId',
        icon: 'fas fa-2x fa-check-square mr-2',
        color: 'btn btn-success',
        action: 'set',
        description: 'Set',
      },
    ],
  },
];
