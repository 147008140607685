import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class DeskService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) {}

  // Date: 'YYYY-MM-DD'
  public getAllDeskBookings(date: string): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'deskBooking/getAllDeskBookings', {
      headers: new HttpHeaders().set('Authorization', this.auth.token()),
      params: new HttpParams().set('bookingDate', date),
    });
  }

  public getUpcomingDeskBookings(userId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'deskBooking/getUpcomingUserBookings',
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('userId', String(userId)),
      }
    );
  }

  // Status: 'Active' or 'Cancelled'
  public modifyBooking(bookingDetails: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'deskBooking/modifyBooking',
      bookingDetails,
      {
        headers: new HttpHeaders()
          .set('Authorization', this.auth.token())
          .set('Content-Type', 'application/json'),
      }
    );
  }

  // Session: 'AM', 'PM' or 'All'
  public createBooking(bookingDetails: any) {
    return this.http.post(
      this.nhsApiUrl + 'deskBooking/createBooking',
      bookingDetails,
      {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }
}
