    <!-- Loading and error -->
    <app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
    <app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
    
    <ng-template #notLoading>
    
        <!-- Table -->
        <div>
            <h4>User activity for last 30 days</h4>
        </div>
        <app-dynamic-table 
        *ngIf="userActivity"
        [data]="userActivity"
        [columns]="UsersActivityTableColumns"
        [tableTitle]="'userActivity'"
        [primaryKey]="'date'"
        [onRowClick]="onUserActivityClick"> 
      </app-dynamic-table>
            <!-- Selected Activity -->
            <app-slider-panel *ngIf="currentUserActivity" [onClose]="closeUserActivitySlider">
                <!-- Heading -->
                <app-area-heading [heading]="activityLayout.heading" [subheading]="activityLayout.subheading" [backRoute]="activityLayout.backRoute"></app-area-heading>
                <!-- Form -->
                <app-dynamic-form [data]="currentUserActivity" [blocks]="userActivityDetailsForm"></app-dynamic-form>
                <!-- JSON viewer -->
                <app-json-viewer [jsonData]="currentUserActivity.parameters"></app-json-viewer>
            </app-slider-panel>
    
