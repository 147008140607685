<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>
  <div class="container-fluid" *ngIf="{ darkMode: darkMode$ | async } as ui">
   
    <div class="row pt-5 justify-content-center" [ngClass]="{'text-dark-600': ui.darkMode}">
      <div class="col-md-11 col-12">
        <div class="row">
          <div class="form-group col-3">
            <h2 [ngClass]="{'text-primary-light': ui.darkMode}">Desks</h2>
            <h6 [class.text-light]="ui.darkMode" class="text-secondary mb-4">
              Book a desk at Benchmark
            </h6>
            <h5 [class.text-light]="ui.darkMode">Select a day:</h5>
            <input type="date" id="date" [min]="currentDate | date:'yyyy-MM-dd'" class="form-control mb-2" [(ngModel)]="date" (change)="getBookings(date)">
          </div>

          <div class="offset-4" *ngIf="upcoming.length === 0">
            <h4 [ngClass]="{ 'text-primary-light': ui.darkMode }">
              You have no desks booked yet
            </h4>
            <h6 [class.text-light]="ui.darkMode" class="text-secondary mb-4">
              Your bookings will appear here
            </h6>
          </div>
          <div class="col-6 offset-3" *ngIf="upcoming.length">
            <h5 [class.text-light]="ui.darkMode">
              Your next {{ upcoming.length }}
              {{ "booking" | makePluralString : upcoming.length }}:
            </h5>
            <app-dynamic-table
              class="table"
              [ngClass]="{ 'table-dark': ui.darkMode,'pagination-dark': ui.darkMode }"
              [data]="upcoming"
              [columns]="deskBookingsColumns"
              [hideExportButton]="true"
              [hideSearch]="true"
              [onTdClick]="onTableTdClick"
              [pageSize]="3"
              [disableRowClick]="true"
              [primaryKey]="'bookingID'"
              >          
            </app-dynamic-table>
          </div>
        </div>

        <ng-container *ngIf="date">
          <hr class="my-4" [ngClass]="{'border-dark': ui.darkMode}">
          <h4 [class.text-light]="ui.darkMode" class="mb-3">
            Showing availability for {{ date | date: 'EEEE d MMMM yyyy' }}
          </h4>
          <div [class.text-light]="ui.darkMode" class="small mb-3">
              <span class="mr-3"><i class="fas fa-circle mr-2" style="color:#17A2B8"></i>Full availability</span>
              <span class="mr-3"><i class="fas fa-circle mr-2" style="color:#8BD0DB"></i>Partial availability</span>
              <span class="mr-3"><i class="fas fa-circle mr-2" style="color:#F3F3F3"></i>No availability</span>
          </div>

          <div class="row mt-5">
            <div class="col-md-9">
              <div class="row">
                <div class="col-md-12">
                  <!-- Floorplan -->
                  <app-floorplan
                    [desks]="desks"
                    [bookings]="bookings"
                    [deskClicked]="deskClicked?.deskReferences"
                    (emitDeskHover)="deskHover($event)"
                    (emitDeskClick)="deskClick($event)">
                  </app-floorplan>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <hr class="mt-5 mb-5">
                  <!-- Table -->
                  <app-dynamic-table
                    *ngIf="desks"
                    [data]="desks"
                    [columns]="deskTableColumns"
                    [tableTitle]="'Desks'"
                    [primaryKey]="'deskId'"
                    [hideExportButton]="true"
                    [onRowClick]="onRowClick"
                    [defaultGroupedColumn]="'bookingDate'"
                  >
                  </app-dynamic-table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="sticky-top pt-custom">
                <!-- No selection-->
                <ng-container *ngIf="!deskClicked">
                  <div class="alert alert-info">
                    <p class="font-weight-bold">
                      <i class="fas fa-info-circle mr-2"></i>How to Book
                    </p>
                    <p>
                      Click on a desk on the floor plan to view the desk's details and availability for the selected day, or scroll down to use the table view.
                    </p>
                  </div>
                </ng-container>
                <!-- Panel -->
                <app-panel
                  *ngIf="deskClicked"
                  [deskClicked]="deskClicked"
                  [currentUser]="currentUser"
                  [date]="date"
                  (emitCreateBooking)="createBooking($event.desk, $event.session, $event.reference)"
                  (emitCancelBooking)="cancelBooking($event.booking, $event.status)"
                  (emitCancelDesk)="deskClicked = $event; deskPicked = false">
                </app-panel>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>