import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-year-select',
  templateUrl: './year-select.component.html',
  styleUrls: ['./year-select.component.scss']
})
export class YearSelectComponent implements OnChanges {

  @Input() public yearList?: Array<any>;
  @Input() public yearDisabled?: boolean = false;
  @Output() public emitCurrentYear = new EventEmitter<number>();

  public currentYear: number;

  constructor(
    private route: ActivatedRoute,
    public tooltipConfig: NgbTooltipConfig
  ) {
    // Set current year from params
    this.route.params.subscribe(params => {
      this.currentYear = +params.year || Constants.currentYear;
    });
    // Set a default list if no @Input
    if (!this.yearList) {
      this.yearList = this.getDefaultYearList();
    }

    tooltipConfig.openDelay = 750
  }

  ngOnChanges(): void { }

  public getDefaultYearList(): Array<string> {
    this.yearList = [];
    for (var i = 2013; i <= new Date().getFullYear() + 1; i++) {
      this.yearList.push(i);
    }
    return this.yearList.reverse();
  }

  public selectYear(year: number): void {
    if(year !== this.currentYear){
      this.currentYear = year;
      this.emitCurrentYear.emit(year);
    }
  }

}
