import { formatDate } from "@angular/common";

import { Constants } from "../constants/constants";
import { IProjectDate } from "../models/project-date.vm";
import { IProjectGuidance } from "../models/project-guidance.vm";
import { IProjectQuestion } from "../models/project-question.vm";
import { IProjectDocumentsDynamic } from "../models/project-documents-dynamic-vm";
import { IProjectDocumentsStatic } from "../models/project-documents-static-vm";
import { IProjectRegistration } from "../models/project-registration.vm";
import { IProjectRole } from "../models/project-role.vm";
import { IProjectServiceItem } from "../models/project-service-item.vm";
import { IProjectSubmission } from "../models/project-submission.vm";
import { IProjectSurvey } from "../models/project-survey.vm";
import { IProjectResponse } from "../models/project-response.vm";
import { IProjectTier } from "../models/project-tier.vm";
import { IProjectReport } from "../models/project-report.vm";
import { IProjectValidation } from "../models/project-validation.vm";
import { IProjectPeerGroup } from "../models/project-peer-group";
import { IProjectDashboard } from "../models/project-dashboard-vm";
import { IProject } from "../models/project.vm";
import { Deserialisable } from "./deserialisable.model";
import { Lookup } from "./lookup";
import Utils from "../helpers/utils";
import moment from "moment";
import { IProjectDataUpload } from "../models/project-data-upload-vm";
import { IDeserialise } from "../models/deserialise.vm";

export class Project implements IProject, Deserialisable {

    public accessRequiresRole: string;
    public awsDocumentsDynamic: Array<IProjectDocumentsDynamic>;
    public awsDocumentsStatic: Array<IProjectDocumentsStatic>;
    public categoryDisplaySequence: number;
    public categoryId: number;
    public categoryName: string;
    public categoryColor: string;
    public cnrLevelSpecDocumentId: number;
    public collectionFrequency: string;
    public commissionerProvider: string;
    public dataCollectionOnline: string;
    public dataCollectionOpen: string;
    public dynamicDocumentCount: number;
    public externalSurveyCount: number;
    public hasOrganisationQuestions: string;
    public individualAccess: string;
    public isVisible: string;
    public orgLevelSpecDocumentId: number;
    public outputsAvailable: string;
    public outputsOnline: string;
    public productId: number;
    public productName: string;
    public projectCode: string;
    public projectDates: Array<IProjectDate>;
    public projectDescription: string;
    public projectId: number;
    public projectIntro: string;
    public projectName: string;
    public projectPhoneNo: string;
    public projectRoles: Array<IProjectRole>;
    public projectUrl: string;
    public registrationAvailable: string;
    public registrationCount: number;
    public registrationTypeId: number;
    public responseCount: number;
    public specDocumentId: number;
    public specificationPdf: string;
    public staticDocumentCount: number;
    public submissionCount: number;
    public supportText: string;
    public questionGroupId: number;
    public questionGroupLevelId: number;
    public peerGroups: Array<IProjectPeerGroup>;
    public serviceItems: Array<IProjectServiceItem>;
    public registrations: Array<IProjectRegistration>;
    public submissions: Array<IProjectSubmission>;
    public questions: Array<IProjectQuestion>;
    public guidance: Array<IProjectGuidance>;
    public surveys: Array<IProjectSurvey>;

    public responses: Array<IProjectResponse>;
    public tiers: Array<IProjectTier>;
    public validations: Array<IProjectValidation>;
    public dashboard: Array<IProjectDashboard>
    public dataUpload: Array<IProjectDataUpload>

    constructor(data: IDeserialise) {
        this.deserialise(data);
    }

    deserialise(input: IDeserialise): this {
        for (const [key, value] of Object.entries(input)) {
            let lookup = Utils.checkForLookup(value);
            if (lookup) {
                // input[key + 'Raw'] = lookup;
                // input[key] = lookup.description;
                //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
                let keyRaw= key + 'Raw'
                Object.defineProperty(input, keyRaw, {
                    value: lookup,
                    enumerable: true,
                    writable: true
                  });
                Object.defineProperty(input, key, {
                    value: lookup.description,
                    enumerable: true,
                    writable: true
                  });
                ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            }
        }
        Object.assign(this, input);
        return this;
    }

    // BGS: Pretty sure this is never used...
    //      the field it set is never referenced either...

    // private setCollectionFrequency(projectId: number): string {
    //     // Projects that have both monthly and annual data collections (ATD, CI, SDEC)
    //     const monthlyAnnual: Array<number> = [4, 5, 6, 29, 31, 35, 38, 48];
    //     // Projects that have only monthly data collections (COVID-19)
    //     const monthlyOnly: Array<number> = [41, 42];
    //     // Projects that have no defined data collections (ICS)
    //     const mixed: Array<number> = [39];
    //     // Set collection frequency
    //     if (monthlyAnnual.includes(projectId)) {
    //         return 'Monthly, Annual';
    //     }
    //     else if (monthlyOnly.includes(projectId)) {
    //         return 'Monthly'
    //     }
    //     else {
    //         return 'Annual'
    //     }
    // }

    public setRegistrationLocked(registrations: Array<IProjectRegistration>): Array<IProjectRegistration> {
        registrations.forEach(reg => {
            if (reg.registrationLocked == null) { reg.registrationLocked = 'N' }
                reg.deputyProjectLeadName =  reg.deputyProjectLeads.map(value => value.name).join(" - ");
                reg.deputyProjectLeadId =  reg.deputyProjectLeads.map(value => value.id).join("-");
                reg.deputyProjectLeadEmail =  reg.deputyProjectLeads.map(value => value.email).join("; ");
        })

        return registrations;
    }

    public setExternalSurveySettings(surveys: Array<IProjectSurvey>): Array<IProjectSurvey> {
        try {
            surveys.forEach(survey => {         
                survey.surveyIntroShort = survey.settingList.find(setting => setting.settingName == 'surveyIntroShort').settingValue || null;
                survey.surveyIntro = survey.settingList.find(setting => setting.settingName == 'surveyIntro').settingValue || null;
                survey.surveyIntroShortSettingId = survey.settingList.find(setting => setting.settingName == 'surveyIntroShort').settingId || null;
                survey.surveyIntroSettingId = survey.settingList.find(setting => setting.settingName == 'surveyIntro').settingId || null;
                survey.maxResponsesSettingId = +survey.settingList.find(setting => setting.settingName == 'maxResponses').settingId || null;
                survey.maxResponses = +survey.settingList.find(setting => setting.settingName == 'maxResponses').settingValue || null;
                survey.translatedSurveyIntroShort = survey.settingList.find(setting => setting.settingName == 'surveyIntroShort_welsh').settingValue || null;
                survey.translatedSurveyIntro = survey.settingList.find(setting => setting.settingName == 'surveyIntro_welsh').settingValue || null;
                survey.surveyOpenDate = moment(survey.surveyOpenDate).format('LLL')
            })
        } catch (error) {
            console.log(error)
        }
        return surveys;
    }

    public setCaseNoteCodes(submission: IProjectSubmission, submissionCaseNoteDetails: any): IProjectSubmission {
        submission.crcCheck = submissionCaseNoteDetails.crcCheck;
        submission.caseNoteCodes = this.setCaseCodeNoteType(submissionCaseNoteDetails.caseNoteCodes);
        return submission;
    }

    private setCaseCodeNoteType(caseCodes: any): any {
        caseCodes.forEach(code => {
            let codeType = code.caseCode.substring(0,2);
            if (Number.isNaN(Number(codeType))) {
                code.caseCodeType = codeType;
            } else {
                code.caseCodeType = 'CNR';
            }
        })
        return caseCodes;
    }

    public setSubmissions(submissions: Array<IProjectSubmission>): Array<IProjectSubmission> {
        submissions.forEach(sub => {
            if (sub.submissionLevel) {
                sub.submissionName = sub.submissionName + ' (' + sub.submissionLevel + ')';
            }
        });
        return submissions;
    }

    public setQuestions(questions: Array<IProjectQuestion>): Array<IProjectQuestion> {
        questions.forEach(question => {
            const questionType = Constants.questionTypes.find(constant => constant.id == question.questionType);
            const formatModifier = Constants.questionFormatModifiers.find(constant => constant.id == question.formatModifier);

            question.questionGroupWithLevel = question.questionGroupLevelDescription + ' - ' + question.questionGroupName;
            question.questionTypeName = questionType ? questionType.name : question.questionType;
            question.formatModifierName = formatModifier ? formatModifier.name : question.formatModifier;
        });
        return questions;
    }

    public setResponses(responses: Array<IProjectResponse>): Array<IProjectResponse> {
        responses.forEach(response => {
            // Update question title with subQuestionText
            if (response.subQuestionText) {
                response.questionText = response.questionText + ' - ' + response.subQuestionText;
            }
            // Define most appropriate question type (parent or child)
            if (response.subQuestionType) { 
                response.questionType = response.subQuestionType
            };


            if (!response.responseType) {
                return;
            }

            // Select correct response
            switch(response.responseType.trim()) {
                case 'boolean':
                    break;
                case 'date':
                    response.response = formatDate(response.responseDt, 'dd/MM/yy', 'en-GB');
                    break;
                case 'list':
                    response.response = response.itemName;
                    break;
                case 'number':
                    response.response = response.responseNum;
                    break;
                case 'radio':
                    break;
                case 'text':
                    response.response = response.responseTxt;
                    break;
                case 'time':
                    response.response = formatDate(response.responseDt, 'h:mm a', 'en-GB');
                    break;
                case 'yn':
                    response.response = response.responseYn;
                    break;
            }
        });
        return responses;
    }

    public setTiers(tiers: Array<IProjectTier>): Array<IProjectTier> {

        let flatTiers = [];

        tiers.forEach(tier => { callRecursively(tier, 0) });

        function callRecursively(tier: IProjectTier, level: number) {
            flatTiers.push(tier);
            if (tier.children) {
                level = level + 1;
                let icon = tier.reportId ? 'fa-chart-bar' : 'fa-layer-group';
                tier.type = tier.reportId ? 'Report Tier' : 'Parent Tier';
                tier.displayName = `
                    <span class="pad-left-${ level } ${ tier.type == 'Parent Tier' ? 'font-weight-bold' : '' }">
                        <i class="fas ${ icon } mr-2 text-secondary"></i>${ tier.name }
                    </span>
                `
                tier.children.forEach(child => {
                    callRecursively(child, level);
                });
            }
        }

        return flatTiers;
    }

    public setReportDetails(reportDetails: IProjectReport, currentYear: number): IProjectReport {

        // Get parameters for the selected year

        //  let currentYearParameters = reportDetails.parameterYears[currentYear].options || null;
        //////////////////////////////////////////////////////////////////////////////////////////////////  FIX ///////////////////////////////////
        let { [currentYear]: temp } = reportDetails.parameterYears;
        let currentYearParameters = temp.options || null;
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        // Artificially set a single option if none exist
        let options = reportDetails.denominators.length > 0 ? reportDetails.denominators : [{ optionId: 0, optionName: 'N/A', seriesCount: null, series: null }];


        // Add series parameters to each option
        options.forEach((opt, i) => {
            // opt.seriesCount = Object.keys(currentYearParameters[i].series).length;
            // opt.series = Object.values(currentYearParameters[i].series);      
        //////////////////////////////////////////////////////////////////////////////////////////////////  FIX ///////////////////////////////////
            let { [i]: currentYearParametersIndex } = currentYearParameters;
            opt.seriesCount = Object.keys(currentYearParametersIndex.series).length;
            opt.series = Object.values(currentYearParametersIndex.series);     
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        });

        // Remove denominators and parameter years
        delete reportDetails.denominators;
        delete reportDetails.parameterYears;

        // Add combined options to reportDetails
        reportDetails.options = options;

        return reportDetails;
    }

    public setValidations(validations: Array<IProjectValidation>): Array<IProjectValidation> {

        validations.forEach(vld => {
            // Validation status 'Pending' if created after last update (or last update does not exist)
            if (vld.status !== 'validated' && (vld.createdAt > vld.lastUpdated || !vld.lastUpdated)) {
                vld.status = 'pending';
            };
            // Validation status 'Updated' if updated since it was created
            if (vld.status !== 'validated' && vld.createdAt <= vld.lastUpdated) {
                vld.status = 'updated';
            };
            // Clarify date/time format
            vld.createdAt = formatDate(vld.createdAt, 'dd/MM/yy, h:mm a', 'en-GB');
            vld.lastUpdated = formatDate(vld.lastUpdated, 'dd/MM/yy, h:mm a', 'en-GB');
        });

        return validations;
    }

}