import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectService } from '../core/services/project.service';
import { LoaderService } from '../core/services/loader.service';

import { INavItem } from '../core/models/nav-item.vm';
import { IProject } from '../core/models/project.vm';
import { ILayout } from '../core/models/layout.vm';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';

import { Constants } from '../core/constants/constants';
import { ProjectNavItems, ProjectTableColumns, ProjectTableFilters } from './project-layouts';

import { Project } from '../core/classes/project';
import { Layout } from '../core/classes/layout';
import { IDynamicPresetFilter } from '../core/models/dynamic-table-filter.vm';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent {

  public layout: ILayout;

  public projectNavItems: Array<INavItem> = ProjectNavItems;
  public projectTableColumns: Array<IDynamicTableColumn> = ProjectTableColumns;

  public presetFilters: Array<IDynamicPresetFilter> = ProjectTableFilters;

  public projectList: Array<Project> = new Array<Project>();

  public currentUrlParams: any;
  public currentYear: number;
  public currentProject: IProject = null;
  public currentSection: string = undefined;

  public loadingError: string = null;

  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.route.params.subscribe(params => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  private routeChange(params: any): void {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    this.currentYear = params.year || Constants.currentYear;
    this.currentSection = params.section;
    // No project selected, return to list
    if (this.currentSection == undefined && this.projectList.length == 0) {
      this.getProjectList(this.currentYear);
    }
    // Project selected, get details and show section
    if (this.currentSection && !this.currentProject) {
      this.getProjectDetails(params.id, this.currentYear);
    }
  }

  public yearChange(year: number): void {
    if (this.currentProject) {
      this.currentProject = null;
      this.router.navigate(['/project', year, this.currentUrlParams.id, this.currentUrlParams.section]);
    } else {
      this.projectList = new Array<Project>();
      this.router.navigate(['/project', year]);
    }
    
  }

  public onTableRowClick = (primaryKey: string): void => {
    this.router.navigate(['project', this.currentYear, primaryKey, 'details']);
  }

  private getProjectList(year: number): void {
    this.currentProject = null;
    this.projectService.getProjects(year).subscribe(
      success => { 
        success.data.projectList.forEach(project => 
          this.projectList.push(new Project(project))
        );
       // replace all the 'null'-'undefined'-'' values with '—', in order to make the sort funcionality in the dynamic table work correctly
       this.projectList.forEach((obj) => {
        Object.keys(obj).forEach((key) => {
          let { [key as keyof typeof obj]: objKey } = obj;
          if (objKey === null || objKey === undefined || objKey === '' || objKey === ' ') {
            Object.defineProperty(obj, key, {
              value: '—',
              enumerable: true,
              writable: true
            });
          }
        });
      });
      //
        this.layout = new Layout('Projects', null, null);
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private getProjectDetails(projectId: number, year: number): void {
    this.projectService.getProject(projectId, year).subscribe(
      success => { 
        this.currentProject = new Project(success.data.projectList[0]);
        this.layout = new Layout(
          this.currentProject.projectName,
          [ `ID: ${this.currentProject.projectId}`, `Category: ${this.currentProject.categoryName}` ],
          `/project/${this.currentYear}`
        );
        // Reset session storage items
        sessionStorage.removeItem('services');
        sessionStorage.removeItem('projectReports');
        sessionStorage.setItem('currentProjectId', this.currentProject.projectId.toString());
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

}
