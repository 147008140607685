<div class="container-fluid d-flex justify-content-center align-items-center vh-100" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="col-lg-3 col-md-4 col-sm-6 col-xs-10 col-12">
  
      <div>
        <h3 class="font-weight-bold" [ngClass]="{'text-primary': !ui.darkMode, 'text-primary-light': ui.darkMode}">
          Admin
        </h3>
        <p class="text-secondary">Login using your normal Members' Area credentials.</p>
      </div>
  
      <div class="card" [ngClass]="{'bg-dark text-dark-600': ui.darkMode}">
        <div class="card-body">
          <form [formGroup]="loginForm" id="loginForm">
            <div class="form-group">
              <label for="username">Username</label>
              <input formControlName="username" type="text" class="form-control" [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}">
            </div>
            <div class="form-group">
              <label for="username">Password</label>
              <input formControlName="password" type="password" class="form-control" [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}">
            </div>
            <button class="btn btn-success" (click)="login()" [disabled]="loginLoading">
              <span *ngIf="loginLoading" >Logging in...</span>
              <span *ngIf="!loginLoading" >Login</span>
            </button>
          </form>
          <br>
          <div *ngIf="loginError" class="alert alert-danger" role="alert">
            {{ loginError }}
          </div>
        </div>
      </div>
  
  </div>
</div>