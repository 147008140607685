import { Component, Input, OnChanges } from '@angular/core';
import { Project } from 'src/app/core/classes/project';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectService } from 'src/app/core/services/project.service';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IProjectRegistration } from 'src/app/core/models/project-registration.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

import {
  RegistrationDeputyProjectLeadsTable,
  RegistrationDetailsForm,
  RegistrationSubmissionTable,
  RegistrationTableColumns,
} from './project-registration-layouts';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import { LoaderService } from 'src/app/core/services/loader.service';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { IProjectSubmission } from 'src/app/core/models/project-submission.vm';

@Component({
  selector: 'app-project-registration',
  templateUrl: './project-registration.component.html',
  styleUrls: ['./project-registration.component.scss'],
})
export class ProjectRegistrationComponent implements OnChanges {
  @Input() currentProject: Project;
  @Input() currentYear: number;

  private currentQueryParams: any;

  public layout: ILayout;
  public registrationTableColumns: Array<IDynamicTableColumn> = RegistrationTableColumns;
  public registrationSubmissionTable: Array<IDynamicTableColumn> = RegistrationSubmissionTable;
  public registrationDeputyProjectLeadTable: Array<IDynamicTableColumn> = RegistrationDeputyProjectLeadsTable;
  public registrationDetailsFormBlocks: Array<IDynamicFormBlock> =  RegistrationDetailsForm;
  public selectedRegistration: IProjectRegistration = null;
  public loadingError: string;

  darkMode$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public loaderService: LoaderService,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnChanges(): void {
    // Check for open registration
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Get registrations, if needed
    if (!this.currentProject.registrations) {
      this.getProjectRegistrations(
        this.currentProject.projectId,
        this.currentYear
      );
    }
  }

  private getProjectRegistrations(
    projectId: number,
    currentYear: number
  ): void {
    this.projectService
      .getProjectRegistrations(projectId, currentYear)
      .subscribe(
        (success) => {
          this.currentProject.registrations =
            this.currentProject.setRegistrationLocked(
              success.data.projectRegistrations
            );
          // Open selected, if in params
          if (this.currentQueryParams.registration) {
            this.onRegistrationClick(this.currentQueryParams.registration);
          }
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public onRegistrationClick = (primaryKey: string): void => {
    this.selectedRegistration = this.currentProject.registrations.find(
      (reg) => reg.registrationId == +primaryKey
    );
    this.layout = new Layout(
      this.selectedRegistration.organisationName,
      [
        `ID: ${this.selectedRegistration.registrationId}`,
        `Date: ${this.formatDateTime(
          this.selectedRegistration.registrationDateTime
        )}`,
      ],
      null
    );
    this.router.navigate([], {
      queryParams: { registration: primaryKey },
      queryParamsHandling: 'merge',
    });
    this.getRegistrationSubmission(
      this.selectedRegistration.projectId,
      this.currentYear,
      this.selectedRegistration.registrationId
    );
  };

  private formatDateTime(timestring: string): string {
    let dt = new Date(timestring);
    return dt.toLocaleDateString();
  }

  public closeRegistrationSlider = (): void => {
    this.selectedRegistration = null;
    this.router.navigate([], {
      queryParams: { registration: null },
      queryParamsHandling: 'merge',
    });
  };

  private getRegistrationSubmission(
    projectId: number,
    year: number,
    registrationId: number
  ): void {
    this.projectService
      .getProjectSubmissions(projectId, year, registrationId)
      .subscribe(
        (success) => {         
          // this.selectedRegistration.submissions = success.data.submissionList[this.currentYear];
          ////////////////////////////////////////////////////////////////////////////////////////////////////   FIX    ///////////////////////////////////////
          let { [this.currentYear]: currentYearSubmissions } = success.data.submissionList;
          this.selectedRegistration.submissions = currentYearSubmissions
          /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////          
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }
}
