export class ValidationProject {

    public projectName: string;
    public tiers: any;
    public validations: any;
    public rawValidations: any;
    public notes: any;
    public validationDescription: any;
    public filters: any = [
        { name: 'Status', key: 'status', options: [], selected: null },
        { name: 'Report name', key: 'reportName', options: [], selected: null },
        { name: 'Submission name', key: 'submissionName', options: [], selected: null },
        { name: 'Service', key: 'serviceItemName', options: [], selected: null },
        { name: 'Assignee', key: 'validatorName', options: [], selected: null },
        { name: 'Organisation', key: 'organisationName', options: [], selected: null }
    ]
    public parameters: any = [
        { id: 'N', desc: 'Numerator: Value included in sum of numerator parameters' },
        { id: 'D', desc: 'Denominator: Value included in sum of denominator parameters' },
        { id: 'F', desc: 'Factor: Multiply numerator by value' },
        { id: 'U', desc: 'Vacancy rate numerator: Decrease numerator by percentage of value' },
        { id: 'V', desc: 'Vacancy rate denominator: Decrease denominator by percentage of value' }
    ]

    constructor(data: any) {
        this.mapValidationProject(data);
    }

    private mapValidationProject(data: any): void {
        this.projectName = data.projectName;
        this.tiers = this.setTiers(data.tiers);
        this.validations = null;
        this.notes = null;
        this.validationDescription = null;
    }

    public setTiers(tiers: any): any {

        let flatTiers = [];
        
        tiers.forEach(tier => { callRecursively(tier, 0) });


        function callRecursively(tier: any, level: number) {
            flatTiers.push(tier);
            if (tier.children) {
                level = level + 1;
                let icon = tier.reportId ? 'fa-chart-bar' : 'fa-layer-group';
                tier.type = tier.reportId ? 'Report Tier' : 'Parent Tier';
                tier.collapsed = false;
                tier.displayName = `
                    <div class="pad-left-${ level } ${ tier.type == 'Parent Tier' ? 'font-weight-bold' : '' }">
                        <div class="d-inline-block mr-2">
                            <i class="fas ${ icon } text-muted"></i></div>
                        <div class="d-inline-block">
                            ${ tier.name }
                        </div>
                    </div>
                `
                tier.children.forEach(child => {
                    callRecursively(child, level);
                });
            }
        }

        return flatTiers;
    }

    public toggleChildren(children: any, collapse?: boolean): any {
        
        children.forEach(child => {
            child.collapsed = !child.collapsed;
            callRecursively(child, child.collapsed)
        });

        function callRecursively(tier: any, status: boolean) {
            if (tier.children && tier.children.length > 0) {
                tier.children.forEach(child => {
                    child.collapsed = status;
                    callRecursively(child, status);
                });
            }
        }

        return children;
    }

    public setReportDetails(details: any, selectedOptionId: number, year: number): any {
        // Replace option title
        let selectedOption = details.options.denominators.find(den => den.optionId == selectedOptionId);
        if (selectedOption) {
            details.reportName = this.replaceOptionTitle(details.reportName, selectedOption.titleOptionName);
        }
        // Replace placeholder
        details.reportName = this.replacePlaceholder(details.reportName, year);
        return details;
    }

    public chartTypeCheck(chartType: any): boolean {
        let allowedChartTypes = ['B1','B2','C1','C2', 'DB'];
        // disallowedChartTypes: YN/TYN, PI, RD, L1, DB, P1, D2, SB
        if (allowedChartTypes.includes(chartType.viewType)) {
            return true;
        } else {
            return false;
        }
    }

    public setValidationsToTiers(validations: any, projectId: number, year: number): void {
        validations.forEach(vld => {
            // Set tier
            vld.tier = this.tiers.find(t => t.id == vld.tierId);
            // Replace question text placeholders
            let displayQuestionText = vld.nestedQuestionText ? `${ vld.questionText } - ${ vld.nestedQuestionText }` : vld.questionText;
            vld.displayQuestionText = this.replacePlaceholder(displayQuestionText, year);
            // Replace option title
            if (vld.titleOptionName) {
                vld.reportName = this.replaceOptionTitle(vld.reportName, vld.titleOptionName);
            }         
            vld.reportName = this.replacePlaceholder(vld.reportName, year);
            // Strip submission code to three numbers
            vld.submissionCode = ('000' + vld.submissionCode).slice(-3);
            // Add status depending on response updates
            if ((vld.status !== 'validated' && vld.status !== 'confirmed' && vld.status !== 'accepted') && (vld.createdAt > vld.lastUpdated || !vld.lastUpdated)) { vld.status = 'pending' };
            if (vld.status !== 'validated' && (vld.questionId && vld.createdAt < vld.lastUpdated)) { vld.status = 'updated' };
            if (vld.responseDeleted == 'Y') { vld.status = 'deleted' }
            // Ensure validation value is a number
            vld.validationValue = +vld.validationValue;
            // Create data collection link
            if (vld.questionGroupId && vld.serviceItemId > 0) {
                vld.dataCollectionLink = 'https://members.nhsbenchmarking.nhs.uk/data-collection/' 
                + vld.questionGroupLevel + '/' 
                + vld.submissionId + '/' 
                + vld.validationYear + '/' 
                + vld.serviceItemId + '?group=' 
                + vld.questionGroupId;
             } else if (vld.questionGroupId && vld.serviceItemId == 0) {
                vld.dataCollectionLink = 'https://members.nhsbenchmarking.nhs.uk/data-collection/' 
                + vld.questionGroupLevel + '/' 
                + vld.submissionId + '/'
                + vld.validationYear + '?group=' 
                + vld.questionGroupId;
            } else {
                vld.dataCollectionLink = null;
            }
            // Create outputs link
            vld.outputsLink = 'https://members.nhsbenchmarking.nhs.uk/outputs/' + projectId + '?tier=' + vld.tierId + '&option=' + vld.optionId
        });
        // Set all validations
        this.validations = validations;
        this.rawValidations = validations;
        // Add validation count to tiers
        this.tiers.forEach(tier => {
            tier.validations = validations.filter(v => v.tierId == tier.id);
            tier.pendingValidationsCount = validations.filter(v => v.tierId == tier.id && v.status == 'pending').length;
        })
    }

    public setValidationsToData(validations: any, data: any, chartType: string, selectedOption): any {
        data.forEach(point => {
            let validation = validations.find(vld => vld.submissionId == point.submissionId);
            let duplicateValidation = validations.find(vld => vld.submissionId == point.submissionId && vld.validationValue == point.response1);
            point.y = point.response1;
            point.status = validation ? (duplicateValidation ? duplicateValidation.status : null) : null;
            point.status = validation?.optionId == selectedOption ? validation.status : point.status;
            switch (point.status) {
                case 'pending':
                    point.color = '#005EB8'
                    break;
                case 'updated':
                    point.color = '#fef3c7'
                    break;
                case 'validated':
                    point.color = 'green'
                    break;
                case 'deleted':
                    point.color = 'darkred'
                    break;
                case 'confirmed':
                    point.color = 'rgb(115, 207, 154)'
                    break;
                case 'accepted':
                    point.color = '#71b1f5'
                    break;
                default:
                    point.color = null
            }
        });
        return data.filter(d => d.response1 !== null);
    }

    public setFilters(validations: any): void {
        this.filters.forEach(filter => {
            filter.options = validations.map(vld => vld[filter.key])
                                        .filter((value, index, self) => self.indexOf(value) === index)
                                        .sort((a, b) => { return a - b });
        });
    }

    public setAvailableResponses(responses: any, year: number, validation: any, responsePosted: boolean): any {
        responses.forEach(resp => {
            let displayQuestionText;
            if (resp.nestedQuestionText) {
                displayQuestionText = `${ resp.questionText } | ${ resp.nestedQuestionText }`
                // Add heading text, if available
                if (resp.nestedQuestionHeadingText) { displayQuestionText = `${ resp.questionText } | ${ resp.nestedQuestionText } | ${ resp.nestedQuestionHeadingText }` }
            } else {
                displayQuestionText = resp.questionText;
            }
            resp.displayQuestionText = this.replacePlaceholder(displayQuestionText, year);
            resp.validationId = validation.validationId;
            resp.submissionId = validation.submissionId;
            resp.parameterDesc = this.parameters.find(param => param.id == resp.parameterType).desc;
            if (validation.questionId && resp.questionId == validation.questionId && resp.questionPart == validation.questionPart) {
                resp.set = true;
            } else {
                resp.set = false;
            }
        });
        return responses;
    }

    private replacePlaceholder(text, year): string {
        if (text == null) { return text; }
    
        if (text.indexOf("|SINGLE_YEAR_CURRENT|") > 0) {
            text = text.replace(/\*\|SINGLE_YEAR_CURRENT\|\*/g, year.toString());
        }
        if (text.indexOf("|SINGLE_YEAR_NEXT|") > 0) {
            text = text.replace(/\*\|SINGLE_YEAR_NEXT\|\*/g, (year + 1).toString());
        }
        if (text.indexOf("|SINGLE_YEAR_PREVIOUS|") > 0) {
            text = text.replace(/\*\|SINGLE_YEAR_PREVIOUS\|\*/g, (year - 1).toString());
        }
        if (text.indexOf("|SINGLE_YEAR_MINUS_2|") > 0) {
            text = text.replace(/\*\|SINGLE_YEAR_MINUS_2\|\*/g, (year - 2).toString());
        }
        if (text.indexOf("|SINGLE_YEAR_MINUS_3|") > 0) {
            text = text.replace(/\*\|SINGLE_YEAR_MINUS_3\|\*/g, (year - 3).toString());
        }
        if (text.indexOf("|DOUBLE_YEAR_NEXT|") > 0) {
            text = text.replace(/\*\|DOUBLE_YEAR_NEXT\|\*/g, (year).toString() + "/" + (year + 1).toString().substring(2, 4));
        } 
        if (text.indexOf("|DOUBLE_YEAR_CURRENT|") > 0) {
            text = text.replace(/\*\|DOUBLE_YEAR_CURRENT\|\*/g, (year - 1).toString() + "/" + (year).toString().substring(2, 4));
        }    
        if (text.indexOf("|DOUBLE_YEAR_PREVIOUS|") > 0) {
            text = text.replace(/\*\|DOUBLE_YEAR_PREVIOUS\|\*/g, (year - 2).toString() + "/" + (year - 1).toString().substring(2, 4));
        }
        if (text.indexOf("|DOUBLE_YEAR_MINUS_2|") > 0) {
            text = text.replace(/\*\|DOUBLE_YEAR_MINUS_2\|\*/g, (year - 3).toString() + "/" + (year - 2).toString().substring(2, 4));
        }
        text = text.replace(/&nbsp;/g, "");
        return text;
    }

    private replaceOptionTitle(text, optionTitle): string {
        if (text.indexOf("|OPTION_TITLE|") > 0) {
            text = text.replace(/\*\|OPTION_TITLE\|\*/g, optionTitle);
        }
        return text;
    }

    public mean(values: Array<number>): number {
        values = values.filter(x => x || x == 0);

        if (values.length < 1) return null;

        let sum = values.reduce((previous, current) => current += previous);

        return sum / values.length;
    }

    public median(values: Array<number>): number {
        values = values.filter(x => x || x == 0);

        if (values.length < 1) return null;

        values.sort((a, b) => a - b);

        let lowMiddle = Math.floor((values.length - 1) / 2);
        let highMiddle = Math.ceil((values.length - 1) / 2);

        return (values[lowMiddle] + values[highMiddle]) / 2;
    }

}