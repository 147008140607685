import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  darkMode$: Observable<boolean>;

  constructor(private store: Store<{ darkMode: boolean }>) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit() {}
}
