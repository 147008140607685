<div (clickElsewhere)="close()">
<div>
    <div>
        <button class="groupBy" [ngClass]="{'active' : openElement == 'filterBy'}" (click)="open('filterBy')">
            <i class="fas fa-filter "></i>
            Filter
            <div *ngIf="counter > 0" class="badge badge-primary ml-2">{{counter}}</div>
        </button>
        <button class="groupBy" [ngClass]="{'active' : openElement == 'groupBy'}" (click)="open('groupBy')">
            <i class="fas fa-layer-group"></i>
            Group By
            <div *ngIf="groupByCounter > 0" class="badge badge-primary ml-2">{{groupByCounter}}</div>
        </button>
        <div class="container pl-0" style="position: absolute; z-index: 100;">
            <div class="d-flex">
                <div style="min-width: 300px;" class="mr-2">
                    <div *ngIf="openElement === 'filterBy'" class="card border rounded shadow py-2 px-3 text-dark ">
                        <div 
                            class="card-body" 
                            *ngFor="let col of groupableColumns" 
                            [ngStyle]="{'display': (col.options[0] == null && col.options.length == 1) ? 'none' : ''}" 
                            [class.active]="col.filterSelected" 
                            [class.active]="col.backgroundActive"
                            (click)="filterSelectOnText(col)">
                            <i 
                                class="far fa-lg mr-2" 
                                style="color: #005eff;" 
                                [class.fa-square]="!col.filterSelected"
                                [class.fa-check-square]="col.filterSelected" 
                                (click)="filterSelect(col)">
                            </i>
                            {{ col.header }}
                            <i class="fas fa-angle-right ml-5 mt-1" style="float: right"></i>
                        </div>
                    </div>
                </div>
                <div style="min-width: 350px;">
                    <div *ngIf="filterObject && openElement === 'filterBy'" class="card border rounded shadow py-2 px-3 text-dark" style="max-height: 50vh; overflow-y: auto">
                        <div class="searchIcon">
                        <input type="text" (focus)="focusFunction()"(focusout)="focusOutFunction()" [(ngModel)]="searchInputValue" placeholder='Filter by...'
                            class="form-control mb-2 mt-1 mr-2" style="padding-left: 30px"/>
                            <i *ngIf="searchIcon" class="fas fa-search"></i>
                        </div>
                        <div class="card-body d-flex align-items-center" *ngFor="let opt of filterObject| filterBy: ['item']: searchInputValue"
                            [class.active]="opt.filterOptionSelected" (click)="filterOptionSelect(opt)">
                            <i class="far fa-lg mr-2" style="color: #005eff;"
                                [class.fa-square]="!opt.filterOptionSelected"
                                [class.fa-check-square]="opt.filterOptionSelected" (click)="filterOptionSelect(opt, $event);"></i>
                            <span *ngIf="opt.item.length > 30" [ngbTooltip]="opt.item" placement="top" container="body">
                                {{ (opt.item | slice:0:30)+'...' }}
                            </span>
                            <span *ngIf="opt.item.length <= 30">
                            {{ opt.item }}
                            </span>
                        </div>
                    </div>
                    <div *ngIf="dateOpen && openElement === 'filterBy'" class="card border rounded shadow py-2 px-3 text-dark">
                        <div class="card-body ">
                        <app-table-date-filter
                        (datePicker)="filterByDate($event)"
                        (resetFilterClickedChangedDate)="resetFilterDateButtonRecieved($event)"
                        (savedBothDates)="savedDate($event)"
                        [savedDates]="savedDates"
                        [resetFilterClicked]="resetFilterDateButton"
                        ></app-table-date-filter>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style="margin-left: 80px; max-width: fit-content;">
        <div *ngIf="openElement === 'groupBy'" class="card border rounded shadow py-2 px-3 text-dark" style="position: absolute; z-index: 100">
            <div class="card-body" *ngFor="let col of groupableColumns" [class.active]="col.selected"
                (click)="groupBySelect(col)" [ngStyle]="{'display': col.header == 'Date' ? 'none' : ''}" 
                [ngStyle]="{'display': col.options[0] == null ? 'none' : ''}">
                <i class="far fa-lg mr-2" style="color: #005eff;" [class.fa-square]="!col.selected"
                    [class.fa-check-square]="col.selected"></i>
                {{ col.header }}
            </div>
        </div>
    </div>
</div>