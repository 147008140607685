import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UsersService } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-users-roles-products-shared',
  templateUrl: './users-roles-products-shared.component.html',
  styleUrls: ['./users-roles-products-shared.component.scss']
})
export class UsersRolesProductsSharedComponent implements OnInit {

  @Input() public tableData:any;
  @Input() public roles:any;
  @Input() public products:any;
  @Output() public tableDataFromUsersRolesAndProductsToUsersPending = new EventEmitter<any>();
  @Output() public assignNullValueToTableData = new EventEmitter<any>();

  public section:string = 'rolesSlider'
 
  constructor(
    private usersService: UsersService,
  ) { }

  ngOnInit(): void {   
  }

  public addTableData(e) {
    this.tableDataFromUsersRolesAndProductsToUsersPending.emit(e)
  }

  public resetTableData() {
    this.assignNullValueToTableData.emit()
  }
}
