import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-textarea',
  templateUrl: './dynamic-form-textarea.component.html',
  styleUrls: ['./dynamic-form-textarea.component.scss']
})
export class DynamicFormTextareaComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;

  public currentValue: string;
  public showEditor: boolean = false;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: '15rem',
      minHeight: '15rem',
      translate: 'no',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
};

  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    this.currentValue = this.form.get(this.block.dataKey).value;
  }

}
