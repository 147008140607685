import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-slider-panel',
  templateUrl: './slider-panel.component.html',
  styleUrls: ['./slider-panel.component.scss']
})
export class SliderPanelComponent implements OnInit {

  @Input() public data?: any;
  @Input() public layer?: number;
  @Input() public pageWidth?: number;
  @Input() onClose: () => void;

  darkMode$: Observable<boolean>;
  backgroundWidth: number;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    if(this.pageWidth){
      this.backgroundWidth = (100 - this.pageWidth)
    }
  }

}
