import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";
import { IDynamicPresetFilter } from "src/app/core/models/dynamic-table-filter.vm";

export const OrganisationUserRolesDetailsForm: IDynamicFormBlock[] = [
    /*
       TODO: The following need adding (API update required):
       -- Product Name
       -- Submission Name
    */
    {
        blockType: 'divider'
    },
    {
        label: 'Product (ID)',
        dataKey: 'productId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project Name',
        dataKey: 'projectName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Role Name',
        dataKey: 'roleName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Year',
        dataKey: 'roleYear',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Submission (ID)',
        dataKey: 'submissionId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service Item Name',
        dataKey: 'serviceItemName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
]

export const OrganisationUserDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'User Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Full Name',
        dataKey: 'fullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Job Title',
        dataKey: 'jobTitle',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Email',
        dataKey: 'logonName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Phone No.',
        dataKey: 'phoneNo',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Roles',
        dataKey: 'logonName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'User ID',
        dataKey: 'userId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
]

export const OrganisationUserTableColumns: IDynamicTableColumn[] = [
    {
        header: 'User ID',
        percWidth: 10,
        dataKey: 'userId',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Full Name',
        dataKey: 'fullName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Job Title',
        dataKey: 'jobTitle',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Email',
        dataKey: 'logonName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Phone Nr.',
        percWidth: 10,
        dataKey: 'phoneNo',
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Admin',
        percWidth: 10,
        dataKey: 'isAdmin',
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    }
];

export const OrganisationUserRolesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Role Name',
        percWidth: 20,
        dataKey: 'roleName',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Data Collection Access',
        dataKey: 'dataCollectionAccess',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Is Project Specific',
        dataKey: 'isProjectSpecific',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Role Priority',
        dataKey: 'rolePriority',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Allow Registration Changes.',
        percWidth: 20,
        dataKey: 'allowRegistrationChanges',
        sortable: true,
        groupable: false,
        searchable: true
    }
];

export const UserTableFilters: IDynamicPresetFilter[] = [
    {
        name: 'Inactive Users', 
        key: 'isActive', 
        value: 'N', 
        hideValue: true
    }
];