import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
  })

export class RolesService {
private nhsApiUrl: string = environment.nhsApiUrl;

constructor(private http: HttpClient, public auth: AuthService) {}


public getAllUserRoles(year: number): Observable<any> {
    return this.http.get(this.nhsApiUrl + 'users/getAllUserRoles', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('year', year.toString()),
      });
  }

  public addNewRole(userId: number, body): Observable<any> {
    return this.http.post(this.nhsApiUrl + 'users/' + userId + '/addRole', body,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      });
  }

  public editRole(body): Observable<any> {
    return this.http.post(this.nhsApiUrl + 'users/' + 'updateUserRole', body,{
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      });
  }

}