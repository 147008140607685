export class Layout {

    public heading: string;
    public subheading: Array<string>;
    public backRoute: string;

    constructor(heading: string, subHeading: Array<string>, backRoute: string) {
        this.heading = heading;
        this.subheading = subHeading;
        this.backRoute = backRoute;
    }

}