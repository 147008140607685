<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<!-- Project List (no current project) -->
<ng-template #notLoading>
    <ng-container *ngIf="!currentProject">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-11 col-12">

                    <div class="row mt-5 mb-3">
                        <div class="col-md-7 col-12" *ngIf="layout">
                            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                        </div>
                        <div class="col-md-5 col-12 d-flex">
                            <div class="ml-auto">
                                <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                            </div>
                        </div>
                    </div>

                    <app-dynamic-table
                        [data]="projectList" 
                        [columns]="projectTableColumns"
                        [presetFilters]="presetFilters"
                        [tableTitle]="'projectList'"
                        [primaryKey]="'projectId'"
                        [onRowClick]="onTableRowClick"
                    >
                    </app-dynamic-table>

                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<!-- Current Project -->
<ng-container *ngIf="currentProject">
    <div class="container-fluid">
        <div class="row justify-content-center pb-5">
            <div class="col-md-11 col-12">

                <div class="row mt-5 mb-3">
                    <div class="col-md-7 col-12" *ngIf="layout">
                        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                    </div>
                    <div class="col-md-5 col-12 d-flex">
                        <div class="ml-auto">
                            <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                        </div>
                    </div>
                </div>
      
                <app-area-nav [navItems]="projectNavItems"></app-area-nav>
                <app-project-detail *ngIf="currentSection == 'details'" [currentProject]="currentProject"></app-project-detail>
                <app-project-registration *ngIf="currentSection == 'registrations'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-registration>
                <app-project-submission *ngIf="currentSection == 'submissions'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-submission>
                <app-project-data-collection *ngIf="currentSection == 'data-collection'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-data-collection>
                <app-project-response *ngIf="currentSection == 'responses'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-response>
                <app-project-toolkit *ngIf="currentSection == 'toolkit'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-toolkit>
                <app-project-validation *ngIf="currentSection == 'validation'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-validation>
                <app-project-dashboard *ngIf="currentSection == 'dashboard'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-dashboard>
                <app-project-data-upload *ngIf="currentSection == 'data-uploads'" [currentProject]="currentProject" [currentYear]="currentYear"></app-project-data-upload>
                <app-dynamic-document *ngIf="currentSection == 'dynamic-documents'" [currentProject]="currentProject" [currentYear]="currentYear"></app-dynamic-document>
                <app-static-document *ngIf="currentSection == 'static-documents'" [currentProject]="currentProject" [currentYear]="currentYear"></app-static-document>

            </div>
        </div>
    </div>            
</ng-container>