    <div class="d-flex ">
        <ng-template #tooltipContent> Download Data (.csv) </ng-template>
        <button 
            *ngIf="!hideExportButton"
            class="btn btn-warning ml-2 mb-2" 
            [ngbTooltip]="tooltipContent" 
            (click)="downloadAsCsv(data, tableTitle)">
            <i class="fas fa-file-csv mr-2"></i>Export
        </button>
        <ng-container *ngIf="!hideSearch && searchableColumns.length > 0">
            <input 
                type="text" 
                class="form-control ml-2" 
                (focusout)="clearInput(true)" 
                (click)="searchInput(null)" 
                (ngModelChange)="searchInput($event)" 
                placeholder="{{ searchPlaceholder }}" 
                [(ngModel)]="searchText" 
                style="width: 400px;"
                />
                

                <i class="far fa-lg fa-times search-clear" 
                *ngIf="searchText.length" 
                (click)="clearInput(false)"></i>
        </ng-container>
    </div>