import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { GroupByPipe, OrderByPipe } from 'ngx-pipes';

import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

import { Project } from 'src/app/core/classes/project';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

import { ResponseDetailsForm, ResponseTableColumns, ValidationDetailsFormBlocks } from './project-response-layouts';
import { IProjectSubmission } from 'src/app/core/models/project-submission.vm';
import { IProjectResponse } from 'src/app/core/models/project-response.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OutputValidationService } from 'src/app/core/services/validation.service';
import { IAlert } from 'src/app/core/models/alert';
import { IDynamicFormEmit } from 'src/app/core/models/dynamic-form-emit.vm';

interface IFilter { id: number; name: string; submissions?: Array<IFilter> }

@Component({
  selector: 'app-project-response',
  templateUrl: './project-response.component.html',
  styleUrls: ['./project-response.component.scss'],
  providers: [ OrderByPipe, GroupByPipe ]
})
export class ProjectResponseComponent implements OnChanges, OnDestroy {

  @Input() currentProject: Project;
  @Input() currentYear: number;

  public loadingError: string;
  public alert: IAlert;

  public responseTableColumns: Array<IDynamicTableColumn> = ResponseTableColumns;
  public responseDetailsForm: Array<IDynamicFormBlock> = ResponseDetailsForm;
  public ValidationDetailsForm: Array<IDynamicFormBlock> = ValidationDetailsFormBlocks;

  public selectedFilterName: string;
  public selectedFilters = <IFilter[]>[];
  public prefilters: any = [
    { filterName: 'submission', filterOptions: <IFilter[]>[] },
    { filterName: 'organisation', filterOptions: <IFilter[]>[] },
    { filterName: 'question', filterOptions: <IFilter[]>[] },
    { filterName: 'service', filterOptions: <IFilter[]>[] },
    { filterName: 'question group', filterOptions: <IFilter[]>[] },
    { filterName: 'question group level', filterOptions: <IFilter[]>[] },
    { filterName: 'question ID', filterOptions: <IFilter[]>[] }
  ]

  public responseLayout: ILayout;
  public selectedResponse: IProjectResponse;
  public selectedResponseValidation: IProjectResponse;
  public validationSet: boolean = false;
  public responseLayoutValidation: ILayout;
  public notePosted: boolean = false;
  public descriptionPosted: boolean = false;
  public validationResponseId: number;
  private unsubscribe = new Subject();
  darkMode$: Observable<boolean>;

  constructor(
    private projectService: ProjectService,
    public loaderService: LoaderService,
    public orderBy: OrderByPipe,
    public groupBy: GroupByPipe,
    private router: Router,
    private outputValidationService: OutputValidationService,
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnChanges(): void {
    this.currentProject.responses = [];
    // Get submissions, if necessary
    if (!this.currentProject.submissions) {
      this.getProjectSubmissions(this.currentProject.projectId, this.currentYear);
    } else {
      this.setSubmissionFilters(this.currentProject.submissions);
    }
    // Get questions, if necessary
    if (!this.currentProject.questions) {
      this.getProjectQuestions(this.currentProject.projectId, this.currentYear);
    } else {
      this.prefilters[2].filterOptions = this.currentProject.questions.map(que => { return { id: que.questionId, name: que.questionText } });
    }
    // Get services, if necessary
    if (!this.currentProject.serviceItems) {
      this.getProjectServiceItems(this.currentProject.projectId);
    } else {
      this.prefilters[3].filterOptions = this.currentProject.serviceItems.map(item => { return { id: item.serviceItemId, name: item.serviceItemName } });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  private getProjectSubmissions(projectId: number, currentYear: number): void {
    this.projectService.getProjectSubmissions(projectId, currentYear).subscribe(
      success => { 
        // let submissions = this.currentProject.setSubmissions(success.data.submissionList[currentYear]);
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////   FIX   //////////////////////////////////
        let { [currentYear]: currentYearSubmissions } = success.data.submissionList;
        let submissions= this.currentProject.setSubmissions(currentYearSubmissions) 
        //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.currentProject.submissions = this.orderBy.transform(submissions, 'submissionName');
        this.setSubmissionFilters(this.currentProject.submissions);
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private getProjectQuestions(projectId: number, currentYear: number): void {
    this.projectService.getProjectQuestions(projectId, currentYear).subscribe(
      success => { 
        let questions = this.currentProject.setQuestions(success.data.projectQuestions);
        // Remove narratives
        questions = questions.filter(que => que.questionType !== 'NR');
        this.currentProject.questions = this.orderBy.transform(questions, ['questionGroupLevelId','questionGroupDisplaySequence','questionDisplaySequence']);
        this.prefilters[2].filterOptions = this.currentProject.questions.map(que => {
          if (que.serviceItemId) {
            return { id: que.questionId, name: `${ que.questionText } (${ que.serviceItemName })`, service: que.serviceItemId }
          } else {
            return { id: que.questionId, name: que.questionText }
          }
        });

        // Populate the prefilters for questionGroupId(4) & questionGroupLevelId(5)
        this.prefilters[4].filterOptions = [...new Map(this.currentProject.questions.map(item => 
          { return { id: item.questionGroupId, name: item.questionGroupId } 
        }).map(item => [item['id'], item])).values()].sort((a, b) => a.id - b.id);
        
        this.prefilters[5].filterOptions = [...new Map(this.currentProject.questions.map(item => { 
          return { id: item.questionGroupLevelId, name: item.questionGroupLevelId } 
        }).map(item => [item['id'], item])).values()].sort((a, b) => a.id - b.id);

        this.prefilters[6].filterOptions = [...new Map(this.currentProject.questions.map(item => { 
          return { id: item.questionId, name: item.questionId } 
        }).map(item => [item['id'], item])).values()].sort((a, b) => a.id - b.id);

       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private getProjectServiceItems(projectId: number): void {
    this.projectService.getProjectServiceItems(projectId).subscribe(
      success => { 
        this.prefilters[3].filterOptions = success.data.serviceItemList.map(item => { return { id: item.serviceItemId, name: item.serviceItemName } });
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private setSubmissionFilters(submissions: Array<IProjectSubmission>): void {
    // Submissions list
    this.prefilters[0].filterOptions = submissions.map(sub => { return { id: sub.submissionId, name: sub.submissionName, level: sub.submissionLevel } });
    // Organisation list
    let groupedByOrganisation = this.groupBy.transform(submissions, 'organisationName');
    // Object.keys(groupedByOrganisation).forEach(org => {
    //   // let organisationId = submissions.find(sub => sub.submissionId == groupedByOrganisation[org][0].submissionId).organisationId
    //   // let organisationName = org
    //   // let organisationSubmissions = groupedByOrganisation[org].map(sub => { return { id: sub.submissionId, name: sub.submissionName, level: sub.submissionLevel } })
    //   this.prefilters[1].filterOptions.push({
    //     id: submissions.find(sub => sub.submissionId == groupedByOrganisation[org][0].submissionId).organisationId,
    //     name: org,
    //     submissions: groupedByOrganisation[org].map(sub => { return { id: sub.submissionId, name: sub.submissionName, level: sub.submissionLevel } })
    //   });      
    // });
    /////////////////////////////////////////////////////////////////////////////////////////// FIX ///////////////////////////////
    let groupedByOrganisationArray= Object.entries(groupedByOrganisation)
    groupedByOrganisationArray.forEach(org => {
      let organisationId = submissions.find(sub => sub.submissionId == org[1][0].submissionId).organisationId
      let organisationName = org[0]
      let organisationSubmissions = [{ id: org[1][0].submissionId, name: org[1][0].submissionName, level: org[1][0].submissionLevel }]
      this.prefilters[1].filterOptions.push({
        id: organisationId,
        name: organisationName,
        submissions: organisationSubmissions
      });
    });
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  }

  public updateResponses(filterName: string, filterItems: any): void {
    this.selectedFilterName = filterName;
    this.selectedFilters = filterItems;
    filterItems.forEach(item => {
      this.getProjectResponses(
        this.currentProject.projectId, 
        this.currentYear, 
        filterName == 'submission' || filterName == 'organisation' ? item.id : null,
        filterName == 'question' ? item.id : null || filterName == 'question ID' ? item.id : null,
        filterName == 'service' ? item.id : null || filterName == 'question' ? item.service : null,
        filterName == 'question group' ? item.id : null,
        filterName == 'question group level' ? item.id : null,
      )
    });
  }

  private getProjectResponses(projectId: number, currentYear: number, submissionId: number, questionId: number, serviceItemId: number, questionGroupId: number, questionGroupLevelId: number): void {
    this.projectService.getProjectResponses(projectId, currentYear, submissionId, questionId, serviceItemId, questionGroupId, questionGroupLevelId).pipe(takeUntil(this.unsubscribe)).subscribe(
      success => {
        // Format responses
        let responses = this.currentProject.setResponses(success.data.responseList);
        // Push individual responses to currentProject
        responses.forEach(response => { 
          // TODO: Add submissionLevel to end-point (LW)
          let matchingSubmission = this.currentProject.submissions.find(sub => sub.submissionId == response.submissionId);
          if (matchingSubmission.submissionLevel) {
            response.submissionName = response.submissionName + ' (' + matchingSubmission.submissionLevel + ')';
          }
          // end TODO
          this.currentProject.responses.push(response)
        });
        // Reorder responses by submission/question
        this.currentProject.responses = this.orderBy.transform(this.currentProject.responses, ['submissionName', 'questionId']);
      },
      error => { 
        console.log('Error: ', error);
        if (error.error.error.code == 'ETIMEOUT') {
          this.loadingError = 'There is too much data to return; if this continues, please contact the Dev Hub for an extract.'
        } else {
          this.loadingError = error.error.error.message;
        }
      }
    )
  }

  public clearFilters(): void {
    this.selectedFilterName = null;
    this.selectedFilters = null;
    this.currentProject.responses = [];
  }

  public onResponseClick = (primaryKey: string): void => {
    if(!this.validationSet) {
      this.selectedResponse = this.currentProject.responses.find(resp => resp.responseId == +primaryKey);
      this.responseLayout = new Layout(
        this.selectedResponse.submissionName,
        [ `Response ID: ${ this.selectedResponse.responseId }`, `Organisation: ${ this.selectedResponse.organisationName }` ],
        null
      )
      this.router.navigate([], { queryParams: { response: primaryKey }, queryParamsHandling: 'merge' });
    }
  }

  public onTableTdClick = (primaryKey: any, action: string): void => {
    this.validationSet = true;
    let response = this.currentProject.responses.find(resp => resp.responseId == +primaryKey);
    if(!response.validationId) {
      this.setResponse(response);
    }
    this.selectedResponseValidation = this.currentProject.responses.find(resp => resp.responseId == +primaryKey);
    this.responseLayoutValidation = new Layout(
      this.selectedResponseValidation.submissionName,
      [ this.selectedResponseValidation.serviceItemName ],
      null
    )

  };

  noteUpdate(note: IDynamicFormEmit) {
    if(note.row.notes) this.editValidationNote(this.selectedResponseValidation, note.row.notes)
    if(note.row.validationDescription) this.editValidationDescription(this.selectedResponseValidation, note.row.validationDescription)
  }

  public editValidationNote(form: IProjectResponse, note: string): void {
    let updatedValidation = {
      validationResponseId: this.validationResponseId,
      note: note
    }
  
    this.outputValidationService.editValidationNote(updatedValidation).subscribe(
      success => {
        form.notes = note;
        this.notePosted = true;
        setTimeout(() => { this.notePosted = false }, 2000);
        this.alert = { message: "Changes saved!", alertClass: 'success', fadeOut: true };
        this.closeResponseSliderValidation();
      },
      error => {
        console.log(error);
        this.alert = { message: "Error! Changes have not been saved", alertClass: 'danger', fadeOut: true };
      }
    )
  }
  
  public editValidationDescription(selectedValidation: IProjectResponse, note: string): void {
    let test: any = this.currentProject.responses.filter(item => item.responseId == selectedValidation.responseId)
    let updatedValidation = {
      validationId: test[0].validationId,
      description: note,
      alertUser: "N"
    }
    this.outputValidationService.editValidationDescription(updatedValidation).subscribe(
      success => {
        selectedValidation.validationDescription = note;
        this.descriptionPosted = true;
        setTimeout(() => { this.descriptionPosted = false }, 2000);
        this.alert = { message: "Changes saved!", alertClass: 'success', fadeOut: true };
        this.closeResponseSliderValidation();
      },
      error => {
        console.log(error);
        this.alert = { message: "Error! Changes have not been saved", alertClass: 'danger', fadeOut: true };
      }
    )
  }

  public setResponse(selectedResponse): void {
  let validationResponse = {
    submissionId: selectedResponse.submissionId,
    questionId: selectedResponse.questionId,
    questionPart: selectedResponse.questionPart,
    serviceItemId: selectedResponse.serviceItemId
  };
  this.outputValidationService.addValidationResponse(validationResponse).subscribe(
    success => {
      this.validationResponseId = success.data.newValidationResponseId
      this.validationSet = false;
      this.currentProject.responses = [];
      this.updateResponses(this.selectedFilterName, this.selectedFilters);
    },
    error => {
      console.log(error);
    }
  )
}

  public closeResponseSlider = (): void => {
    this.selectedResponse = null;
    this.router.navigate([], { queryParams: { response: null }, queryParamsHandling: 'merge' });
  }

  public closeResponseSliderValidation = (): void => {
    this.validationSet = false;
    this.selectedResponseValidation = null;
    this.router.navigate([], { queryParams: { response: null }, queryParamsHandling: 'merge' });
  }
}
