import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { Layout } from 'src/app/core/classes/layout';
import { ILayout } from 'src/app/core/models/layout.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { ToolService } from 'src/app/core/services/tool.service';
import { dataExtractorNavItems } from './data-extractor-layouts';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAlert } from 'src/app/core/models/alert';

interface IFilter {
  id: number;
  name: string;
  project?: Array<IFilter>;
  placeholder: string;
}

@Component({
  selector: 'app-data-extractor',
  templateUrl: './data-extractor.component.html',
  styleUrls: ['./data-extractor.component.scss'],
})
export class DataExtractorComponent implements OnInit, OnChanges {
  public componentName:string = "data-extractor";
  public layout: ILayout;
  public alert: IAlert;
  public loadingError: string = null;
  public searchInputValue: string = '';
  public filterOptions: Array<any> = [];
  public yearList: any;
  public currentYear: number;
  public projects: any;
  public submissions: string;
  public currentProject: string;
  public filterTitle: string;
  public selectedItemsObj: Array<string> = [];
  public dataExtractorNavItems: Array<INavItem> = dataExtractorNavItems;
  public projectList: string;
  public testYearOptions: string;
  public selectedItemsYearObj: Array<any> = [];
  public selectedItemsIdObj: any;
  public selectedItemsYearArr: Array<any> = [];
  public selectedItemsIdArr: Array<any> = [];
  public submissionsMapped: string;
  public clicked: boolean = false;
  public selectedItemsSubmissionArr: Array<string> = [];
  public selectedItemsOrgArr: Array<string> = [];
  public selectedItemsServicesArr: Array<string> = [];
  public selectedQuestionGLArr: Array<string> = [];
  public inputTitle: string;
  public dataExtractObj: any;
  public dataExtractLoading: any = false;
  public show: boolean = false;
  public format: any = [{format: 'Reportomatic (Matrix)', selected: true}, {format: 'Inverted Reportomatic (Matrix)'}, {format: 'Linear'}]
  public sorting: any = [{sort: 'Numerical', selected: true}, {sort: 'Alphabetical'}]
  public caseCode: any = [{case: 'All responses', selected: true}, {case: 'Case code only'}, {case: 'Non-case code only'}]
  public questionFocus: any = [{question: 'Query based on responses (default)', selected: true}, {question: 'Query based on questions'}]
  public toggleServer: any = [{server: 'Live server', selected: true}, {server: 'Test server'}]
  public showReportomaticDropdown: boolean = false
  public showSortingDropdown: boolean = true
  public showSortingOption: boolean = false
  public showCaseCodeDropdown: boolean = false
  public showquestionFocusDropdown: boolean = false
  public showToggleServerDropdown: boolean = false
  public selectedFormat: number;
  public selectedCaseCode: number;
  public selectedSortCode: number;
  public selectedQuestionFocus: number;
  public selectedServer: number;
  public disableExtractButton: boolean;
  darkMode$: Observable<boolean>;
  public formSelection: any = {
    submissions: {},
    services: {},
    organisations: {},
    questionGL: {},
    format: {},
  };
  public prefilters: any = [
    {
      filterName: 'Year',
      filterOptions: [],
      placeholder: 'Search by Year...',
      name: 'year',
    },
    {
      filterName: 'Project',
      filterOptions: <IFilter[]>[],
      placeholder: 'Search by name or ID...',
      name: 'projectName',
    },
  ];



  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private toolService: ToolService,
    private ref: ChangeDetectorRef,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void {
    if (!this.yearList) {
      this.yearList = this.getDefaultYearList();
      this.currentYear = 2023;
    }
    this.getDefaultYearList();
    this.getProjectList(2023);
    this.layout = new Layout('Data Extract Service', null, '/tool');
  }

  ngOnChanges(): void {
  }

  public getDefaultYearList(): void {
    this.yearList = [];
    for (var i = 2013; i <= new Date().getFullYear(); i++) {
      this.yearList.push(i);
    }
    this.yearList.reverse();
    let yearObj = this.yearList.map((year) => {
      return {
        year: year,
      };
    });
    this.filterOptions = this.prefilters[0].filterOptions = yearObj;
  }

  public selectYear(year: number): void {
    if (year !== this.currentYear) {
      this.currentYear = year;
      this.getProjectList(year);
    }
  }

  public selectOneFormat(item: any) {
    let oneFormat = []
    oneFormat.push(item)
    if(oneFormat.find(obj => obj.format == 'Reportomatic (Matrix)')){
      this.format[0].selected = true
      this.format[1].selected = false
      this.format[2].selected = false

      this.selectedFormat = 2

      this.showSortingDropdown = false
      this.showSortingDropdown = true
      this.sorting[0].selected = true
      this.sorting[1].selected = false
      this.selectedSortCode = 2
    } else if(oneFormat.find(obj => obj.format == 'Inverted Reportomatic (Matrix)')){
      this.format[1].selected = true
      this.format[0].selected = false
      this.format[2].selected = false

      this.selectedFormat = 3

      this.showSortingDropdown = true
      this.sorting[0].selected = true
      this.sorting[1].selected = false
      this.selectedSortCode = 2
    } else if(oneFormat.find(obj => obj.format == 'Linear')){
      this.showSortingDropdown = false

      this.format[2].selected = true
      this.format[0].selected = false
      this.format[1].selected = false

      this.selectedFormat = 1
      this.selectedSortCode = null
    }
  }

  public selectOneSort(item: any){
    let oneSortingOption = []
    oneSortingOption.push(item)
    if(oneSortingOption.find(obj => obj.sort == 'Numerical')){
      this.sorting[0].selected = true
      this.sorting[1].selected = false
      this.selectedSortCode = 2
    } else if(oneSortingOption.find(obj => obj.sort == 'Alphabetical')) {
      this.sorting[1].selected = true
      this.sorting[0].selected = false
      this.selectedSortCode = 1
    }
  }

  public selectOneCaseCode(item: any) {
    let oneCaseCode = []
    oneCaseCode.push(item)
    if(oneCaseCode.find(obj => obj.case == 'All responses')){
      this.caseCode[0].selected = true
      this.caseCode[1].selected = false
      this.caseCode[2].selected = false
      this.selectedCaseCode = 0
    } else if(oneCaseCode.find(obj => obj.case == 'Case code only')) {
      this.caseCode[1].selected = true
      this.caseCode[0].selected = false
      this.caseCode[2].selected = false
      this.selectedCaseCode = 1
    }
    else if (oneCaseCode.find(obj => obj.case == 'Non-case code only')) {
      this.caseCode[0].selected = false
      this.caseCode[1].selected = false
      this.caseCode[2].selected = true
      this.selectedCaseCode = 2
    }
  }

  public selectOneQuestionFocus(item: any){
    let oneQuestionFocus = []
    oneQuestionFocus.push(item)
    if(oneQuestionFocus.find(obj => obj.question == 'Query based on responses (default)')){
      this.questionFocus[0].selected = true
      this.questionFocus[1].selected = false
      this.selectedQuestionFocus = 1
    } else if(oneQuestionFocus.find(obj => obj.question == 'Query based on questions')) {
      this.questionFocus[1].selected = true
      this.questionFocus[0].selected = false
      this.selectedQuestionFocus = 2
    }
  }

  public selectOneServer(item: any){
    let oneServer = []
    oneServer.push(item)
    if(oneServer.find(obj => obj.server == 'Live server')){
      this.toggleServer[0].selected = true
      this.toggleServer[1].selected = false
      this.selectedServer = 1
    } else if(oneServer.find(obj => obj.server == 'Test server')) {
      this.toggleServer[1].selected = true
      this.toggleServer[0].selected = false
      this.selectedServer = 2
    }
  }

  public newSelection(newSelection) {
    switch (newSelection.filterName) {
      case 'year':
        this.newYearSelection(newSelection.items.data);
        break;

      case 'projectName':
        this.newProjectSelection(newSelection.items.data);
        break;

      case 'submissions':
        this.newSubmissionSelection(newSelection.items);
        break;

      case 'services':
        this.newServiceSelection(newSelection.items);
        break;

      case 'organisations':
        this.newRegistrationSelection(newSelection.items);
        break;

      case 'questionGL':
        this.newQuestionGLSelection(newSelection.items);
        break;
    }
  }

  public newYearSelection(selectedItems) {
    this.selectedItemsYearObj = selectedItems;
    this.selectedItemsYearArr = this.selectedItemsYearObj.map((a) => a.year);
  }

  public newProjectSelection(selectedItems) {
    this.selectedItemsIdObj = selectedItems;
    this.selectedItemsIdArr = this.selectedItemsIdObj.map((a) => a.id);
  }

  public newSubmissionSelection(newSelection) {
    // this.formSelection['submissions'][newSelection.key['item']] = newSelection.data.map((a) => a.submissionId);
    //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
    Object.defineProperty(this.formSelection.submissions, newSelection.key.item, {
      value: newSelection.data.map((a) => a.submissionId),
      enumerable: true,
      writable: true
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  public newServiceSelection(newSelection) {
    // this.formSelection['services'][newSelection.key] = newSelection.data.map( (a) => a.serviceId);
    //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
    Object.defineProperty(this.formSelection.services, newSelection.key.item, {
      value: newSelection.data.map((a) => a.serviceId),
      enumerable: true,
      writable: true
    });
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  public newRegistrationSelection(newSelection) {
    // this.formSelection['organisations'][newSelection.key] = newSelection.data.map((a) => a.registrationId);
    //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
    Object.defineProperty(this.formSelection.organisations, newSelection.key.item, {
      value: newSelection.data.map((a) => a.organisationId),
      enumerable: true,
      writable: true
    });  
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  }

  public newQuestionGLSelection(newSelection) {
    
    // this.formSelection['questionGL'][newSelection.key] = newSelection.data.map((a) => a.groupLevelId);
    //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
    Object.defineProperty(this.formSelection.questionGL, newSelection.key.item, {
      value: newSelection.data.map((a) => a.groupLevelId),
      enumerable: true,
      writable: true
    });  
  
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////    
  }

  public flattenFormSelection() {
    let flattened: any = {
      organisations: [],
      submissions: [],
      services: [],
      questionGL: [],
    }

    let arr_keys = ["organisations", "submissions", "services", "questionGL"]

    arr_keys.forEach(key => {
      const { [key]: selection } = this.formSelection
      let values = Object.values(selection)
      
      values.forEach((arr: Array<any>) => {
        const { [key]: output } = flattened
        arr.forEach(value => {
          output.push(value)
        })
        Object.defineProperty(flattened, key, {value: output})
      })
    })

    this.dataExtractObj = {
      title: this.inputTitle,
      project_id: this.selectedItemsIdArr,
      year: this.selectedItemsYearArr,
      submission_id: flattened.submissions,
      organisation_id: flattened.organisations,
      service_item_id: flattened.services,
      question_group_level_id: flattened.questionGL,
      case_code_data: this.selectedCaseCode,
      query_focus: this.selectedQuestionFocus,
      sort: this.selectedSortCode,
      format: this.selectedFormat,
      is_prod: this.selectedServer,
    };

    Object.keys(this.dataExtractObj).forEach((key) => {
      if (
        this.dataExtractObj[key] == '' || this.dataExtractObj[key] == undefined || this.dataExtractObj.length == 0
      )
      //////////////////////////////////////////////////////////////////////////////////////////   FIX ////////////////////////////////////////////////
      // let { [key]: dataExtractObjKey } = this.dataExtractObj;
      // if (
      //   dataExtractObjKey == '' ||
      //   dataExtractObjKey == undefined ||
      //   this.dataExtractObj.length == 0
      // ) 
      {
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // delete this.dataExtractObj[key];
        //////////////////////////////////////////////////////////////////////////////////////////   FIX ////////////////////////////////////////////////
        Reflect.deleteProperty(this.dataExtractObj, key);
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      }
    });    
  }

  public submitDataExtract() {
    this.dataExtractLoading = true;
    this.flattenFormSelection();
    this.toolService
      .submitDataExtract(this.dataExtractObj)
      .subscribe((success) => {
        document.getElementById("alert-backdrop").classList.add('alert-backdrop')
        this.alert = {
          message: `<strong>Your data extract has been queued, please wait for your email</strong>`,
          alertClass: 'success',
          fadeOut: false,
        };
        this.dataExtractLoading = false;
        this.ref.detectChanges();
      },
      (error) => {
        console.log('Error: ', error);
        document.getElementById("alert-backdrop").classList.add('alert-backdrop')
        this.alert = {
          message: `<strong>There was an issue with your extract, please try again or contact Dev Hub</strong>`,
          alertClass: 'danger',
          fadeOut: false,
        };
        this.dataExtractLoading = false;
        this.ref.detectChanges();
      }
    );
  }

  removeBackdrop() {
    document.getElementById("alert-backdrop").classList.remove('alert-backdrop')
    this.clicked= false
  }

  public showOptions() {
    this.show = true;
  }

  private getProjectList(year: number): void {
    this.projectService.getProjects(year).subscribe(
      (success) => {
        this.projects = success.data.projectList;
        this.filterOptions = this.prefilters[1].filterOptions =
          this.projects.map((sub) => {
            return {
              id: sub.projectId,
              projectName: sub.projectName,
            };
          });
        this.filterOptions.forEach((item) => (item.selected = false));
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }
}
