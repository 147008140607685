import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-highchart',
  templateUrl: './highchart.component.html',
  styleUrls: ['./highchart.component.scss']
})
export class HighchartComponent implements OnInit {

  @Input() chartObject: any;
  @Input() chartClick: (primaryKey: string) => void;

  constructor() { }

  ngOnInit(): void { }
}
