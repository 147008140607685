import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DataCollectionTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Question Group',
        dataKey: 'questionGroupWithLevel',
        percWidth: 0,
        sortable: false,
        groupable: true,
        searchable: false
    },
    {
        header: 'ID',
        dataKey: 'questionId',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Text',
        dataKey: 'questionText',
        percWidth: 30,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Help',
        dataKey: 'questionHelp',
        percWidth: 30,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Type',
        dataKey: 'questionTypeName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Format',
        dataKey: 'formatModifierName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Service',
        dataKey: 'serviceItemName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
];

export const QuestionChildrenTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'questionIdPart',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Text',
        dataKey: 'nestedQuestionText',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Heading',
        dataKey: 'headingText',
        percWidth: 35,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Help',
        dataKey: 'nestedQuestionHelp',
        percWidth: 30,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Type',
        dataKey: 'nestedQuestionType',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Nested ID',
        dataKey: 'questionId',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true
    }
];

export const QuestionGroupForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Group Details',
        blockType: 'header',
    },
    {
        label: 'Question Group Name',
        dataKey: 'questionGroupName',
        blockType: 'input',  
        colWidth: 12
    },
    // {
    //     label: 'Service Item',
    //     helpText: '[Needs to be a drop-down from available service items, returning ID]',
    //     dataKey: 'serviceItemId',
    //     blockType: 'input',
    //     inputType: 'number',
    //     disabled: true,
    //     colWidth: 6
    // },
    {
        label: 'Service Item',
        dataKey: 'serviceItemId',
        blockType: 'searchDropdown',             
        colWidth: 6,
        optionsType: 'services'
    },
]

export const QuestionDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Question Details',
        blockType: 'header',
    },
    {
        label: 'Question Text',
        dataKey: 'nestedQuestionText',
        blockType: 'input',  
        colWidth: 12,
        placeholder: true
    },
    {
        label: 'Help Text',
        dataKey: 'nestedQuestionHelp',
        blockType: 'textarea',
        colWidth: 12,
        placeholder: true
    },
    {
        label: 'Question Type',
        dataKey: 'questionType',
        blockType: 'select',
        options: [
            {
                group: "Standard",
                children: Constants.questionTypes,
            }
        ],
        optionsAreGrouped: true,
        colWidth: 6
    },
    {
        label: 'Format Modifier',
        dataKey: 'formatModifier',
        blockType: 'select',
        options: Constants.questionFormatModifiers,
        colWidth: 6
    },
    {
        label: 'Decimal Places',
        dataKey: 'decimalPlace',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    },
    {
        label: 'Minimum Value',
        dataKey: 'rangeMin',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    },
    {
        label: 'Maximum Value',
        dataKey: 'rangeMax',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    }, 
    {
        label: 'Parent Question',
        helpText: 'List all questions in same group',
        dataKey: 'parentQuestionId',
        blockType: 'input',
        inputType: 'number',
        disabled: true,
        colWidth: 6
    }
]