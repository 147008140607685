import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm-overview',
  templateUrl: './crm-overview.component.html',
  styleUrls: ['./crm-overview.component.scss']
})
export class CrmOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
