import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class DataExtractorLibraryService {
  private nhsApiUrl: string = environment.nhsApiUrl;
  private nhsApiUrlTEST: string = environment.nhsApiUrlTEST;

  constructor(private http: HttpClient, public auth: AuthService) {}

  //NHS
  public getExtract(): Observable<any> {
    return this.http.get(
      this.nhsApiUrlTEST + 'userRawQueue',{
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      }
    );
  }
}
