import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IOrganisationProduct } from 'src/app/core/models/organisation-product.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationProductDetailsForm, OrganisationProductTableColumns } from './organisation-product-layouts';

@Component({
  selector: 'app-organisation-product',
  templateUrl: './organisation-product.component.html',
  styleUrls: ['./organisation-product.component.scss'],
})
export class OrganisationProductComponent implements OnChanges {
  @Input() public currentOrganisation;
  @Input() currentYear: number;

  public selectedProduct: IOrganisationProduct = null;
  public layout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public organisationProductTableColumns: Array<IDynamicTableColumn> = OrganisationProductTableColumns;
  public organisationProductDetailsForm: Array<IDynamicFormBlock> = OrganisationProductDetailsForm;

  constructor(
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  
  ngOnChanges(): void {
    // Check for open product
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Open slider, if in params
    if (this.currentQueryParams.product) {
      this.onProductClick(this.currentQueryParams.product);
    }

    if(this.currentOrganisation.products == undefined) {
      this.currentOrganisation.products = [];
    }
    
    if (!Array.isArray(this.currentOrganisation.products)) {
      this.currentOrganisation.products = []
    }
    
  }

  public onProductClick = (primaryKey: string): void => {
    this.selectedProduct = this.currentOrganisation.products.find((pro) => pro.productId == +primaryKey);
    this.layout = new Layout(
      this.selectedProduct.productName,
      [`ID: ${this.selectedProduct.productId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { product: primaryKey }, queryParamsHandling: 'merge', });
  };

  public closeProductSlider = (): void => {
    this.selectedProduct = null;
    this.router.navigate([], { queryParams: { product: null }, queryParamsHandling: 'merge', });
  };
  
}
