import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dynamic-form-input',
  templateUrl: './dynamic-form-input.component.html',
  styleUrls: ['./dynamic-form-input.component.scss']
})
export class DynamicFormInputComponent implements OnInit {

  @Input() public form: FormGroup;
  @Input() public block: IDynamicFormBlock;
  public copiedMessage: string = null;
  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void { }

  limitDigits(event: Event): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    // Determine the limit 
    const limit = this.block.digitLimit;
    // Update the input value to restrict to the specified limit
    if (value.length > limit) {
      input.value = value.slice(0, limit);
    }
    // Update the form control value to ensure it reflects the limited input
    this.form.get(input.id)?.setValue(input.value); // Set the value in the form control
  }

  checkWhitespace(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.value.startsWith(' ')) {
      input.value = '';
    }
  }

  async copyToClipboard(id: string) {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}
