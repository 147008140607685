import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderByPipe } from 'ngx-pipes';

import { Layout } from 'src/app/core/classes/layout';
import { Project } from 'src/app/core/classes/project';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IProjectQuestion } from 'src/app/core/models/project-question.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

import { DataCollectionTableColumns, QuestionChildrenTableColumns, QuestionDetailsForm, QuestionGroupForm } from './project-data-collection-layouts';

import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConstantType, Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-project-data-collection',
  templateUrl: './project-data-collection.component.html',
  styleUrls: ['./project-data-collection.component.scss'],
  providers: [ OrderByPipe ]
})
export class ProjectDataCollectionComponent implements OnInit, OnDestroy {

  @Input() currentProject: Project;
  @Input() currentYear: number;

  private currentQueryParams: any;

  public selectedQuestion: IProjectQuestion;
  public selectedChildQuestion: any;

  public questionLayout: ILayout;
  public childQuestionLayout: ILayout;

  public dataCollectionTableColumns: Array<IDynamicTableColumn> = DataCollectionTableColumns;
  
  public questionGroupForm: Array<IDynamicFormBlock> = QuestionGroupForm;
  public questionDetailsForm: Array<IDynamicFormBlock> = QuestionDetailsForm;
  public questionChildrenTableColumns: Array<IDynamicTableColumn> = QuestionChildrenTableColumns;
  public loadingError: string = null;
  private unsubscribe = new Subject();
  darkMode$: Observable<boolean>;

  constructor(
    public loaderService: LoaderService,
    private projectService: ProjectService,
    private orderBy: OrderByPipe,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode')
  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }

  ngOnInit(): void {
    // Check for open registration
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    // Get questions, if needed
    if (!this.currentProject.questions) {
      this.getProjectQuestions(this.currentProject.projectId, this.currentYear);
    }
  }


  private getProjectQuestions(projectId: number, currentYear: number): void {
    this.projectService.getProjectQuestions(projectId, currentYear).pipe(takeUntil(this.unsubscribe)).subscribe(
      success => { 
        let questions = this.currentProject.setQuestions(success.data.projectQuestions);

        let allQuestionTypes = questions
          .map(q => q.questionType)
          .filter((val, i, arr) => {
            return arr.indexOf(val) === i
          })

        const questionForm = this.questionDetailsForm.find(obj => obj.dataKey === 'questionType')
        const index = this.questionDetailsForm.findIndex(obj => obj === questionForm)

        let extraQuestionTypes: Array<ConstantType> = []
        allQuestionTypes.forEach(typeCode => {
          if (!Constants.questionTypes.find(q => q.id === typeCode)) {
            extraQuestionTypes.push({
              id: typeCode,
              name: typeCode,
            })
          }
        })

        if (extraQuestionTypes.length) {
          this.questionDetailsForm[index].options.push({
            group: "Additional",
            children: extraQuestionTypes
          })
        }

        this.currentProject.questions = this.orderBy.transform(questions, ['questionGroupLevelId','questionGroupDisplaySequence','questionDisplaySequence']);
        // Open selected, if in params
        if (this.currentQueryParams.question) {
          this.onQuestionClick(this.currentQueryParams.question);
        }
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private getProjectQuestionDetails(questionId: number): void {
    this.projectService.getProjectQuestionDetails(questionId).subscribe(
      success => {
        // let nestedQuestions = success.data.questionDetails.nestedQuestions.map(q=>{
        //   q.questionIdPart= q.questionId+"-"+q.questionPart
        //   return q
        // })
        // Choose the syntax below because the one above mutates the Objects inside success.data.questionDetails.nestedQuestions 
        // Not sure if mutating those Objects can cause issues down the line...
        let nestedQuestions = success.data.questionDetails.flatNestedQuestions.map(q => {
          let questionIdPart = this.currentQueryParams.question + "-" + q.questionPart;
          return {...q, questionIdPart}
        })
        this.selectedQuestion.children = nestedQuestions;
        // Open selected child, if in params
        if (this.currentQueryParams.child) {
          this.onChildQuestionClick(this.currentQueryParams.child)
        }
      },
      error => {
        this.selectedQuestion.children = null;
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onQuestionClick = (primaryKey: string): void => {
    this.selectedQuestion = this.currentProject.questions.find(question => question.questionId == +primaryKey);
    this.getProjectQuestionDetails(+primaryKey);
    this.questionLayout = new Layout(
      this.selectedQuestion.questionText,
      [ `ID: ${this.selectedQuestion.questionId}` ],
      null
    )
    this.router.navigate([], { queryParams: { question: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeQuestionSlider = (): void => {
    this.selectedQuestion = null;
    this.router.navigate([], { queryParams: { question: null }, queryParamsHandling: 'merge' });
  }

  public onChildQuestionClick = (primaryKey: string): void => {
    this.selectedChildQuestion = this.selectedQuestion.children.find(child => child.questionId == +primaryKey);
    this.childQuestionLayout = new Layout(
      this.selectedChildQuestion.nestedQuestionText,
      [ `ID: ${this.selectedChildQuestion.questionId}` ],
      null
    )
    this.router.navigate([], { queryParams: { child: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeChildQuestionSlider = (): void => {
    this.selectedChildQuestion = null;
    this.router.navigate([], { queryParams: { child: null }, queryParamsHandling: 'merge' });
  }

}
