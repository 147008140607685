import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const EventAttendeeDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Attendee Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        blockType: 'break'
    },
    {
        label: 'Email',
        dataKey: 'emailAddress',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Job Title',
        dataKey: 'jobTitle',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation Name',
        dataKey: 'organisationName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Meeting Role',
        dataKey: 'meetingRole',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Registered',
        dataKey: 'registered',
        blockType: 'input',
        colWidth: 6,
        options: Constants.registrationStatus,
        disabled: true,
    },
    {
        label: 'Duration',
        dataKey: 'attendanceDuration',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Date/Time Joined',
        dataKey: 'joinDate',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Date/Time Left',
        dataKey: 'leaveDate',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    }
]

export const EventAttendeeTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Email',
        dataKey: 'emailAddress',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Job Title',
        dataKey: 'jobTitle',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Meeting Role',
        dataKey: 'meetingRole',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: false,
        
    },
    {
        header: 'Registered',
        dataKey: 'registered',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Duration',
        dataKey: 'attendanceDuration',
        percWidth: 25,
        sortable: false,
        groupable: false,
        searchable: false,
    }
];