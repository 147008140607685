<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>

    <!-- Info -->
    <div class="alert alert-info mb-4">
        <i class="fas fa-info-circle mr-1"></i><strong>Dynamic document links are created when the user actually clicks to download the document.</strong>  Links expire after a few hours. The intention is to ensure these documents can only be downloaded by active users, and not shared with others. Categories include reports, case studies, presentations and offline toolkits.
    </div>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.awsDocumentsDynamic" 
        [data]="currentProject.awsDocumentsDynamic" 
        [columns]="dynamicDocumentsTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'dynamicDocumentsList'"
        [primaryKey]="'documentId'"
        [onRowClick]="onTableRowClick"
        [dynamicButton]="dynamicButton"
        (buttonClicked)="newDocumentClick()">
    </app-dynamic-table>
</ng-template>


<!-- Selected Document -->
<app-slider-panel *ngIf="documentLayout" [onClose]="closeQuestionSlider">
    <app-area-heading [heading]="documentLayout.heading" [subheading]="documentLayout.subheading"></app-area-heading>
    <app-dynamic-form [data]="displayedDocuments" [blocks]="questionGroupForm"(dataUpdate)="dataUpdateEdit($event)"
    ></app-dynamic-form>

    <!-- Access Log table -->
    <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
        <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Access Log</h4>
        <app-dynamic-table 
            *ngIf="accessedByInfo" 
            [data]="accessedByInfo"
            [columns]="DocumentsDynamicTableAccessLogColumns" 
            [currentYear]="currentYear" 
            [tableTitle]="'Access Log'"
            [primaryKey]="'documentId'" 
            [disableRowClick]="true">
        </app-dynamic-table>
    </ng-container>
</app-slider-panel>

<app-slider-panel *ngIf="newDocumentLayout" [onClose]="closeQuestionSlider">
    <!-- Slider header -->
    <app-area-heading [heading]="newDocumentLayout.heading" [subheading]="newDocumentLayout.subheading"></app-area-heading>

    <!-- Drag & Drop -->
    <app-drag-and-drop-uploader [callerName]="'dynamicDocument'" (uploadFile)="saveFiles($event)"></app-drag-and-drop-uploader>

    <!-- Document form -->
    <app-dynamic-form [data]="UploadDocumentData" [blocks]="UploadDocumentsDynamicForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
</app-slider-panel>
