import { Lookup } from '../classes/lookup';

export default class Utils {
  static checkForLookup(value: any): Lookup {
    if (
      value &&
      typeof value === 'object' &&
      !Array.isArray(value) &&
      value !== null
    ) {
      var lookup = new Lookup(value);
      if (lookup.isLookup()) {
        return lookup;
      }
    }
    return null;
  }
}
