<app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event"></app-alert-status>
  <form action="/action_page.php">
    <div >
    <h4 >{{ Title }}</h4>
    <div style="border-bottom: 1px solid #dee2e6; max-width: 100%; margin-bottom: 20px;" class="d-flex">
    <p  class="pr-4 pt-2">{{ Paragraph }}</p>
    <div class="mb-3 pr-2" >
      <input class="form-control form-control-sm" style="padding-bottom: 30px; padding-top: 7px;"  (change)="onFileSelected($event)" id="formFileSm" type="file">
    </div>
    <div>
      <input class="upload" (click)="uploadFile()" type="submit">
      <div *ngIf="loading" class="spinner-border spinner-border-sm ml-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
  </div>
  </form>