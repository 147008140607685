import { IDynamicTableColumn } from "../core/models/dynamic-table-column.vm";

export const RolesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'User Full Name',
        dataKey: 'full_name',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        percWidth: 40,
        dataKey: 'organisation_name',
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Role Type Name',
        dataKey: 'role_name',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Product Name',
        dataKey: 'product_name',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Project Name',
        dataKey: 'project_name',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Enabled',
        dataKey: 'is_enabled',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    }
];