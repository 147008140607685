import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../core/classes/user';
import { Desk } from '../core/classes/desk';

import { IUser } from '../core/models/user.vm';
import { IAlert } from '../core/models/alert';

import { DeskService } from '../core/services/desk.service';
import { LoaderService } from '../core/services/loader.service';
import { UserService } from '../core/services/user.service';

import { formatDate } from '@angular/common';

import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { DeskBookingsColumns, DeskTableColumns } from './desk-layouts';
import { GroupByPipe } from 'ngx-pipes';
import { Constants } from '../core/constants/constants';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import moment from 'moment';
import { IBooking } from '../core/models/booking.vm';


@Component({
  selector: 'app-desk',
  templateUrl: './desk.component.html',
  styleUrls: ['./desk.component.scss'],
  providers: [ GroupByPipe ],
})
export class DeskComponent implements OnInit {

  public alert: IAlert;

  public deskTableColumns: Array<IDynamicTableColumn> = DeskTableColumns;

  public deskBookingsColumns: Array<IDynamicTableColumn> = DeskBookingsColumns;

  public currentUser: IUser;

  public currentDate: string = formatDate(Constants.currentDate, 'yyyy-MM-dd', 'en-GB');
  public date: string;

  public desks: Array<Desk> = new Array<Desk>();
  public upcoming: Array<any> = new Array();
  public deskClicked: Desk;
  public deskPicked: boolean = false;
  public deletedBooking: Array<IBooking>;

  public bookings: IBooking;

  darkMode$: Observable<boolean>;

  constructor(
    private userService: UserService,
    private deskService: DeskService,
    public loaderService: LoaderService,
    private router: Router,
    private route: ActivatedRoute,
    public tooltipConfig: NgbTooltipConfig,
    public groupBy: GroupByPipe,
    private store: Store<{ darkMode: boolean }>
  ) {
    tooltipConfig.openDelay = 750;
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    this.getUserDetails();
    this.route.queryParams.subscribe((params) => {
      this.getBookings(params.date || this.currentDate);
    });
  }

  public getUpcomingBookings(): void {
    this.upcoming = new Array<Desk>();

    this.deskService.getUpcomingDeskBookings(this.currentUser.userId).subscribe(
      success => {
        success.data.upcomingUserBookings.forEach(a => a.bookingDate= new Date(a.bookingDate).getTime())
        success.data.upcomingUserBookings.sort((a, b) => a.bookingDate - b.bookingDate);
        this.upcoming = success.data.upcomingUserBookings;
        this.upcoming.forEach(value => {
          value["bookingDate"] = moment(value.bookingDate).format('ddd D/MM/YY');
        });
      },
      error => {
        console.log(error);
        this.alert = { message: error.message, alertClass: 'danger', fadeOut: false };
      }
    );
  }

  public getBookings(date: string): void {

    this.date = formatDate(date, 'yyyy-MM-dd', 'en-GB') ;
    this.desks = new Array<Desk>();
    this.deskClicked = null;
    this.deskPicked = false;
    
    this.deskService.getAllDeskBookings(this.date).subscribe(
      success => {

        // TEMPORARY TRIMS
        // This needs fixing in the API
        success.data.deskBookings.forEach(db => {
          db.deskReferences = db.deskReferences.trim();
          if (db.bookingSession) { 
            db.bookingSession = db.bookingSession.trim(); 
          }
        });

        // Update API call to return all desks, and bookings array within each
        // Front-end team to spec improved response

        // Get list of all desks (no bookings)
        let desks = this.groupBy.transform(success.data.deskBookings, 'deskID');
        // this.desks = Object.keys(desks).map(desk => { return new Desk(desks[desk][0]) });
        //////////////////////////////////////////////////////////////////////////////////////////   FIX ////////////////////////////////////////////////
        this.desks = Object.keys(desks).map((d) => {
          let { [d]: desk } = desks;
          let { [0]: uniqueDesk } = desk;
          return new Desk(uniqueDesk);
        });

        // temporarily remove desks with rerefence 7, 11, 15, 19, 23, 28
        // TODO: tweak this 2 lines below to add/remove desks from the booking table
        let tempDesks = this.desks.filter(item => !['7','11','15','19','23','320', '352'].includes(item.deskReferences));
        this.desks = tempDesks
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        
        // Block booking for decommisioned desks
        this.desks = this.getAvailableDesks(date);
        
        // below lists deskReferences in number order
        this.desks.sort((a, b) => {
          const parsedA = parseInt(a.deskReferences, 10);
          const parsedB = parseInt(b.deskReferences, 10);
          return parsedA - parsedB;
        });

        // Get list of all bookings (no desk references)
        // let bookings = success.data.deskBookings.filter(booking => booking.bookingID);
        this.bookings = success.data.deskBookings.filter(booking => booking.bookingID);


        //////// Moved to floorplan.component.ts because with slow networks the svg file was rendering before the correct colors 
        ////////   for the desks where assigned 
        // Map bookings and color circles
        // this.desks.forEach(desk => {
        //   desk = desk.mapBookings(desk, this.bookings);
        //   setTimeout(() => {
        //     const circle = document.getElementById(desk.deskReferences);
        //     if (circle) {
        //       circle.setAttribute('fill', desk.color);
        //     }
        //   }, 200);
        // });

        // Save date to URL
        this.router.navigate([], { queryParams: { date: this.date }, queryParamsHandling: 'merge' });

      },
      error => {
        console.log(error);
        this.alert = { message: error.message, alertClass: 'danger', fadeOut: false };
      }
    )
  }

  getAvailableDesks(date:string): Array<any> {
    const decommisionDates: Array<{ date: string, desks: Array<string>}> = [
      {
        date: '2024-11-01',
        desks: ['1', '3', '27', '28', '29']
      },
      {
        date: '2024-11-25',
        desks: ['2', '4', '5', '14', '16', '17', '18', '20', '21', '22', '24', '25', '26', '306', '325', '340', '350']
      },
      {
        date: '2024-11-29',
        desks: ['6', '8', '9', '10', '12', '13']
      }
    ];
    let desks: Array<any> = this.desks;
    decommisionDates.forEach((item:{ date: string, desks: Array<string>}) => {
      if(moment(date).isSameOrAfter(item.date)) {
        desks = desks.filter((desk:any) => !item.desks.includes(desk.deskReferences));
      }
    })
    return desks;
  }

  public createBooking(desk: string, session: string, reference: string): void {
    let bookingDetails = {
      bookingDate: this.date,
      bookingSession: session,
      bookingDeskId: +desk
    };
    this.deskService.createBooking(bookingDetails).subscribe(
      success => {
        this.alert = { message: `<strong>Booking successful.</strong> Desk ${reference} booked for ${session} of ${formatDate(this.date, 'EEEE, d MMMM y', 'en-GB')}`, alertClass: 'success', fadeOut: true };
        this.getBookings(this.date);
        this.getUpcomingBookings();
      },
      error => {
        console.log('Error: ', error);
      }
    );
  }

  public onTableTdClick = (primaryKey: any): void => {
    this.upcoming.forEach(booking => {
      if (booking.bookingID == primaryKey) {
        console.log(this.deletedBooking);

        this.deletedBooking = booking;
      };
    })
    this.cancelBooking(this.deletedBooking, 'Cancelled')
  };

  public cancelBooking(booking: any, status: string): void {
    let bookingDetails = {
      bookingId: booking.bookingID,
      bookingStatus: status
    };
    this.deskService.modifyBooking(bookingDetails).subscribe(
      success => {
        this.alert = { message: `<strong>Booking cancelled.</strong> Your desk booking has been successfully cancelled.`, alertClass: 'success', fadeOut: true };
        this.getBookings(this.date);
        this.getUpcomingBookings();
      },
      error => {
        this.alert = { message: error.error.error.message, alertClass: 'danger', fadeOut: true };
        console.log('Error: ', error);
      }
    );
  }

  private getUserDetails(): void {
    this.userService.getUser().subscribe(
      success => {
        this.currentUser = new User(success.data);
        let fullName = this.currentUser.fullName.split(' ');
        this.currentUser.shortName = `${fullName[0]} ${fullName[1].substring(0, 1)}`;
        this.getUpcomingBookings();
      },
      error => {
        console.log('Error: ', error);
        this.alert = { message: error.error.error.message, alertClass: 'danger', fadeOut: false };
      }
    );
  }

  public deskClick(reference: string): void {
    this.deskClicked = this.desks.find((desk) => desk.deskReferences == reference);
    this.deskPicked = true;
  }

  public deskHover(reference: string): void {
    if (!this.deskPicked) {
      this.deskClicked = this.desks.find((desk) => desk.deskReferences == reference);
    }
  }

  public onRowClick = (primaryKey: number): void => {
    this.deskClicked = this.desks.find((desk) => desk.deskId == primaryKey);
    this.deskPicked = true;
  }
  
}
