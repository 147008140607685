import { createReducer, on } from "@ngrx/store";
import { loadOrganisationListSuccess } from "./organisation-list.actions";
import { IOrganisation } from "../core/models/organisation.vm";

export interface OrganisationListState {
    organisationlist: any;
};

const initialState: OrganisationListState = {
    organisationlist: null
};

export const organisationListReducer = createReducer(
    initialState,
    on(loadOrganisationListSuccess, (state, action) => {
        return {
            ...state,
            organisationlist: action.organisationlist
        }
    })
);