<div *ngIf="yearList">
    <ng-template #tooltipContent> Year </ng-template>
    <div ngbDropdown>
        <button class="btn btn-outline-secondary" [disabled]="yearDisabled" [ngbTooltip]="tooltipContent" id="dropdownYear" ngbDropdownToggle>
            {{ currentYear }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownYear" class="mt-2">
            <button *ngFor="let year of yearList" (click)="selectYear(year)" [class.active]="currentYear == year" ngbDropdownItem>{{ year }}</button>
        </div>
    </div>
</div>
