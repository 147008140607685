import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const TierTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Tier ID',
        dataKey: 'id',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Type',
        dataKey: 'type',
        percWidth: 0,
        sortable: false,
        groupable: true,
        searchable: false
    },
    {
        header: 'Name (hidden)',
        dataKey: 'name',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Name',
        dataKey: 'displayName',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: false,
        exportable: false
    },
    {
        header: 'Chart Type',
        dataKey: 'defaultChartType',
        percWidth: 15,
        sortable: false,
        groupable: true,
        searchable: false
    },
    {
        header: 'Visible',
        dataKey: 'isVisible',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Submission Level',
        dataKey: 'submissionLevel',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Service',
        dataKey: 'serviceItemName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Report ID',
        dataKey: 'reportId',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'View Count',
        dataKey: 'reportViewCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    }
];

export const TierDetailsFormBlocks: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Tier Details',
        helpText: 'This section edits the details of the tier (left-hand side of the outputs).',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Tier Name',
        dataKey: 'name',
        blockType: 'input',
        colWidth: 12
    },
    {
        label: 'Visibility',
        dataKey: 'isVisible',
        blockType: 'select',
        options: [
            { id: 'Y', name: 'Visible' },
            { id: 'H', name: 'Hidden (Active Admin only)' },
            { id: 'N', name: 'Not Visible' },
        ],
        colWidth: 4
    },
    {
        label: 'Report',    
        dataKey: 'reportId',
        blockType: 'searchDropdown',
        colWidth: 4,
        optionsType: 'projectReports'
    },
    {
        label: 'Default Year',
        dataKey: 'defaultYear',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4,
        disabled: true
    },
]

export const ReportDetailsFormBlocks: IDynamicFormBlock[] = [
  {
    blockType: 'divider',
  },
  {
    label: 'Report Details',
    helpText:
      'This section edits the basic details of the report linked to the tier above.',
    blockType: 'header',
    colWidth: 12,
  },
  {
    label: 'Report Name',
    dataKey: 'reportName',
    blockType: 'input',
    colWidth: 12,
  },
  {
    label: 'Service Item Name',
    dataKey: 'serviceItemName',
    blockType: 'select',
    softOptions: true,
    colWidth: 4,
  },
  {
    label: 'Format Modifier',
    helpText: 'Format of data being shown',
    dataKey: 'formatModifier',
    blockType: 'select',
    options: Constants.formatModifier,
    colWidth: 4,
  },
  {
    label: 'Decimal Places',
    helpText: 'Number decimal places on the chart',
    dataKey: 'decPlaces',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4,
  },
  {
    label: 'Max Axis Value',
    helpText: 'Max value on the data axis',
    dataKey: 'maxValue',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4,
  },
  {
    label: 'Axis Label',
    helpText: 'Overrides the report name on the data axis',
    dataKey: 'axisLabel',
    blockType: 'input',
    colWidth: 8,
  },
  {
    label: 'Sort Order',
    helpText: 'For bar and column charts only',
    dataKey: 'sortOrder',
    blockType: 'select',
    options: [
      { id: '', name: 'Descending (default)' },
      { id: 'ASC', name: 'Ascending' },
    ],
    colWidth: 4,
  },
  {
    label: 'Hide Codes',
    helpText: 'Hides submission codes',
    dataKey: 'hideCodes',
    blockType: 'select',
    colWidth: 4,
    options: Constants.yesNo,
  },
  {
    label: 'Show Nulls',
    helpText: 'Show null values',
    dataKey: 'showNulls',
    blockType: 'select',
    colWidth: 4,
    options: Constants.yesNo,
  },
  {
    label: 'Select Submissions',
    helpText: 'Enable submissions drop-down',
    dataKey: 'selectSubmissions',
    blockType: 'select',
    colWidth: 4,
    options: Constants.yesNo,
  },
  {
    blockType: 'divider',
  },
  {
    label: 'View Types',
    helpText: 'The first view in the order will be the default.',
    blockType: 'header',
    colWidth: 12,
  },
  {
    dataKey: 'reportTypes',
    blockType: 'array',
    colWidth: 12,
    children: [
      {
        label: 'View Code',
        dataKey: 'chartType',
        blockType: 'input',
        inputType: 'text',
        disabled: true,
        colWidth: 3,
      },
      {
        label: 'Chart Types',
        dataKey: 'chartTypeDupe',
        blockType: 'select',
        options: Constants.chartTypes,
        colWidth: 6,
      },
      {
        label: 'Order',
        dataKey: 'displaySequence',
        blockType: 'input',
        inputType: 'number',
        colWidth: 3,
      },
    ],
  },
  {
    blockType: 'breaker',
  },
  {
    label: '<p></p>',
    blockType: 'body',
    colWidth: 12,
  },
  {
    blockType: 'divider',
  },
  {
    label: 'Report Options and Parameters',
    helpText: 'This section defines the data used to create the report.',
    blockType: 'header',
    colWidth: 12,
  },
  {
    label: '<p></p>',
    blockType: 'body',
    colWidth: 12,
  },
];

export const ReportOptionTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'optionId',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Option Name',
        dataKey: 'optionName',
        percWidth: 60,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Order',
        dataKey: 'displaySequence',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Format Modifier',
        dataKey: 'formatModifier',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: '# Series',
        dataKey: 'seriesCount',
        percWidth: 10,
        sortable: false,
        groupable: false,
        searchable: false
    },
];

export const ReportviewDetailsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Time Accessed',
        dataKey: 'timeAccessed',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Full Name',
        dataKey: 'fullName',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Option',
        dataKey: 'denominatorName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
];

export const OptionDetailsFormBlocks: IDynamicFormBlock[] = [
  {
    label: 'Name',
    dataKey: 'optionName',
    blockType: 'input',
    inputType: 'text',
    colWidth: 6,
  },
  {
    label: 'Option Title',
    dataKey: 'titleOptionName',
    helpText: 'This is the text that replaces the placeholder (*|OPTION_TITLE|*)',
    blockType: 'input',
    inputType: 'text',
    colWidth: 6,
  },
  {
    label: 'Format modifier',
    dataKey: 'formatModifier',
    blockType: 'select',
    options: Constants.formatModifier,
    colWidth: 3
  },
];


export const SeriesDetailsFormBlocks: IDynamicFormBlock[] = [
  {
    label: 'Series Name',
    dataKey: 'seriesName',
    blockType: 'input',
    colWidth: 8,
    disabled: true
  },
  {
    label: 'Display Sequence',
    dataKey: 'displaySequence',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4
  },
];

export const ParametersTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'parameterId',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Type',
        dataKey: 'parameterType',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Question ID',
        dataKey: 'questionId',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Question Part',
        dataKey: 'questionPart',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Constant',
        dataKey: 'constantValue',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Mandatory',
        dataKey: 'isMandatory',
        percWidth: 20,
        sortable: false,
        groupable: false,
        searchable: false
    },
];

export const ParameterDetailsFormBlocks: IDynamicFormBlock[] = [
    {
        label: 'Parameter Type',
        dataKey: 'parameterType',
        blockType: 'select',
        options: [
            { id: 'N', name: 'Numerator' },
            { id: 'D', name: 'Denominator' },
            { id: 'F', name: 'Factor' },
            { id: 'U', name: 'Vacancy Rate (numerator)' },
            { id: 'V', name: 'Vacancy Rate (denominator)' }
        ],
        colWidth: 4
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Question ID',
        dataKey: 'questionId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    },
    {
        label: 'Question Part',
        dataKey: 'questionPart',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Constant Value',
        dataKey: 'constantValue',
        blockType: 'input',
        inputType: 'number',
        colWidth: 4
    },
    {
        label: 'Mandatory',
        dataKey: 'isMandatory',
        blockType: 'select',
        colWidth: 4,
        options: Constants.yesNo
    }
];

export const MetadataFormBlocks: IDynamicFormBlock[] = [
    {
        helpText: 'DEV HUB ONLY',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Generic Stored Procedure',
        dataKey: 'genericSp',
        blockType: 'input',
        disabled: true,
        colWidth: 4
    },
    // {
    //     label: 'Stored Procedure',
    //     dataKey: 'storedProcedure',
    //     blockType: 'select',
    //     options: [],
    //     colWidth: 8
    // },
]