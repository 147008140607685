import { INavItem } from "../core/models/nav-item.vm";
import { IDynamicTableColumn } from "../core/models/dynamic-table-column.vm";
import { IDynamicPresetFilter } from "../core/models/dynamic-table-filter.vm";

export const ProjectNavItems: INavItem[] = [
    {
        icon: 'fa-house',
        label: 'Details',
        link: '../details'
    },
    {
        label: 'Registrations',
        icon: 'fa-tasks',
        link: '../registrations',
    },
    {
        icon: 'fa-map-marker-alt',
        label: 'Submissions',
        link: '../submissions'
    },
    {
        icon: 'fa-th-list',
        label: 'Data Collection',
        link: '../data-collection',
    },
    {
        icon: 'fa-database',
        label: 'Responses',
        link: '../responses'
    },
    {
        icon: 'fa-chart-bar',
        label: 'Toolkit',
        link: '../toolkit'
    },
    {
        icon: 'fa-comment-check',
        label: 'Validation',
        link: '../validation',
    },
    {
        icon: 'far fa-th',
        label: 'Dashboard',
        link: '../dashboard',
    },
    {
        icon: 'fas fa-file-upload',
        label: 'Data Uploads',
        link: '../data-uploads',
    },
    {
        label: 'More',
        children: [
            {
                icon: 'fa-file-alt',
                label: 'Dynamic Documents',
                link: '../dynamic-documents',
            },
            {
                icon: 'fa-file-alt',
                label: 'Static Documents',
                link: '../static-documents',
            }
        ]
    }
];

export const ProjectDetailNavItems: INavItem[] = [
    {
        label: 'Basics',
        link: '../details',
        scope: 'basics'
    },
    {
        label: 'Roles',
        link: '../details',
        scope: 'roles'
    },
    {
        label: 'Dates',
        link: '../details',
        scope: 'dates'
    },
    {
        label: 'Services',
        link: '../details',
        scope: 'services'
    },
    {
        label: 'Guidance',
        link: '../details',
        scope: 'guidance'
    },
    {
        label: 'Surveys',
        link: '../details',
        scope: 'surveys'
    },
    //TODO: uncomment the following lines of code once the Peer Groups page has been reviewed
    // {
    //     label: 'Peer Groups',
    //     link: '../details',
    //     scope: 'peer-groups'
    // }
];

export const ProjectTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Project ID',
        dataKey: 'projectId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Project Name',
        percWidth: 50,
        dataKey: 'projectName',
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Category',
        dataKey: 'categoryName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'category'
    },
    {
        header: 'Visible',
        dataKey: 'isVisible',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Registration Open',
        dataKey: 'registrationAvailable',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Data Collection Open',
        dataKey: 'dataCollectionOpen',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    }
];

export const ProjectTableFilters: IDynamicPresetFilter[] = [
    {
        name: 'Archived', 
        key: 'isVisible', 
        value: 'Archived', 
        hideValue: true
    }
];