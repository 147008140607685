<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <app-dynamic-table 
        *ngIf="followups" 
        [data]="followups"
        [columns]="followupsTableColumns"
        [defaultGroupedColumn]="'deadline'"
        [currentYear]="currentYear"
        [tableTitle]="'responseList'"
        [primaryKey]="'interactionId'"
        [onRowClick]="onFollowUpClick">
    </app-dynamic-table>

     <app-slider-panel *ngIf="selectedFollowUp" [onClose]="closeFollowUpSlider">

        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>

        <app-dynamic-form [data]="selectedFollowUp" [blocks]="followupsDetailsForm"></app-dynamic-form>
    </app-slider-panel>
</ng-template>
