<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !selectedRegistration); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.registrations" 
        [data]="currentProject.registrations" 
        [columns]="registrationTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'registrationList'"
        [primaryKey]="'registrationId'"
        [onRowClick]="onRegistrationClick">
    </app-dynamic-table>

    <!-- Selected Registration -->
    <app-slider-panel *ngIf="selectedRegistration" [onClose]="closeRegistrationSlider">
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
            <!-- Heading -->
            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
            <!-- Form -->
            <app-dynamic-form [data]="selectedRegistration" [blocks]="registrationDetailsFormBlocks"></app-dynamic-form>
            <!-- Table: Deputy Project Leads -->
            <ng-container *ngIf="selectedRegistration.deputyProjectLeads" >
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Deputy Project Leads</h4>
                <app-dynamic-table                     
                    [data]="selectedRegistration.deputyProjectLeads" 
                    [columns]="registrationDeputyProjectLeadTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'deputyProjectLeads'"
                    [primaryKey]="'registrationId'"      
                    [disableRowClick]="true"
                    [hideExportButton]="true"
                    [hideSearch]="true">
                </app-dynamic-table>
            </ng-container>
            <!-- Table: Submissions -->
            <ng-container *ngIf= "selectedRegistration.submissions" >
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Submissions</h4>
                <app-dynamic-table                     
                    [data]="selectedRegistration.submissions" 
                    [columns]="registrationSubmissionTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'submissionList'"
                    [primaryKey]="'registrationId'"
                    [disableRowClick]="true" 
                    [hideExportButton]="true"
                    [hideSearch]="true">      
                </app-dynamic-table>
            </ng-container>     
        </ng-container>
    </app-slider-panel>

</ng-template>