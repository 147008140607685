import { INavItem } from "src/app/core/models/nav-item.vm";

export const dataExtractorNavItems: INavItem[] = [
    {
        icon: 'fa-house',
        label: 'New Extract',
        link: '/tool/data-extract'
      },
      {
        icon: 'fa-tasks',
        label: 'Library',
        link: '/tool/data-extract-library',
      }
]