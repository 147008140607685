import { Component, EventEmitter, Input, OnChanges, Output, AfterContentInit, AfterContentChecked, AfterViewInit, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'app-alert-status',
  templateUrl: './alert-status.component.html',
  styleUrls: ['./alert-status.component.scss'],
})
export class ErrorStatusComponent implements OnChanges {


  @Input() public loadingError?: string;
  @Input() public loadingMessage?: string;
  @Input() public alert?: { message: string, alertClass: string, fadeOut: boolean };
  @Input() public parent?: string;


  @Output() public emitAlertCancelled = new EventEmitter<any>();
  @Output() public removeAlertBackDrop = new EventEmitter<any>();

  public dismissAlertButton: HTMLElement;

  constructor() { }

  ngOnChanges(): void {
    if (this.alert && this.alert.fadeOut == true) {
      setTimeout(() => {
        this.emitAlertCancelled.emit(null);
      }, 4000)
    }
  }

  ngAfterViewInit() {
    const alertMessage = document.getElementById("alert")
    this.dismissAlertButton = document.getElementById("dismiss-alert")  
    if (this.parent == "data-extractor" && alertMessage) {     
        alertMessage.appendChild(this.dismissAlertButton)
      }
    }

  ngAfterViewChecked() {
    const alertMessage = document.getElementById("alert")
    if (this.parent == "data-extractor" && alertMessage) {     
        alertMessage.appendChild(this.dismissAlertButton)
      }
    }


  closeAlert() {
    this.alert = null
    const alertMessage = document.getElementById("alert")
    this.removeAlertBackDrop.emit()
  }

}

