import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IUser } from 'src/app/core/models/user.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  @Input() public deskClicked: any;
  @Input() public currentUser: IUser;
  @Input() public date: string;

  @Output() emitCreateBooking = new EventEmitter<{desk: string, session: string, reference: string}>();
  @Output() emitCancelBooking = new EventEmitter<{booking: any, status: string}>();
  @Output() emitCancelDesk = new EventEmitter<any>();

  public bookingOptions: any = [
    {
      title: "All Day",
      dataKey: "adBooking",
      shortReference: "All"
    },
    {
      title: "Morning (8am to 1pm)",
      dataKey: "amBooking",
      shortReference: "AM"
    },
    {
      title: "Afternoon (1pm to 6pm)",
      dataKey: "pmBooking",
      shortReference: "PM"
    }
  ]

  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void { }

  public createBooking(desk: string, session: string, reference: string): void {
    this.emitCreateBooking.emit({ desk: desk, session: session, reference: reference });
  }

  public cancelBooking(booking: any, status: string): void {
    this.emitCancelBooking.emit({ booking: booking, status: status });
  }

  public cancelDeskClick(): void {
    //this.deskClicked = null;
    this.emitCancelDesk.emit(null);
  }

}
