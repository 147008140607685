import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OrganisationSubmissionDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Submission Details',
        blockType: 'header',
    },
    {
        label: 'Project Name',
        dataKey: 'projectName',
        blockType: 'input',
        colWidth: 12,
        disabled: true
    },
    {
        label: 'Submission Name',
        dataKey: 'submissionName',
        blockType: 'input',
        colWidth: 12
    },
    {
        label: 'Submission Level',
        dataKey: 'submissionLevel',
        helpText: 'Select organisation or submission level',
        blockType: 'select',
        options: [
            { id: '', name: 'Submission level' },
            { id: 'O', name: 'Organisation level' },
        ],
        colWidth: 4
    },
    {
        label: 'Submission Code',
        dataKey: 'submissionCode',
        helpText: 'Edit numeric element only',
        blockType: 'input',
        disabled: true,
        colWidth: 4
    },
    {
        label: 'Peer Group',
        dataKey: 'peerGroupName',
        helpText: 'List of available peer groups needed',
        blockType: 'input',
        disabled: true,
        colWidth: 4
    },
    {
        label: 'Submitted/Locked by',
        dataKey: 'submittedByFullName',
        blockType: 'input',       
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Submitted/Locked on',
        dataKey: 'submittedDt',
        blockType: 'input',
        inputType: 'datetime-local',     
        colWidth: 6,
        disabled: true
    }
]

export const OrganisationSubmissionTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Project',
        dataKey: 'projectName',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Sub. ID',
        dataKey: 'submissionId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Sub. Code',
        dataKey: 'submissionCode',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Sub. Name',
        dataKey: 'submissionName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Level',
        dataKey: 'submissionLevel',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Peer Group',
        dataKey: 'peerGroupName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: '# Services',
        dataKey: 'submissionServiceCount',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '# Responses',
        dataKey: 'responseCount',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    }
];