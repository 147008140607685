import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class CrmService {
    private nhsApiUrl: string = environment.nhsApiUrl;

    constructor(private http: HttpClient, public auth: AuthService) {}

    public getFollowUps(): Observable<any> {
      return this.http.get(this.nhsApiUrl + 'crm/' + 'getAllFollowUps', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()), 
      });
    }

    public getOpportunities(): Observable<any> {
      return this.http.get(this.nhsApiUrl + 'crm/' + 'getAllOpportunities', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
      });
    }

    public getAllInteractions(opportunityId: number): Observable<any> {
      return this.http.get(this.nhsApiUrl + 'crm/' + 'getAllInteractions', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('opportunityId', opportunityId.toString())
      });
    }
    public getInteractions(): Observable<any> {
      return this.http.get(this.nhsApiUrl + 'crm/' + 'getAllInteractions',
    {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
    });
  }

    public getInteractionDetail(interactionId: number): Observable<any> {
      return this.http.get(this.nhsApiUrl + 'crm/' + 'getInteractionDetail',
    {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('interactionId', interactionId.toString()),
    }
    );
  }
}