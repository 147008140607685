<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-11 col-12">

                <div class="row mt-5 mb-3">
                    <div class="col-md-7 col-12" *ngIf="layout">
                        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                    </div>
                </div>
                <div class="row justify-content-start mt-5" *ngIf="currentUser">
                    <div class="col-md-2">
                        <app-section-nav [navItems]="profileNavItems"></app-section-nav>
                    </div>
                    <div class="col-md-10">
                        <app-dynamic-form *ngIf="currentScope == 'basics'" [data]="currentUser" [blocks]="profileBasics"></app-dynamic-form>
                        <app-dynamic-table 
                            *ngIf="currentScope == 'roles'" 
                            [data]="currentUser.roles" 
                            [columns]="profileRoles" 
                            [currentYear]="currentYear"
                            [tableTitle]="'userRoles'"
                            [primaryKey]="'roleType'"
                            [onRowClick]="onUserRoleClick">
                        </app-dynamic-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Selected Role -->
<app-slider-panel *ngIf="currentUserRole" [onClose]="closeUserRoleSlider">
    <!-- Heading -->
    <app-area-heading [heading]="roleLayout.heading" [subheading]="roleLayout.subheading" [backRoute]="roleLayout.backRoute"></app-area-heading>
    <!-- Form -->
    <app-dynamic-form [data]="currentUserRole" [blocks]="profileRolesDetailsForm"></app-dynamic-form>
</app-slider-panel>