import { createReducer, on } from "@ngrx/store";
import { IProjectUser } from "../core/models/project-user.vm";
import { loadAdminUsersSuccess } from "./admin-userlist.actions";

export interface UserListState {
    userlist: IProjectUser[]
};

const initialState: UserListState = {
    userlist: null
};

export const adminUserListReducer = createReducer(
    initialState,
    on(loadAdminUsersSuccess, (state, action) => {
        return {
            ...state,
            userlist: action.userlist,
        }
    })
);