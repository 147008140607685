import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';

export const UsersTableColumns: IDynamicTableColumn[] = [
  {
    header: 'User ID',
    dataKey: 'ID',
    percWidth: 5,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Name',
    dataKey: 'fullname',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Job Title',
    dataKey: 'jobtitle',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Email',
    dataKey: 'email',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Organisation ID',
    dataKey: 'organisationId',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Project',
    dataKey: 'projects',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Comment',
    dataKey: 'comment',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
  },
];

export const topLevelUserNavItems: INavItem[] = [
  {
    icon: 'fa-house',
    label: 'Current Users',
    link: '/users',
  },
  {
    label: 'Pending Users',
    icon: 'fa-tasks',
    link: '/users-pending',
  },
];

export const UserPendingForm: IDynamicFormBlock[] = [
  {
    blockType: 'divider',
  },
  {
    label: 'User Details',
    blockType: 'header',
    colWidth: 8,
  },
  {
    label: 'Organisation',
    dataKey: 'organisationId',
    blockType: 'searchDropdown',
    colWidth: 8,
    optionsType: 'organisations',
  },
  {
    label: 'Job Title',
    dataKey: 'jobtitle',
    blockType: 'input',
    colWidth: 8,
    disabled: true,
  },
  {
    label: 'Projects',
    dataKey: 'projects',
    blockType: 'input',
    colWidth: 8,
    disabled: true,
  },
  {
    blockType: 'addingRoles',
  },
  {
    blockType: 'acceptRejectButton'
  }
];