<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<!-- Organisation List -->
<ng-template #notLoading>
    <ng-container *ngIf="!currentOrganisation">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-11 col-12">

                    <div class="row mt-5 mb-3">
                        <div class="col-md-7 col-12" *ngIf="layout">
                            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                        </div>
                        <div class="col-md-5 col-12 d-flex">
                            <div class="ml-auto">
                                <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                            </div>
                        </div>
                    </div>
    
                    <app-dynamic-table
                        [data]="organisationList" 
                        [columns]="organisationTableColumns" 
                        [tableTitle]="'organisationList'"
                        [primaryKey]="'organisationId'"
                        [onRowClick]="onTableRowClick"
                        [dynamicButton]="addOrganisationButton"
                        (buttonClicked)="openAddOrganisationSlider()">
                    </app-dynamic-table>

                </div>
            </div>
        </div>
    </ng-container>

    <!-- Add Organisation -->
    <app-slider-panel *ngIf="addOrganisationLayout" [onClose]="closeAddOrganisationSlider" [pageWidth]="30">
        <!-- Heading -->
        <app-area-heading [heading]="addOrganisationLayout.heading" [subheading]="addOrganisationLayout.subheading"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="organisationList" [blocks]="createOrganisationForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
    </app-slider-panel>
</ng-template>

<!-- Current Organisation -->
<ng-container *ngIf="currentOrganisation">
    <div class="container-fluid">
        <div class="row justify-content-center mb-5">
            <div class="col-md-11 col-12">

                <div class="row mt-5 mb-3">
                    <div class="col-md-7 col-12" *ngIf="layout">
                        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                    </div>
                    <div class="col-md-5 col-12 d-flex">
                        <div class="ml-auto">
                            <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
                        </div>
                    </div>
                </div>
      
                <app-area-nav [navItems]="organisationNavItems"></app-area-nav>
                <app-organisation-detail *ngIf="currentSection == 'details'" [currentOrganisation]="currentOrganisation"></app-organisation-detail>
                <app-organisation-product *ngIf="currentSection == 'products'" [currentOrganisation]="currentOrganisation" [currentYear]="currentYear"></app-organisation-product>
                <app-organisation-user *ngIf="currentSection == 'users'" [currentOrganisation]="currentOrganisation" [currentYear]="currentYear"></app-organisation-user>
                <app-organisation-registration *ngIf="currentSection == 'registrations'" [currentOrganisation]="currentOrganisation" [currentYear]="currentYear"></app-organisation-registration>
                <app-organisation-submission *ngIf="currentSection == 'submissions'" [currentOrganisation]="currentOrganisation" [currentYear]="currentYear"></app-organisation-submission>
                <app-organisation-mou *ngIf="currentSection == 'mou'" [currentOrganisation]="currentOrganisation" [currentYear]="currentYear"></app-organisation-mou>

            </div>
        </div>
    </div>            
</ng-container>