<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.products" 
        [data]="currentOrganisation.products" 
        [columns]="organisationProductTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'productList'"
        [primaryKey]="'productId'"
        [onRowClick]="onProductClick">
    </app-dynamic-table>

    <!-- Selected Product -->
    <app-slider-panel *ngIf="selectedProduct" [onClose]="closeProductSlider">
        <!-- Heading -->
        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="selectedProduct" [blocks]="organisationProductDetailsForm"></app-dynamic-form>
    </app-slider-panel>

</ng-template>