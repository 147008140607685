import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import { UserService } from '../core/services/user.service';
import { AuthService } from '../core/services/auth.service';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadAdminUsers } from '../store/admin-userlist.actions';
import { loadStoredProcedures } from '../store/stored-procedures.actions';
import { loadOrganisationList } from '../store/organisation-list.actions';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ AuthService ]
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public loginLoading: boolean = false;
  public loginError: string;
  private returnUrl: string;

  darkMode$: Observable<boolean>;

  constructor(
    private user: UserService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService,
    private store: Store<{ darkMode: boolean }>,
    private ref: ChangeDetectorRef,
  ) { 
    this.darkMode$ = store.select('darkMode')
  }


  ngOnInit() {
    if (this.auth.authenticated() && this.auth.isAdmin()) {
      this.router.navigate(['project']);
    } else {
      this.buildLoginForm();
      this.returnUrl = this.route.snapshot.queryParams['return'];
    }
  }

  private buildLoginForm(): void {
    this.loginForm = this.formBuilder.group({    
      username: ['', Validators.required],
      password: ['', [Validators.required]]
    });
  }

  public login(): void {
    this.loginLoading = true;
    this.loginError = null;
    this.user.getToken(this.loginForm.value.username, this.loginForm.value.password).subscribe(
      success => { 
        // Check user is an admin
        if (this.auth.isAdmin()) {
          // Get admin users and store
          this.store.dispatch(loadAdminUsers());
          this.store.dispatch(loadStoredProcedures());
          this.store.dispatch(loadOrganisationList());

          // Navigate to return URL or direct to projects
          if (this.returnUrl) {
            this.router.navigateByUrl(this.returnUrl);
          } else {
            this.router.navigate(['project']);
          }
        } else {
          this.logout();
        }
      },
      error => { 
        console.log(error);
        this.loginLoading = false;
        this.loginError = error 
        this.ref.detectChanges();
      }
    );
  }

  public logout(): void {
    localStorage.removeItem('adminUserToken');
    sessionStorage.clear();
    this.loginLoading = false;
    this.loginError = "You are not an admin user. Contact Dev Hub for support."
    this.ref.detectChanges();
  }

}
