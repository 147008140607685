import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const UsersActivityTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Date and time',
        percWidth: 40,
        dataKey: 'date',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Action',
        dataKey: 'action',
        percWidth: 60,
        sortable: true,
        groupable: false,
        searchable: true,
    },
]

export const UserActivityDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Activity Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Date and time',
        dataKey: 'date',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Action',
        dataKey: 'action',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
]