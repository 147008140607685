import { IDynamicTableColumn } from "../core/models/dynamic-table-column.vm";

export const DeskTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Desk Ref.',
        dataKey: 'deskReferences',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '# Screens',
        dataKey: 'noScreens',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Dock Type',
        dataKey: 'dockingStation',
        percWidth: 25,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'All Day',
        dataKey: 'userNameAd',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Morning',
        dataKey: 'userNameAm',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Afternoon',
        dataKey: 'userNamePm',
        percWidth: 15,
        sortable: false,
        groupable: false,
        searchable: true
    },
];

export const DeskBookingsColumns: IDynamicTableColumn[] = [
    {
        header: 'Date',
        dataKey: 'bookingDate',
        percWidth: 40,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Desk',
        dataKey: 'deskReferences',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Session',
        dataKey: 'bookingSession',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '',
        percWidth: 20,
        dataKey:'',
        sortable: false,
        groupable: false,
        searchable: false,
        options: ['clickable'],
        button: 'set',
        actions: [
            {
              color: 'btn btn-danger btn-sm',
              action: 'set',
              description: 'Remove',
            },
          ],
      }
];