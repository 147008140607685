import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import exceljs from 'exceljs';
import fileSaver from 'file-saver';

@Component({
  selector: 'app-table-export-search',
  templateUrl: './table-export-search.component.html',
  styleUrls: ['./table-export-search.component.scss'],
})
export class TableExportSearchComponent {
  @Input() public data: any;
  @Input() public columns: Array<IDynamicTableColumn>;
  @Input() public tableTitle: string;
  @Input() public hideSearch: boolean;
  @Input() public hideExportButton: boolean;
  @Input() public searchableColumns: Array<string>;
  @Output() public searchValue = new EventEmitter<String>();

  public searchText: string = '';
  public searchPlaceholder: string = 'Search...';

  constructor(public tooltipConfig: NgbTooltipConfig) {
    tooltipConfig.openDelay = 750;
  }

  // Clears input if user has not typed
  public clearInput(clickAway: boolean): void {
    if (this.searchText == '' && clickAway) {
      this.applyInputSettings('Search...', null);
      setTimeout(() => {
        this.applyInputSettings('Search...', undefined);
      }, 200);
    } else if (!clickAway) {
      this.searchText = '';
      this.applyInputSettings('Search...', undefined);
    }
  }

  // Applying the correct placeholder and emit value
  public applyInputSettings(placeholder: string, value: any) {
    this.searchPlaceholder = placeholder;
    this.searchValue.emit(value);
  }

  // Clears input or keeps it if user selects from table
  public searchInput(event: any) {
    if (event == '') {
      this.applyInputSettings('Search...', undefined);
    }
    if (event == null && this.searchText !== '') {
      this.applyInputSettings(this.searchPlaceholder, this.searchText);
    } else {
      let placeholder = 'Search highlighted columns...';
      this.applyInputSettings(placeholder, event);
    }
  }

  public async downloadAsCsv(data: Array<any>, filename: string) {
    const { Workbook } = exceljs;
    const { saveAs } = fileSaver;

    const wb = new Workbook();
    const ws = wb.addWorksheet('Sheet 1');

    const filteredColumns = this.columns.filter((column) => {
      return column.exportable != false
    });

    ws.columns = filteredColumns.map((column) => {
      return {
        header: column.header,
        key: column.dataKey,
      };
    });

    data.forEach((row) => {
      const values: Array<any> = [];
      filteredColumns.forEach((column) => {
        values.push(row[column.dataKey]);
      });
      ws.addRow(values);
    });

    const csv_buffer = await wb.csv.writeBuffer();
    saveAs(new Blob([csv_buffer]), `${filename}.csv`);
  }
}
