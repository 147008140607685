import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Layout } from 'src/app/core/classes/layout';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IOrganisationSubmission } from 'src/app/core/models/organisation-submission.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { OrganisationSubmissionDetailsForm, OrganisationSubmissionTableColumns } from './organisation-submission-layouts';

@Component({
  selector: 'app-organisation-submission',
  templateUrl: './organisation-submission.component.html',
  styleUrls: ['./organisation-submission.component.scss']
})
export class OrganisationSubmissionComponent implements OnChanges {

  @Input() public currentOrganisation: Organisation;
  @Input() currentYear: number;

  public selectedSubmission: IOrganisationSubmission = null;
  public layout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public organisationSubmissionTableColumns: Array<IDynamicTableColumn> = OrganisationSubmissionTableColumns;
  public organisationSubmissionDetailsForm: Array<IDynamicFormBlock> = OrganisationSubmissionDetailsForm;

  constructor(
    public loaderService: LoaderService,
    private organisationService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnChanges(): void {
    // Check for open submission
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    // Get submissions, if needed
    if (!this.currentOrganisation.submissions) {
      this.getOrganisationSubmissions(this.currentOrganisation.organisationId, this.currentYear);
    }
  }

  private getOrganisationSubmissions(organisationId: number, currentYear?): void {
    this.organisationService.getOrganisationSubmission(organisationId, currentYear).subscribe(
      success => {        
        // if(success.data.submissionList[currentYear]) {
        //   this.currentOrganisation.submissions = success.data.submissionList[currentYear];
        // } else {
        //   this.currentOrganisation.submissions = [];
        // }
        ///////////////////////////////////////////////////////////////////// FIX  /////////////////////////////////////////////////////////////
        let { [currentYear]: currentYearSubmissions } = success.data.submissionList;
        if(currentYearSubmissions) {
          this.currentOrganisation.submissions = currentYearSubmissions;
        } else {
          this.currentOrganisation.submissions = [];
        }
        /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      

        // Open slider, if in params
        if (this.currentQueryParams.submission) {
          this.onSubmissionClick(this.currentQueryParams.submission);
        }
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onSubmissionClick = (primaryKey: string): void => {
    this.selectedSubmission = this.currentOrganisation.submissions.find(sub => sub.submissionId == +primaryKey);
    this.layout = new Layout(
      this.selectedSubmission.submissionName,
      [ `ID: ${this.selectedSubmission.submissionId}`, `Organisation: ${this.selectedSubmission.organisationName}` ],
      null
    )
    this.router.navigate([], { queryParams: { submission: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeSubmissionSlider = (): void => {
    this.selectedSubmission = null;
    this.router.navigate([], { queryParams: { submission: null }, queryParamsHandling: 'merge' });
  }

}
