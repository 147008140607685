<div *ngIf="{ darkMode: darkMode$ | async } as ui">

  <!-- disabled role input, if not table-->
  <div *ngIf="!tableData && !(section == 'rolesSlider')" class="form-group mb-3 pr-3">
    <label [ngClass]="{'text-light': ui.darkMode}" class="col-7 pl-0 mb-2">Role</label>
    <input 
      [ngClass]="{'text-light bg-dark border-dark-700': ui.darkMode}"
      class="form-control col-8" 
      type="text" 
      placeholder="none" 
      disabled="true"
      />
  </div>

  <!-- table -->  
  <div *ngIf="tableData" class="mt-3 mb-1 col-{{ section === 'rolesSlider' ? '9' : '8' }} pl-0 pr-2">
    <h4 *ngIf="section == 'rolesSlider'" class="mb-3 text-primary">Remove roles</h4>
    <div class="table-responsive rounded">
      <table class="table table-bordered">
        <thead class="bg-dark-400" [ngClass]="{'bg-dark-800 text-light': ui.darkMode}">
          <tr>
            <th [ngClass]="{'border-secondary': ui.darkMode}">Role</th>
            <th [ngClass]="{'border-secondary': ui.darkMode}">Product</th>
          </tr>
        </thead>
        <tbody [ngClass]="{'text-light': ui.darkMode}">
          <tr *ngFor="let row of tableData; let i = index">
            <td class="col-5" title="{{ getRoleNames(row.roles) }}" [ngClass]="{'border-secondary': ui.darkMode}">{{ getRoleNames(row.roles) }}</td>
            <td class="col-5" title="{{ getProductName(row.product) }}" [ngClass]="{'border-secondary': ui.darkMode}">{{ getProductName(row.product) }}</td>
            <td class="col-2" *ngIf="removeActionColumn" class="border-0">
              <i class="fas fa-md fa-trash" (click)="deleteRow(i)" title="Delete row" style="cursor: pointer; padding: 5px; color: #dc3545"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


