import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { LibraryExtractsTableColumns } from './data-extractor-library-extracts-layouts';
import { DataExtractorLibraryService } from 'src/app/core/services/data-extractor-library.service';
import { Extract } from 'src/app/core/classes/extract';
import { UserService } from 'src/app/core/services/user.service';
import { dataExtractorNavItems } from '../data-extractor-layouts';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { ToolService } from 'src/app/core/services/tool.service';
import { IAlert } from 'src/app/core/models/alert';

@Component({
  selector: 'app-data-extractor-library',
  templateUrl: './data-extractor-library.component.html',
  styleUrls: ['./data-extractor-library.component.scss'],
})
export class DataExtractorLibraryComponent implements OnInit {
  public alert: IAlert;
  public currentUrlParams: any;
  public loadingError: string = null;
  public layout: ILayout;
  public libraryExtractsTableColumns: Array<IDynamicTableColumn> = LibraryExtractsTableColumns;
  public extractList: Array<Extract> = new Array<Extract>();
  public currentExtract: Extract;
  public currentYear: number;
  public selectedYear: any;
  public currentSection: string = undefined;
  public selectedExtract: Extract = null;
  public dataExtractorNavItems: Array<INavItem> = dataExtractorNavItems;
  public currentUser: number;
  public dataExtractComplete: boolean = false;
  public dataExtractLoading: boolean = false;

  constructor(
    public loaderService: LoaderService,
    private dataExtractorLibraryService: DataExtractorLibraryService,
    private userService: UserService,
    private toolService: ToolService
  ) {}
  ngOnInit(): void {
    this.getUserDetails();
    this.layout = new Layout('Data Extractor', null, '/tool');
    this.getExtractList();
  }

  private getUserDetails(): void {
    this.userService.getUser().subscribe(
      (success) => {
        this.currentUser = success.data.userId;
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  private getExtractList(): void {
    this.currentExtract = null;
    this.dataExtractorLibraryService.getExtract().subscribe(
      (success) => {
        success.data.forEach((extract) => {
          this.extractList.push(new Extract(extract));
        });
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public submitDataExtract(body, primaryKey) {
    this.toolService.submitDataExtract(body).subscribe(
      (success) => {
        this.dataExtractComplete = true;
        this.dataExtractLoading = false;
        this.alert = {
          message: `<strong>New extract queued</strong>`,
          alertClass: 'success',
          fadeOut: true,
        };
        this.extractList.forEach((item) => {
          if (item.extractId == +primaryKey) {
            item.loading = false;
          }
          location.reload();
        });
      },
      (error) => {
        console.log('Error: ', error);
        this.alert = {
          message: `<strong>Extract Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
        };
      }
    );
  }

  public onTableTdClick = (primaryKey: any): void => {
    this.selectedExtract = this.extractList.find(
      (extract) => extract.extractId == +primaryKey
    );
    this.extractList.forEach((item) => {
      if (item.extractId == +primaryKey) {
        item.loading = true;
      }
    });
    let replacedColon = this.selectedExtract.extractParameters.replaceAll(':',',');
    let removedWhiteSpace = replacedColon.replaceAll(' ', '');
    let madeArray = removedWhiteSpace.split(',');
    let obj = {};
    let i = 0;
    while (i < madeArray.length) {
      let indexPlusOne= i + 1;
      Object.defineProperty(obj, madeArray[i], {
        value: JSON.parse(madeArray[indexPlusOne]),
        enumerable: true,
        writable: true
      });  
      i += 2;
    }
    this.submitDataExtract(obj, primaryKey);
    this.dataExtractLoading = true;
  };
}