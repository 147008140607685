//TODO: this class needs to be refactored once the "conflicts" between the datakeys requested on my task for data-extractor-library-extracts-layout
// and the datakeys in the objects received from the endpoint will be resolved
export class Extract {
  public extractId: number;
  public extractLocation: string;
  public extractName: string;
  public extractUpdated: any;
  public extractAdded: any;
  public extractParameters: any;
  public loading: any = false;

  constructor(data: any, year?: number) {
    this.mapExtractBasicDetails(data);
  }

  private mapExtractBasicDetails(data: any, year?: number): void {
    
    this.extractParameters = this.removeSlash(data.extractParameters);
    this.extractId = data.dataExtractId;
    this.extractLocation = data.status;
    this.extractName = data.title;
    this.extractAdded = data.created;
    this.extractUpdated = data.lastUpdate;
    this.loading = data.loading;
  }

  private removeSlash(data) {
    let result: string;
    if(data.includes("No data returned")) {
      result = "No data returned";
    } else {
        result = "Data returned via email";
        // result = data.replace(/['"{}\\]/g, "")
    }

    return result;
}}



