<div [formGroup]="form" [class.d-none]="block.hidden" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="form-group">
        <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }}
            <i *ngIf="block.helpText" class="far fa-info-circle form-info-icon" [title]="block.helpText" placement="right"></i>
        </label>
        <input
            [type]="block.inputType"
            class="form-control"
            [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}"
            [id]="block.dataKey"
            [formControlName]="block.dataKey"
            [attr.min]="block.minValue !== undefined ? block.minValue : null"  
            [attr.max]="block.maxValue !== undefined ? block.maxValue : null"  
            [attr.aria-describedby]="block.dataKey + 'Help'"
            (input)="(block.digitLimit ? limitDigits($event) : null); checkWhitespace($event)">      
        <app-input-placeholder *ngIf="block.placeholder"></app-input-placeholder>
        <app-input-copy *ngIf="block.copyToClip" [block]="block"></app-input-copy>       
    </div>
</div>



