import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';

@Component({
  selector: 'app-data-extractor-filter',
  templateUrl: './data-extractor-filter.component.html',
  styleUrls: ['./data-extractor-filter.component.scss'],
})
export class DataExtractorFilterComponent implements OnInit, OnChanges {
  @Input() public year: number;
  @Input() public id: number;
  @Output() newItemEvent = new EventEmitter<any>();
  public submissions: any;
  public submissionsMapped: Array<string> = [];
  public loadingError: string;
  public registrations: any;
  public registrationsMapped: Array<string> = [];
  public services: any;
  public servicesMapped: Array<string> = [];
  public shopdrodown: boolean;
  public showDropdown: boolean;
  public projectQuestionList: string;
  public disabled: any = true
  public reportomatic: any = [{boolean: 'Yes'}]

  constructor(
    private ref: ChangeDetectorRef,
    public loaderService: LoaderService,
    private projectService: ProjectService
  ) {}

  ngOnInit(): void {
    this.getProjectSubmissions(this.id, this.year);
    this.getProjectRegistrations(this.id, this.year);
    this.getProjectServiceItems(this.id);
    this.getProjectQuestionLevelId(this.id, this.year);
  }

  ngOnChanges(): void {
  }
  public newSelection(selectedItems, filterName) {

    this.newItemEvent.emit({ items: selectedItems, filterName: filterName });
  }

  public makeKey(): any {
    return {
      item: this.id + '-' + this.year,
      year: this.year,
    };
  }

  private getProjectSubmissions(projectId: number, currentYear: number) {
    this.projectService.getProjectSubmissions(projectId, currentYear).subscribe(
      (success) => {
        // this.submissions = success.data.submissionList[currentYear];
        //////////////////////////////////////////////////////////////////////////////////////////   FIX ///////////////////////////////////////////////
        let { [currentYear]: currentYearSubmissions } = success.data.submissionList;
        this.submissions = currentYearSubmissions
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        this.submissionsMapped = this.submissions.map((sub) => {
          return {
            submissionId: sub.submissionId,
            submissionName: sub.submissionName,
            submissionYear: sub.submissionYear,
          };
        });
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  private getProjectRegistrations(
    projectId: number,
    currentYear: number
  ): void {
    this.projectService
      .getProjectRegistrations(projectId, currentYear)
      .subscribe(
        (success) => {
          this.registrations = success.data.projectRegistrations;
          this.registrationsMapped = this.registrations.map((sub) => {
            return {
              organisationId: sub.organisationId,
              registrationId: sub.registrationId,
              registrationName: sub.organisationName,
            };
          });
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  private getProjectServiceItems(projectId): void {
    this.projectService
      .getProjectServiceItems(projectId)
      .subscribe((success) => {
        this.services = success.data.serviceItemList;
        this.servicesMapped = this.services.map((sub) => {
          return {
            serviceId: sub.serviceItemId,
            serviceName: sub.serviceItemName,
          };
        });
      });
  }

  private getProjectQuestionLevelId(projectId, year): void {
    this.projectService
      .getProjectQuestionLevelId(projectId, year)
      .subscribe((success) => {
        this.projectQuestionList = success.data.responseList;
      });
  }


}
