<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">

<ul class="nav border-bottom mb-4" [ngClass]="{'border-dark': ui.darkMode}">
    <ng-container *ngFor="let item of navItems">
        <!-- Navigation item -->
        <li *ngIf="!item.divider && !item.children" class="nav-item mr-3" routerLinkActive="active">
            <a class="nav-link" [class.disabled]="item.disabled" [routerLink]="item.link">
                <span [class]="item.icon" [class.fas]="!item.disabled" [class.fal]="item.disabled" class="fa-fw mr-2"></span>{{ item.label }}
            </a>
        </li>
        <!-- Divider (no link) -->
        <li *ngIf="item.divider">
            <a class="nav-link text-secondary disabled">
                |
            </a>
        </li>
        <!-- Items with children (dropdown) -->
        <li *ngIf="item.children" class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" [class.disabled]="item.disabled" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" id="areaNavMore" ngbDropdownToggle>
                <span [class]="item.icon" [class.fas]="!item.disabled" [class.fal]="item.disabled" class="mr-2"></span>{{ item.label }}
            </a>
            <div class="dropdown-menu mt-2" ngbDropdownMenu aria-labelledby="areaNavMore">
                <a *ngFor="let childItem of item.children" class="dropdown-item" routerLinkActive="active" [class.disabled]="childItem.disabled" [routerLink]="childItem.link">
                    <span [class]="childItem.icon" [class.fas]="!childItem.disabled" [class.fal]="childItem.disabled" class="fa-fw mr-2"></span>{{ childItem.label }}
                </a>
            </div>
        </li>
    </ng-container>
</ul>

</ng-container>