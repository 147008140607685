import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class OutputValidationService {
  private nhsApiUrl: string = environment.nhsApiUrl;

  constructor(private http: HttpClient, public auth: AuthService) { }

  public getProject(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'projects/list/' + projectId.toString(), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('year', year.toString())
      }
    );
  }

  public getProjectTiers(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'outputs/tiers', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    )
  }

  public getValidations(projectId: number, year: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + 'validation/getValidations', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('projectId', projectId.toString()).set('year', year.toString())
      }
    )
  }

  public getTier(
    reportId: number,
    organisationId: number,
    peerGroup: number,
    year: number,
    submissionLevel?: string,
    serviceItemId?: number,
    optionId?: number,
  ): Observable<any> {
    let params: HttpParams = new HttpParams();

    // Mandatory params
    params = params
      .set("organisationId", organisationId.toString())
      .set("peerGroup", peerGroup.toString())
      .set("year", year.toString());

    // Optional params
    if (submissionLevel) { params = params.append("submissionLevel", submissionLevel.toString()) }
    if (serviceItemId) { params = params.append("serviceItemId", serviceItemId.toString()) }
    if (optionId) { params = params.append("denominatorOptionId", optionId.toString()); }

    return this.http.get(this.nhsApiUrl + "outputs/" + reportId + "/data", {
      headers: new HttpHeaders().set("Authorization", this.auth.token()),
      params: params
    });
  }

  public postValidation(validation): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/addValidation', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public getValidationResponses(reportId, year, submissionId, serviceItemId, optionId): Observable<any> {
    let params: HttpParams = new HttpParams();

    // Mandatory params
    params = params
      .set('year', year.toString())
      .set('submissionId', submissionId.toString())
      .set('serviceItemId', serviceItemId.toString())

    // Optional params
    if (reportId) { params = params.append("reportId", reportId.toString()) }
    if (optionId) { params = params.append("optionId", optionId.toString()) }

    return this.http.get(
      this.nhsApiUrl + 'validation/getValidationResponses', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: params
      }
    );
  }

  public addValidationResponse(validationResponse): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/addValidationResponse', JSON.stringify(validationResponse), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public deleteValidation(validationId: number): Observable<any> {
    return this.http.delete(
      this.nhsApiUrl + 'validation/deleteValidation', {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('validationId', validationId.toString())
      }
    );
  }

  public editValidationStatus(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/changeValidationStatus', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationNote(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/editValidationNote', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public editValidationDescription(validation: any): Observable<any> {
    return this.http.post(
      this.nhsApiUrl + 'validation/editValidationDescription', JSON.stringify(validation), {
        headers: new HttpHeaders().set('Authorization', this.auth.token()).set('Content-Type', 'application/json')
      }
    );
  }

  public ValidationConversationHistory(validationId: number): Observable<any> {
    return this.http.get(
      this.nhsApiUrl + "validation/getValidationConversationHistory", {
        headers: new HttpHeaders().set('Authorization', this.auth.token()),
        params: new HttpParams().set('validationId', validationId.toString())
      });
  }
}