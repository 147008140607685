import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-status',
  templateUrl: './loading-status.component.html'
})
export class LoadingStatusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }

}
