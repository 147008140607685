import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Layout } from 'src/app/core/classes/layout';
import { ILayout } from 'src/app/core/models/layout.vm';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { crmNavItems } from './crm-layouts';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {
  public layout: ILayout;
  public crmNavItems: Array<INavItem> = crmNavItems;
  public currentSection: string = undefined;
  public currentUrlParams: any;
  public currentYear: number;
  public currentProject = null;
  public currentQueryParams: any;
  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>,
    private route: ActivatedRoute,
    private router: Router,
  ) { 
    this.darkMode$ = store.select('darkMode');
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });
    this.route.params.subscribe(params => {
        this.currentUrlParams = params;
        this.routeChange(params);
      });

    }

  ngOnInit(): void {}

  private routeChange(params: any): void {
    this.currentYear = params.year || Constants.currentYear;
    this.currentSection = params.section;

    if(!this.currentQueryParams.id) {
      if(this.currentSection) {
        this.router.navigate(['tool', 'crm', this.currentYear, this.currentSection]);
      } else {
        this.router.navigate(['tool', 'crm', this.currentYear, 'overview']);
      }
    }
  }

  public yearChange(year: number): void {
    this.router.navigate(['tool', 'crm', year, this.currentSection]);
  }

}
