import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { formatDate } from "@angular/common";
import { NgbDropdown, NgbModal, NgbModalConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { FilterByPipe, OrderByPipe, UniquePipe } from 'ngx-pipes';

import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDynamicPresetFilter } from 'src/app/core/models/dynamic-table-filter.vm';
import { IDynamicChangeList } from 'src/app/core/models/dynamic-table-change-list.vm';
import moment from 'moment';
import Fuse from 'fuse.js'
import { IDynamicTableColumnInsight } from 'src/app/core/models/dynamic-table-column-insight.vm';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  providers: [NgbDropdown, UniquePipe, OrderByPipe, FilterByPipe, NgbModalConfig, NgbModal],
})
export class DynamicTableComponent implements OnInit, OnChanges {
  @Input() public data: any;
  @Input() public columns: Array<IDynamicTableColumn>;
  @Input() public currentYear: number;
  @Input() public tableTitle: string;
  @Input() public primaryKey: string;
  @Input() public disableRowClick: boolean;
  @Input() public hideExportButton: boolean;
  @Input() public dynamicButton: any;
  @Input() public hideSearch: boolean;
  @Input() onRowClick: (primaryKey: string, secondaryKey?: string) => void;
  @Input() public defaultGroupedColumn?: string;
  @Input() public presetFilters?: Array<IDynamicPresetFilter>;
  @Input() public pageSize: number = 20;
  @Input() public userTableUpdate?: Array<IDynamicChangeList>;
  @Input() onTdClick: (primaryKey: string, secondaryKey?: string) => void;
  @Input() changeUsersList: (name: string) => void;

  @Output() public buttonClicked = new EventEmitter<any>();


  public sourceData: any;
  public exportData: any;
  public searchText: string = undefined;
  public archived: boolean;
  public searchableColumns: Array<string>;
  public selectedColumn: string;
  public groupableColumns: Array<IDynamicTableColumn>;
  public groupedColumn: string = null;
  public resetFilterClickedGrouped: boolean;
  public paginationReset = false;
  public filterList: Array<any> = [];
  public darkMode$: Observable<boolean>;
  public pageOfItems: Array<any>;
  public pager: any = {};
  public filterDateColumns: Array<any> = [];
  public pickedDates: Array<string> = [];
  public allDates: Array<string> = [];
  public filteredDates: Array<string> = [];
  public datesConverted: Array<string> = [];
  public filterDate: string;
  public selectedPage: number = 1;
  public status: IDynamicTableColumnInsight;
  @ViewChild('modal') modal: ElementRef;

  constructor(
    private unique: UniquePipe,
    private order: OrderByPipe,
    public tooltipConfig: NgbTooltipConfig,
    config: NgbModalConfig, 
    private modalService: NgbModal,
    private store: Store<{ darkMode: boolean }>
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
    tooltipConfig.openDelay = 750;
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void {
    if (this.presetFilters) {
      this.presetFilters.forEach((filter) => {
        filter.apply = true;
        filter.hideValue = true;
      });
    }
    this.updateTable();
  }

  ngOnChanges(): void {
    this.selectedPage = this.pager.currentPage
    this.sourceData = this.data;
    this.exportData = this.data;
    this.setSearchableColumns();
    this.setGroupableColumns(this.defaultGroupedColumn);

    this.paginationReset = false;
    this.updateTable();
  }

  openModal(event, data: IDynamicTableColumnInsight) {
    event.stopPropagation();
    this.status = data;
    setTimeout(() => {
      this.modalService.open(this.modal, { centered: true });
    }, 200);
  }

  private setSearchableColumns(): void {
    this.searchableColumns = this.columns
      .filter((col) => col.searchable)
      .map((col) => col.dataKey);
  }

  private setGroupableColumns(defaultGroupedColumn?: string): void {
    // Set groupable columns
    this.groupableColumns = this.columns.filter((col) => col.groupable);
    this.groupableColumns.forEach((col) => {
      // let options = this.unique.transform(this.data.map(data => data[col.dataKey]));
      ////////////////////////////////////////////////////////////////////////////// FIX //////////////////////////////////////////////
      const colDataKey = col.dataKey;
      let options = this.unique.transform(
        this.data.map((data) => {
          let { [colDataKey]: dataColDataKey } = data;
          return dataColDataKey;
        })
      );
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      col.options = this.order.transform(options);
    });
    // Set default, if passed
    if (defaultGroupedColumn) {
      this.groupByColumn(
        this.columns.find((col) => col.dataKey == defaultGroupedColumn)
      );
    }
  }

  public filterColumn(column: any): void {
    let category = column[2];
    let dataKey = column[1].dataKey;
    let selected = column[3];
    let removeEverything = column[4];
    if (!(column[1].dataKey in this.filterList)) {
      Object.defineProperty(this.filterList, dataKey, {
        value: [category],
        enumerable: true,
        writable: true,
      });
    } else {
      Object.keys(this.filterList).forEach((item) => {
        if (item == dataKey && removeEverything) {
          this.filterList[dataKey] = [];
        } else if (item == dataKey && selected) {
          let { [dataKey]: data } = this.filterList;
          data.push(category);
        } else if (item == dataKey && !selected) {
          let finalFilterList = this.filterList[dataKey].filter(
            (item) => item != category
          );
          this.filterList[dataKey] = finalFilterList;
        }
      });
    }
    this.updateTable();
  }

  public dynamicButtonClick(){
    this.buttonClicked.emit('clicked')
  }

  public togglePresetFilter(filter: any) {
    filter.hideValue = !filter.hideValue;
    filter.apply = !filter.apply;
    this.updateTable();
  }

  public groupByColumn(column: IDynamicTableColumn): void {
    this.exportData = this.sourceData;
    this.groupedColumn = column?.dataKey;
    this.updateTable();
  }

  public recieveDatePickerValue(date) {
    this.pickedDates = date;
    this.updateTable();
  }

  public searchValue(value: string): void {
    this.searchText = value;
    this.updateTable();
  }

  public sortColumn(column): void {
    if (this.selectedColumn == column) {
      this.selectedColumn = '-' + column;
    } else {
      this.selectedColumn = column;
    }
    this.updateTable();
  }

  public resetFilterClickChange(value) {
    this.resetFilterClickedGrouped = value;
  }

  public removeDateFilter(bool) {
this.pickedDates = []
this.updateTable();
  }

  public checkSort(column): string {
    let revColumn = '-' + column;

    if (column == this.selectedColumn) {
      return 'asc';
    }
    if (revColumn == this.selectedColumn) {
      return 'desc';
    }
    return null;
  }

  private updateTable(): void {
    let data = this.sourceData;

    // preset filters
    if (this.presetFilters) {
      this.presetFilters.forEach((item) => {
        if (item.apply) {
          // data = data.filter((data) => data[item.key] !== item.value);
          //////////////////////////////////////////////////////////////////////////////////////////   FIX ////////////////////////////////////////////////
          let tempData = [];
          data.forEach((d) => {
            let { [item.key]: dataItemKey } = d;
            if (dataItemKey !== item.value) {
              tempData.push(d);
              data = tempData;
            }
          });
          ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        }
      });
    }

    // filter
    Object.keys(this.filterList).forEach((key: any) => {
      let { [key]: list } = this.filterList;
      if (list.length > 0) {
        data = data.filter((item) => {
          let { [key]: filter } = item;
          return list.includes(filter);
        });
      } else {
        return data;
      }
    });
    //Date Filter
    if (this.columns.filter((col) => col.filterDate).length > 0) {
      this.filterDate = 'date';
      if (this.pickedDates && this.pickedDates.length > 0) {
        this.filterDateColumns = this.columns.filter((col) => col.filterDate);
        this.filterDateColumns.forEach((col) => {
          this.allDates = this.unique.transform(
            this.data.map((data) => {
              let { [col.dataKey]: dataColDataKey } = data;
              return dataColDataKey;
            })
          );
          this.datesConverted = this.allDates.map((date) =>
            moment(date).format()
          );
        });
        // this.filteredDates = this.datesConverted.filter(
        //   (f) => f >= this.pickedDates[0] && f <= this.pickedDates[1]
        // );

        //vulnerability fix
        let { [0]: firstPickedDate } = this.pickedDates;
        let { [1]: secondPickedDated } = this.pickedDates;
        this.filteredDates = this.datesConverted.filter(
          (f) => f >= firstPickedDate && f <= secondPickedDated
        );
        this.filterDateColumns.forEach((col) => {
          // data = data.filter((date) =>
          //   this.filteredDates.includes(moment(date[col.dataKey]).format())
          // );

          data = data.filter((date) => {
            let { [col.dataKey]: formattedDates } = date;
            return this.filteredDates.includes(moment(formattedDates).format());
          });
        });
      }
      //DateTime Filter
    } else if (this.columns.filter((col) => col.filterDateTime).length > 0) {
      this.filterDate = 'dateTime';
      if (this.pickedDates && this.pickedDates.length > 0) {
        this.filterDateColumns = this.columns.filter(
          (col) => col.filterDateTime
        );
        this.filterDateColumns.forEach((col) => {
          this.allDates = this.unique.transform(
            data.map((data) => {
              let { [col.dataKey]: dataColDataKey } = data;
              return dataColDataKey;
            })
          );
          this.datesConverted = this.allDates.map((date) =>
            moment(date).format()
          );
        });
        // this.filteredDates = this.datesConverted.filter(
        //   (f) => f >= this.pickedDates[0] && f <= this.pickedDates[1]
        // );

        //vulnerability fix
        let { [0]: firstPickedDate } = this.pickedDates;
        let { [1]: secondPickedDated } = this.pickedDates;
        this.filteredDates = this.datesConverted.filter(
          (f) => f >= firstPickedDate && f <= secondPickedDated
        );
        this.filterDateColumns.forEach((col) => {
          // data = data.filter((date) =>
          //   this.filteredDates.includes(moment(date[col.dataKey]).format())
          // );
          //vulnerability fix
          data = data.filter((date) => {
            let { [col.dataKey]: formattedDates } = date;
            return this.filteredDates.includes(moment(formattedDates).format());
          });
        });
      }
    }

    // search

    if (this.searchText) {
      const options = {
        threshold: 0.3,
        includeScore: true,
        keys: this.searchableColumns,
      };
      const fuse = new Fuse(data, options);
      const result = fuse.search(this.searchText);
      let mappedResult = result.map((a) => a.item);
      data = mappedResult;
    }

    // reorder
    if (this.selectedColumn) {
      data = this.sortBy(data, true);
      data = this.order.transform(data, this.selectedColumn);
    }

    if (this.groupedColumn) {
      data = this.order.transform(data, this.groupedColumn);
    }
    this.data = data;
    this.exportData = data;
  }

  sortBy(arr, ascending) {
    return arr.sort((a, b) => {
        if(!a[this.selectedColumn]) return ascending ? 1 : -1;
        if(!b[this.selectedColumn]) return ascending ? -1 : 1;
        if (ascending) return a[this.selectedColumn] > b[this.selectedColumn] ? 1 : -1;
        return a > b ? -1 : 1;
    })
  }

  rowDisplay(): string {
    let str = 'Showing ';
    if (this.pager.startIndex !== this.pager.endIndex) {
      str +=
        this.pager.startIndex + 1 + ' to ' + (this.pager.endIndex + 1) + ' of ';
    }
    str += this.pager.totalItems;
    return str;
  }

  onChangePage(newPage) {
    this.pageOfItems = newPage.pageOfItems;
    this.pager = newPage.pager;
  }
}
