import { Component, Input, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/core/classes/event';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { EventsService } from 'src/app/core/services/events.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { EventDocumentsDynamicForm, EventDocumentsHistoryTableColumns, EventDocumentsTableColumns } from './event-document-layouts';
import { saveAs } from "file-saver";
import { IEventDocument } from 'src/app/core/models/event-document.vm';
declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-event-document',
  templateUrl: './event-document.component.html',
  styleUrls: ['./event-document.component.scss']
})
export class EventDocumentComponent implements OnChanges {

  @Input() currentEvent: Event;
  @Input() currentYear: number;

  public eventDocumentsTableColumns: Array<IDynamicTableColumn> = EventDocumentsTableColumns;
  public eventDocumentsHistoryTableColumns: Array<IDynamicTableColumn> = EventDocumentsHistoryTableColumns;
  public questionGroupForm: Array<IDynamicFormBlock> = EventDocumentsDynamicForm;

  public displayedDocuments: Array<IEventDocument>;
  public displayedDocument: IEventDocument;

  public documentLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;

  constructor(
    public loaderService: LoaderService,
    private eventsService: EventsService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges(): void {
    // Check for open slider
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });

    if (!this.currentEvent.awsDocumentsDynamic) {
      this.getEventDocuments(this.currentEvent.eventId, this.currentYear);
    }
  }

  private getEventDocuments(eventId: number, currentYear: number): void {
    this.eventsService.getEventDynamicDocuments(eventId).subscribe(
      success => {
        if (success.data.Presentation) {
          let { [this.currentYear]: currentYearPresentations } = success.data.Presentation;
          this.currentEvent.awsDocumentsDynamic = currentYearPresentations
        } else {
          this.currentEvent.awsDocumentsDynamic = [];
        }

        // Open slider, if in params
        if (this.currentQueryParams.id) {
          this.onTableRowClick(this.currentQueryParams.id);
        }
       },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public getAwsDocuments() {
    this.eventsService.getEventDynamicDocument(this.displayedDocument.documentId).subscribe(s => {
      saveAs(s.data.documentUrl, this.displayedDocument.fileName);
    },
    e => {
      console.log(e)
    })
}

  public onTableRowClick = (primaryKey: string): void => {
    this.displayedDocuments = this.currentEvent.awsDocumentsDynamic;
    this.displayedDocument = this.displayedDocuments.find(document => document.documentId == +primaryKey);
    this.documentLayout = new Layout(
      this.displayedDocument.documentName,
      [ `ID: ${this.displayedDocument.documentId}` ],
      null
    )
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeQuestionSlider = (): void => {
    this.documentLayout = null;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }
}
