<ng-container>
    <div class="d-inline-block position-relative" (clickElsewhere)="showDropdown = false">
        <button id="{{ filterName }}-data" class="btn btn-info mr-2 mb-2" (click)="showDropdown = !showDropdown">
            Filter by {{ filterName }}
            <i class="fas ml-1" [class.fa-caret-up]="showDropdown" [class.fa-caret-down]="!showDropdown"></i>
        </button>
        <div class="border rounded shadow py-2 px-3 dd-container" *ngIf="showDropdown">
            <input type="text" [(ngModel)]="searchInputValue" placeholder="Search by name or ID..." class="form-control mb-2 mt-1">
            <div *ngFor="let item of filterOptions | filterBy: ['id','name']: searchInputValue" class="dd-item" [class.active]="item.selected" (click)="selectItem(item)">
                <div class="d-flex">
                    <div>
                        <i class="far mr-2" [class.fa-square]="!item.selected" [class.fa-check-square]="item.selected"></i>
                    </div>
                    <div>
                        <span [innerHTML]="item.name | highlight: searchInputValue"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="(filterOptions | filterBy: ['id','name']: searchInputValue).length == 0" class="text-muted text-center">
                No results for this search
            </div>
        </div>
    </div>
</ng-container>