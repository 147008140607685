import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { usersDetailNavItems} from '../users-layout';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActivatedRoute, Router } from '@angular/router'; 
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { User } from 'src/app/core/classes/user';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { UserActivityDetailsForm, UsersActivityTableColumns } from './users-activity-layout';
import { Layout } from 'src/app/core/classes/layout';
import { IUserActivity } from 'src/app/core/models/user-activity.vm';
import { UsersService } from 'src/app/core/services/users.service';

@Component({
  selector: 'app-users-activity',
  templateUrl: './users-activity.component.html',
  styleUrls: ['./users-activity.component.scss']
})
export class UsersActivityComponent implements OnChanges, OnInit{

  @Input() public currentUser: User;

  public currentScope: string = null;
  public loadingError: string = null;
  public UsersActivityTableColumns: Array<IDynamicTableColumn> = UsersActivityTableColumns;
  public usersDetailNavItems: Array<INavItem> = usersDetailNavItems;
  public currentUserActivity: IUserActivity = null;
  public userActivityDetailsForm: Array<IDynamicFormBlock> = UserActivityDetailsForm;
  public activityLayout: Layout;
  public userActivity: Array<IUserActivity>


  constructor(
    public loaderService: LoaderService,
    public UsersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.getUserActivity(params.id)
    });
  }
   
  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      if (params.activity) {
        this.onUserActivityClick(params.activity);
      }
    });
  }

  public onUserActivityClick = (primaryKey: string): void => {
    this.currentUserActivity = this.userActivity.find(activity => activity.date == primaryKey);
    this.activityLayout = new Layout(
      this.currentUserActivity.action,
      null,
      null
    )
    this.router.navigate([], { queryParams: { activity: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeUserActivitySlider = (): void => {
    this.currentUserActivity = null;
    this.router.navigate([], { queryParams: { activity: null }, queryParamsHandling: 'merge' });
  }

  public getUserActivity = (userId): void => {
    this.UsersService.getUserActivity(userId).subscribe(
      success => {
        this.userActivity = success.data.userActivities
        this.userActivity.forEach((activity)=>{activity.date = new Date(activity.date).toLocaleString()})
      }
    )
  }

  
}
