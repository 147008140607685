<!-- Loading and error -->
<app-loading-status *ngIf="validationSet && loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>
    <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
        <!-- Response Filters -->
        <div class="row justify-content-start">
            <div class="col-12">
                <div class="form-group mb-4">
                    <ng-container *ngIf="!selectedFilterName">
                        <h4 [ngClass]="{'text-primary-light': ui.darkMode}">
                            Filter Responses
                        </h4>
                        <p class="text-secondary">
                            Responses can be returned for individual submissions, all submissions for an organisation, or for a specific question.
                        </p>
                        <ng-container *ngFor="let filter of prefilters">
                            <app-project-response-filter 
                                [filterName]="filter.filterName" 
                                [filterOptions]="filter.filterOptions" 
                                (emitSelectedItems)="updateResponses($event.filterName, $event.filterItems)">
                            </app-project-response-filter>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="selectedFilterName">
                        <div class="d-block text-info c-pointer filters" (click)="clearFilters()">
                            <i class="fas fa-times mr-2"></i>Showing all responses for {{ selectedFilters.length }} {{ selectedFilterName | makePluralString: selectedFilters.length }}:
                            <span *ngFor="let filter of selectedFilters" class="font-weight-bold ml-1">{{ filter.name }}&nbsp;<i>|</i>&nbsp;</span>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <!-- Table -->
        <app-dynamic-table 
            *ngIf="selectedFilterName" 
            [data]="currentProject.responses" 
            [columns]="responseTableColumns" 
            [currentYear]="currentYear"
            [tableTitle]="'responsesList'"
            [primaryKey]="'responseId'"
            [onTdClick]="onTableTdClick"
            [onRowClick]="onResponseClick">
        </app-dynamic-table>
    </ng-container>
    
</ng-template>

<!-- Selected Response -->
<app-slider-panel *ngIf="selectedResponse" [onClose]="closeResponseSlider">

    <!-- Heading -->
    <app-area-heading [heading]="responseLayout.heading" [subheading]="responseLayout.subheading" [backRoute]="responseLayout.backRoute"></app-area-heading>
    
    <!-- Response-->
    <app-dynamic-form [data]="selectedResponse" [blocks]="responseDetailsForm"></app-dynamic-form>

    <!-- Response Metadata -->
    <hr>
    <div class="text-muted">
        <div>
            <strong>Created by:</strong> {{ selectedResponse.createdByFullName }} on {{ selectedResponse.createdDt | date: 'EEEE d MMMM y, h:mm' }}
        </div>
        <div *ngIf="selectedResponse.updatedBy">
            <strong>Last updated by:</strong> {{ selectedResponse.updatedByFullName }} on {{ selectedResponse.updatedDt | date: 'EEEE d MMMM, at h:mm' }} 
        </div>
    </div>

</app-slider-panel>

<!-- Selected Response Validation -->
<app-slider-panel *ngIf="selectedResponseValidation" [onClose]="closeResponseSliderValidation">

    <!-- Heading -->
    <app-area-heading [heading]="responseLayoutValidation.heading" [subheading]="responseLayoutValidation.subheading" [backRoute]="responseLayoutValidation.backRoute"></app-area-heading>
    <!-- Validation Details -->
    <app-dynamic-form [data]="selectedResponseValidation" [blocks]="ValidationDetailsForm" (dataUpdate)="noteUpdate($event)"></app-dynamic-form>

</app-slider-panel>
