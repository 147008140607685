import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

export const SubmissionTableColumns: IDynamicTableColumn[] = [
  {
    header: 'ID',
    dataKey: 'submissionId',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Code',
    dataKey: 'submissionCodeNumber',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Organisation ID',
    dataKey: 'organisationId',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Organisation Name',
    dataKey: 'organisationName',
    percWidth: 50,
    sortable: true,
    groupable: true,
    searchable: true,
  },
  {
    header: 'Submission Name',
    dataKey: 'submissionName',
    percWidth: 55,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: '# Responses',
    dataKey: 'responseCount',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: '# Services',
    dataKey: 'submissionServiceCount',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: '# Case Codes',
    dataKey: 'submissionCaseCodeCount',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Peer Group',
    dataKey: 'peerGroupName',
    percWidth: 20,
    sortable: true,
    groupable: true,
    searchable: true,
  },
  {
    header: 'Level',
    dataKey: 'submissionLevel',
    percWidth: 20,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Actions',
    percWidth: 12,
    dataKey: '',
    sortable: false,
    groupable: false,
    searchable: false,
    exportable: false,
    options: ['clickable'],
    button: 'options',
    buttonType: 'submissions',
    actions: [
      {
        icon: 'fas fa-lg fa-minus-square mr-2',
        color: '#a94442',
        action: 'delete',
        description: 'Delete Submission',
      },
      {
        icon: 'fas fa-lg fa-pen-square mr-2',
        color: '#005eb8',
        action: 'edit',
        description: 'Edit Submission',
      },
    ],
  },
];

export const CaseNoteCodeTableColumns: IDynamicTableColumn[] = [
  {
    header: 'ID',
    dataKey: 'caseCodeId',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Type',
    dataKey: 'caseCodeType',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Case Code',
    dataKey: 'caseCode',
    percWidth: 30,
    sortable: false,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Status',
    dataKey: 'completionStatus',
    percWidth: 30,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: '# Responses',
    dataKey: 'responseCount',
    percWidth: 20,
    sortable: false,
    groupable: false,
    searchable: false,
  },
];

export const ServiceTableColumns: IDynamicTableColumn[] = [
  {
    header: 'Service',
    dataKey: 'serviceItemName',
    percWidth: 80,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: '# Responses',
    dataKey: 'responseCount',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
];

export const editHistoryTable: IDynamicTableColumn[] = [
  {
    header: 'User ID',
    dataKey: 'userId',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Data Submitters',
    dataKey: 'logonName',
    percWidth: 80,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Edit Type',
    dataKey: 'type',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: false,
  },
];

export const SubmissionDetailsForm: IDynamicFormBlock[] = [
  {
    blockType: 'divider',
  },
  {
    label: 'Submission Details',
    blockType: 'header',
  },
  {
    label: 'Submission Name',
    dataKey: 'submissionName',
    blockType: 'input',
    colWidth: 12,
  },
  {
    label: 'Submission Level',
    dataKey: 'submissionLevel',
    helpText: 'Select organisation or submission level',
    blockType: 'select',
    disabled: true,
    options: [
      { id: '', name: 'Submission level' },
      { id: 'O', name: 'Organisation level' },
    ],
    colWidth: 4,
  },
  {
    label: 'Submission Code',
    dataKey: 'submissionCodeNumber',
    helpText: 'Edit numeric element only (max. 3 digits)',
    blockType: 'input',
    inputType: 'number',
    minValue: 0,
    maxValue: 999,
    digitLimit: 3,
    colWidth: 4,
  },
  {
    label: 'Peer Group',
    dataKey: 'peerGroupId',
    helpText: 'List of available peer groups needed',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4,
  },
  {
    label: 'Submitted/Locked by',
    dataKey: 'submittedByFullName',
    blockType: 'input',
    colWidth: 6,
    disabled: true,
  },
  {
    label: 'Submitted/Locked on',
    dataKey: 'submittedDt',
    blockType: 'input',
    inputType: 'datetime-local',
    colWidth: 6,
    disabled: true,
  },
  {
    blockType: 'changesButton',
    requiredKeys: ['submissionName'],
    dataKeys: ['submissionName', 'submissionLevel','submissionCodeNumber', 'peerGroupId'],
    label: 'Save',
  },
];

export const DataUploadTableColumns: IDynamicTableColumn[] = [
  {
    header: 'Status',
    dataKey: 'status_name',
    percWidth: 8,
    sortable: true,
    groupable: false,
    searchable: true,
    badge: 'uploads',
    insight: {
      title: 'Possible States',
      noHeader: true,
      body: [
        {
          column1: 'Submitted',
          column2: 'Submission from portal',
          badge: 'rgba(108, 117, 125, 0.2)',
        },
        {
          column1: 'Updated',
          column2: 'Submission has been assigned matching details',
          badge: '#fef3c7',
        },
        {
          column1: 'Rejected',
          column2: 'NHSBN user has rejected submission',
          badge: '#d65533',
        },
        {
          column1: 'Intray',
          column2: 'Submission awaiting MDI input',
          badge: '#47754b',
        },
        {
          column1: 'Imported',
          column2: 'Submission within database',
          badge: '#17a2b8',
        },
        {
          column1: 'Error',
          column2: 'Error with MDI input process',
          badge: '#fcc000',
        },
      ],
    },
  },
  {
    header: 'File ID',
    dataKey: 'file_tracking_id',
    percWidth: 5,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Submission ID',
    dataKey: 'submission_id',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Organisation',
    dataKey: 'organisation_name',
    percWidth: 25,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Comment',
    dataKey: 'tracking_note',
    percWidth: 25,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Upload Time',
    dataKey: 'upload_time',
    percWidth: 13,
    sortable: true,
    groupable: false,
    searchable: true,
    dateFormat: 'MMM D, h:mm A',
  },
];

// TODO:
// registrationId = links back to registration (opens slider)
// responses = link to responses
// services = list of services against submission
