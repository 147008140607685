import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/core/classes/event';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IEventAttendee } from 'src/app/core/models/event-attendee.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { EventsService } from 'src/app/core/services/events.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { EventAttendeeDetailsForm, EventAttendeeTableColumns } from './event-attendee-layouts';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-event-attendee',
  templateUrl: './event-attendee.component.html',
  styleUrls: ['./event-attendee.component.scss']
})
export class EventAttendeeComponent implements OnChanges, OnInit {

  
  @Input() public currentEvent: Event;
  @Input() currentYear: number;

  public selectedAttendee: IEventAttendee = null;
  public layout: ILayout;

  public currentQueryParams: any;
  public loadingError: string;

  public eventAttendeeTableColumns: Array<IDynamicTableColumn> = EventAttendeeTableColumns;
  public eventAttendeeDetailsForm: Array<IDynamicFormBlock> = EventAttendeeDetailsForm;

  public uploaderTitle: string = "Upload Attendees"
  public uploaderParagraph: string = "To upload attendees please choose a file then click submit"
  public extraInput: boolean = true
  public extraInputText: string = 'Event ID: '
  public eventAttendeeUploaderEndpoint: string =  'https://event-attendance-service.bmcapps.p.azurewebsites.net/api/event-attendance?code=emdzBooxqJwP_1gl5rWhMEi6BL8KFjwrV2hO4fCxHtxdAzFuFsKfqQ=='
  public eventId: number 


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private eventsService: EventsService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
      this.eventId = this.currentEvent.eventId
  }

  ngOnChanges(): void {

    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });

    if (!this.currentEvent.attendees) {
      this.getEventAttendees(this.currentEvent.eventId, this.currentYear);
    }
  }

  private getEventAttendees(eventId: number, currentYear: number): void {
    this.eventsService.getEventAttendee(eventId, currentYear).subscribe(
        success => {
          if (success.data.attendeeList) {
            this.currentEvent.attendees = success.data.attendeeList;
            success.data.attendeeList.forEach(value => {
              value["joinDate"] = this.datePipe.transform(value.joinDate, 'medium');
              value["leaveDate"] = this.datePipe.transform(value.leaveDate, 'medium');
              value["attendanceDuration"] = Math.round((value.attendanceDuration / 60) * 100 ) / 100 + " mins";
            });
          } else {
            this.currentEvent.attendees = [];
          }

          if (this.currentQueryParams.attendees) {
            this.onAttendeeClick(this.currentQueryParams.attendees);
          }
        },
        error => {
          console.log('Error: ', error);
          this.loadingError = error.error.message;
        }
      );
  }

  public onAttendeeClick = (primaryKey: string): void => {
    this.selectedAttendee = this.currentEvent.attendees.find((attendee) => attendee.attendanceId == +primaryKey);
    this.layout = new Layout(
      this.selectedAttendee.fullName,
      [this.selectedAttendee.jobTitle],
      null
    );
    this.router.navigate([], {
      queryParams: { registration: primaryKey }, queryParamsHandling: 'merge', });
  };

  public closeAttendeeSlider = (): void => {
    this.selectedAttendee = null;
    this.router.navigate([], { queryParams: { registration: null }, queryParamsHandling: 'merge', });
  };
}
