<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="position-relative d-inline-block" (clickElsewhere)="placeholderHelp = false">
        <button class="btn btn-sm btn-outline-secondary mt-2 mr-2" type="button" (click)="placeholderHelp = !placeholderHelp">
            <ng-container *ngIf="!placeholderHelp">
                <i class="fas fa-question-circle mr-2"></i>Placeholders
            </ng-container>
            <ng-container *ngIf="placeholderHelp">
                <i class="fas fa-question-circle mr-2"></i>Hide Placeholders
            </ng-container>
        </button>
        <div class="border rounded py-2 px-3 dd-container mt-1" *ngIf="placeholderHelp" [ngClass]="{'bg-dark-900 text-light': ui.darkMode}">
            <p>
                <strong>Available Placeholders</strong><br>
                Click on a placeholder below to copy to clipboard
            </p>
            <div *ngFor="let p of placeholders" (click)="copyToClipboard(p.id)">
                <p class="placeholder"><span>{{ p.example }}</span><strong [id]="p.id"><i>*|</i>{{ p.name }}<i>|*</i></strong></p>
            </div>
            <div class="placeholder-copied" *ngIf="copiedMessage">
                <i class="fas fa-check-circle"></i> {{ copiedMessage }}
            </div>
        </div>
    </div>
</ng-container>