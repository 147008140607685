<ng-container>
  <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="row">
        <div class="col-sm-4 mb-3" *ngFor="let item of filterOptions | filterBy: ['id','name'] | orderBy: ['id']">
          <div class="card" [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}">
            <div class="card-body">
              <h5 class="card-title mb-0">{{ item.name }}</h5>
              <p class="text-muted">Service ID: {{ item.id }}</p>
              <button (click)="selectItem(item)" class="btn btn-primary">View Dashboard</button>
            </div>         
          </div>
        </div>
      </div>
    </ng-container>
</ng-container>

