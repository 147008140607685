<!-- Typeahead (visible) -->
<div class="form-group">
    <label [for]="block.dataKey">{{ block.label }}
        <i *ngIf="block.helpText" class="far fa-info-circle form-info-icon" [title]="block.helpText" placement="right"></i>
    </label>
    <input 
        [id]="block.dataKey"
        type="text"
        [attr.aria-describedby]="block.dataKey + 'Help'"
        [ngbTypeahead]="search"
        [inputFormatter]="formatter"
        [resultFormatter]="formatter"
        [editable]="false"
        [(ngModel)]="selectedOption"
        (selectItem)="selectedItem($event)"
        class="form-control" 
        placeholder="Search by name..."
    />
</div>

<!-- formControl (hidden) -->
<div [formGroup]="form" style="display:none"> 
    <input [type]="block.inputType" [formControlName]="block.dataKey" />
</div>