import { Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-table-date-filter',
  templateUrl: './table-date-filter.component.html',
  styleUrls: ['./table-date-filter.component.scss']
})
export class TableDateFilterComponent implements OnInit, OnChanges {
  @Output() public datePicker = new EventEmitter<any>();
  @Output() public resetData = new EventEmitter<any>();
  @Output() public resetFilterClickedChangedDate = new EventEmitter<any>();
  @Output() public savedBothDates = new EventEmitter<any>();
  @Input() public resetFilterClicked: boolean
  @Input() public savedDates: any = new EventEmitter<any>();
  @Input() public whichDatePicker: string
  public clicked: boolean = false
  public dateInputStart: any
  public dateInputEnd: any
  public today: string;
  constructor() { }

  ngOnInit(): void {
    this.dateInputStart = document.getElementById('startDate');
    this.dateInputEnd = document.getElementById('endDate');
    if(this.savedDates?.length > 0){
      (document.getElementById('startDate') as HTMLInputElement).value = this.savedDates[0];
      (document.getElementById('endDate') as HTMLInputElement).value = this.savedDates[1];
    }
  }

  ngOnChanges(): void {
    if(this.resetFilterClicked) {
      this.clicked = false
      this.resetFilterClicked = false
      this.resetFilterClickedChangedDate.emit(this.resetFilterClicked)
    }
  }

  getDates() {                  
    this.savedBothDates.emit([this.dateInputStart.value, this.dateInputEnd.value])
    this.clicked = true
    let pickedDates = []
    pickedDates.push(moment(this.dateInputStart.value).format())
    pickedDates.push(moment(this.dateInputEnd.value).format())
    this.datePicker.emit(pickedDates)
  }

  resetFilters(): void {
    this.resetData.emit();
  }
}
