import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { OrganisationService } from "../core/services/organisation.service";
import { loadOrganisationList, loadOrganisationListSuccess } from "./organisation-list.actions";

@Injectable()
export class organisationListEffects {
    constructor(
        private actions$: Actions,
        private organisationService: OrganisationService,
    ) {}

    loadOrganisationList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadOrganisationList),
            mergeMap(() => {
                return this.organisationService.getOrganisations(+sessionStorage.getItem('currentProjectId')).pipe(
                    map(organisationlist => {
                        return loadOrganisationListSuccess({ organisationlist })
                    })
                )
            })
        )
    });
}