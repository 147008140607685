import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const EventDocumentsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Category ID',
        dataKey: 'documentCategoryId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'File Name',
        dataKey: 'fileName',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Document Name',
        dataKey: 'documentName',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Event Id',
        dataKey: 'eventId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
];

export const EventDocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Event Documents',
        blockType: 'header',
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',  
        colWidth: 12,
        disabled: true
    },
    {
        label: 'Event Id',
        dataKey: 'eventId',
        blockType: 'input',    
        colWidth: 6,
        disabled: true,
    },
    {
        label: 'Display Sequence',
        dataKey: 'displaySequence',
        blockType: 'input',    
        colWidth: 6,
        disabled: true,
    },
    {
        label: 'File Name',
        dataKey: 'fileName',
        blockType: 'input',
        colWidth: 12,
        disabled: true,
    }
];

export const EventDocumentsHistoryTableColumns: IDynamicTableColumn[] = [
    {
        header: 'User ID',
        dataKey: 'downloadingUserId',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Name',
        dataKey: 'downloadingUserFullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Email',
        dataKey: 'downloadingUserLogonName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Organisation',
        dataKey: 'downloadingUserOrganisation',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
];