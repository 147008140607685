import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';

export const EventDetailBasics: IDynamicFormBlock[] = [
  {
    label: 'Event Details',
    helpText: 'The section covers the basic details of the selected event',
    blockType: 'header',
    colWidth: 12,
  },
  {
    label: 'Event Name',
    dataKey: 'eventName',
    blockType: 'input',
    inputType: 'text',
    colWidth: 12,
  },
  {
    label: 'Event Date',
    dataKey: 'eventDt',
    blockType: 'input',
    inputType: 'datetime-local',
    colWidth: 4,
  },
  {
    label: 'Registration Enabled',
    dataKey: 'registrationEnabled',
    blockType: 'input',
    inputType: 'text',
    colWidth: 4,
  },
  {
    label: 'Is Visible',
    dataKey: 'isVisible',
    blockType: 'input',
    inputType: 'text',
    colWidth: 4,
  },
  {
    label: 'Event Description',
    dataKey: 'eventDescription',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 12,
  },
  {
    label: 'Event Location',
    dataKey: 'eventLocation',
    blockType: 'textarea',
    inputType: 'text',
    colWidth: 8,
  },
  {
    label: 'Is Online',
    dataKey: 'isOnline',
    blockType: 'input',
    inputType: 'text',
    colWidth: 4,
  },
  {
    label: 'Registration Link',
    dataKey: 'registrationLink',
    blockType: 'input',
    inputType: 'text',
    colWidth: 4,
  },
  {
    label: 'Request Tags',
    dataKey: 'requestTags',
    blockType: 'input',
    inputType: 'text',
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Max. Capacity',
    dataKey: 'maxCapacity',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4,
  },
  {
    label: 'Places per Organisation',
    dataKey: 'placesPerOrganisation',
    blockType: 'input',
    inputType: 'number',
    colWidth: 4,
  },
  {
    blockType: 'breaker'
  },
  {
    label: 'Project Category',
    dataKey: 'projectCategoryName',
    blockType: 'input', // Needs to be a drop-down of available categories (id, name)
    inputType: 'text',
    colWidth: 4,
  },
  {
    label: 'Region',
    dataKey: 'regionName',
    blockType: 'input', // Needs to be a drop-down of available regions (id, name)
    inputType: 'text',
    colWidth: 4,
  },
  
];
