<app-loading-status
  *ngIf="loaderService.isLoading | async; else notLoading"
></app-loading-status>
<app-alert-status
  *ngIf="loadingError"
  [loadingError]="loadingError"
></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>
  <ng-container *ngIf="!currentExtract">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12">
          <div class="row mt-5 mb-3">
            <div class="col-md-7 col-12" *ngIf="layout">
              <app-area-heading
                [heading]="layout.heading"
                [subheading]="layout.subheading"
                [backRoute]="layout.backRoute"
              ></app-area-heading>
            </div>
          </div>
          <div>
            <app-area-nav [navItems]="dataExtractorNavItems"></app-area-nav>
          </div>
          <app-dynamic-table
            [data]="extractList"
            [columns]="libraryExtractsTableColumns"
            [tableTitle]="'extractList'"
            [primaryKey]="'extractId'"
            [onTdClick]="onTableTdClick"
            [disableRowClick]="true"
          >
          </app-dynamic-table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
