import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-input-placeholder',
  templateUrl: './input-placeholder.component.html',
  styleUrls: ['./input-placeholder.component.scss']
})
export class InputPlaceholderComponent implements OnInit {

  public placeholderHelp: boolean = false;
  public placeholders = Constants.placeholders;
  public copiedMessage: string = null;

  darkMode$: Observable<boolean>;
  
  constructor(private store: Store<{ darkMode: boolean }>) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void { }

  async copyToClipboard(id: string) {
    var range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
    document.execCommand('copy');
    window.getSelection().removeAllRanges();
    this.copiedMessage = 'Copied to clipboard'
  }

}
