import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'app-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent implements OnInit, OnChanges {
  public showDropdown: boolean = false;
  public loadingError: string = null;
  public searchInputValue: string = '';
  public yearList: string;
  public currentYear: string;
  public projects: string;
  public currentProject: string;
  public filterTitle: string;

  public selectedItems: Array<string> = [];
  

  @Input() public title: string;
  @Input() public filterOptions: Array<string> = [];
  @Input() public placeholder: string;
  @Input() public filtered: string;
  @Input() public itemName: string;
  @Input() public key: string;
  @Input() public singleSelect: string
  @Input() public removeSearch: boolean
  @Output() public newSelection = new EventEmitter<any>();
  public oneItem: Array<string> = [];
  

  constructor() {}

  ngOnInit(): void {
   
  }

  ngOnChanges(): void {}

  public selectItem(item: any) {
    if (this.selectedItems.find((obj) => obj == item)) {
      this.selectedItems = this.selectedItems.filter((obj) => obj != item);
      item.selected = false;
    } else {
      item.selected = true;
      this.selectedItems.push(item);
    }
    this.newSelection.emit({ data: this.selectedItems, key: this.key });
  }
}
