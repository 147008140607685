import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, mergeMap } from "rxjs/operators";
import { UserService } from "../core/services/user.service";
import { loadAdminUsers, loadAdminUsersSuccess } from "./admin-userlist.actions";

@Injectable()
export class adminUserListEffects {
    constructor(
        private actions$: Actions,
        private userService: UserService
    ) {}

    loadAdminUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadAdminUsers),
            mergeMap(() => {
                return this.userService.getAdminUsers().pipe(
                    map(userlist => {
                        return loadAdminUsersSuccess({ userlist })
                    })
                )
            })
        )
    });
}