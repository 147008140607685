<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !selectedQuestion); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.questions" 
        [data]="currentProject.questions" 
        [columns]="dataCollectionTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'questionList'"
        [primaryKey]="'questionId'"
        [defaultGroupedColumn]="'questionGroupWithLevel'"
        [onRowClick]="onQuestionClick">
    </app-dynamic-table>
</ng-template>

<!-- Selected Question -->
<app-slider-panel *ngIf="selectedQuestion" [onClose]="closeQuestionSlider">
    <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
        <app-area-heading [heading]="questionLayout.heading" [subheading]="questionLayout.subheading"></app-area-heading>
        <app-dynamic-form [data]="selectedQuestion" [blocks]="questionGroupForm"></app-dynamic-form>
        <app-dynamic-form [data]="selectedQuestion" [blocks]="questionDetailsForm"></app-dynamic-form>
        <hr>
        <h4 class="mt-2 mb-3" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">
            Nested Questions
        </h4>
        <app-dynamic-table 
            *ngIf="selectedQuestion.children" 
            [data]="selectedQuestion.children" 
            [columns]="questionChildrenTableColumns" 
            [currentYear]="currentYear"
            [tableTitle]="'nestedQuestions'"
            [primaryKey]="'questionId'"
            [onRowClick]="onChildQuestionClick"
            [hideExportButton]="true"
            [hideSearch]="true"> 
        </app-dynamic-table>
    </ng-container>
</app-slider-panel>

<!-- Selected Child Question -->
<app-slider-panel *ngIf="selectedChildQuestion" [layer]="1" [onClose]="closeChildQuestionSlider">
    <app-area-heading [heading]="childQuestionLayout.heading" [subheading]="childQuestionLayout.subheading"></app-area-heading>
    <app-dynamic-form [data]="selectedChildQuestion" [blocks]="questionDetailsForm"></app-dynamic-form>
</app-slider-panel>