    <!-- Loading and error -->
    <app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
    <app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
    
    <ng-template #notLoading>
    
        <!-- Table -->
        <app-dynamic-table 
        *ngIf="currentUser.logonAttempts"
        [data]="currentUser.logonAttempts"
        [columns]="UsersRolesTableColumns"
        [tableTitle]="'User Login Attempts'"
        [disableRowClick]="true"
        >
      </app-dynamic-table>
          
