import { Component, Input, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/core/services/loader.service';
import { User } from 'src/app/core/classes/user';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { UsersLoginAttemptsTableColumns } from './users-login-attempts-layout';

@Component({
  selector: 'app-users-login-attempts',
  templateUrl: './users-login-attempts.component.html',
  styleUrls: ['./users-login-attempts.component.scss']
})
export class UsersLoginAttemptsComponent implements OnInit {
  @Input() public currentUser: User;

  public loadingError: string = null;

  public UsersRolesTableColumns: Array<IDynamicTableColumn> = UsersLoginAttemptsTableColumns;


  constructor(
    public loaderService: LoaderService,   
  ) {}

  ngOnInit(): void {
    this.currentUser.logonAttempts.forEach(login => login.logonAttemptDate= new Date(login.logonAttemptDate).toLocaleString())
  }

   
}
