import { Component, OnInit } from '@angular/core';
import { Layout } from 'src/app/core/classes/layout';
import { ILayout } from 'src/app/core/models/layout.vm';

@Component({
  selector: 'app-user-engagement-tool',
  templateUrl: './user-engagement-tool.component.html',
  styleUrls: ['./user-engagement-tool.component.scss']
})
export class UserEngagementToolComponent implements OnInit {

  public layout: ILayout;

  constructor() { }

  ngOnInit(): void {
    this.layout = new Layout('User Engagement Tool', null, '/tool');
  }

}
