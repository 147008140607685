import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DocumentsDynamicTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'documentId',
        percWidth: 3,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Category',
        dataKey: 'documentCategoryName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Document',
        dataKey: 'documentName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Date',
        dataKey: 'uploadTime',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
        filterDateTime: true,
        dateFormat: 'MMM D Y, h:mm A'
    },
    {
        header: 'Uploader',
        dataKey: 'fullName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Downloads',
        dataKey: 'downloads',
        percWidth: 5,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Visible',
        dataKey: 'isVisible',
        percWidth: 3,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    }
];

export const DocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Dynamic Documents',
        blockType: 'header',
    },
    {
        label: 'File Name',
        dataKey: 'fileName',
        blockType: 'input',  
        colWidth: 9,
        disabled: true
    },
    {
        label: 'Display Sequence',
        dataKey: 'displaySequence',
        blockType: 'input',
        colWidth: 3,
        disabled: true
    },
    {
        label: 'AWS File Name',
        dataKey: 'awsKeyLocation',
        blockType: 'input',  
        colWidth: 9,
        disabled: true
    },
    {
        label: 'Upload Date',
        dataKey: 'uploadTime',
        inputType: 'datetime-local',
        blockType: 'input',    
        colWidth: 3,
        disabled: true
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',
        colWidth: 5,
    },
    {
        label: 'Organisation',
        dataKey: 'organisationId',
        blockType: 'searchDropdown',
        colWidth: 4,
        optionsType: 'organisations',
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Created By',
        dataKey: 'createdBy',
        blockType: 'searchDropdown',
        colWidth: 5,
        optionsType: 'adminUsers'
    },
    {
        label: 'Visible',
        dataKey: 'isVisible',
        blockType: 'select',
        colWidth: 2,
        options: [{ id: 'Y' , name: 'Yes' }, { id: 'N', name: 'No' }]
    },
    {
        label: 'Category',
        dataKey: 'documentCategoryId',
        blockType: 'select',
        options: [
            { id: 1, name: 'Report' },
            { id: 2, name: 'CaseStudy' },
            { id: 3, name: 'Presentation' },
            { id: 4, name: 'Toolkit' },
            { id: 6, name: 'Bespoke' },
            { id: 7, name: 'Specification' },
        ],
        colWidth: 2
    },
    {
        blockType: 'changesButton',
        requiredKeys: ['documentName'],
        dataKeys: ['isVisible','documentCategoryId', 'createdBy'],
        action: 'create',
        label: 'Update Document',
    },
    {
        blockType: 'divider'
    }
]

export const DocumentsDynamicTableAccessLogColumns: IDynamicTableColumn[] = [
    {
        header: 'Organisation',
        dataKey: 'organisationId',
        percWidth: 50,
        sortable: true,
        groupable: true,
        searchable: true,
        organisation: true
    },
    {
        header: 'Full Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Time',
        dataKey: 'time',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
        dateFormat: 'MMM D Y, h:mm A'
    },
];

export const UploadDocumentsDynamicForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Organisation',
        dataKey: 'organisationId',
        blockType: 'searchDropdown',
        colWidth: 4,
        optionsType: 'organisations',
    },
    {
        label: 'Document Name',
        dataKey: 'documentName',
        blockType: 'input',
        colWidth: 4,
    },
    {
        label: 'Category',
        dataKey: 'documentCategoryId',
        blockType: 'select',
        options: [
            { id: 1, name: 'Report' },
            { id: 2, name: 'CaseStudy' },
            { id: 3, name: 'Presentation' },
            { id: 4, name: 'Toolkit' },
            { id: 6, name: 'Bespoke' },
            { id: 7, name: 'Specification' },
        ],
        colWidth: 2
    },
    {
        label: 'Visible',
        dataKey: 'isVisible',
        blockType: 'select',
        colWidth: 2,
        options: [{ id: 'Y', name: 'Yes' },{ id: 'N', name: 'No' }]
    },
    {
        blockType: 'saveButton',
        dataKey: 'documentName',
        dataKeys: ['documentName', 'documentCategoryId', 'isVisible'],
        label: 'Upload Document',
        action: 'create'
    }
]
