import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './core/services/auth.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { toggleDarkMode } from './store/darkmode.actions';
import { loadAdminUsers } from './store/admin-userlist.actions';
import { loadStoredProcedures } from './store/stored-procedures.actions';
import { loadOrganisationList } from './store/organisation-list.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AuthService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public title: string = 'Admin Tool';
  public navbarCollapsed: boolean = true;
  public currentYear: number;

  darkMode$: Observable<boolean>;

  constructor(
    public auth: AuthService,
    public router: Router,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void {
    if (this.auth.authenticated()) {
      this.store.dispatch(loadAdminUsers());
      this.store.dispatch(loadStoredProcedures());
      this.store.dispatch(loadOrganisationList());
    }
  }

  public logout(): void {
    localStorage.removeItem('adminUserToken');
    sessionStorage.clear();
    location.reload();
  }

  public switch_theme(): void {
    this.store.dispatch(toggleDarkMode());

    this.darkMode$.subscribe((x) =>
      localStorage.setItem('darkMode', String(x))
    );
  }
}
