import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-area-heading',
  templateUrl: './area-heading.component.html',
  styleUrls: ['./area-heading.component.scss']
})
export class AreaHeadingComponent implements OnChanges {

  @Input() public heading: string;
  @Input() public subheading?: Array<string>;
  @Input() public backRoute?: string;
  @Input() public titleButton?: any;

  darkMode$: Observable<boolean>;
  
  constructor(    
    public tooltipConfig: NgbTooltipConfig,
    private store: Store<{ darkMode: boolean }>
  ) {
    tooltipConfig.openDelay = 750
    this.darkMode$ = store.select('darkMode')
  }

  ngOnChanges(): void { }

}
