import { Component, Input, OnChanges, OnInit, SecurityContext } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { InteractionsTableColumns, OpportunitiesForm, OpportunitiesTableColumns } from './crm-opportunities-layouts';
import { CrmService } from 'src/app/core/services/crm.services';
import { Layout } from 'src/app/core/classes/layout';
import { ICrmOpportunity } from 'src/app/core/models/crm-opportunity.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { ICrmInteraction } from 'src/app/core/models/crm-interaction.vm';
import moment from 'moment';

@Component({
  selector: 'app-crm-opportunities',
  templateUrl: './crm-opportunities.component.html',
  styleUrls: ['./crm-opportunities.component.scss']
})
export class CrmOpportunitiesComponent implements OnInit {
  @Input() currentYear: number;

  public opportunitiesTableColumns: Array<IDynamicTableColumn> = OpportunitiesTableColumns;
  public interactionsTableColumns: Array<IDynamicTableColumn> = InteractionsTableColumns;
  public opportunitiesForm: Array<IDynamicFormBlock> = OpportunitiesForm;

  public selectedOpportunity: ICrmOpportunity;
  public allInteractions: Array<ICrmInteraction>;
  public opportunityLayout: Layout;
  public loadingError: string;
  public currentQueryParams: any;
  public opportunities: Array<ICrmOpportunity>;
  darkMode$: any;

  constructor(
    public loaderService: LoaderService,
    public crmService: CrmService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ darkMode: boolean }>
  ) {this.darkMode$ = store.select('darkMode');}

  ngOnInit(): void {
    // Check for open slider
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    this.getOpportunities();
  }
  
  getOpportunities() {
    this.crmService.getOpportunities().subscribe(
      (success) => {
        success.data.opportunitiesList.forEach((value) => {
          value['initialContactDate'] = moment(value.initialContactDate).format('LLL');
        });
        this.opportunities = success.data.opportunitiesList;

        if(this.currentQueryParams.id) {
          this.onTableRowClick(this.currentQueryParams.id)
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  getAllInteractions(opportunityId) {
    this.crmService.getAllInteractions(opportunityId).subscribe(
      (success) => {
        this.allInteractions = success.data.interactionList;
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public onTableRowClick = (primaryKey: number): void => {
    this.selectedOpportunity = this.opportunities.find(item => item.opportunityId == primaryKey);
    this.opportunityLayout = new Layout(
      this.selectedOpportunity.name,
      [`Organisation ID: ${this.selectedOpportunity.organisationId}`],
      null
    );
    this.getAllInteractions(primaryKey);
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  };

  public closeQuestionSlider = (): void => {
    this.opportunityLayout = null;
    this.allInteractions = null;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  };
}
