<ng-container>
  <div class="container-fluid">
      <div class="row justify-content-center pb-5">
          <div class="col-md-11 col-12">
              <div class="row mt-5 mb-3">
                  <div class="col-md-7 col-12" *ngIf="layout">
                      <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
                  </div>            
              </div>  
              <div class="row justify-content-center">
                <div class="col-md-9 col-12 mt-5">
                  <div>
                    <iframe title="Report Section" width="100%" height="700"
                      src="https://app.powerbi.com/view?r=eyJrIjoiYjQwYzQ3NTktMjVjYi00MGEyLWI1MzctZmY4MzliNTM3YThhIiwidCI6Ijc3Y2I0NjVlLWNiMzItNDZlNC1iM2NjLWQ0M2QwYTU1YTkzNiJ9&pageName=ReportSection"
                      frameborder="0" allowFullScreen="true">
                    </iframe>
                  </div>
                  <div>
                      <div class="card">
                          <div class="card-body justify-content-center" style="background-color: rgba(118,134,146,.05);">
                              <h3 class="card-title">User Engagement Report Guide</h3>
                              <hr />
                              <h5 class="card-text">Overview</h5>
                              <p class="card-text">The User Engagement Report was created with the intention of visualising the logins we receive from our users accessing the Members’ Area.
                                  Whilst extracting this, lots of other useful information came to the forefront and has been included for the wider team’s benefit. If you need to find
                                  information about an organisation, from the projects it has registered for over the years, to the number of times their project leads have logged on
                                  in the last month, you can find it here.
                                  If there are any issues, please contact Jasmine Anwar (<b>j.anwar@bmchealth.co.uk</b>).
                              </p>
                              <hr />
                              <h5 class="card-text">Things to note</h5>
                              <ul class="card-text list-group pl-3">
                                  <li class="list-group-item">All NHSBN users and organisation have been removed from this report and therefore, are not included in any counts or lists of users
                                  </li>
                                  <li class="list-group-item">Defunct organisations have also been removed
                                  </li>
                                  <li class="list-group-item">A single user logging in multiple times in a day will only add 1 to the login count
                                  </li>
                                  <li class="list-group-item">All logins logged prior to March 2018 have been considered defunct as a development occurred since that which checks that the
                                      password was authenticated
                                  </li>
                                  <li class="list-group-item">There are blanks within the report as a result of gaps in our historical data. For example, not every organisation that has had access to
                                      our Members' Area has been assigned an organisation type. I have left these blanks in as I believe they are a valuable insight into our
                                      structural storage processes
                                  </li>
                                  <li class="list-group-item">Every visual on each page can be expanded to see more detail using the expand button on the top right of the visual
                                  </li>
                                  <li class="list-group-item">drill down capabilities (overrides filters)
                                  </li>
                                  <li class="list-group-item">The report’s dataset has an automatic refresh set for every day just after midnight
                                  </li>
                                </ul>
                              <hr />
                              <h5 class="card-text">Drill down feature</h5>
                              <p class="card-text">You have several ways to drill into your visual:<br />
                                  <br />
                                  <b>Drill down all fields at once.</b> Selecting the double arrow drill-down icon takes you to the next level in the hierarchy.<br />
                                  <br />
                                  <b>Expand all fields at once.</b> Expand adds an additional hierarchy level to the current view. So if you're looking at the 'Year' level, you can expand
                                  all current leaves in the tree at the same time. Your first drill adds monthly data for all organisations. The next drill adds daily data, and keeps
                                  monthly data as well. Each step in the path shows you the same information and adds on one level of new information. <br />
                                  <br />
                                  <b>Drill down one field at a time.</b> Select the drill down icon to turn it on. Now you have the option of drilling down one field at a time by selecting a
                                  visual element. Note: If you don't turn on the drill-down option, selecting a visual element (like a bar, bubble, or leaf) won't drill down. Instead, it
                                  will cross-filter the other charts on the report page.
                              </p>
                              <hr />
                              <h5 class="card-text">Definitions</h5>
                              <table class="table table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">Term</th>
                                      <th scope="col">Definition</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <th scope="row">Login</th>
                                      <td>A unique user (excluding NHSBN users) accessing the Members’ area, with password authenticated, at least once
                                          within 24 hours.</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Active User</th>
                                      <td>Defined by you on the Active Users page, by the number of months it has been since they last logged in. E.g.
                                          selecting 3 will show all users who have logged in at least once in the last 3 months as active.</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Current Member</th>
                                      <td>An organisation considered a current member by the information we have stored in the database, as listed on the
                                          marketing website.</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Data submitted</th>
                                      <td>At least one submission for an organisation registered for a project, submitted data for that project (not necessarily
                                          all of them)</td>
                                    </tr>
                                  </tbody>
                                </table>
                                <hr />
                                <h5 class="card-text">Dashboard</h5>
                                <p class="card-text">The dashboard is intended to provide visualisations of the overarching data on our user engagement and highlight key metrics. The filters on the
                                  left of the page allow you to interact with the data and create a spotlight view on a specific organisation(s) and/or date interval.<br />
                                  <br />
                                  Additional to impacting the graphs using these filters, there is also an ability to drill down on specific sections of the visuals, which will
                                  consequently update the metric cards and other graph. Certain years/months/days can be drilled down to on the line graph, and specific
                                  organisations can be spotlighted on the bar graph. Please note that if you have drilled down into a graph this overrides the date interval filter and
                                  you will have to drill all the way back up again to use this.<br />
                                  <br />
                                  The only static metric on the dashboard is the ‘Number of Logins Yesterday’ card, which is calculated via an SQL script that is run just after
                                  midnight for the day before, every day. Therefore, this will not be updated by features and cannot be seen for individual organisations through
                                  this card.<br />
                                  <br />
                                  You may view all the visuals on this page with only the logins from users within an organisation that is one of our current members, for example.
                                </p>
                                <hr />
                                <h5 class="card-text">Roletype Logins</h5>
                                <p class="card-text">This page focuses on the data grouped by the roles stored for users that log in. Please note that, since users have multiple roles, the counts on
                                  this page are not accurate. Hence why a 100% stacked bar chart has been used to focus on the split rather than the total.<br />
                                  <br />
                                  The first table shows the total login count for each organisation as well as the login count for users assigned the role of ‘Project Lead’ for that
                                  organisation. I have also included the count of Project Leads for the organisation listed, in order to highlight those with low logins compared to
                                  Project Lead count. The table at the bottom right of the page lists individual users alongside the date of their most recent login, their email, and
                                  their assigned role. You can use the filters to the left of the page to gain a list of all the users within a certain organisation, with a certain role, and
                                  the dates that they last logged in.<br />
                                  <br />
                                  The other two visuals give insight into the split of the roles within our login count. It is important to consider the effect of multiple roles for a single
                                  user when looking at these.
                                </p>
                                <hr />
                                <h5 class="card-text">Product Logins</h5>
                                <p class="card-text">NB: the data on this page is grouped into products based on the assignment of a product ID alongside user roles. As a result, it is not to be read
                                  as logins which had the specific intention of accessing the listed product projects, but instead provides an interesting insight into the way we store
                                  our data and the split of activity for those users who are linked to a certain product.<br />
                                  <br />
                                  Each user has roles within a certain organisation which is linked to a product. As users have multiple roles that have the potential to span multiple
                                  products (although this is rare), the count of these products will be skewed slightly. In the same way as the previous page this provokes an
                                  inspection of the clarity and efficiency of how we store information about our users and, looking forward, provides a potential to show more
                                  accurate data, should we amend our processes. The data is visualised for the purpose of comparison between products as opposed to analysis
                                  of the actual counts, as in the last page.<br />
                                  <br />
                                  A timeseries graph, split by product, shows the interaction and activity linked to each product individually over the years. To inspect this in a
                                  reduced time frame, the date interval filter can be used, as well as the drill down functionality. As in the previous pages the option to filter by
                                  organisation and see each visual specifically for their users is available.
                                </p>
                                <hr />
                                <h5 class="card-text">Organisation Logins</h5>
                                <p class="card-text">Due to the structure of our database, the information displayed on this page has more accuracy than the previous two in terms of the distribution
                                  of logins linked to a certain organisation, since users are only assigned roles within one organisation.<br />
                                  <br />
                                  There are slightly more filter options available on this page to allow the visuals to be adapted to show data for a certain organisation type as well
                                  as individual organisations.<br />
                                  <br />
                                  The map provides a look at the distribution of our members across the country which is especially interesting when looking at a certain
                                  organisation type. I have included a timeseries 100% split bar chart as well to highlight the changing engagement with different types of
                                  organisations over the years. There is also a table listing organisation names, alongside their type and the login count for users with a role
                                  assigned for them.
                                </p>
                                <hr />
                                <h5 class="card-text">Project Participation</h5>
                                <p class="card-text">NB: the data shown on this page is referencing the registration to a project, and therefore differs to the numbers when looking at organisations
                                  that actually submitted data (see next page for this comparison).<br />
                                  <br />
                                  The ribbon chart, split by project, visualises any changes in projects with the highest registrations over the years. As usual, this can be viewed for
                                  individual months or days too.<br />
                                  <br />
                                  The table provides more information including the time the registration occurred. Using the filters, this can be used to gain a list of all the
                                  organisations that registered for a certain project in a certain year, for example.
                                </p>
                                <hr />
                                <h5 class="card-text">Registration vs Data Submission</h5>
                                <p class="card-text">NB: our data stored across two databases, one for monthly projects and the other for annual. Since this report is only pulling data from the
                                  database holding the annual project submissions, it does not include data for monthly, or offline projects.<br />
                                  <br />
                                  Use table (filtered by year and selecting data submitted=’N’) and order by organisation name to check organisations that have registered but not
                                  submitted data for multiple years/projects etc.<br />
                                  <br />
                                  The pie chart shows percentage of registrations which actually resulted in data being submitted. The tree map visualises the number of
                                  registrations without data submission grouped by project. This is then impacted by the filters for year/organisation. (If there is an organisation
                                  listed on this page then it registered for some project on some year.)
                                </p>
                                <hr />
                                <h5 class="card-text">Active Users</h5>
                                <p class="card-text">Using the selector to the top left of this page, you can choose the number of months that defines an active user, and update the remaining visuals
                                  and metric accordingly. Once you have decided on this definition, the rest work the same as usual, with filters to the left of the page, and
                                  drilldown capabilities on graphs. The table on the bottom right can take a while longer to load due to the nature of the script needed to extract this
                                  data from the database - so please be patient with it.
                                </p>
                                <hr />
                                <h5 class="card-text">Events Analytics</h5>
                                <p class="card-text">It does what it says on the box. The data on this page is corresponding to the registrations for each event, not the attendance, which is currently
                                  not stored in the database but will hopefully be added at a later date.<br />
                                  <br />
                                  The table allows more detailed information including which user registered which organisation and what their email is, as well as the organisation
                                  and event name that they registered for. Multi-selecting specific events or years provides a comprehensive list of the registrations and contacts.<br />
                                  <br />
                                  The bar/line graph aims to show an overall insight into our events. The right hand vertical axis, corresponds to the line graph and shows the
                                  count of events we held. The left hand vertical axis corresponds to the stacked bar chart and visualises the count of registrations to our events,
                                  grouped by event. I have placed these on the same graph to indicate the years we had less events with higher attendance or vice versa.
                                </p>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
          </div>
      </div>
  </div>            
</ng-container>

