import { Pipe, PipeTransform } from '@angular/core';

import SuperExpressive  from 'super-expressive'

@Pipe({ name: 'highlight' })

export class HighlightPipe implements PipeTransform {

    transform(value: string, args: any): string {        
        value = value + "";
        if (!args) { return value; }  
        const myRegex = SuperExpressive()
        .allowMultipleMatches
        .caseInsensitive
        .string(args)
        .toRegex();
        return value.replace(myRegex, "<mark>$&</mark>");
    }

}