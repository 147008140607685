<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.users" 
        [data]="currentOrganisation.users" 
        [columns]="organisationUserTableColumns" 
        [currentYear]="currentYear"
        [presetFilters]="presetFilters"
        [tableTitle]="'userList'"
        [primaryKey]="'userId'"
        [onRowClick]="onUserClick">
    </app-dynamic-table>
</ng-template>

    <!-- Selected User -->
    <app-slider-panel *ngIf="selectedUser" [onClose]="closeUserSlider">
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
            <!-- Heading -->
            <app-area-heading [heading]="userLayout.heading" [subheading]="userLayout.subheading" [backRoute]="userLayout.backRoute"></app-area-heading>
            <!-- Form -->
            <app-dynamic-form [data]="selectedUser" [blocks]="organisationUserDetailsForm"></app-dynamic-form>

            <ng-container *ngIf="selectedUser.roles">
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Roles</h4>
                <app-dynamic-table 
                    [data]="selectedUser.roles"
                    [columns]="organisationUserRolesTableColumns" 
                    [currentYear]="currentYear"
                    [tableTitle]="'userRoles'"
                    [primaryKey]="'userRoleId'"
                    [onRowClick]="onUserRoleClick"
                    [hideExportButton]="true"
                    [hideSearch]="true"> 
                </app-dynamic-table>
            </ng-container>
        </ng-container>
    </app-slider-panel>
    <!-- Selected Role -->
    <app-slider-panel *ngIf="selectedUserRole" [layer]="1" [onClose]="closeUserRoleSlider">
        <!-- Heading -->
        <app-area-heading [heading]="roleLayout.heading" [subheading]="roleLayout.subheading" [backRoute]="roleLayout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="selectedUserRole" [blocks]="organisationUserRolesDetailsForm"></app-dynamic-form>
    </app-slider-panel>
