import { Injectable } from '@angular/core';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
  constructor(public jwtHelper: JwtHelperService) {}

  public authenticated(): boolean {
    return !this.jwtHelper.isTokenExpired();
  }

  public isAdmin(): boolean {
    let token = localStorage.getItem('adminUserToken');
    const decode = this.jwtHelper.decodeToken(token);
    return decode.isAdmin == 'Y';
  }

  public token(): string {
    return localStorage.getItem('adminUserToken') ?? null;
  }
}
