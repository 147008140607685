<button class="btn btn-primary shadow fix-bottom" (click)="showHelp = true">
    <i class="fal fa-lg fa-life-ring"></i><span class="ml-2">Dev Hub Request</span>
</button>

<!-- Teamwork Request Form -->
<ng-container *ngIf="showHelp">
    <div class="teamwork-container-bg" (click)="showHelp = false"></div>
    <div class="teamwork-container" >
        <iframe class="teamwork-embed teamwork-dynamic-height" 
            [src]="'https://rcigroup.eu.teamwork.com/app/public/forms/65wRMmLco3gKjYGwOgO0' | safe"
            frameborder="0" onmousewheel="" width="100%" height="100%">
        </iframe>
    </div>
</ng-container>