import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";

export const RolesAndProductsForm: IDynamicFormBlock[] = [
  {
    label: 'Role',
    helpText: 'Hold down CTRL to select multiple roles',
    dataKey: 'role',
    blockType: 'select',
    options: [],
    colWidth: 8,
  },
  {
    label: 'Product',
    helpText: 'Hold down CTRL to select multiple products',
    dataKey: 'product',
    blockType: 'select',
    options: [],
    colWidth: 8,
  },
];