import { IDeserialise } from '../models/deserialise.vm';
import { Deserialisable } from './deserialisable.model';

export class Lookup implements Deserialisable {
  public code: any;
  public description: any;

  constructor(data: IDeserialise) {
    this.deserialise(data);
  }

  public isLookup(): boolean {
    return this && this.code && this.description;
  }

  deserialise(input: IDeserialise): this {
    Object.assign(this, input);
    return this;
  }
}
