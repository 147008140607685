import { Component, OnChanges, Input } from '@angular/core';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Store } from '@ngrx/store';
import { CrmService } from 'src/app/core/services/crm.services';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { Layout } from 'src/app/core/classes/layout';
import { DatePipe } from '@angular/common';
import { IInteractionsAssignee, IInteractionsComment, IInteractionsContact, IInteractionsDetail } from 'src/app/core/models/crm-interaction-detail.vm';
import { Observable } from 'rxjs';
import { InteractionsAssigneesTable, InteractionsCommentsTable, InteractionsContactsTable, InteractionsDetailsForm, InteractionsFollowUpsTable, InteractionsTableColumns } from './crm-interactions-layouts';
import { IFollowUp } from 'src/app/core/models/crm-follow-ups.vm';
import { IInteraction } from 'src/app/core/models/crm-interactions.vm';

@Component({
  selector: 'app-crm-interactions',
  templateUrl: './crm-interactions.component.html',
  styleUrls: ['./crm-interactions.component.scss']
})
export class CrmInteractionsComponent implements OnChanges {

  @Input() currentYear: number;

  public selectedInteraction: IInteraction= null;
  public layout: ILayout;
  public interactionsTableColumns: Array<IDynamicTableColumn> = InteractionsTableColumns;
  public interactionsDetailsForm: Array<IDynamicFormBlock> = InteractionsDetailsForm;
  public interactionsFollowUpsTable = InteractionsFollowUpsTable;
  public interactionsContactsTable: Array<IDynamicTableColumn> = InteractionsContactsTable;
  public interactionsAssigneesTable: Array<IDynamicTableColumn> = InteractionsAssigneesTable;
  public interactionsCommentsTable: Array<IDynamicTableColumn> = InteractionsCommentsTable;
  public loadingError: string;
  public interactions: Array<IInteraction> = [];;
  public interactionDetail: IInteractionsDetail;
  public followUps: Array<IFollowUp> = [];
  public contacts: Array<IInteractionsContact> = [];
  public assignees: Array<IInteractionsAssignee> = [];
  public comments: Array<IInteractionsComment> = [];
  public interactionId: number;
  darkMode$: Observable<boolean>;

  constructor(
    public loaderService: LoaderService,
    private store: Store<{ darkMode: boolean }>,
    private crmService: CrmService,
    private datePipe: DatePipe
  ) { 
    this.darkMode$ = store.select('darkMode');
    this.getCrmInteractions();
  }

  ngOnChanges(): void {}

  private getCrmInteractions() {
    this.crmService.getInteractions().subscribe(
      success => {
        if (success.data.interactionList) {
          this.interactions = success.data.interactionList;
          success.data.interactionList.forEach(value => {
            value["interactionDate"] = this.datePipe.transform(value.interactionDate, 'M/d/yy, h:mm a');
          });
        }
      },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.message;
      }
    );
  }

  private getCrmInteractionDetail(interactionId) {
    this.crmService.getInteractionDetail(interactionId).subscribe(
      success => {
          this.interactionDetail = success.data.interactionDetail;
          this.followUps = this.interactionDetail.followUpsList;
          this.contacts = this.interactionDetail.personList;
          this.assignees = this.interactionDetail.userList;
          this.comments = this.interactionDetail.commentList;

          // Uncomment below & change followups at top and in interface file to an array when endpoint updated.
          this.followUps.forEach(value => {
            value["deadline"] = this.datePipe.transform(value.deadline, 'fullDate');
          });

          this.comments.forEach(value => {
            value["datetime"] = this.datePipe.transform(value.datetime, 'M/d/yy, h:mm a');
          });
      },
      error => {
        console.log('Error: ', error);
        this.loadingError = error.error.message;
      }
    );
  }

  public onInteractionClick = (primaryKey: number): void => {
    this.selectedInteraction = this.interactions.find((followup) => followup.interactionId == +primaryKey)
    this.layout = new Layout(
      this.selectedInteraction.organisationName,
      [null],
      null
      );
      this.getCrmInteractionDetail(primaryKey)
  };

  public closeInteractionSlider = (): void => {
    this.selectedInteraction = null;
  };
}