<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">

<ul class="nav flex-column rounded mb-3" [ngClass]="{'bg-light': !ui.darkMode, 'bg-dark': ui.darkMode}">
    <ng-container *ngFor="let item of navItems">
        <li class="nav-item">
            <a class="nav-link" [class.disabled]="item.disabled" [routerLink]="item.link" [queryParams]="{scope: item.scope}" [class.active]="activeScope == item.scope">
                {{ item.label }}
            </a>
        </li>
    </ng-container>
</ul>

</ng-container>
