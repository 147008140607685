import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Desk } from 'src/app/core/classes/desk';
import { IBooking } from 'src/app/core/models/booking.vm';

@Component({
  selector: 'app-floorplan',
  templateUrl: './floorplan.component.svg',
  styleUrls: ['./floorplan.component.scss']
})
export class FloorplanComponent implements OnInit {

  @Input() public desks: Array<Desk>
  @Input() public bookings: Array<IBooking>;
  @Input() public deskClicked?: string;

  @Output() public emitDeskClick = new EventEmitter<string>();
  @Output() public emitDeskHover = new EventEmitter<string>();

  public darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode')
  }

  ngOnInit(): void {
    
  }

  ngOnChanges(): void {
    this.updateDesks()
  }

  public deskHover(reference: string): void {
    this.emitDeskHover.emit(reference);
  }

  public deskClick(reference: string): void {
    this.deskClicked = reference;
    this.emitDeskClick.emit(reference);
  }

  public updateDesks() {
    // Map bookings and color circles
    this.desks.forEach(desk => {
      desk = desk.mapBookings(desk, this.bookings);
      setTimeout(() => {
        const circle = document.getElementById(desk.deskReferences);
        if (circle) {
          circle.setAttribute('fill', desk.color);
        }
      }, 200);
    });
  }
}