import { Component, Input, OnChanges } from '@angular/core';
import { Event } from 'src/app/core/classes/event';
import { eventDetailNavItems } from '../events-layouts';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/core/services/events.service';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { EventDetailBasics } from './event-detail-layout';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnChanges {
  @Input() public currentEvent: Event;

  public currentQueryParams: any;
  public currentScope: string = null;
  public loadingError: string = null;

  public eventDetailBasicsBlocks: Array<IDynamicFormBlock> = EventDetailBasics;
  public eventDetailNavItems: Array<INavItem> = eventDetailNavItems;

  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
  ) {}

  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
      this.scopeChange(this.currentQueryParams, this.currentEvent);
    });
  }
  private scopeChange(params: any, currentEvent: Event): void {
    this.currentScope = params.scope || 'basics';
  }
}
