import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

export const LibraryExtractsTableColumns: IDynamicTableColumn[] = [
  {
    header: 'ID',
    dataKey: 'extractId',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
  },
  {
    header: 'Title',
    dataKey: 'extractName',
    percWidth: 40,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Added',
    dataKey: 'extractAdded',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: true,
    dateFormat: 'MMM D Y, h:mm A'
  },
  {
    header: 'Updated',
    dataKey: 'extractUpdated',
    percWidth: 20,
    sortable: true,
    groupable: false,
    searchable: true,
    dateFormat: 'MMM D Y, h:mm A'
  },
  {
    header: 'Status',
    dataKey: 'extractLocation',
    percWidth: 15,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'status',
    insight: {
      title: "Possible States",
      noHeader: true,
      body: [
        {
          column1: 'In Progress',
          column2: 'Extract still in process',
          badge: '#fcc000',
        },
        {
          column1: 'Complete',
          column2: 'Extract has completed',
          badge: '#47754b',
          textColor: 'white'
        },
        {
          column1: 'Error',
          column2: 'Extract has failed',
          badge: 'rgba(108, 117, 125, 0.2)'
        },
      ]
    }
  },
  {
    header: 'Options',
    dataKey:'',
    percWidth: 10,
    sortable: false,
    groupable: false,
    searchable: false,
    options: ['clickable'],
    button: 'set',
    actions: [
      {
        color: 'btn btn-secondary btn-sm',
        action: 'set',
        description: 'Rerun',
      },
    ],
  },
];
