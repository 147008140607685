<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
  <ng-container *ngIf="!currentEvent">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12">
          <div class="row mt-5 mb-3">
            <div class="col-md-7 col-12" *ngIf="layout">
              <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading"
                [backRoute]="layout.backRoute"></app-area-heading>
            </div>
            <div class="col-md-5 col-12 d-flex">
              <div class="ml-auto">
                <app-year-select (emitCurrentYear)="yearChange($event)"></app-year-select>
              </div>
            </div>
          </div>
          <app-dynamic-table 
            [data]="eventList" 
            [columns]="eventTableColumns" 
            [tableTitle]="'eventList'"
            [primaryKey]="'eventId'" 
            [defaultGroupedColumn]="'pastOrFuture'"
            [onRowClick]="onTableRowClick">
          </app-dynamic-table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<!-- Current Event -->
<ng-container *ngIf="currentEvent">
  <div class="container-fluid">
    <div class="row justify-content-center mb-5">
      <div class="col-md-11 col-12">
        <div class="row mt-5 mb-3">
          <div class="col-md-7 col-12" *ngIf="layout">
            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading"
              [backRoute]="layout.backRoute"></app-area-heading>
          </div>
          <div class="col-md-5 col-12 d-flex">
            <div class="ml-auto">
              <app-year-select (emitCurrentYear)="yearChange($event)" [yearDisabled]="currentSection"></app-year-select>
            </div>
          </div>
        </div>

        <app-area-nav [navItems]="eventNavItems"></app-area-nav>
        <app-event-detail *ngIf="currentSection == 'details'" [currentEvent]="currentEvent"></app-event-detail>
        <app-event-document *ngIf="currentSection == 'documents'" [currentEvent]="currentEvent" [currentYear]="currentYear"></app-event-document>
        <app-event-registration *ngIf="currentSection == 'registrations'" [currentEvent]="currentEvent" [currentYear]="currentYear"></app-event-registration>
        <app-event-attendee *ngIf="currentSection == 'attendees'" [currentEvent]="currentEvent" [currentYear]="currentYear"></app-event-attendee>
      </div>
    </div>
  </div>
</ng-container>