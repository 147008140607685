import { Component, Input, OnChanges} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Layout } from 'src/app/core/classes/layout';
import { Project } from 'src/app/core/classes/project';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IProjectDashboard } from 'src/app/core/models/project-dashboard-vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import { DashboardMetricDetailsForm, DashboardTableColumns } from './project-dashboard-layouts';
interface IFilter { id: number; name: string; }

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnChanges{

  @Input() currentProject: Project;
  @Input() currentYear: number;
  
  private currentQueryParams: any;

  public layout: ILayout;

  public selectedDashboardMetric: IProjectDashboard;

  public dashboardTableColumns: Array<IDynamicTableColumn> = DashboardTableColumns;
  public dashboardMetricDetailsForm: Array<IDynamicFormBlock> = DashboardMetricDetailsForm;

  public loadingError: string;
  public noAvailableDashboards = false;
  public displayDashboard: boolean = false;
  public skipDashboardSelect: boolean = false;
  public selectedFilters = <IFilter[]>[];
  public prefilters: any = [
    { filterName: 'services', filterOptions: <IFilter[]>[] }
  ]

  darkMode$: Observable<boolean>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    public loaderService: LoaderService,
    private store: Store<{ darkMode: boolean }>
  ) { 
    this.darkMode$ = store.select('darkMode')
  }

  ngOnChanges(): void {
    // Check for open dashboard
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });

    // Open selected, if in params
    if (this.currentQueryParams.service) {
      this.displayDashboard = true;
      this.getProjectDashboard(this.currentProject.projectId, this.currentYear, this.currentQueryParams.service);
    } else {
      this.getProjectDashboard(this.currentProject.projectId, this.currentYear, null);
    }
  }

  private getProjectDashboard(projectId: number, currentYear: number, serviceItemId?: number): void {
    this.projectService.getDashboardMetrics(projectId, serviceItemId, currentYear).subscribe(
      success => { 
        if(success.data.metricList.length) {
          this.currentProject.dashboard = success.data.metricList;
          // this.setDashboardFilter(success.data.availableYearServices[this.currentYear]);
          ///////////////////////////////////////////////////////////////////////////////////////////////////   FIX //////////////////////////////////////////
          let { [this.currentYear]: currentYearServices } = success.data.availableYearServices;
          this.setDashboardFilter(currentYearServices);
          //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        } else {
            this.noAvailableDashboards = true;
            this.currentProject.dashboard = [];
        }
       },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  private setDashboardFilter(dashboards): void {
    this.prefilters[0].filterOptions = dashboards.map(sub => { return { id: sub.serviceItemId, name: sub.serviceItemName } });

    if(this.prefilters[0].filterOptions[0].id == null){
      this.skipDashboardSelect = true;
      }
    if(this.currentQueryParams) {
      this.selectedFilters = this.prefilters[0].filterOptions.filter(c => c.id == this.currentQueryParams.service)
      
    } 
    if(this.currentQueryParams.metric) {
      this.onDashboardMetricClick(this.currentQueryParams.metric);
    }

  }

  public onDashboardMetricClick = (primaryKey: string): void => {
    this.selectedDashboardMetric = this.currentProject.dashboard.find(dash => dash.metricId == +primaryKey);
    this.layout = new Layout(
      this.selectedDashboardMetric.metricText,
      [ `ID: ${this.selectedDashboardMetric.metricId}`, `Service ID: ${this.selectedDashboardMetric.serviceItemId}` ],
      null
    )
    this.router.navigate([], { queryParams: { metric: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeDashboardMetricSlider = (): void => {
    this.selectedDashboardMetric = null;
    this.router.navigate([], { queryParams: { metric: null }, queryParamsHandling: 'merge' });
  }

  public updateDashboard(filterItems: any): void {
    this.displayDashboard = true;
    this.selectedFilters = filterItems;
    this.router.navigate([], { queryParams: { service: filterItems[0].id }, queryParamsHandling: 'merge' });
    this.getProjectDashboard(this.currentProject.projectId, this.currentYear, filterItems[0].id);
  }

  public clearFilter(): void {
    this.selectedFilters = null;
    this.displayDashboard = false;
    this.currentProject.dashboard = [];
    this.noAvailableDashboards = false;
    this.router.navigate([], { queryParams: { service: null }, queryParamsHandling: 'merge' });
  }
}
