import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicChangeList } from "src/app/core/models/dynamic-table-change-list.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const EventRegistrationDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Registration Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        blockType: 'break'
    },
    {
        label: 'Name',
        dataKey: 'fullName',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Email',
        dataKey: 'emailAddress',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Job Title',
        dataKey: 'jobTitle',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Contact Number',
        dataKey: 'contactNo',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Twitter',
        dataKey: 'twitterHandle',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Organisation ID',
        dataKey: 'organisationId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    }, 
    {
        label: 'Status',
        dataKey: 'status',
        blockType: 'select',
        colWidth: 6,
        options: Constants.registrationStatus,
    },
    {
        label: 'Attended',
        dataKey: 'attended',
        blockType: 'select',
        colWidth: 6,
        options: Constants.attendedStatus,
    },  
    {
        label: 'Dietary Requirements',
        dataKey: 'dietaryRequirements',
        blockType: 'select',
        colWidth: 6,
        options: Constants.dietaryRequirements,
    },
    {
        label: 'Dietary Details',
        dataKey: 'dietaryDetails',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Access Requirements',
        dataKey: 'accessRequirements',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Event ID',
        dataKey: 'eventId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Registrant Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        blockType: 'break'
    },
    {
        label: 'Registrant',
        dataKey: 'registeringUserFullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'User ID',
        dataKey: 'userId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registrant Email',
        dataKey: 'registeringUserLogonName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registration Date',
        dataKey: 'registrationDt',
        blockType: 'input',
        colWidth: 6,
        disabled: true,
    },
    {
        label: 'Registration ID',
        dataKey: 'registrationId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
]


export const RegistrationTableUpdate: IDynamicChangeList[] = [
    {
        header: 'Events',
        name: 'Registrations',
        currentList: 'R',
        hideValue: true,
        displayHelpText: false
    },
    {
        header: 'Events',
        name: 'Non Member Registration Requests',
        currentList: 'PENDING',
        hideValue: true,
        displayHelpText: false
    }
];

export const EventRegistrationTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Member',
        dataKey: 'isMember',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Email',
        dataKey: 'emailAddress',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Job Title',
        dataKey: 'jobTitle',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
    },    
    {
        header: 'Attended',
        dataKey: 'attended',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    {
        header: 'Dietary Requirements (hidden)',
        dataKey: 'dietaryRequirements',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Dietary Details (hidden)',
        dataKey: 'dietaryDetails',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false,
    },
];