import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teamwork-form',
  templateUrl: './teamwork-form.component.html',
  styleUrls: ['./teamwork-form.component.scss']
})
export class TeamworkFormComponent implements OnInit {
  public showHelp: boolean = false;

  constructor(
    ) {}

  ngOnInit(): void { 
  }

}
