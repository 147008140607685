import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const ResponseTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'responseId',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Submission',
        dataKey: 'submissionName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Service',
        dataKey: 'serviceItemName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Question',
        dataKey: 'questionText',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Question ID',
        dataKey: 'questionId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Response',
        dataKey: 'response',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Question Part',
        dataKey: 'questionPart',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Question Type',
        dataKey: 'questionType',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Case Code',
        dataKey: 'caseCode',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
      header: 'Validate',
      percWidth: 5,
      dataKey: 'validationId',
      sortable: true,
      groupable: false,
      searchable: false,
      options: ['clickable'],
      button: 'validate',
      actions: [
        {
          color: 'btn btn-secondary btn-sm',
          action: 'set',
          description: 'Set',
        },
      ],
    },
];

export const ValidationDetailsFormBlocks: IDynamicFormBlock[] = [
    {
      blockType: 'divider',
    },
    {
      label: 'External note:',
      dataKey: 'validationDescription',
      helpText: 'This is an external note, visible to users when validation points are shared.',
      blockType: 'textarea',
      colWidth: 12,
      withoutEditor: true,
      rowCount: 6,
    },
    {
      label: 'Internal note:',
      dataKey: 'notes',
      helpText: 'This is an internal note, only visible to admins and not users.',
      blockType: 'textarea',
      colWidth: 12,
      withoutEditor: true,
      rowCount: 6,
    },
    {
      blockType: 'saveButton',
      dataKeys: [],
      label: 'Save',
    }
  ];

export const ResponseDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Response Details',
        helpText: 'This section edits the details of the response.',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Question',
        dataKey: 'questionText',
        blockType: 'input',
        inputType: 'text',
        colWidth: 12,
        disabled: true
    },
    {
        label: 'Question Group Name',
        dataKey: 'questionGroupName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Question Group Level Description',
        dataKey: 'questionGroupLevelDescription',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Question Group ID',
        dataKey: 'questionGroupId',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Question Group Level ID',
        dataKey: 'questionGroupLevelId',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation ID',
        dataKey: 'organisationId',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Response',
        dataKey: 'response',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service',
        dataKey: 'serviceItemName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Case Code',
        dataKey: 'caseCode',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6,
        disabled: true
    },
    // {
    //     blockType: 'divider'
    // },
    // {
    //     label: 'Validations',
    //     helpText: 'NB: New end-point required',
    //     blockType: 'header',
    //     colWidth: 12
    // },
]