import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const NonMemberEventRegistrationTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Email',
        dataKey: 'emailAddress',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Job Title',
        dataKey: 'jobTitle',
        percWidth: 35,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisation',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true,
    }
];

export const NonMemberEventRegistrationDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Registration Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        blockType: 'break'
    },
    {
        label: 'Name',
        dataKey: 'fullName',
        blockType: 'input',
        disabled: true,
        colWidth: 6,
    },
    {
        label: 'Email',
        dataKey: 'emailAddress',
        blockType: 'input',
        disabled: true,
        colWidth: 6,
    },
    {
        label: 'Organisation',
        dataKey: 'organisationId',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisations',
        button: [{
            text: 'Create a new organisation',
            style: 'fas fa-plus-square c-pointer',
            action: 'createOrganisation',
        }]
    },  
    {
        label: 'Job Title',
        dataKey: 'jobTitle',
        blockType: 'input',
        disabled: true,
        colWidth: 6,
    }, 
    {
        label: 'Comments',
        dataKey: 'comment',
        blockType: 'input',
        disabled: true,
        colWidth: 12,
    },
    {
        label: 'Dietary Requirements',
        dataKey: 'dietaryRequirements',
        blockType: 'select',
        colWidth: 4,
        options: Constants.dietaryRequirements,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Dietary Details',
        dataKey: 'dietaryDetails',
        blockType: 'input',
        disabled: true,
        colWidth: 12,
    },   
    {
        label: 'Access Requirements',
        dataKey: 'accessRequirements',
        blockType: 'input',
        disabled: true,
        colWidth: 12,
    },    
    {
        blockType: 'divider'
    },
    {
        blockType: 'accept-Amend&Accept-Reject-Button'
    }, 
]

export const CreateOrganisationForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Organisation Name *',
        dataKey: 'organisationName',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Area Code *',
        dataKey: 'postalAddressPc',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Organisation Url',
        dataKey: 'organisationUrl',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 1',
        dataKey: 'postalAddress1',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Organisation Type *',
        dataKey: 'organisationTypeId',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisationTypes',        
    },
    {
        label: 'Address 2',
        dataKey: 'postalAddress2',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Organisation Type Other',
        dataKey: 'organisationTypeOther',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 3',
        dataKey: 'postalAddress3',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'VAT',
        dataKey: 'vatCharge',
        inputType: 'number',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Address 4',
        dataKey: 'postalAddress4',
        inputType: 'string',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'PO',
        dataKey: 'poRequired',
        blockType: 'select',
        colWidth: 6,
        options: Constants.yesNo,
    },
    {
        blockType: 'divider'
    }, 
    {
        label: 'Save',
        dataKeys: ['organisationName','postalAddressPc','organisationTypeId'],
        blockType: 'saveButton',
    }
];

