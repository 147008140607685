import { Component, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Layout } from '../core/classes/layout';
import { User } from '../core/classes/user';
import { Constants } from '../core/constants/constants';

import { IDynamicFormBlock } from '../core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { ILayout } from '../core/models/layout.vm';
import { INavItem } from '../core/models/nav-item.vm';
import { IUserRole } from '../core/models/user-role.vm';
import { IUser } from '../core/models/user.vm';

import { LoaderService } from '../core/services/loader.service';
import { UserService } from '../core/services/user.service';

import { ProfileBasics, ProfileNavItems, ProfileRolesDetailsForm, ProfileRolesTableColumns } from './profile-layouts';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnChanges {

  public loadingError: string = null;
  public currentScope: string = 'basics';
  public currentQueryParams: any;

  public layout: ILayout;
  public roleLayout: ILayout;
  public currentYear: number = Constants.currentYear;
  public profileNavItems: Array<INavItem> = ProfileNavItems;
  public profileBasics: Array<IDynamicFormBlock> = ProfileBasics;
  public profileRoles: Array<IDynamicTableColumn> = ProfileRolesTableColumns;
  public profileRolesDetailsForm: Array<IDynamicFormBlock> = ProfileRolesDetailsForm;

  public currentUser: IUser = null;
  public currentUserRole: IUserRole = null;

  constructor(
    private route: ActivatedRoute,
    private users: UserService,
    public loaderService: LoaderService,
    private router: Router
  ) { 
    this.route.params.subscribe(p => {
      this.routeChange();
    });
  }

  private routeChange(): void {
    this.route.queryParams.subscribe((params) => {
      this.currentScope = params.scope || 'basics';
      this.currentQueryParams = params;
      if (!this.currentUser) { 
        this.getUserDetails();
      }
    });
  }

  ngOnChanges() { }

  private getUserDetails() {
    this.users.getUser().subscribe(
      success => {
        this.currentUser = new User(success.data);
        this.layout = new Layout('My Profile', [`ID: ${this.currentUser.userId}`, `Name: ${this.currentUser.fullName}`], null)

        // Open slider, if in params
        if (this.currentQueryParams.role) {
          this.onUserRoleClick(this.currentQueryParams.role);
        }
      },
      error => { 
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    )
  }

  public onUserRoleClick = (primaryKey: string): void => {
    this.currentUserRole = this.currentUser.roles.find(user => user.roleType == +primaryKey);
    this.roleLayout = new Layout(
      this.currentUserRole.roleName,
      [ this.currentUserRole.organisationName ],
      null
    )
    this.router.navigate([], { queryParams: { role: primaryKey }, queryParamsHandling: 'merge' });
  }

  public closeUserRoleSlider = (): void => {
    this.currentUserRole = null;
    this.router.navigate([], { queryParams: { role: null }, queryParamsHandling: 'merge' });
  }
  
}
