<!-- formControl (hidden) -->
<div [formGroup]="form" class="d-none"> 
    <input [type]="block.inputType" [formControlName]="block.dataKey" />
</div>


<!-- Tooltip -->
<ng-template #rowTooltipContent>
    <span [innerHTML]="selectedOption?.name"></span>
</ng-template>

<!-- Drop-down (visible) -->
<ng-container *ngIf="options.length > 0">
    <div class="form-group position-relative" (clickElsewhere)="showDropdown = false" *ngIf="{ darkMode: darkMode$ | async } as ui">
        <div class="d-flex justify-content-between">
            <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }}
                <i *ngIf="block.helpText" class="far fa-info-circle form-info-icon" [title]="block.helpText" placement="right"></i>
            </label>
            <span *ngIf="block.button">
                <span *ngFor="let item of block.button">
                    <i [class]="item.style" style="color: #003087;" [ngbTooltip]="item.text" (click)="buttonAction(item.action)"></i>  
                </span>
            </span>
        </div>
        <!-- <small [id]="block.dataKey + 'Help'" class="form-text text-muted" [innerHTML]="block.helpText"></small> -->
                <div [id]="block.dataKey" class="form-control c-pointer text-truncate" [ngbTooltip]="rowTooltipContent"
                    [ngClass]="{'bg-dark-900 text-light border-dark-700': ui.darkMode}" 
                    (click)="toggleDropdown()" [innerHtml]="selectedOption?.name">
                </div>
                <div class="border rounded py-2 px-3 dd-container mt-5" *ngIf="showDropdown" [ngClass]="{'bg-dark text-light border-dark-700': ui.darkMode}">
                    <input type="text" #searchInput [(ngModel)]="searchInputValue" placeholder="Search by name..." class="form-control mb-2 mt-1">
                    <div class="dd-item" *ngFor="let opt of options | filterBy: ['name']: searchInputValue" (click)="selectItem(opt)">
                        <span [innerHTML]="opt.name | highlight: searchInputValue"></span>
                    </div>
                    <div *ngIf="(options | filterBy: ['name']: searchInputValue).length == 0" class="text-muted text-center">
                        No results for this search
                    </div>
                </div>
        </div>
</ng-container>