import { INavItem } from "src/app/core/models/nav-item.vm";

export const crmNavItems: INavItem[] = [
    {
        icon: 'fa-house',
        label: 'Overview',
        link: '../overview'
      },
      {
        icon: 'fa-lightbulb',
        label: 'Opportunities',
        link: '../opportunities',
      },
      {
        icon: 'fa-user',
        label: 'Interactions',
        link: '../interactions'
      },
      {
        icon: 'fa-tasks',
        label: 'Follow-Ups',
        link: '../follow-ups',
      }
]