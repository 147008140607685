<app-alert-status *ngIf="alert" [alert]="alert" [parent]="componentName" (emitAlertCancelled)="alert = $event" (removeAlertBackDrop)="removeBackdrop()"></app-alert-status>
<div class="container-fluid" *ngIf="{ darkMode: darkMode$ | async } as ui">
  <div id="alert-backdrop"></div>
  <div class="row justify-content-center" [ngClass]="{ 'text-light': ui.darkMode }">
    <div class="col-md-11 col-12">
      <div class="row mt-5 mb-3">
        <div class="col-md-7 col-12" *ngIf="layout">
            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        </div>
      </div>

      <div>
        <app-area-nav [navItems]="dataExtractorNavItems"></app-area-nav>
      </div>

      <section class="form-group border-bottom pb-4 mb-4" [ngClass]="{ 'border-dark': ui.darkMode }">
        <h4 class="font-weight-bold mb-3">Step 1: Name your extract</h4>

        <input
          type="input"
          class="form-control"
          aria-describedby="input title"
          placeholder="Enter Title"
          [(ngModel)]="inputTitle"
          name="inputTitle"
        />
      </section>

      <section class="border-bottom pb-4 mb-4" [ngClass]="{ 'border-dark': ui.darkMode }">
        <h4 class="font-weight-bold mb-3">Step 2: General Settings</h4>
        <p>
          Start by selecting the years and projects for which you require data, with the format you would like it in.
          You can download all the data for the project/years selected, or
          refine the extract at the next step.
        </p>

        <div class="mb-3">
          <ng-container *ngFor="let filter of prefilters">
            <app-multi-select-filter
              [title]="filter.filterName"
              [filterOptions]="filter.filterOptions"
              [placeholder]="filter.placeholder"
              [filtered]="'id'"
              [itemName]="filter.name"
              [key]="filter.name"
              (newSelection)="
                newSelection({ items: $event, filterName: filter.name })
              "
              class="mr-2"
            >
            </app-multi-select-filter>
          </ng-container>
        <!-- formatter Reportomatic dropdown -->
        <div class="d-inline-block position-relative" (clickElsewhere)="showReportomaticDropdown = false">
          <div class="d-inline-block position-relative" (clickElsewhere)="showReportomaticDropdown = false">
            <button id="Format-data" class="btn btn-light border"(click)="showReportomaticDropdown = !showReportomaticDropdown;">
              Select Format
              <i class="fas ml-1" [class.fa-caret-up]="showReportomaticDropdown" [class.fa-caret-down]="!showReportomaticDropdown"></i>
            </button>
            <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showReportomaticDropdown">
              <div *ngFor="let item of format" class="dd-item" (click)="selectOneFormat(item);">
                <div class="d-flex">
                <div>
                  <i class="far mr-2" [class.fa-check-square]="item.selected"></i>
                </div>
                  <div>
                    <span>
                      {{item['format']}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>

          <!-- sorting reportomatic -->
          <div class="d-inline-block position-relative" (clickElsewhere)="showSortingOption = false" *ngIf="showSortingDropdown">
            <div class="d-inline-block position-relative" (clickElsewhere)="showSortingOption = false">
              <button id="Format-data" class="btn btn-light border ml-2" (click)="showSortingOption = !showSortingOption;">
                Select Sorting
                <i class="fas ml-1" [class.fa-caret-up]="showSortingOption" [class.fa-caret-down]="!showSortingOption"></i>
              </button>
              <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showSortingOption">
                <div *ngFor="let item of sorting" class="dd-item" (click)="selectOneSort(item);">
                  <div class="d-flex">
                  <div>
                    <i class="far mr-2" [class.fa-check-square]="item.selected"></i>
                  </div>
                    <div>
                      <span>
                        {{item['sort']}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

          <!-- case code dropdown -->
          <div class="d-inline-block position-relative" (clickElsewhere)="showCaseCodeDropdown = false">
            <div class="d-inline-block position-relative" (clickElsewhere)="showCaseCodeDropdown = false">
              <button class="btn btn-light border ml-2"(click)="showCaseCodeDropdown = !showCaseCodeDropdown;">
                Select Case Code
                <i class="fas ml-1" [class.fa-caret-up]="showCaseCodeDropdown" [class.fa-caret-down]="!showCaseCodeDropdown"></i>
              </button>
              <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showCaseCodeDropdown">
                <div *ngFor="let item of caseCode" class="dd-item" (click)="selectOneCaseCode(item);">
                  <div class="d-flex">
                  <div>
                    <i class="far mr-2" [class.fa-check-square]="item.selected"></i>
                  </div>
                    <div>
                      <span>
                        {{item['case']}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

          <!-- Question Focus dropdown -->
             <div class="d-inline-block position-relative" (clickElsewhere)="showquestionFocusDropdown = false">
              <div class="d-inline-block position-relative" (clickElsewhere)="showquestionFocusDropdown = false">
                <button class="btn btn-light border ml-2"(click)="showquestionFocusDropdown = !showquestionFocusDropdown;">
                  Select Question Focus
                  <i class="fas ml-1" [class.fa-caret-up]="showquestionFocusDropdown" [class.fa-caret-down]="!showquestionFocusDropdown"></i>
                </button>
                <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showquestionFocusDropdown">
                  <div *ngFor="let item of questionFocus" class="dd-item" (click)="selectOneQuestionFocus(item);">
                    <div class="d-flex">
                    <div>
                      <i class="far mr-2" [class.fa-check-square]="item.selected"></i>
                    </div>
                      <div>
                        <span>
                          {{item['question']}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>

            <!-- Toggle server dropdown -->
             <div class="d-inline-block position-relative" (clickElsewhere)="showToggleServerDropdown = false">
              <div class="d-inline-block position-relative" (clickElsewhere)="showToggleServerDropdown = false">
                <button class="btn btn-light border ml-2"(click)="showToggleServerDropdown = !showToggleServerDropdown;">
                  Select Server
                  <i class="fas ml-1" [class.fa-caret-up]="showToggleServerDropdown" [class.fa-caret-down]="!showToggleServerDropdown"></i>
                </button>
                <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showToggleServerDropdown">
                  <div *ngFor="let item of toggleServer" class="dd-item" (click)="selectOneServer(item);">
                    <div class="d-flex">
                    <div>
                      <i class="far mr-2" [class.fa-check-square]="item.selected"></i>
                    </div>
                      <div>
                        <span>
                          {{item['server']}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
        </div>

        <button class="btn btn-info" (click)="showOptions()" *ngIf="!show">
          Confirm Selection
        </button>
      </section>

      <section *ngIf="show" class="pb-4 mb-4">
        <h4 class="font-weight-bold mb-3">Step 3: Refine extract</h4>
        <p>
          Choose to download data from specific submissions, organisations,
          services and question group levels within the above selections. You
          can select any combination below.
        </p>

        <div *ngFor="let year of selectedItemsYearObj" class="mb-4">
          <h5 class="font-weight-bold">{{ year.year }}</h5>
          <div class="pl-3 border-left border-dark">
            <div *ngFor="let project of selectedItemsIdObj" class="mb-3">
              <h5>{{ project.projectName }}</h5>
              <app-data-extractor-filter
                [year]="year.year"
                [id]="project.id"
                (newItemEvent)="newSelection($event)"
                
              >
              </app-data-extractor-filter>
            </div>
          </div>
        </div>

        <button
          class="btn btn-primary"
          [disabled]="clicked"
          [disabled]="disableExtractButton"
          (click)="submitDataExtract(); clicked = true"
        >
          Extract Data
        </button>
        <div *ngIf="dataExtractLoading" class="spinner-border spinner-border-sm ml-2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </section>
    </div>
  </div>
</div>
