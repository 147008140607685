import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const UsersRolesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Role Type',
        percWidth: 8,
        dataKey: 'roleType',
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Role Name',
        dataKey: 'roleName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Product Name',
        dataKey: 'productName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Project Name',
        dataKey: 'projectName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Enabled',
        dataKey: 'isEnabled',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false,
    },
]

export const UserRolesDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Role Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Project ID',
        dataKey: 'projectId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project Name',
        dataKey: 'projectName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Name',
        dataKey: 'roleName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Type',
        dataKey: 'roleType',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Year',
        dataKey: 'roleYear',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation Id',
        dataKey: 'organisationId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation Name',
        dataKey: 'organisationName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'product Id',
        dataKey: 'productId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Product Name',
        dataKey: 'productName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Region ID',
        dataKey: 'regionId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service Item ID',
        dataKey: 'serviceItemId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service Item Name',
        dataKey: 'serviceItemName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Is Defunct',
        dataKey: 'isDefunct',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Enabled',
        dataKey: 'isEnabled',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
]