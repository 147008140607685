import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StoredProcedures } from 'src/app/core/models/stored-procedures';

@Component({
  selector: 'app-project-toolkit-stored-procedures-select',
  templateUrl: './project-toolkit-stored-procedures-select.html',
  styleUrls: ['./project-toolkit-stored-procedures-select.component.scss']
})
export class ProjectToolkitStoredProceduresSelectFilterComponent implements OnInit {

  @Input() public storedProcedures: StoredProcedures
  
  @Output() public emitSelectedItems = new EventEmitter<any>();

  public showDropdown: boolean = false;
  public searchInputValue: string = '';

  darkMode$: Observable<boolean>;


  constructor(
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void {
   
  }

  public selectItem(item: any): void {
    this.showDropdown = !this.showDropdown
    this.storedProcedures.storedProcedure.objectName= item.objectName  
    this.emitSelectedItems.emit(item)
  }

}
