import { Component, Renderer2 } from '@angular/core';
import { LoaderService } from '../core/services/loader.service';
import { toolInformation } from './tool-info';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tool',
  templateUrl: './tool.component.html',
  styleUrls: ['./tool.component.scss']
})
export class ToolComponent {
  
  public loadingError: string = null;
  public toolInformation: Array<object> = toolInformation.getToolInfo();
  public selectedTool: any = null;

  constructor(   
    public loaderService: LoaderService,
    private router: Router,
    private renderer: Renderer2    
  ) {}
  
  public openTool(tool: any): void {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)   
    if (tool.route) {
      this.router.navigate(tool.route);       
    }  
  }

}
