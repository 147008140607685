<div
  class="container-fluid d-flex justify-content-center align-items-center vh-100"
  *ngIf="{ darkMode: darkMode$ | async } as ui"
>
  <div>
    <div>
    <h1
      [ngClass]="{
        'text-primary': !ui.darkMode,
        'text-primary-light': ui.darkMode
      }"
      class="display-3 mt-5"
    >
      404 Error
    </h1>
  <h2 [ngClass]="{'text-dark-600': ui.darkMode }" class="display-2 mt-5">Page Not Found</h2>
    <h3 [ngClass]="{'text-dark-600': ui.darkMode }"class="mt-3">
      The page you're looking for doesn't exist
    </h3>
  <button [routerLink]="['']"class="btn btn-primary shadow mt-5">Go Home</button>
  </div>
</div>
</div>

