<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="processLoading" loadingMessage="Loading..."></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>
    <div class="row justify-content-start">
        <div class="col-md-2">
            <app-section-nav [navItems]="organisationDetailNavItems"></app-section-nav>
        </div>
        <div class="col-md-10">
            <app-dynamic-form *ngIf="currentScope == 'basics'" [data]="currentOrganisation" [blocks]="organisationDetailBasicsBlocks" (dataUpdate)="updateOrganisation($event)"></app-dynamic-form>
            <!-- <app-dynamic-form *ngIf="currentScope == 'roles'" [data]="currentOrganisation" [blocks]="projectDetailRolesBlocks"></app-dynamic-form>
            <app-dynamic-form *ngIf="currentScope == 'dates'" [data]="currentOrganisation" [blocks]="projectDetailDatesBlocks"></app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'services' && currentOrganisation.serviceItems" 
                [data]="currentOrganisation" 
                [blocks]="projectDetailServiceItemBlocks">
            </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'guidance' && currentOrganisation.guidance" 
                [data]="currentOrganisation" 
                [blocks]="projectDetailGuidanceBlocks">
            </app-dynamic-form>
            <app-dynamic-form 
                *ngIf="currentScope == 'surveys' && currentOrganisation.surveys" 
                [data]="currentOrganisation" 
                [blocks]="projectDetailSurveyBlocks">
            </app-dynamic-form> -->
        </div>
    </div>
</ng-template>