import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Layout } from 'src/app/core/classes/layout';
import { ErrorStatusComponent } from '../alert-status/alert-status.component';

@Component({
  selector: 'app-drag-and-drop-uploader',
  templateUrl: './drag-and-drop-uploader.component.html',
  styleUrls: ['./drag-and-drop-uploader.component.scss']
})
export class DragAndDropUploaderComponent implements OnInit {
  @Output() uploadFile = new EventEmitter<any>();
  @Input() callerName: string;
  public newDocumentLayout: Layout;

  public files: any[] = [];
  public dynamicDocument: boolean = false;
  // Set limit to each file size to 100MB
  public maxFileBytesSize: number = 104857600
  public fileExceedingSizeError = { message: '', show: false } 

  constructor() { }

  ngOnInit(): void {
    if (this.callerName === 'dynamicDocument') {
      this.dynamicDocument = true
    }
  }

  public newDocumentClick() {
    this.newDocumentLayout = new Layout(
      'New Document Upload',
      ['Please upload a document file'],
      null
    )
  }

  public onDrop(event){
    // Reset error message and visibility
    this.fileExceedingSizeError.message = '';
    this.fileExceedingSizeError.show = false;

    const files = event.target ? event.target.files : event

    for (const item of files) {
      if (item.size < this.maxFileBytesSize) {       
         // Check for duplicates based on file name and size
         const isDuplicate = this.files.some(file => file.name === item.name && file.size === item.size);
         if (!isDuplicate) {
             this.files.push(item);
         }
      } else {   
        // Clear and re-set the value of the file input element 
        if (event.target) {
          event.target.value = ''   
        }
        // Show alert message
        this.fileExceedingSizeError.message = `File: ${item.name} cannot be uploaded because it exceeeds the maximum size allowed per file (100MB). <br> Please contact the Dev Hub for assistance.`
        this.fileExceedingSizeError.show = true
      }
    }
    if(this.dynamicDocument == true){
      this.uploadFile.emit(this.files)
    } 
  }

  onFileChange(file){
    this.files.push(Object.keys(file).map(key => file[key]))
  }

  public untickFile(file){
    if(this.files.find(item => item == file )){
     this.files = this.files.filter(item => item != file)
    }
    if(this.dynamicDocument == true){
      this.uploadFile.emit(this.files)
    }
  }

  public uploadDocument(){
    this.uploadFile.emit(this.files)
  }

  public closeAlert() {
    this.fileExceedingSizeError.show = false   
  }

}
