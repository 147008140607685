<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
    <label [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}">
        Stored Procedure</label>
    <div class="position-relative" (clickElsewhere)="showDropdown = false">
        <button class="mb-2" (click)="showDropdown = !showDropdown"
            [ngClass]="{'text-light bg-dark-900 border-dark-700 dark-mode': ui.darkMode}">
            {{storedProcedures.storedProcedure.objectName}}
            <i class="fas ml-1" [class.fa-caret-up]="showDropdown" [class.fa-caret-down]="!showDropdown"></i>
        </button>
        <div class="border rounded shadow py-2 px-3 dd-container"
            [ngClass]="{'text-light bg-dark-900 border-dark-700': ui.darkMode}" *ngIf="showDropdown">
            <input type="text" [(ngModel)]="searchInputValue" placeholder="Search by stored procedure name"
                [ngClass]="{'text-light bg-dark-900 border-dark-700 dark-mode': ui.darkMode}"
                class="form-control mb-2 mt-1">
            <div *ngFor="let item of storedProcedures.allStoredProcedures | filterBy: ['objectName']: searchInputValue"
                class="dd-item option" (click)="selectItem(item)">
                <div class="d-flex">
                    <div>
                        <span [innerHTML]="item.objectName | highlight: searchInputValue"></span>
                    </div>
                </div>
            </div>
            <div *ngIf="(storedProcedures.allStoredProcedures | filterBy: ['objectName']: searchInputValue).length == 0"
                class="text-muted text-center">
                No results for this search
            </div>
        </div>
    </div>
</ng-container>