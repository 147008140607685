<!-- // pass submissions to a multi select -->

<app-multi-select-filter
  [title]="'Submissions'"
  [filterOptions]="submissionsMapped"
  [itemName]="'submissionName'"
  [placeholder]="'Search by name or ID...'"
  [key]="makeKey()"
  class="mr-2"
  (newSelection)="newSelection($event, 'submissions')"
>
</app-multi-select-filter>

<!-- // pass registrations to a multi select -->

<app-multi-select-filter
  [title]="'Organisations'"
  [filterOptions]="registrationsMapped"
  [itemName]="'registrationName'"
  [placeholder]="'Search by name or ID...'"
  [key]="makeKey()"
  class="mr-2"
  (newSelection)="newSelection($event, 'organisations')"
>
</app-multi-select-filter>

<!-- // pass services to a multi select -->

<app-multi-select-filter
  [title]="'Services'"
  [filterOptions]="servicesMapped"
  [itemName]="'serviceName'"
  [placeholder]="'Search by name or ID...'"
  [key]="makeKey()"
  class="mr-2"
  (newSelection)="newSelection($event, 'services')"
>
</app-multi-select-filter>

<app-multi-select-filter
  [title]="'Question Group Level'"
  [filterOptions]="projectQuestionList"
  [itemName]="'groupLevelDescription'"
  [placeholder]="'Search by name or ID...'"
  [key]="makeKey()"
  class="mr-2"
  (newSelection)="newSelection($event, 'questionGL')"
>
</app-multi-select-filter>

