<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !opportunities); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="opportunities" 
        [data]="opportunities" 
        [columns]="opportunitiesTableColumns"
        [defaultGroupedColumn]="'stage'"
        [currentYear]="currentYear"
        [tableTitle]="'opportunitiesList'"
        [primaryKey]="'opportunityId'"
        [onRowClick]="onTableRowClick">
    </app-dynamic-table>
</ng-template>

<!-- Selected opportunity -->

<app-slider-panel *ngIf="opportunityLayout" [onClose]="closeQuestionSlider">
    <app-area-heading [heading]="opportunityLayout.heading" [subheading]="opportunityLayout.subheading"></app-area-heading>
    <app-dynamic-form [data]="selectedOpportunity" [blocks]="opportunitiesForm"></app-dynamic-form>

    <ng-container *ngIf="allInteractions?.length > 0 && { darkMode: darkMode$ | async } as ui">
        <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Interactions</h4>
        <app-dynamic-table
            [data]="allInteractions"
            [columns]="interactionsTableColumns" 
            [currentYear]="currentYear" 
            [tableTitle]="'Interaction'"
            [primaryKey]="'interactionId'"
            [hideExportButton]="true"
            [disableRowClick]="true">
        </app-dynamic-table>
    </ng-container>
</app-slider-panel>
