import { Component, OnInit, Renderer2 } from '@angular/core';
import { LoaderService } from '../core/services/loader.service';
import { ILayout } from '../core/models/layout.vm';
import { RolesService } from '../core/services/roles.service';
import { IUserRole } from '../core/models/user-role.vm';
import { RolesTableColumns } from './roles-layouts';
import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { Layout } from '../core/classes/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../core/constants/constants';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  public loadingError: string = null;
  public layout: ILayout;
  public currentUrlParams: any;
  public rolesList: Array<IUserRole> = new Array<IUserRole>();
  public rolesTableColumns: Array<IDynamicTableColumn> = RolesTableColumns;
  public currentYear: number;
  public currentSection: any


  
  constructor( 
    public loaderService: LoaderService,
    private rolesService: RolesService,
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2
  ) {
    this.route.params.subscribe(params => {
      this.currentUrlParams = params;
      this.routeChange(params);
    });
  }

  ngOnInit(): void {
  }

  private routeChange(params: any): void {
    this.renderer.setProperty(document.documentElement, 'scrollTop', 0)
    this.currentYear = params.year || Constants.currentYear;
      this.getRolesList(this.currentYear);
    
  }

  public yearChange(year: number): void {
      this.rolesList = new Array<IUserRole>();
      this.router.navigate(['/roles', year]);

    
  }

  private getRolesList(year: number): void {
    this.rolesService.getAllUserRoles(year).subscribe(success => {
      this.rolesList = success.userRoles
      this.layout = new Layout('Roles', null, null);
    })
  }

  public onTableRowClick = (primaryKey: any): void => {
    let currentUser = this.rolesList.find(a => a.user_role_id == primaryKey)
    this.router.navigate([`/users/${currentUser.user_id}/roles`], { queryParams: { role: primaryKey } });

  }


}
