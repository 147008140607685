<div class="row justify-content-start">
    <div class="col-md-2">
      <app-section-nav [navItems]="eventDetailNavItems"></app-section-nav>
    </div>
    <div class="col-md-10">
      <app-dynamic-form
        *ngIf="currentScope == 'basics'"
        [data]="currentEvent"
        [blocks]="eventDetailBasicsBlocks"
      ></app-dynamic-form>
    </div>
    </div>
