<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !selectedSubmission); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.submissions" 
        [data]="currentProject.submissions" 
        [columns]="submissionTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'submissionList'"
        [primaryKey]="'submissionId'"
        [disableRowClick]="true"
        [onTdClick]="onTableTdClick">
    </app-dynamic-table>

</ng-template>

    <!-- Selected Submission -->
    <app-slider-panel *ngIf="selectedSubmission" [onClose]="closeSubmissionSlider">
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
            <!-- Heading -->
            <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
            <!-- Form -->
            <app-dynamic-form
            
            [data]="selectedSubmission" [blocks]="SubmissionDetailsForm" (dataUpdate)="submissionUpdate($event)"></app-dynamic-form>
            <!-- Table: Services -->
            <ng-container *ngIf="selectedSubmission.services">
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Services</h4>
                <app-dynamic-table 
                    [data]="selectedSubmission.services"
                    [columns]="serviceTableColumns" 
                    [currentYear]="currentYear"
                    [tableTitle]="'submissionServices'"
                    [primaryKey]="'serviceItemName'"
                    [disableRowClick]="true"
                    [onRowClick]="onServiceClick"
                    [hideExportButton]="true"
                    [hideSearch]="true"> 
                </app-dynamic-table>
            </ng-container>
            <!-- Table: Case Codes -->
            <ng-container *ngIf="selectedSubmission.caseNoteCodes">
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Case Codes</h4>
                <app-dynamic-table 
                    [data]="selectedSubmission.caseNoteCodes"
                    [columns]="caseNoteCodeTableColumns" 
                    [currentYear]="currentYear"
                    [tableTitle]="'caseNoteCodes'"
                    [primaryKey]="'caseNoteId'"
                    [disableRowClick]="true"
                    [onRowClick]="onCaseCodeClick">
                </app-dynamic-table>
            </ng-container>
            <!-- Table: Editors -->
            <ng-container *ngIf="selectedSubmission.editHistory">
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Editors</h4>
                <app-dynamic-table 
                    [data]="selectedSubmission.editHistory"
                    [columns]="editHistoryTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'editHistory'"
                    [primaryKey]="'userId'"
                    [disableRowClick]="true"
                    [hideExportButton]="true"
                    [hideSearch]="true"> 
                </app-dynamic-table>
            </ng-container>
            <!-- Table: Data Uploads -->
            <ng-container *ngIf="selectedSubmission.allUploads">
                <hr>
                <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Uploads</h4>
                 <app-dynamic-table 
                        [data]="selectedSubmission.allUploads"
                        [columns]="dataUploadTableColumns" 
                        [currentYear]="currentYear"
                        [tableTitle]="'dataUploadList'"
                        [disableRowClick]="true"
                        [hideExportButton]="true"
                        [hideSearch]="true"> 
                    </app-dynamic-table>
            </ng-container>
        </ng-container>
    </app-slider-panel>


<app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)"></app-popup-modal>
