import { Component, Input, ViewEncapsulation, OnDestroy, OnChanges, Output, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FlattenPipe } from 'ngx-pipes';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Layout } from 'src/app/core/classes/layout';

import { Project } from 'src/app/core/classes/project';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IProjectTier } from 'src/app/core/models/project-tier.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import {
  ParameterDetailsFormBlocks,
  ParametersTableColumns,
  ReportDetailsFormBlocks,
  ReportOptionTableColumns,
  OptionDetailsFormBlocks,
  SeriesDetailsFormBlocks,
  TierDetailsFormBlocks,
  TierTableColumns,
  MetadataFormBlocks,
  ReportviewDetailsTableColumns,
} from './project-toolkit-layouts';
import { StoredProcedures } from 'src/app/core/models/stored-procedures';
import { selectStoredProcedures } from 'src/app/store/stored-procedures.selectors';
import moment from 'moment';

@Component({
  selector: 'app-project-toolkit',
  templateUrl: './project-toolkit.component.html',
  styleUrls: ['./project-toolkit.component.scss'],
  providers: [FlattenPipe],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectToolkitComponent implements OnChanges, OnDestroy {
  @Input() public currentProject: Project;
  @Input() public currentYear: number;
  @Output() public id: number;

  public currentQueryParams: any;

  public tierTableColumns: Array<IDynamicTableColumn> = TierTableColumns;
  public reportOptionsTableColumns: Array<IDynamicTableColumn> = ReportOptionTableColumns;
  public reportviewDetailsTableColumns: Array<IDynamicTableColumn> = ReportviewDetailsTableColumns;
  public parametersTableColumns: Array<IDynamicTableColumn> = ParametersTableColumns;

  public tierDetailsForm: Array<IDynamicFormBlock> = TierDetailsFormBlocks;
  public reportDetailsForm: Array<IDynamicFormBlock> = ReportDetailsFormBlocks;
  public seriesDetailsForm: Array<IDynamicFormBlock> = SeriesDetailsFormBlocks;
  public optionDetailsForm: Array<IDynamicFormBlock> = OptionDetailsFormBlocks;
  public parameterDetailsForm: Array<IDynamicFormBlock> = ParameterDetailsFormBlocks;
  public metadataForm: Array<IDynamicFormBlock> = MetadataFormBlocks;

  public storedProcedures: StoredProcedures;

  public selectedTier: IProjectTier;
  public selectedOption: any;
  public selectedParameter: any;

  public searchText: string;

  public tierLayout: ILayout;
  public optionLayout: ILayout;
  public parameterLayout: ILayout;

  public loadingError: string = null;
  private unsubscribe = new Subject();

  public currentYearQuestions: any[];
  public respondedQuestions: any[];
  public isQuestionSelectorOpened = false;
  public searchPlaceholder: string = 'Search by question text or ID';
  public searchValue: string;

  public serviceItemId: Array<number> = []

  darkMode$: Observable<boolean>;
  public reportViewDetails: string[];
  public serviceItems: any = []
  public viewCode: string;

  constructor(
    public loaderService: LoaderService,
    private flatten: FlattenPipe,
    private projectService: ProjectService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store<{ darkMode: boolean }>,
    private storeTest: Store<{testList}>
  ) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnChanges(): void {
    this.getServiceItems(this.currentProject.projectId)
    // Check for open registration
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    // Get tiers, if needed
    if (!this.currentProject.tiers) {
      this.getProjectTiers(this.currentProject.projectId, this.currentYear);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public getProjectTiers(projectId: number, currentYear: number): void {
    this.projectService
      .getProjectTiers(projectId, currentYear)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (success) => {
          this.currentProject.tiers = this.currentProject.setTiers(
            success.data.tiers
          );
          // Open selected, if in params
          if (this.currentQueryParams.tier) {
            this.onTierClick(this.currentQueryParams.tier);
          }
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public onTierClick = (primaryKey: string): void => {
    this.selectedTier = this.currentProject.tiers.find(
      (tier) => tier.id == +primaryKey
    );
    this.tierLayout = new Layout(
      this.selectedTier.name,
      [`Tier ID: ${this.selectedTier.id}`],
      null
    );
    this.router.navigate([], {
      queryParams: { tier: primaryKey },
      queryParamsHandling: 'merge',
    });
    // Get report details
    if (this.selectedTier.reportId) {
      this.getReportDetails(this.selectedTier); 
      this.getAllReports(this.selectedTier.projectId)
    }
  };

  private getAllReports(projectId: number) {
    this.projectService
      .getAllReports(projectId)
      .subscribe(
        (success) => {
          const allReports = success.data.responseList.map(item => { return { id: item.reportId, name: "[" + item.reportId + "] " + item.reportName } });
          sessionStorage.setItem('projectReports',JSON.stringify(allReports))
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  private getReportDetails(selectedTier: IProjectTier): void {
    this.projectService
      .getReportDetails(selectedTier.reportId, selectedTier.id)
      .subscribe(
        (success) => {
          this.getReportViewDetails(selectedTier.reportId, this.currentYear)
          selectedTier.reportDetails = this.currentProject.setReportDetails(
            success.data.reportDetails,
            this.currentYear
          );
          this.selectedTier.reportDetails.softOptions = this.serviceItems
          this.selectedTier.reportDetails.serviceItemName = this.selectedTier.serviceItemName
          this.duplicateChartTypes()
          const genericSp =
            selectedTier.reportDetails.genericSp === 'Y' ? 'Yes' : 'No';
          this.storedProcedures = {
            genericSp: genericSp,
            storedProcedure: {
              objectId: null,
              objectName: selectedTier.reportDetails.procName,
            },
            allStoredProcedures: [],
          };
          this.getStoredProcedures();

          // Open selected, if in params
          if (this.currentQueryParams.option) {
            this.onOptionClick(this.currentQueryParams.option);
          }
        },
        (error) => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public closeTierSlider = (): void => {
    this.selectedTier = null;
    this.router.navigate([], {
      queryParams: { tier: null },
      queryParamsHandling: 'merge',
    });
  };

  public onOptionClick = (primaryKey: string): void => {
    this.selectedOption = this.selectedTier.reportDetails.options.find(
      (opt) => opt.optionId == +primaryKey
    );
    this.selectedOption.formatModifier =  this.selectedOption.formatModifier ? this.selectedOption.formatModifier : ''; 
    this.optionLayout = new Layout(
      this.selectedOption.optionName,
      [`Option ID: ${this.selectedOption.optionId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { option: primaryKey },
      queryParamsHandling: 'merge',
    });
    // Open selected, if in params
    if (this.currentQueryParams.param) {
      this.onParameterClick(this.currentQueryParams.param);
    }
  };

  public closeOptionSlider = (): void => {
    this.selectedOption = null;
    this.router.navigate([], {
      queryParams: { option: null },
      queryParamsHandling: 'merge',
    });
  };

  public onParameterClick = (primaryKey: string): void => {
    // TODO: Look for a faster way to do this
    // Change dynamic-table to accept whole object?
    let allParameters = this.flatten.transform(
      this.selectedOption.series.map((series) =>
        series.parameters.map((param) => {
          return { seriesId: series.seriesId, paramId: param.parameterId };
        })
      )
    );
    let selectedParameter = allParameters.find(
      (param) => param.paramId == +primaryKey
    );
    let selectedSeries = this.selectedOption.series.find(
      (series) => series.seriesId == selectedParameter.seriesId
    );
    // end

    this.selectedParameter = selectedSeries.parameters.find(
      (param) => param.parameterId == selectedParameter.paramId
    );
    this.parameterLayout = new Layout(
      //this.selectedParameter.questionText,
      selectedSeries.seriesName,
      [`Parameter ID: ${this.selectedParameter.parameterId}`],
      null
    );
    this.router.navigate([], {
      queryParams: { param: primaryKey },
      queryParamsHandling: 'merge',
    });
  };

  public closeParameterSlider = (): void => {
    this.isQuestionSelectorOpened = false;
    this.selectedParameter = null;
    this.router.navigate([], {
      queryParams: { param: null },
      queryParamsHandling: 'merge',
    });
  };

  private getStoredProcedures() {
    this.store.select(selectStoredProcedures).subscribe(
      (data) => {
        this.storedProcedures.allStoredProcedures = data;
        this.storedProcedures.storedProcedure.objectName =
          this.storedProcedures.allStoredProcedures.find(
            (sP) =>
              sP.objectName ===
              this.storedProcedures.storedProcedure.objectName
          ).objectName;
        this.storedProcedures.storedProcedure.objectId =
          this.storedProcedures.allStoredProcedures.find(
            (sP) =>
              sP.objectName ===
              this.storedProcedures.storedProcedure.objectName
          ).objectId;
        
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public getReportViewDetails(reportId, currentYear){
    this.projectService.getReportViewDetails(reportId, currentYear).subscribe(
      (success) => {
        success.data.reportViews.forEach(value => {
          value["timeAccessed"] = moment(value.timeAccessed).format('LLL')
        })
        this.reportViewDetails = success.data.reportViews
      }
    )
  }

  public getServiceItems(projectId){
    this.projectService.getProjectServiceItems(projectId).subscribe(
      (success) => {
        success.data.serviceItemList.forEach(a => this.serviceItems.push(a.serviceItemName))
        success.data.serviceItemList.forEach(a => this.serviceItemId.push(a.serviceItemId))
      }
    )
  }

  public duplicateChartTypes(){
    this.selectedTier.reportDetails.reportTypes.forEach(type => {
      type.chartTypeDupe = type.chartType
    })
  }

  public idReceived(event){
    this.selectedTier.reportDetails.reportTypes.filter(value => {
      if(value.displaySequence == event.count ){
        value.chartTypeDupe = event.name
        value.chartType = event.name
      }
    })
    this.selectedTier.reportDetails = Object.assign({}, this.selectedTier.reportDetails)
 }

  // // ///////////////////////////////////////////// QUESTION SELECTOR LOGIC //////////////////////////////////////////////////////////////////////////////////////

  private getProjectQuestions(projectId: number, currentYear: number, serviceItemId): void {
    this.projectService.getProjectQuestions(projectId, currentYear, serviceItemId).subscribe(
      (success) => {
        this.currentYearQuestions = success.data.projectQuestions;
        // filter out not responded questions
        this.respondedQuestions = this.currentYearQuestions.filter(
          (q) => q.questionType !== 'NR'
        );
        this.respondedQuestions.forEach(q=>q['questionDetails']= [])
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public updateStoredProcedure(objectName: string, objectId: number) {
    this.storedProcedures.storedProcedure = { objectId, objectName };
  }

  public isQuestionNested(questionType: string): boolean {
    let isNested = true;
    if (['N1', 'YN', 'LS', 'TX', 'N2', 'N3', 'RD', 'YNX', 'N4'].includes(questionType)) 
      {
        isNested = false;
      }
    return isNested;
  }

  public getQuestionDetails(questionId: number, event): void {
    event.target.classList.toggle('rotated')
    let collapsible = document.getElementById(questionId.toString())
    collapsible.classList.toggle('hidden')
    /// A new endpoint is going to be created to replace the one used from the service below;
    /// when the new endpoint (something like “/projects/:id/serviceItemId/getProjectQuestions”) will be available create a new method inside projectService 
    /// and replace the method below with the new method
    this.projectService.getProjectQuestionDetails(questionId).subscribe(
      (success) => {
        let details = success.data.questionDetails
        let nestedQuestions: Array<any> = details.nestedQuestions;
        // Do the headings array's element have to be displayed as children questions? If yes, uncomment the lines below!
        // let headings: Array<any> = details.headings or nestedQuestions.headings ?????????????????????
        // let combined = nestedQuestions.concat(headings) ????????????????????????????????
        // this.questionDetails= combined; ??????????????????????????????????????????????????
        this.respondedQuestions.forEach(q=>{
          if(q.questionId===questionId) {
            q.questionDetails=nestedQuestions
          }
        })
      },
      (error) => {
        console.log('Error: ', error);
        this.loadingError = error.error.error.message;
      }
    );
  }

  public onSelectQuestion(questionId: number, questionPart: number = 1, questionText:string): void {
    this.selectedParameter.questionId = questionId;
    this.selectedParameter.questionPart= questionPart;
    this.selectedParameter.questionText= questionText;
    this.selectedParameter= {...this.selectedParameter}
    this.isQuestionSelectorOpened = !this.isQuestionSelectorOpened;
  }

  public onQuestionsSectionToggle(): void {
    this.clearInput()
    if (!this.currentYearQuestions)
      this.getProjectQuestions(this.currentProject.projectId, this.currentYear, this.serviceItemId);
    this.isQuestionSelectorOpened = !this.isQuestionSelectorOpened;
  }

  public clearInput() {
    this.searchValue='';
  }

  public searchInput(event: any) {
    if (event == '') {
      this.searchValue = '';
    } else {
      this.searchValue = event;
    }
  }

}
