import { Component, Input, OnInit } from '@angular/core';
import { IAlert } from 'src/app/core/models/alert';
import { UploaderService } from 'src/app/core/services/uploader.service';

@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  public alert: IAlert;
  public componentName:string = "app-uploader";
  @Input() public Title: string;
  @Input() public Paragraph: string;
  @Input() public extraInput: boolean;
  @Input() public extraInputText: string;
  @Input() public endpoint: string;
  @Input() public uploaderId: string;

  selectedFile: any;
  openUploader: boolean = false;
  loading: boolean = false
  constructor(    private Uploader: UploaderService,
    ) { }

  ngOnInit(): void {
  }

  public onFileSelected(event){
    this.selectedFile = event.target.files[0];
  }
  public uploadFile(){
    this.loading = true
    if(this.selectedFile){
      this.Uploader.upload(this.endpoint, this.selectedFile, this.uploaderId).subscribe(success => {
        this.alert = {
          message: `<strong>Upload Successful</strong>`,
          alertClass: 'success',
          fadeOut: true,
        };
        this.loading = false
      },
      (error) => {
        this.loading = false
        console.log('Error: ', error);
        this.alert = {
          message: `<strong>Upload Failed</strong>`,
          alertClass: 'danger',
          fadeOut: true,
        };
      }
    );
    } else {
      this.loading = false
      this.alert = {
        message: `<strong>Please attach file</strong>`,
        alertClass: 'danger',
        fadeOut: true,
      };
    }
    }
  }
  

