import { Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';

@Component({
  selector: 'app-table-filters',
  templateUrl: './table-filters.component.html',
  styleUrls: ['./table-filters.component.scss'],
})
export class TableFiltersComponent implements OnInit, OnChanges {
  @Input() public groupableColumns: Array<IDynamicTableColumn>;
  @Input() public columns: Array<IDynamicTableColumn>;
  @Input() public defaultGroupedColumn: string;
  @Input() public groupedColumn;
  @Output() public filterColumnValue = new EventEmitter<any>();
  @Output() public groupByColumnValue = new EventEmitter<IDynamicTableColumn>();
  @Output() public resetData = new EventEmitter<any>();
  @Output() public statusNotification = new EventEmitter<any>();
  @Output() public resetFilterClickChange = new EventEmitter<any>();
  @Output() public datePickerValue = new EventEmitter<any>();
  @Output() public removeDateFilter = new EventEmitter<any>();
  @Input() public resetFilterClicked: boolean;
  public selectedItems: Array<IDynamicTableColumn> = [];
  public filterSelected: Array<any> = [];
  public filterObject: Array<any>;
  public toBeFilteredColumn: string;
  public groupByClicked: boolean = false;
  public filterByClicked: boolean = false;
  public objHoldsItems: Array<any> = [];
  public counter: number = 0;
  public groupByCounter: number = 0;
  public searchInputValue: any;
  public placeholder: any;
  public openElement: string;
  public dateOpen: boolean = false;
  public resetFilterDateButton: boolean = false;
  public savedDates: string;
  public searchIcon = true;
  public filterableColumn: IDynamicTableColumn[];
  public concatenateUrl: string;

  constructor(
    public tooltipConfig: NgbTooltipConfig,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.url.subscribe((url) => {
      let urlTest = [];
      url.forEach((url) => urlTest.push(url.path));
      this.concatenateUrl = urlTest.join('_');
    });

    if (
      JSON.parse(sessionStorage.getItem(this.concatenateUrl + 'groupBy')) !==
      null
    ) {
      this.defaultGroupedColumn = JSON.parse(
        sessionStorage.getItem(this.concatenateUrl + 'groupBy')
      ).dataKey;
    }
    this.groupableColumns.forEach((col) => {
      if (col.dataKey == this.defaultGroupedColumn) {
        this.groupBySelect(col);
      }
      col.filterSelected = false;
      col.backgroundActive = false;
    });

    if (
      Object.keys(sessionStorage).find(
        (a) => a == this.concatenateUrl + 'Selections'
      )
    ) {
      if (
        JSON.parse(
          sessionStorage.getItem(this.concatenateUrl + 'Selections')
        ) !== null
      ) {
        this.filterSelected = JSON.parse(
          sessionStorage.getItem(this.concatenateUrl + 'Selections')
        );
        this.filterSelected.forEach((obj2) => {
          let getCol = this.groupableColumns.find(
            (obj1) => obj1.dataKey == obj2.dataKey
          );
          if (getCol) {
            obj2.nestedObject = getCol;
          }
        });
      }
      this.groupableColumns.forEach((item) => {
        this.objHoldsItems.push({
          keys: item.dataKey,
          selected: false,
          items: [],
        });
      });

      //update counter for filters
      for (let i = 0; i < this.objHoldsItems.length; i++) {
        for (let j = 0; j < this.filterSelected.length; j++) {
          if (this.objHoldsItems[i].keys == this.filterSelected[j].dataKey) {
            this.objHoldsItems[i].items.push(this.filterSelected[j].item);
          }
        }
      }

      this.counter = 0;
      this.objHoldsItems.forEach((item) => {
        this.counter += item.items.length;
      });

      if (this.filterSelected !== null) {
        this.filterSelected.forEach((a) => {
          this.filterColumn(a.nestedObject, a.item, true, false);
        });

        for (let i = 0; i < this.groupableColumns.length; i++) {
          for (let j = 0; j < this.filterSelected.length; j++) {
            if (
              this.groupableColumns[i].dataKey == this.filterSelected[j].dataKey
            ) {
              this.groupableColumns[i].filterSelected = true;
            }
          }
        }
      }
    } else {
      this.groupableColumns.forEach((item) => {
        this.objHoldsItems.push({
          keys: item.dataKey,
          selected: false,
          items: [],
        });
      });
    }

    if (this.columns.find((col) => col.header == 'Date')) {
      this.groupableColumns.push({
        header: 'Date',
        dataKey: 'date',
        sortable: false,
        groupable: false,
        searchable: false,
        options: ['date']
    });
    }

    this.groupableColumns.forEach((item) => {
      this.objHoldsItems.push({
        keys: item.dataKey,
        selected: false,
        items: [],
      });
    })

    setTimeout(() => {
      if (this.groupedColumn) {
        let preSetGroup = this.groupableColumns.find(
          (x) => x.dataKey == this.groupedColumn
        );
        this.groupByColumn(preSetGroup);
      }
    }, 500);
  }

  ngOnChanges(): void {
    if (this.resetFilterClicked) {
      this.resetFilterClicked = false;
      this.resetFilterClickChange.emit(this.resetFilterClicked);
    }
  }

  //emits datepicker to dynamic table - adds hard coded data to objHoldsItems
  filterByDate(e) {
    this.datePickerValue.emit(e);
    if (e) {
      this.objHoldsItems.forEach((a) => {
        if (a.keys == 'date') {
          a.items = ['datePicked'];
          this.tickFirstFilter(a);
          this.counter = 0;
          this.objHoldsItems.forEach((item) => {
            this.counter += item.items.length;
          });
        }
      });
    }
  }

  public open(elementId): void {
    if (this.openElement === elementId) {
      this.openElement = null;
    } else {
      this.openElement = elementId;
    }
    if (!this.filterSelected.length) {
      this.groupableColumns.forEach(c => {
        c.filterSelected = false
        c.backgroundActive = false
      })
    }
  }

  public close(): void {
    this.openElement = null;
    this.filterObject = null;
    if (!this.filterSelected.length) {
      this.groupableColumns.forEach(c => {
        c.filterSelected = false
        c.backgroundActive = false
      })
    }
  }

  public focusFunction() {
    this.searchIcon = false;
    this.placeholder = '';
  }
  public focusOutFunction() {
    this.searchIcon = true;
  }

  public savedDate(e) {
    this.savedDates = e;
  }

  filterColumn(column, option: string, selected, removeEverything) {
    this.filterColumnValue.emit({
      1: column,
      2: option,
      3: selected,
      4: removeEverything,
    });
  }

  groupByColumn(column: IDynamicTableColumn) {
    sessionStorage.setItem(
      this.concatenateUrl + 'groupBy',
      JSON.stringify(column)
    );

    this.groupByColumnValue.emit(column);
  }

  public resetFilterDateButtonRecieved(bool) {
    this.resetFilterDateButton = bool;
  }

  public groupBySelect(item: any) {
    if (this.selectedItems.find((obj) => obj == item)) {
      this.selectedItems = this.selectedItems.filter((obj) => obj != item);
      this.groupByColumn(null);
      item.selected = false;
    } else {
      item.selected = true;
      this.groupableColumns.forEach((column) => {
        if (column != item) {
          column.selected = false;
        }
      });
      this.selectedItems = [];
      this.selectedItems.push(item);
      this.groupByColumn(item);
    }
    this.groupByCounter = this.selectedItems.length;
    this.close();
  }

  //Checking if filter is selected
  public filterSelect(item: any) {
    if (item.dataKey == 'date') {
      this.resetFilterDateButton = true;
      this.removeDateFilter.emit(true);
      let targetDateObj = this.groupableColumns.find(
        (col) => col.dataKey == 'date'
      );
      if (targetDateObj) {
        targetDateObj.filterSelected = false;
      }
      let removeCountOfDate = this.objHoldsItems.find(
        (col) => col.keys == 'date'
      );
      if (removeCountOfDate) {
        removeCountOfDate.items = [];
      }
    } else {
      this.toBeFilteredColumn = item;
      this.groupableColumns.forEach((col) => {
        if (item.dataKey == col.dataKey && col.filterSelected == true) {
          col.filterSelected = false;
          this.filterSelected = this.filterSelected.filter(
            (a) => a.dataKey != item.dataKey
          );
          this.objHoldsItems.forEach((b) => {
            if (b.keys == item.dataKey) {
              b.items = [];
            }
          });
          this.filterColumn(this.toBeFilteredColumn, undefined, false, true);
          sessionStorage.setItem(
            this.concatenateUrl + 'Selections',
            JSON.stringify(this.filterSelected)
          );
        }
      });
    }
    this.counter = 0;
    this.objHoldsItems.forEach((item) => {
      this.counter += item.items.length;
    });
  }

  public filterSelectOnText(text) {
    this.groupableColumns.forEach((t) => {
      if (t == text) {
        text.backgroundActive = false;
      }
      if (t != text) {
        t.backgroundActive = false;
      }
    });
    text.backgroundActive = true;
    this.toBeFilteredColumn = text;
    if (text.header == 'Date') {
      this.dateOpen = true;
      this.filterObject = null;
    } else {
      this.dateOpen = false;
      let dashRemoved = text.options.filter((item) => item !== null);
      this.filterObject = dashRemoved.map((text) => {
        return {
          item: text.toString(),
        };
      });
      this.filterObject.forEach((obj) => {
        obj.dataKey = text.dataKey;
        obj.key = obj.item + '-' + text.dataKey;
      });
      this.filterSelected.forEach((a) => {
        this.filterObject.forEach((b) => {
          if (a.dataKey == b.dataKey && a.item == b.item) {
            b.filterOptionSelected = true;
          }
        });
      });
    }
  }

  public filterOptionSelect(item: any, singleSelect?) {
    singleSelect?.stopPropagation();
    //check if option is ticked
    if (
      this.filterSelected.find(
        (obj) => obj.dataKey == item.dataKey && obj.item == item.item
      )
    ) {
      this.filterSelected = this.filterSelected.filter(
        (obj) => obj.key != item.key
      );
      item.filterOptionSelected = false;
      this.filterColumn(
        this.toBeFilteredColumn,
        item.item,
        item.filterOptionSelected,
        false
      );
      sessionStorage.setItem(
        this.concatenateUrl + 'Selections',
        JSON.stringify(this.filterSelected)
      );
    } else {
      item.filterOptionSelected = true;
      this.filterSelected.push(item);
      this.filterColumn(
        this.toBeFilteredColumn,
        item.item,
        item.filterOptionSelected,
        false
      );
      sessionStorage.setItem(
        this.concatenateUrl + 'Selections',
        JSON.stringify(this.filterSelected)
      );
    }

    //add to objHoldsItems and remove if unticked
    this.objHoldsItems.forEach((filters) => {
      if (filters.keys == item.dataKey) {
        if (filters.items.find((a) => a == item.item)) {
          filters.items = filters.items.filter((key) => key != item.item);
        } else if (item.dataKey == filters.keys) {
          filters.items.push(item.item);
        }
      }
    });
    this.counter = 0;

    this.objHoldsItems.forEach((c) => {
      this.counter += c.items.length;
    });
    this.tickFirstFilter(item);

    if (!singleSelect) this.close();
  }

  //check through objHoldItems and determine if filter select should be ticked
  public tickFirstFilter(item) {
    this.groupableColumns.forEach((b) => {
      if (b.dataKey == item.dataKey) {
        let checkLengthOfArray = this.objHoldsItems.find(
          (a) => a.keys == item.dataKey
        );
        if (checkLengthOfArray.items.length > 0) {
          b.filterSelected = true;
        } else if (checkLengthOfArray.items.length == 0) {
          b.filterSelected = false;
        }
        //date section
      } else if (b.dataKey == item.keys) {
        let checkLengthOfArray = this.objHoldsItems.find(
          (a) => a.keys == item.keys
        );
        if (checkLengthOfArray.items.length > 0) {
          b.filterSelected = true;
        } else if (checkLengthOfArray.items.length == 0) {
          b.filterSelected = false;
        }
      }
    });
  }
}
