<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
    <ul *ngIf="pager.pages && pager.pages.length && pager.startPage != pager.endPage" class="pagination mt-4" [ngClass]="{'pagination-dark': ui.darkMode}">
        <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item first-item">
            <a (click)="setPage(1)" class="page-link" role="button"><i class="fas fa-angle-double-left"></i></a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item previous-item">
            <a (click)="setPage(pager.currentPage - 1)" class="page-link" role="button"><i class="fas fa-angle-left"></i></a>
        </li>
        <li *ngFor="let page of pager.pages" [ngClass]="{active:pager.currentPage === page}" class="page-item number-item">
            <a (click)="setPage(page)" class="page-link" role="button">{{page}}</a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item next-item">
            <a (click)="setPage(pager.currentPage + 1)" class="page-link" role="button"><i class="fas fa-angle-right"></i></a>
        </li>
        <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item last-item">
            <a (click)="setPage(pager.totalPages)" class="page-link" role="button"><i class="fas fa-angle-double-right"></i></a>
        </li>
    </ul>
    <button *ngIf="showAllButton && items.length > initialPageSize" type="button" class="btn btn-primary" (click)="showAllToggle()">Show all</button>
    <button *ngIf="rePaginationButton && items.length > initialPageSize" type="button" class="btn btn-primary" (click)="showAllToggle()">Repaginate</button>
    <div *ngIf="showAllLoading" class="spinner-border spinner-border-sm ml-2" role="status">
        <span class="sr-only">Loading...</span>
      </div>
</ng-container>