import { Component, Input, OnChanges } from '@angular/core';
import { Organisation } from 'src/app/core/classes/organisation';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { OrganisationService } from 'src/app/core/services/organisation.service';
import { MOUCreateForm, MOUDetailsForm, OrganisationMOUTableColumns } from './organisation-mou-layouts';
import { ActivatedRoute, Router } from '@angular/router';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { Layout } from 'src/app/core/classes/layout';
import moment from 'moment';

@Component({
  selector: 'app-organisation-mou',
  templateUrl: './organisation-mou.component.html',
  styleUrls: ['./organisation-mou.component.scss']
})
export class OrganisationMouComponent implements OnChanges {

  @Input() public currentOrganisation: Organisation;
  @Input() public currentYear: number;

  public organisationMOUTableColumns: Array<IDynamicTableColumn> = OrganisationMOUTableColumns;
  public MOUDetailsForm: Array<IDynamicFormBlock> = MOUDetailsForm;
  public MOUCreateForm: Array<IDynamicFormBlock> = MOUCreateForm;
  public layout: ILayout;
  public selectedMOU: any;
  public createMOUData: any;
  public MouSigned: string;
  public MouReview: string;
  public MouSentOut: string;
  public currentQueryParams: any;
  public createMOULayout: ILayout;

  constructor(
    public loaderService: LoaderService,
    private organisationService: OrganisationService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnChanges(): void {
    this.getMOU(this.currentOrganisation.organisationId);
    this.route.queryParams.subscribe(params => {
      this.currentQueryParams = params;
    });

    this.createMOUData = {
      createMOU: true,
      signingOrganisationId: '',
      leadId: this.currentOrganisation.organisationId,
      notes: '',
      review: '',
      sent_out: '',
      signed: '',
      state: '',
    }
  }

  private getMOU(organisationId: number): void {
    this.organisationService.getMOU(organisationId).subscribe(
      success => {
        this.currentOrganisation.mou = success.MOUInfo;

        if (this.currentQueryParams.id) {
          this.onTableRowClick(this.currentQueryParams.id);
        }
      },
      error => {
        console.log('Error: ', error);
      }
    )
  }

  public createMOU(MOU){
    if(MOU.signed){
      let newMouSignedDate = new Date(MOU.signed)
      this.MouSigned = newMouSignedDate.toISOString()
    }

    if(MOU.review){
      let newMouReviewDate = new Date(MOU.review)
      this.MouReview = newMouReviewDate.toISOString()
    }

    if(MOU.sent_out){
      let newMouSentOutDate = new Date(MOU.sent_out)
      this.MouSentOut = newMouSentOutDate.toISOString()
    }

    let body = {
      "leadId": MOU.leadId,
      "signingOrganisationId": MOU.signingOrganisationId,
      "state": MOU.state,
      "notes": MOU.notes,
      "sentOut": this.MouSentOut,
      "signed": this.MouSigned,
      "review": this.MouReview,
    }
    this.organisationService.createMOU(body).subscribe(
      success => {
        this.getMOU(this.currentOrganisation.organisationId)
      },
      error => {
        console.log('Error: ', error);
      }
    )   
  }

  public deleteMOU(MOUid: number){
    let body = {
      "mouId": MOUid
    }
    this.organisationService.deleteMOU(body).subscribe(
      success => {
        this.getMOU(this.currentOrganisation.organisationId);
      },
      error => {
        console.log('Error: ', error);
      }
    )
  }

  createMOULayoutButton(){
    this.createMOULayout = new Layout(
      "Create MOU", [' - Required Fields need to be filled - '], null
    );
  }

  public updateMOU(MOU){
    if(MOU.signed){
      this.MouSigned = moment(MOU.signed).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }

    if(MOU.review){
      this.MouReview = moment(MOU.review).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
    
    if(MOU.sent_out){
      this.MouSentOut = moment(MOU.sent_out).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
    

    let body = {
      "mouId": this.selectedMOU.mou_id,
      "state": MOU.state,
      "notes": MOU.notes,
      "sentOut": this.MouSentOut,
      "signed": this.MouSigned,
      "review": this.MouReview,
    }
    this.organisationService.updateMOU(body).subscribe(
      success => {
        this.getMOU(this.currentOrganisation.organisationId)
      },
      error => {
        console.log('Error: ', error);
      }
    )
  }

  public onTableTdClick = (id, button) => {
    event.stopPropagation();
    this.deleteMOU(id)
  }

  public closeMOUSlider = (): void => {
    this.createMOULayout = null
    this.selectedMOU = null;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge' });
  }

  public onTableRowClick = (primaryKey: number): void => {
    this.selectedMOU = this.currentOrganisation.mou.find(mou => mou.mou_id == primaryKey)
    this.layout = new Layout(
      this.selectedMOU.organisation_name,
      [ `ID: ${this.selectedMOU.mou_id}` ], 
      null
    )
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  }

  public dataUpdate(event){
    if(event.action == 'create'){
      this.createMOU(event.row)
    }

    if(event.action == 'add'){
      this.updateMOU(event.row)
    }
  }

}