import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-project-response-filter',
  templateUrl: './project-response-filter.component.html',
  styleUrls: ['./project-response-filter.component.scss']
})
export class ProjectResponseFilterComponent implements OnInit {

  @Input() public filterName: string;
  @Input() public filterOptions: any;

  @Output() public emitSelectedItems = new EventEmitter<any>();

  public showDropdown: boolean = false;
  public searchInputValue: string = '';

  constructor() { }

  ngOnInit(): void {
    this.filterOptions.forEach(item => item.selected = false );
  }

  public selectItem(item: any): void {
    if (this.filterName == 'organisation') {
      this.emitSelectedItems.emit({ filterName: this.filterName, filterItems: item.submissions });
    } else {
      this.emitSelectedItems.emit({ filterName: this.filterName, filterItems: [ item ] });
    }
  }

}
