<div [formGroup]="form" *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div *ngIf="block.withoutEditor == true" class="form-group">
        <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }}
            <i *ngIf="block.helpText" class="far fa-info-circle form-info-icon" [title]="block.helpText" placement="right"></i>
        </label>
        <small [id]="block.dataKey + 'Help'" class="form-text text-muted" [innerHTML]="block.helpText"></small>
        <textarea [formControlName]="block.dataKey" class="form-control" rows={{block.rowCount}}></textarea>
    </div>
    <div *ngIf="block.withoutEditor !== true" class="form-group">
        <label [for]="block.dataKey" [ngClass]="{'text-light': ui.darkMode}">{{ block.label }}
            <i *ngIf="block.helpText" class="far fa-info-circle form-info-icon" [title]="block.helpText" placement="right"></i>
        </label>
        <!-- Limited preview -->
        <div [innerHtml]="currentValue || '—'" *ngIf="!showEditor" class="border rounded bg-light editor-preview" [ngClass]="{'bg-light': !ui.darkMode, 'bg-dark-900 border-dark-700 text-light': ui.darkMode}"></div>
        <!-- Angular Editor -->
        <div [ngClass]="{'bg-light': !ui.darkMode, 'bg-dark-900 border-dark-700 text-light': ui.darkMode}" *ngIf="showEditor">
            <angular-editor
                [formControlName]="block.dataKey" 
                [config]="editorConfig"
            >
            </angular-editor>
        </div>
        <!-- Toggle preview/editor -->
        <button class="btn btn-sm mt-2 mr-2" type="button" [ngClass]="{'btn-outline-primary': !ui.darkMode, 'btn-primary-light': ui.darkMode}" (click)="showEditor = !showEditor">
            <ng-container *ngIf="!showEditor">
                <i class="fas fa-edit mr-2"></i>Edit Content
            </ng-container>
            <ng-container *ngIf="showEditor">
                <i class="fas fa-times mr-2"></i>Hide Editor
            </ng-container>
        </button>
        <app-input-placeholder *ngIf="block.placeholder"></app-input-placeholder>
    </div>
</div>