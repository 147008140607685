import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const InteractionsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Name',
        dataKey: 'description',
        percWidth: 8,
        sortable: false,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 8,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Type',
        dataKey: 'type',
        percWidth: 8,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'When',
        dataKey: 'interactionDate',
        percWidth: 8,
        sortable: true,
        groupable: false,
        searchable: false,
        format: 'date'
    },
    {
        header: 'Assignee',
        dataKey: '',
        percWidth: 8,
        sortable: true,
        groupable: true,
        searchable: true
    }
];

export const InteractionsDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Interaction Details',
        dataKey: 'interactionId',
        blockType: 'header',
        colWidth: 12,
        disabled: true
    },
    {
        label: 'Name',
        dataKey: 'description',
        blockType: 'input',
        colWidth: 6,
        disabled: true  
    },
    {
        label: 'Organisation',
        dataKey: 'organisationName',
        blockType: 'input',
        colWidth: 6,
        disabled: true  
    },
    {
        label: 'Type',
        dataKey: 'type',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Date',
        dataKey: 'interactionDate',
        blockType: 'input',
        colWidth: 6,
        disabled: true,
    }
];

export const InteractionsFollowUpsTable: IDynamicTableColumn[] = [
    {
        header: 'Deadline',
        dataKey: 'deadline',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true,
        format: 'date'
    },
    {
        header: 'Purpose',
        dataKey: 'purpose',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Outcome',
        dataKey: 'outcome',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true   
    }
];

export const InteractionsContactsTable: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'personId',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true   
    },
    {
        header: 'Job Title',
        dataKey: 'jobTitle',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    }
];

export const InteractionsCommentsTable: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'commentId',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'User ID',
        dataKey: 'userId',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Detail',
        dataKey: 'commentDetail',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Date',
        dataKey: 'datetime',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    }
];

    export const InteractionsAssigneesTable: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'userId',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Name',
        dataKey: 'fullName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    }
];