<ng-container>
  <div class="d-inline-block position-relative" (clickElsewhere)="showDropdown = false">
    <button id="{{ title }}-data" class="btn btn-light border"(click)="showDropdown = !showDropdown">
      Select {{ title }}
      <i class="fas ml-1" [class.fa-caret-up]="showDropdown" [class.fa-caret-down]="!showDropdown"></i>
    </button>
    <div class="border rounded shadow py-2 px-3 dd-container text-dark" *ngIf="showDropdown">
      <div *ngIf="!removeSearch">
      <input type="text" [(ngModel)]="searchInputValue" placeholder={{placeholder}} class="form-control mb-2 mt-1" />
      </div>
      <div *ngFor="let item of filterOptions | filterBy: ['groupLevel','serviceId','registrationId','submissionId','id','projectName','name', 'year', 'format', 'organisationId', 'registrationName', 'submissionName']: searchInputValue"  class="dd-item" [class.active]="item.selected" (click)="selectItem(item)">
        <div class="d-flex">
          <div>
            <i class="far mr-2" [class.fa-square]="!item.selected" [class.fa-check-square]="item.selected"></i>
          </div>
          <div>
            <span>
              {{item[itemName]}}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="(filterOptions).length == 0" class="text-muted text-center">
        No results for this search
      </div>
    </div>
  </div>
</ng-container>

