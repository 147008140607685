<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.submissions" 
        [data]="currentOrganisation.submissions" 
        [columns]="organisationSubmissionTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'submissionList'"
        [primaryKey]="'submissionId'"
        [onRowClick]="onSubmissionClick">
    </app-dynamic-table>

    <!-- Selected Submission -->
    <app-slider-panel *ngIf="selectedSubmission" [onClose]="closeSubmissionSlider">
        <!-- Heading -->
        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="selectedSubmission" [blocks]="organisationSubmissionDetailsForm"></app-dynamic-form>
    </app-slider-panel>

</ng-template>