<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <app-dynamic-table 
        *ngIf="interactions" 
        [data]="interactions"
        [columns]="interactionsTableColumns"
        [currentYear]="currentYear"
        [tableTitle]="'interactionList'"
        [primaryKey]="'interactionId'"
        [onRowClick]="onInteractionClick">
    </app-dynamic-table>

     <app-slider-panel *ngIf="selectedInteraction" [onClose]="closeInteractionSlider">
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">

        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>

        <app-dynamic-form [data]="selectedInteraction" [blocks]="interactionsDetailsForm"></app-dynamic-form> 

        <ng-container>
            <hr>
            <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Follow-Ups</h4>
            <app-dynamic-table
                    *ngIf="followUps"                   
                    [data]="followUps" 
                    [columns]="interactionsFollowUpsTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'followUps'"
                    [primaryKey]="'interactionId'"
                    [disableRowClick]="true" 
                    [hideExportButton]="true"
                    [hideSearch]="true">     
            </app-dynamic-table>
        </ng-container>

        <ng-container>
            <hr>
            <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Contacts</h4>
            <app-dynamic-table
                    *ngIf="contacts"
                    [data]="contacts"
                    [columns]="interactionsContactsTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'personList'"
                    [primaryKey]="'interactionId'"
                    [disableRowClick]="true"
                    [hideExportButton]="true"
                    [hideSearch]="true">  
            </app-dynamic-table>
        </ng-container>

        <ng-container>
            <hr>
            <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Comments</h4>
            <app-dynamic-table
                    *ngIf="comments"
                    [data]="comments"
                    [columns]="interactionsCommentsTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'commentList'"
                    [primaryKey]="'commentId'"
                    [disableRowClick]="true"
                    [hideExportButton]="true"
                    [hideSearch]="true">  
            </app-dynamic-table>
        </ng-container>

        <ng-container>
            <hr>
            <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Assignees</h4>
            <app-dynamic-table
                    *ngIf="assignees"
                    [data]="assignees"
                    [columns]="interactionsAssigneesTable" 
                    [currentYear]="currentYear"
                    [tableTitle]="'userList'"
                    [primaryKey]="'userId'"
                    [disableRowClick]="true"
                    [hideExportButton]="true"
                    [hideSearch]="true">  
            </app-dynamic-table>
        </ng-container>


     </ng-container>
  </app-slider-panel>
</ng-template>