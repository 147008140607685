import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DashboardTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Metric ID',
        dataKey: 'metricId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Metric Text',
        dataKey: 'metricText',
        percWidth: 25,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Report ID',
        dataKey: 'reportId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Tier ID',
        dataKey: 'tierId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Service ID',
        dataKey: 'serviceItemId',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: true
    }
]

export const DashboardMetricDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Dashboard Metric Details',
        blockType: 'header',
    },
    {
        label: 'Icon Code',
        dataKey: 'metricFa',
        helpText: "<a href=https://fontawesome.com/v5/search target=_blank>Font Awesome<i class='fab fa-font-awesome-flag ml-1'></i></a>",
        blockType: 'input',
        colWidth: 12,
    },
    {
        label: 'Service ID',
        dataKey: 'serviceItemId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6
    },
    {
        label: 'Text',
        dataKey: 'metricText',
        blockType: 'input',
        colWidth: 6,
    },
    {
        label: 'Tier ID',
        dataKey: 'tierId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6
    },
    {
        label: 'Report ID',
        dataKey: 'reportId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6
    },
    {
        label: 'Option ID',
        dataKey: 'optionId',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6
    },
    {
        label: 'Constant Value',
        dataKey: 'rangeMin',
        blockType: 'input',
        inputType: 'number',
        colWidth: 6
    },
    {
        label: 'Prefix',
        dataKey: 'prefix',
        blockType: 'select',
        options: Constants.prefixTypes,
        colWidth: 6
    }, 
    {
        label: 'Suffix',
        dataKey: 'suffix',
        blockType: 'select',
        options: Constants.suffixTypes,
        colWidth: 6
    }
]