<tr *ngFor="let row of data; let i = index" (click)="!disableRowClick ? onRowClick(row[primaryKey]) : null"
  [class.c-pointer]="!disableRowClick">
  <td *ngFor="let col of columns" [class]="col.button == 'options' ? 'align-middle options' : 'align-middle'"
    [style.display]="col.percWidth === 0 ? 'none' : ''">

    <!-- Tooltip -->
    <ng-template #rowTooltipContent>
      <span [innerHTML]="row[col.dataKey]"></span>
    </ng-template>

    <!-- Searchable Column -->
    <span *ngIf="col.searchable && !col.linkable && !col.linkKey" [class]="columnBadge(col.badge, row[col.dataKey])"
      [ngbTooltip]="rowTooltipContent" [innerHTML]="columnText(col.format, row[col.dataKey], col.dateFormat, col.organisation) | highlight : searchText">
      <a [href]="row[col.linkKey]"></a>
    </span>

    <!-- Link set in the parent onClick -->
    <span *ngIf="col.linkable || col.linkKey" [class]="row[col.dataKey] ? 'linkable' : ''" [ngbTooltip]="rowTooltipContent"
      (click)="row[col.dataKey] ? addClicked(row, ((col.linkKey == true) ? 'externalLink' : 'internalLink')) : ''">
      {{ row[col.dataKey] || '—' }}
    </span>

    <!-- Unsearchable Column -->
    <span *ngIf="!col.searchable && col.button !== 'validate' && col.header !== 'Options'" [class]="columnBadge(col.badge, row[col.dataKey])" [ngbTooltip]="rowTooltipContent"
      [innerHTML]="columnText(col.format, row[col.dataKey])">
      <a [href]="row[col.linkKey]"></a>
    </span>

    <!-- Validation buttons -->
    <div *ngIf="col.buttonType == 'validation'" class="d-flex">
      <div *ngFor="let rek of col.actions; let i = index">
        <i *ngIf="i !== 1" [class]="rek.icon" class="c-pointer" (click)="enableClick(col) ? addClicked(row[primaryKey], col.actions[i].action) : null"
          [style.color]="(row.status != 'validated' && i == 0) ? 'grey' : rek.color" [ngbTooltip]="rek.description"></i>
        <i *ngIf="i == 1" [class]="rek.icon" class="c-pointer" (click)="enableClick(col) ? addClicked(row[primaryKey], col.actions[i].action) : null"
          [style.color]="(row.status != 'accepted' && i == 1) ? 'grey' : rek.color" [ngbTooltip]="rek.description"></i>
      </div>
    </div>

    <!--Action Buttons: Data Uploads & Submission -->
    <div *ngIf="col.buttonType == 'dataUploads' || col.buttonType == 'submissions'" class="d-flex">
      <div *ngFor="let rek of col.actions">
        <i [class]="rek.icon" class="c-pointer"
          [ngbTooltip]="rek.description"
          [style.cursor]="buttonAction(row, rek, col.buttonType) ? '' : 'not-allowed'"
          [style.color]="buttonAction(row, rek, col.buttonType) ? rek.color : 'grey'"
          (click)="(enableClick(col) && buttonAction(row, rek, col.buttonType)) ? addClicked(row[primaryKey], rek.action) : null">
        </i>
      </div>
    </div>

    <div style="padding-top: 2px; padding-bottom: 5px;" *ngIf="col.buttonType == 'delete'" class="d-flex">
      <button class="btn btn-danger" (click)="(addClicked(row[primaryKey], 'delete'))">Delete</button>
    </div>

    <!-- One button -->
    <div *ngIf="col.button === 'set'" style="display: flex">
      <div *ngFor="let rek of col.actions; let i = index">
        <div *ngIf="row['set'] == true && row.validationId; else showButtons">
          <i class="far fa-lg fa-check" style="color: green;"></i>
        </div>
        <ng-template #showButtons>
          <button *ngIf="!optionSelected; else showButtonss" [class]="rek.color" [disabled]="clicked" [ngbTooltip]="rek.description"
            (click)="enableClick(col) ? addClicked(row[rek.key ? rek.key : primaryKey], col.actions[i].action) : null"> 
            {{ rek.description }}
          </button>
          <ng-template #showButtonss>
            —
          </ng-template>
        </ng-template>
      </div>
    </div>

    <!-- Validation button -->
    <div *ngIf="col.button === 'validate'" style="display: flex">
      <div *ngFor="let rek of col.actions; let i = index">
        <div *ngIf="row.validationId; else showButtons" (click)="enableClick(col) ? addClicked(row[rek.key ? rek.key : primaryKey], col.actions[i].action) : null">
          <i class="far fa-lg fa-check" style="color: green;"></i>
        </div>
        <ng-template #showButtons>
          <button [class]="rek.color" [disabled]="optionSelected" [ngbTooltip]="rek.description"
            (click)="enableClick(col) ? addClicked(row[rek.key ? rek.key : primaryKey], col.actions[i].action) : null"> 
            {{ rek.description }}
          </button>
        </ng-template>
      </div>
    </div>

    <div *ngIf="col.button" [class]="(row.clicked && row.loading)  ? 'spinner-border spinner-border-sm ml-2': ''"
      role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </td>
  <td class="text-right">
    <ng-container *ngIf="!disableRowClick">
      <i class="fas fa-lg fa-angle-right c-pointer"></i>
    </ng-container>
  </td>
</tr>