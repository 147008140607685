import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const FollowUpsTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Interaction',
        dataKey: 'interactionId',
        percWidth: 8,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Purpose',
        dataKey: 'purpose',
        percWidth: 8,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Deadline',
        dataKey: 'deadline',
        percWidth: 7,
        sortable: true,
        groupable: false,
        searchable: false,
        format: 'date'
    },
    {
        header: 'Outcome',
        dataKey: 'outcome',
        percWidth: 8,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation',
        dataKey: 'organisationId',
        percWidth: 9,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Status',
        dataKey: 'status',
        percWidth: 6,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Assignee',
        dataKey: 'assignee',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: true
    }
];

export const FollowUpsDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Interaction',
        dataKey: 'interactionId',
        blockType: 'header',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        blockType: 'break'
    },
    {
        label: 'Purpose',
        dataKey: 'purpose',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Deadline',
        dataKey: 'deadline',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Outcome',
        dataKey: 'outcome',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation',
        dataKey: 'organisationId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'divider'
    },
    {
        label: 'Status',
        dataKey: 'status',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Assignee',
        dataKey: 'assignee',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    }
];