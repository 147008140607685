<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
<div class="card position-relative" [ngClass]="{'bg-dark-700 text-light': ui.darkMode, 'bg-light': !ui.darkMode}">
    <div class="card-header" [ngClass]="{'bg-dark': ui.darkMode}">
        <!-- Close -->
        <div class="close">
            <i class="fas fa-times c-pointer" [ngClass]="{'text-light': ui.darkMode}" (click)="cancelDeskClick()"></i>
        </div>
        <!-- Header + metadata-->
        <h3 class="font-weight-bold">
            Desk {{ deskClicked.deskReferences }}
        </h3>
        <div>
            Number of screens: <strong>{{ deskClicked.noScreens }}</strong>
        </div>
        <div>
            Docking station: <strong>{{ deskClicked.dockingStation }}</strong>
        </div>
        <div *ngIf="deskClicked.capacity != null">
            Capacity: <strong>{{ deskClicked.capacity }}</strong>
        </div>
    </div>
    <div class="card-body">
        <!-- Booking options -->
        <div *ngFor="let opt of bookingOptions" class="mb-3">
            <div class="font-weight-bold mb-2">{{ opt.title }}</div>
            <ng-container *ngIf="!deskClicked[opt.dataKey]">
                <button 
                    class="btn"
                    [ngClass]="{'btn-primary': !ui.darkMode, 'btn-primary-light': ui.darkMode}" 
                    (click)="createBooking(deskClicked.deskId, opt.shortReference, deskClicked.deskReferences)"
                    [disabled]="opt.dataKey == 'adBooking' && (deskClicked.amBooking || deskClicked.pmBooking)"
                >
                    <i class="far fa-calendar-check mr-2"></i>Book
                </button>
            </ng-container>
            <ng-container *ngIf="deskClicked[opt.dataKey]">
                <div>
                    {{ deskClicked[opt.dataKey]['userName'] ? deskClicked[opt.dataKey]['userName'] : '—' }}
                    <ng-template #tooltipContent>Cancel booking</ng-template>
                    <span 
                        *ngIf="currentUser.userId == deskClicked[opt.dataKey]['userID']"
                        class="badge badge-danger ml-2 c-pointer"
                        (click)="cancelBooking(deskClicked[opt.dataKey], 'Cancelled')"
                        [ngbTooltip]="tooltipContent">
                        Cancel
                    </span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
</ng-container>