<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
    <div class="d-flex">
        <ng-template #tooltipContent> Back </ng-template>
        <div *ngIf="backRoute" class="pt-1 pr-2" [ngbTooltip]="tooltipContent">
            <a [routerLink]="backRoute">
                <i class="far fa-angle-left mr-2" [ngClass]="{'text-secondary': !ui.darkMode, 'text-light': ui.darkMode}" style="font-size:1.75rem"></i>
            </a>
        </div>
        <div>
            <h2 [ngClass]="{'text-light': ui.darkMode}">{{ heading }}</h2>
        </div>
        <a *ngIf="titleButton" [href]="titleButton.link" target="_blank" class="btn btn-primary ml-4 mb-2"
            style="min-width: fit-content; height: fit-content;">
            <i [class]="titleButton.icon"></i>
            {{ titleButton.title }}
        </a>
    </div>
    <div class="d-block">
        <h6 [class.pad-left]="backRoute" class="text-secondary">
            <ng-container *ngFor="let sub of subheading; let isLast = last">
                {{ sub }}<span *ngIf="!isLast" class="ml-2 mr-1">&bull;</span>
            </ng-container>
        </h6>
    </div>
</ng-container>