import { Component, Input, OnChanges } from '@angular/core';
import { usersDetailNavItems} from '../users-layout';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ActivatedRoute } from '@angular/router';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { User } from 'src/app/core/classes/user';
import { usersDetailBasics } from './users-detail-layout';
import { UsersService } from 'src/app/core/services/users.service';
import { IAlert } from 'src/app/core/models/alert';
import { IUserUpdate } from 'src/app/core/models/user.vm';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.scss'],
})
export class UsersDetailComponent implements OnChanges {
  @Input() public currentUser: User;

  public currentScope: string = null;
  public loadingError: string = null;

  public usersDetailBasicsBlocks: Array<IDynamicFormBlock> = usersDetailBasics;
  public usersDetailNavItems: Array<INavItem> = usersDetailNavItems;
  public alert: IAlert;
  public componentName:string = 'app-users-detail';
  public modalInfo: Object = null; 


  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    public UsersService: UsersService,
  ) {}

  ngOnChanges() {
    this.route.queryParams.subscribe((params) => {
      this.currentScope = params.scope || 'basics';
    });
  }

  public resetPassword() {
    this.modalInfo = {
      item: this.currentUser.fullName, 
      subText: "Are you sure you would like to reset the password for", 
      title: "Reset",
      action: "Password",
      request: "Reset"
    }
  }

  public dataUpdate(data) {
    if(data == 'resetPassword') {
      this.resetPassword();
    } else {
      this.updateUser(data.row);
    }
  }

  public updateUser(details: User) {
    let userDetails: IUserUpdate = {
      userId: details.userId,
      fullName: details.fullName,
      logonName: details.logonName,
      jobTitle: details.jobTitle,
      phoneNo: details.phoneNo,
      isAdmin: details.isAdmin
    }

    this.UsersService.updateUser(userDetails).subscribe(
      success => {
        this.alert = {
          message: `<strong>Save Successful</strong>`,
          alertClass: 'success',
          fadeOut: true,
        };
      },(error) => {
        this.alert = {
          message: `<strong>Save Failed</strong> <br/> ${ error.error.error.message }`,
          alertClass: 'danger',
          fadeOut: true,
        };
        console.log('Error: ', error);
      }
    )
  }

  public closeModal(event) {
    if(event){
      this.UsersService.resetUserPassword(this.currentUser.logonName).subscribe(
        success => {
          this.alert = {
            message: `<strong>Password Reset Successful</strong>`,
            alertClass: 'success',
            fadeOut: true,
          }
        },(error) => {
          console.log('Error: ', error);
          this.alert = {
            message: `<strong>Password Reset Failed</strong>`,
            alertClass: 'danger',
            fadeOut: true,
          };
        }
      )
    }
    this.modalInfo = null
  }
}
