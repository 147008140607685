import { IDynamicTableColumn } from '../core/models/dynamic-table-column.vm';
import { INavItem } from '../core/models/nav-item.vm';

export const EventTableColumns: IDynamicTableColumn[] = [
  {
    header: 'ID',
    dataKey: 'eventId',
    percWidth: 5,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Name',
    dataKey: 'eventName',
    percWidth: 30,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Location',
    dataKey: 'eventLocation',
    percWidth: 15,
    sortable: true,
    groupable: false,
    searchable: true,
  },
  {
    header: 'Date',
    dataKey: 'eventDt',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: true,
    filterDateTime: true,
  },
  {
    header: 'Past/Future',
    dataKey: 'pastOrFuture',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Region Name',
    dataKey: 'regionName',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Registrations',
    dataKey: 'registrationCount',
    percWidth: 10,
    sortable: true,
    groupable: true,
    searchable: false,
  },
  {
    header: 'Registration Enabled',
    dataKey: 'registrationEnabled',
    percWidth: 10,
    sortable: true,
    groupable: false,
    searchable: false,
    badge: 'yesNo'
  },
  {
    header: 'Online',
    dataKey: 'isOnline',
    percWidth: 5,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'yesNo'
  },
  {
    header: 'Visible',
    dataKey: 'isVisible',
    percWidth: 5,
    sortable: true,
    groupable: true,
    searchable: false,
    badge: 'yesNo'
  },

];

export const eventNavItems: INavItem[] = [
  {
    icon: 'fa-house',
    label: 'Details',
    link: '../details'
  },
  {
    icon: 'fa-file-alt',
    label: 'Documents',
    link: '../documents',
  },
  {
    icon: 'fa-tasks',
    label: 'Registrations',
    link: '../registrations',
  },
  {
    icon: 'fa-clipboard-check',
    label: 'Attendees',
    link: '../attendees',
  }
];

export const eventDetailNavItems: INavItem[] = [
  {
    label: 'Basics',
    link: '../details',
    scope: 'basics',
  },
];
