<!-- LoadingError -->
<div *ngIf="loadingMessage" class="fixed-top d-flex justify-content-center mt-lg">
    <div class="alert alert-info">
        {{ loadingMessage }}
    </div>
</div>

<!-- LoadingError -->
<div *ngIf="loadingError" class="fixed-top d-flex justify-content-center mt-lg">
    <div class="alert alert-danger">
        {{ loadingError }}
    </div>
</div>

<!-- Alert -->
<div *ngIf="alert" class="fixed-top d-flex justify-content-center mt-lg">
    <div id="alert" class="alert alert-dismissible fade show px-5 py-2" role="alert" [class]="'alert-' + alert.alertClass" [innerHTML]="alert.message">
    </div>
</div>

<!-- Dismiss-alert button -->
<button id="dismiss-alert" type="button" (click)="closeAlert()" class="alert-dismiss-btn rounded" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
</button>

