import { IBooking } from "../models/booking.vm";

export class Desk {

    public deskId: number;
    public deskReferences: string;
    public noScreens: number;
    public dockingStation: string;
    public capacity: number;

    public adBooking: IBooking; // MODEL
    public amBooking: IBooking; // MODEL
    public pmBooking: IBooking; // MODEL
    public color: string;
    public userNameAd: string;
    public userNameAm: string;
    public userNamePm: string;

    constructor(data: IBooking) {
        this.mapDeskDetails(data);
    }

    private mapDeskDetails(data: IBooking): void { 
        this.deskId = data.deskID;
        this.deskReferences = data.deskReferences;
        this.noScreens = data.noScreens;
        this.dockingStation = data.dockingStation;
        this.capacity = data.capacity;
    }

    public mapBookings(desk: Desk, bookings: Array<IBooking>): Desk {
        
        // Find bookings for desk
        let ad = bookings.find(bkg => bkg.deskReferences == desk.deskReferences && bkg.bookingSession == 'All' && bkg.bookingStatus == 'Active');
        let am = bookings.find(bkg => bkg.deskReferences == desk.deskReferences && bkg.bookingSession == 'AM' && bkg.bookingStatus == 'Active');
        let pm = bookings.find(bkg => bkg.deskReferences == desk.deskReferences && bkg.bookingSession == 'PM' && bkg.bookingStatus == 'Active');

        // Set bookings, where they exist
        if (ad != undefined) {
            desk.adBooking = ad;
            desk.amBooking = ad;
            desk.pmBooking = ad;
        } else {
            desk.adBooking = am != undefined || pm != undefined ? undefined : null;
            desk.amBooking = am != undefined ? am : null;
            desk.pmBooking = pm != undefined ? pm : null;
        }

        // Set circle color based on availability
        if (ad != undefined || (am != undefined && pm != undefined)) {
            desk.color = '#F3F3F3';
        } else if (ad == undefined && (am != undefined || pm != undefined)) {
            desk.color = '#8BD0DB';
        } else {
            desk.color = '#17A2B8';
        }

        // Set user names by session
        this.userNameAd = desk.adBooking ? desk.adBooking.userName : null;
        this.userNameAm = desk.amBooking ? desk.amBooking.userName : null;
        this.userNamePm = desk.pmBooking ? desk.pmBooking.userName : null;

        return desk;
    }
}