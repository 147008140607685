import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OrganisationRegistrationDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Registration Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        blockType: 'break'
    },
    {
        label: 'Project Lead',
        dataKey: 'projectLeadFullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project Lead Email',
        dataKey: 'projectLeadEmail',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registration Lead',
        dataKey: 'registrationUserFullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registration Lead Email',
        dataKey: 'registrationUserEmail',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Registration Complete/Locked',
        dataKey: 'registrationLocked',
        helpText: 'Ensure all registrations are completed before data collection opens',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6
    },
]

export const OrganisationRegistrationTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'registrationId',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Registration Date',
        dataKey: 'registrationDateTime',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
        format: 'date'
    },
    {
        header: 'Project',
        dataKey: 'projectName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Project Lead',
        dataKey: 'projectLeadFullName',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Project Lead Email',
        dataKey: 'projectLeadEmail',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: '# Deputy Project Leads',
        dataKey: 'deputyProjectLeadCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '# Submissions',
        dataKey: 'submissionCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Locked',
        dataKey: 'registrationLocked',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
];