<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
    <app-uploader
    [Title]="uploaderTitle"
    [Paragraph]="uploaderParagraph"
    [extraInput]="extraInput"
    [extraInputText]="extraInputText"
    [endpoint]="eventAttendeeUploaderEndpoint"
    [uploaderId]="eventId"
    >
    </app-uploader>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentEvent.attendees" 
        [data]="currentEvent.attendees" 
        [columns]="eventAttendeeTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'attendeeList'"
        [primaryKey]="'attendanceId'"
        [onRowClick]="onAttendeeClick">
    </app-dynamic-table>

    <!-- Selected Registration -->
    <app-slider-panel *ngIf="selectedAttendee" [onClose]="closeAttendeeSlider">
        <!-- Heading -->
        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="selectedAttendee" [blocks]="eventAttendeeDetailsForm"></app-dynamic-form>
    </app-slider-panel>

</ng-template>