import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const DocumentsStaticTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'documentId',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Creator',
        dataKey: 'fullName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'File',
        dataKey: 'awsKeyLocation',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Date',
        dataKey: 'uploadTime',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true,
        filterDateTime: true
    }
];

export const DocumentsStaticForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Static Documents',
        blockType: 'header',
    },
    {
        label: 'URL',
        dataKey: 'URL',
        blockType: 'input',  
        colWidth: 12,
        disabled: true,
        copyToClip: true
    },
    {
        label: 'Created By',
        dataKey: 'createdBy',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'adminUsers'
    },
    {
        label: 'Upload Date',
        dataKey: 'uploadTime',
        blockType: 'input',    
        colWidth: 6,
        optionsType: 'services',
        disabled: true
    },
]