import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const RegistrationTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'registrationId',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Registration Date',
        dataKey: 'registrationDateTime',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
        format: 'date',
        filterDate: true,
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Project Lead',
        dataKey: 'projectLeadFullName',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Project Lead Email',
        dataKey: 'projectLeadEmail',
        percWidth: 15,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: '# Deputy Project Leads',
        dataKey: 'deputyProjectLeadCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Deputy Project Leads Name (hidden)',
        dataKey: 'deputyProjectLeadName',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: 'Deputy Project Leads Email (hidden)',
        dataKey: 'deputyProjectLeadEmail',
        percWidth: 0,
        sortable: false,
        groupable: false,
        searchable: false
    },
    {
        header: '# Submissions',
        dataKey: 'submissionCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Locked',
        dataKey: 'registrationLocked',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: false,
        badge: 'yesNo'
    },
    
];

export const RegistrationDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Registration Details',
        blockType: 'header',
        colWidth: 12
    },
   
    {
        label: 'Project Lead',
        dataKey: 'projectLeadFullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project Lead Email',
        dataKey: 'projectLeadEmail',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registration Lead',
        dataKey: 'registrationUserFullName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Registration Lead Email',
        dataKey: 'registrationUserEmail',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Registration Complete/Locked',
        dataKey: 'registrationLocked',
        helpText: 'Ensure all registrations are completed before data collection opens',
        blockType: 'select',
        options: Constants.yesNo,
        colWidth: 6
    },
    {
        label: 'Organisations',
        dataKey: 'testId',
        blockType: 'searchDropdown',
        colWidth: 6,
        optionsType: 'organisations'
    },
]

export const RegistrationSubmissionTable: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'submissionId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Code',
        dataKey: 'submissionCode',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Name',
        dataKey: 'submissionName',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Level',
        dataKey: 'submissionLevel',
        percWidth: 5,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Organisation ID',
        dataKey: 'organisationId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Organisation Name',
        dataKey: 'organisationName',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Peer Group',
        dataKey: 'peerGroupName',
        percWidth: 5,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: '# Services',
        dataKey: 'submissionServiceCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '# Responses',
        dataKey: 'responseCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: '# Case Notes',
        dataKey: 'submissionCaseCodeCount',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: false
    }
];

export const RegistrationDeputyProjectLeadsTable: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'id',
        percWidth: 20,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Name',
        dataKey: 'name',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: true
    },    
    {
        header: 'Email',
        dataKey: 'email',
        percWidth: 40,
        sortable: true,
        groupable: false,
        searchable: false
    }
];