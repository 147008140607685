<!-- Loading and error -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !currentProject; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>
<app-alert-status *ngIf="alert" [alert]="alert" (emitAlertCancelled)="alert = $event"></app-alert-status>

<ng-template #notLoading>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.dataUpload" 
        [data]="currentProject.dataUpload"
        [columns]="dataUploadTableColumns" 
        [currentYear]="currentYear" 
        [tableTitle]="'dataUploadList'"
        [primaryKey]="'file_tracking_id'" 
        [onTdClick]="onTableTdClick" 
        [disableRowClick]="true">
    </app-dynamic-table>
</ng-template>

<!-- Selected Upload -->
<app-slider-panel *ngIf="dataUploadLayout" [onClose]="closeQuestionSlider">
    <app-area-heading [heading]="dataUploadLayout.heading" [subheading]="dataUploadLayout.subheading" [titleButton]="titleButton"></app-area-heading>
    <a class="text-decoration-none text-primary" [href]="ulidLink" target="_blank">Submission Portal</a>
    <div class="mt-1">
        <span *ngIf="!wrongProjectSubmission && fileProjectId" class="text-success font-weight-bold"> <i class="fa fa-check" aria-hidden="true"></i> Project ID is matching </span>
        <span *ngIf="wrongProjectSubmission && fileProjectId" class="text-danger font-weight-bold flashing"> <i class="fa fa-exclamation-circle fa-lg" aria-hidden="true"></i> Project ID is not matching (submitted as project ID {{fileProjectId}} instead of project ID {{currentProject.projectId}}) </span>
        <span *ngIf="fileProjectId"> |</span>
        <span class="text-primary c-pointer" (click)="changeProjectSlider()"> Change Project ID</span>
    </div> 
    <!-- Change Project Slider -->
    <app-slider-panel *ngIf="createChangeProjectLayout" [layer]="1" [onClose]="closeChangeProjectSlider">
        <app-area-heading [heading]="createChangeProjectLayout.heading" [subheading]="createChangeProjectLayout.subheading"></app-area-heading>
        <app-dynamic-form [data]="currentProject.dataUpload" [blocks]="updateProjectIDForm" (dataUpdate)="updateProjectId($event)"></app-dynamic-form>
    </app-slider-panel>
    <div *ngIf="displayedData.viewOnly"
        class="bg-warning text-center mt-5 mb-3 p-4 d-flex justify-content-between align-items-center">
        <b>VIEW ONLY</b>
        <span class="text-uppercase">{{ displayedData.status_name }}</span>
        <b>VIEW ONLY</b>
    </div>
    <div [style.opacity]="displayedData.viewOnly ? '0.7' : ''">
        <div [style.pointer-events]="displayedData.viewOnly ? 'none' : ''">
            <app-dynamic-form [data]="displayedData" [blocks]="dataUploadForm" (buttonAction)="buttonAction($event)" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
            <!-- Create Slider: Organisation -->
            <app-slider-panel *ngIf="createOrgLayout" [layer]="1" [onClose]="closeOrgSlider">
                <app-area-heading [heading]="createOrgLayout.heading" [subheading]="createOrgLayout.subheading"></app-area-heading>
                <app-dynamic-form [data]="displayedData" [blocks]="createOrganisationForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
            </app-slider-panel>

            <!-- Create Slider: Submission -->
            <app-slider-panel *ngIf="createSubLayout" [layer]="1" [onClose]="closeSubSlider">
                <app-area-heading [heading]="createSubLayout.heading" [subheading]="createSubLayout.subheading"></app-area-heading>
                <app-dynamic-form [data]="displayedData" [blocks]="createSubmissionForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
            </app-slider-panel>

            <!-- Table: File Data -->
            <ng-container *ngIf="fileData">
                <hr>
                <h4>File Data</h4>
                <small>Information Retrieved From Submitted File</small>

                <!-- Table -->
                <app-dynamic-table 
                    *ngIf="fileData" 
                    [data]="fileData" 
                    [columns]="keyInformationFromFileTableColumns"
                    [currentYear]="currentYear" 
                    [tableTitle]="'staticDocumentsList'" 
                    [primaryKey]="'documentId'"
                    [hideExportButton]="true" 
                    [hideSearch]="true" 
                    [disableRowClick]="true">
                </app-dynamic-table>

            </ng-container>
        </div>
        <!-- Table: File History -->
        <ng-container *ngIf="fileHistory">
            <hr>
            <h4>File History</h4>
            <small>Changes made With The File</small>
            <!-- Table -->
            <app-dynamic-table 
                *ngIf="fileHistory" 
                [data]="fileHistory" 
                [columns]="fileHistoryTableColumns"
                [currentYear]="currentYear" 
                [tableTitle]="'staticDocumentsList'" 
                [primaryKey]="'documentId'"
                [hideExportButton]="true" 
                [hideSearch]="true" 
                [disableRowClick]="true">
            </app-dynamic-table>
        </ng-container>
    </div>
</app-slider-panel>