<!-- Loading -->
<app-loading-status *ngIf="(loaderService.isLoading | async) && !createMOUData; else notLoading"></app-loading-status>

<ng-template #notLoading>
    <button class="btn btn-primary " style="margin-bottom: 10px; width: 78px;" (click)="createMOULayoutButton()">Create</button>
    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.mou" 
        [data]="currentOrganisation.mou" 
        [columns]="organisationMOUTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'MOUList'"
        [primaryKey]="'mou_id'"
        [onTdClick]="onTableTdClick" 
        [onRowClick]="onTableRowClick"
        >
    </app-dynamic-table>

    <app-slider-panel *ngIf="selectedMOU" [onClose]="closeMOUSlider">
    <!-- Heading -->
    <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
    <!-- Form -->
    <app-dynamic-form [data]="selectedMOU" [blocks]="MOUDetailsForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
    </app-slider-panel>

    <app-slider-panel *ngIf="createMOULayout" [layer]="1" [onClose]="closeMOUSlider">
        <app-area-heading [heading]="createMOULayout.heading" [subheading]="createMOULayout.subheading"></app-area-heading>
        <app-dynamic-form [data]="createMOUData" [blocks]="MOUCreateForm" (dataUpdate)="dataUpdate($event)"></app-dynamic-form>
    </app-slider-panel>

</ng-template>