import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-section-nav',
  templateUrl: './section-nav.component.html',
  styleUrls: ['./section-nav.component.scss']
})
export class SectionNavComponent implements OnInit {

  @Input() public navItems: Array<INavItem>;
  
  public activeScope: string;
  
  darkMode$: Observable<boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode');
    this.route.queryParams.subscribe(params => {
      this.activeScope = params.scope || null;
    });
  }

  ngOnInit(): void {
    if (this.activeScope == null) {
      this.setDefaultActiveScope();
    }
  }

  private setDefaultActiveScope(): void {
    this.activeScope = this.navItems[0].scope;
    this.router.navigate([], { queryParams: { scope: this.activeScope }, queryParamsHandling: 'merge' });
  }

}
