<!-- Loading and error -->
<app-loading-status *ngIf="((loaderService.isLoading | async) && !selectedTier); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentProject.tiers" 
        [data]="currentProject.tiers" 
        [columns]="tierTableColumns"
        [currentYear]="currentYear"
        [tableTitle]="'tierList'"
        [primaryKey]="'id'"
        [onRowClick]="onTierClick">
    </app-dynamic-table>
    
</ng-template>

<!-- Selected Tier -->
<app-slider-panel *ngIf="selectedTier" [onClose]="closeTierSlider">

    <!-- Heading -->
    <app-area-heading [heading]="tierLayout.heading" [subheading]="tierLayout.subheading" [backRoute]="tierLayout.backRoute"></app-area-heading>

    <!-- Tier Details -->
    <app-dynamic-form [data]="selectedTier" [blocks]="tierDetailsForm"></app-dynamic-form>

    <ng-container *ngIf="selectedTier.reportDetails">
        
        <!-- Basic Report Details -->
        <app-dynamic-form [data]="selectedTier.reportDetails" [blocks]="reportDetailsForm" (id)="idReceived($event)"></app-dynamic-form>
        
        <!-- Report Options/parameters -->
        <app-dynamic-table 
            *ngIf="selectedTier.reportDetails.options" 
            [data]="selectedTier.reportDetails.options" 
            [columns]="reportOptionsTableColumns" 
            [currentYear]="currentYear"
            [tableTitle]="'reportOptions'"
            [primaryKey]="'optionId'"
            [onRowClick]="onOptionClick"
            [hideExportButton]="true"
            [hideSearch]="true"
            >
            
        </app-dynamic-table>

        <!-- Report View Details -->
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
            <h4 class="my-4" [ngClass]="{'text-primary-light': ui.darkMode, 'text-primary': !ui.darkMode}">Report View Details
            </h4>
            <app-dynamic-table *ngIf="reportViewDetails" 
            [data]="reportViewDetails" 
            [columns]="reportviewDetailsTableColumns"
            [tableTitle]="'reportOptions'" 
            [primaryKey]="'optionId'"
            [disableRowClick]="true"
            >
            </app-dynamic-table>
        </ng-container>
        
        <hr>
        
        <div *ngIf="{ darkMode: darkMode$ | async } as ui" class="border rounded mb-2 mt-5 p-3"
            [ngClass]="{'border-dark-700': ui.darkMode}">
            <app-dynamic-form [data]="storedProcedures" [blocks]="metadataForm"></app-dynamic-form>
            <app-project-toolkit-stored-procedures-select 
                [storedProcedures]="storedProcedures"
                (emitSelectedItems)="updateStoredProcedure($event.objectName, $event.objectId)">
            </app-project-toolkit-stored-procedures-select>
        </div>

    </ng-container>
    
</app-slider-panel>

<!-- Selected Option -->
<app-slider-panel *ngIf="selectedOption" [layer]="1" [onClose]="closeOptionSlider">

    <!-- Heading -->
    <app-area-heading [heading]="optionLayout.heading" [subheading]="optionLayout.subheading" [backRoute]="optionLayout.backRoute"></app-area-heading>

    <!-- Series and parameters -->
    <app-dynamic-form [data]="selectedOption" [blocks]="optionDetailsForm"></app-dynamic-form>
    <ng-container *ngFor="let series of selectedOption.series">
        <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">

        <hr class="mt-3">
        <h5 class="text-secondary my-3">Series {{ series.seriesName }}</h5>
        <app-dynamic-form [data]="series" [blocks]="seriesDetailsForm"></app-dynamic-form>
        <label class="font-weight-bold mb-3" [ngClass]="{'text-secondary': ui.darkMode, 'text-secondary-light': !ui.darkMode}">Report Parameters</label>
        <app-dynamic-table 
            *ngIf="series.parameters" 
            [data]="series.parameters" 
            [columns]="parametersTableColumns" 
            [currentYear]="currentYear"
            [tableTitle]="'parameters'"
            [primaryKey]="'parameterId'"
            [onRowClick]="onParameterClick"
            [hideExportButton]="true"
            [hideSearch]="true"> 
        </app-dynamic-table>
    </ng-container>
    </ng-container>

</app-slider-panel>

<!-- Selected Parameter -->
<app-slider-panel *ngIf="selectedParameter" [layer]="2" [onClose]="closeParameterSlider">

    <!-- Heading -->
    <app-area-heading [heading]="parameterLayout.heading" [subheading]="parameterLayout.subheading" [backRoute]="parameterLayout.backRoute"></app-area-heading>
    <hr>
    
    <!-- Parameter-->
    <app-dynamic-form [data]="selectedParameter" [blocks]="parameterDetailsForm"></app-dynamic-form>

    <!-- Question Metadata -->
    <hr>
    <div *ngIf="selectedParameter.questionId && { darkMode: darkMode$ | async } as ui" [ngClass]="{'text-light': ui.darkMode}">
        <strong>Question ID-Part:</strong> {{ selectedParameter.questionId }}-{{ selectedParameter.questionPart }}<br>
        <strong>Question Text:</strong> {{ selectedParameter.questionText }}
        <span *ngIf="selectedParameter.subQuestionText">| {{ selectedParameter.subQuestionText }}</span>
        <span *ngIf="selectedParameter.subQuestionHeadingText">| {{ selectedParameter.subQuestionHeadingText }}</span>
        <i *ngIf="selectedParameter.dataDefinition || selectedParameter.parentQuestionText">
            <br>Context: {{ selectedParameter.dataDefinition ? selectedParameter.dataDefinition : selectedParameter.parentQuestionText }}
        </i>
    </div>

    
    <!-- Question Selector -->
    <ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
        <div class="mt-2">
            <button class="btn btn-primary mt-5" 
                    (click)="onQuestionsSectionToggle()" 
                    *ngIf="!isQuestionSelectorOpened" 
                    >Open Question Selector
                    </button>
            <button class="btn btn-primary mt-5" 
                    (click)="onQuestionsSectionToggle()" 
                    *ngIf="isQuestionSelectorOpened"
                    >Close Question Selector
                    </button>
            <div class="mt-3 pb-3 d-flex flex-column border border-dark-700 rounded" 
                [ngClass]="{'text-light': ui.darkMode}" *ngIf="currentYearQuestions && isQuestionSelectorOpened">
                <!-- Search bar -->
                <div class="d-flex container">                
                    <input 
                    type="text" 
                    class="form-control ml-3 mr-4 my-4" 
                    (ngModelChange)="searchInput($event)" 
                    placeholder="{{ searchPlaceholder }}" 
                    [(ngModel)]="searchValue">    
                    <i  class="far fa-lg fa-times input-clear mr-4 my-4" 
                        *ngIf="searchValue.length > 0" 
                        (click)="clearInput()">
                    </i>
                </div>
                <div *ngFor="let question of respondedQuestions | filterBy: ['questionText', 'questionId']: searchValue">
                    <!-- Nested Questions -->
                    <div class="d-flex flex-column my-2" *ngIf="isQuestionNested(question.questionType)">
                        <div class="d-flex mx-4 mt-2 mb-1 px-3 rounded">
                            <p class="mr-3 align-self-center mb-0" [innerHTML]="question.questionText + '<br />' + ' ( ID: ' + question.questionId + ' )' | highlight: searchValue"></p>
                            <div class="ml-2 text-dark" (click)="getQuestionDetails(question.questionId,$event)" type="button">
                            <i class="fa fa-chevron-down"  [ngClass]="{'fa-chevron-down-DarkMode': ui.darkMode}"></i>
                            </div>
                        </div>                
                        <div class="hidden border border-dark-700 rounded ml-5 mr-4 mb-3 mt-1 pr-2" id="{{question.questionId}}">
                            <div *ngIf="question.questionDetails.length > 0" >
                                <ul *ngFor="let childQuestion of question.questionDetails">
                                    <li class="d-flex justify-content-between ml-4 mr-2 my-3 py-1 px-2 hover rounded" [ngClass]="{'hoverDarkMode': ui.darkMode}">
                                        <p class="align-self-center mb-0" 
                                        [innerHTML]="childQuestion.questionText + '<br />' + ' ( ID: ' + childQuestion.questionId + ' )'">
                                        </p>                        
                                        <button 
                                        class="btn btn-secondary btn-sm ml-5 align-self-start mt-1" [ngClass]="{'btn btn-light': ui.darkMode}"
                                        (click)="onSelectQuestion(childQuestion.questionId,childQuestion.questionPart, childQuestion.questionText)"
                                        >Select
                                        </button>
                                    </li>
                                </ul>
                            </div>     
                            <div *ngIf="question.questionDetails.length === 0" class="container mt-3">
                                <p class="mx-5"> There is nothing to display.</p>                    
                                <p class="mx-5"> Please report the issue using the Dev Hub Request feature</p>                    
                            </div>           
                        </div>
                    </div>
                    <!-- Flat Questions -->
                    <div class="d-flex justify-content-between mx-4 my-2 px-3 py-1 hover rounded" 
                        [ngClass]="{'hoverDarkMode': ui.darkMode}" *ngIf="!isQuestionNested(question.questionType)">
                        <p class="mr-5 mb-0 align-self-center"
                        [innerHTML]="question.questionText + '<br />' + ' ( ID: ' + question.questionId + ' )' | highlight: searchValue" > 
                        </p>    
                        <button class="btn btn-secondary btn-sm align-self-start mt-1 mr-2 ml-5" [ngClass]="{'btn btn-light': ui.darkMode}"
                        (click)="onSelectQuestion(question.questionId,question.questionPart, question.questionText)"
                        >Select
                        </button>
                    </div>              
                </div>     
                <!-- No results -->
                    <ng-container *ngIf="(respondedQuestions | filterBy: ['questionText', 'questionId']: searchValue).length == 0">
                        <div class="d-flex flex-column m-2">
                            <p class="align-self-center">There are no results for this search</p>   
                        </div>
                    </ng-container>     
            </div>
        </div>
    </ng-container>
    
    
</app-slider-panel>