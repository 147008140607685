import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-project-dashboard-filter',
  templateUrl: './project-dashboard-filter.component.html',
  styleUrls: ['./project-dashboard-filter.component.scss']
})
export class ProjectDashboardFilterComponent implements OnInit {

  @Input() public filterName: string;
  @Input() public filterOptions: any;

  @Output() public emitSelectedItems = new EventEmitter<any>();


  darkMode$: Observable<boolean>;
  constructor(
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode')
   }

  ngOnInit(): void {
    this.filterOptions.forEach(item => item.selected = false );
  }

  public selectItem(item: any): void {
      this.emitSelectedItems.emit({ filterItems: [ item ] });
  }

}