import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProjectService } from '../core/services/project.service';
import {
  loadStoredProcedures,
  loadStoredProceduresSuccess,
} from './stored-procedures.actions';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class storedProceduresEffects {
  constructor(
    private actions$: Actions,
    private projectService: ProjectService
  ) {}

  loadStoredProcedures$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadStoredProcedures),
      mergeMap(() => {
        return this.projectService.getStoredProcedures().pipe(
          map((list) => {
            return loadStoredProceduresSuccess({ list });
          })
        );
      })
    );
  });
}
