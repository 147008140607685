import moment from 'moment';
import { IEventRegistration } from '../models/event-registration.vm';
import { IEventAttendee } from '../models/event-attendee.vm';
import { IEventDocument } from '../models/event-document.vm';

export class Event {
  public cubeFile: string;
  public documentCount: number;
  public eventDescription: string;
  public eventDt: string;
  public eventYear: number
  public eventEndDt: string;
  public eventId: number;
  public eventLocation: string;
  public eventName: string;
  public isOnline: string;
  public isVisible: string;
  public maxCapacity: number;
  public pastOrFuture: string;
  public placesPerOrganisation: number;
  public projectCategory: number;
  public projectCategoryName: string;
  public regionId: number;
  public regionName: string;
  public registrationCount: string;
  public registrationEnabled: string;
  public registrationLink: string;
  public requestTags: string;
  public awsDocumentsDynamic: Array<IEventDocument>;
  public registrations: Array<IEventRegistration>;
  public attendees: Array<IEventAttendee>;

  constructor(data: Event, year?: number) {
    this.mapEventBasicDetails(data);
  }

  private mapEventBasicDetails(data: Event, year?: number): void {
    this.cubeFile = data.cubeFile;
    this.documentCount = data.documentCount;
    this.eventDescription = data.eventDescription
    this.eventDt = this.formatDate(data.eventDt);
    this.eventYear = parseInt(this.formatYear(data.eventDt));
    this.eventEndDt = data.eventEndDt;
    this.eventId = data.eventId;
    this.eventLocation = data.eventLocation;
    this.eventName = data.eventName;
    this.isOnline = data.isOnline;
    this.isVisible = data.isVisible;
    this.maxCapacity = data.maxCapacity;
    this.pastOrFuture = data.pastOrFuture;
    this.placesPerOrganisation = data.placesPerOrganisation;
    this.projectCategory = data.projectCategory;
    this.projectCategoryName = data.projectCategoryName;
    this.regionId = data.regionId;
    this.regionName = data.regionName;
    this.registrationCount=data.registrationCount;
    this.registrationEnabled = data.registrationEnabled;
    this.registrationLink = data.registrationLink;
    this.requestTags = data.requestTags;
  }

  private formatDate(date: string) {
    return moment(date).format('LLL');
  }

  private formatYear(date: string) {
    return moment(date).format('Y');
  }
}
