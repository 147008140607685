import { createReducer, on } from "@ngrx/store";
import { IStoredProcedure } from "../core/models/stored-procedure.vm";
import { loadStoredProceduresSuccess } from "./stored-procedures.actions";


export interface StoredProceduresState {
  list: IStoredProcedure[]
}

const initialState: StoredProceduresState = {
  list: null
}

export const storedProcedureReducer = createReducer(
  initialState,
  on(loadStoredProceduresSuccess, (state, action) => {
    return {
      ...state,
      list: action.list,
    }
  })
)