import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "src/app/core/models/dynamic-table-column.vm";

export const OrganisationProductDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Product Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Membership Status',
        dataKey: 'membershipStatus',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Has Document Access',
        dataKey: 'hasDocumentAccess',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        blockType: 'break'
    },
    {
        label: 'Product Is Enabled',
        dataKey: 'productIsEnabled',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    }
]

export const OrganisationProductTableColumns: IDynamicTableColumn[] = [
    {
        header: 'ID',
        dataKey: 'productId',
        percWidth: 10,
        sortable: true,
        groupable: false,
        searchable: true,
    },
    {
        header: 'Product Name',
        dataKey: 'productName',
        percWidth: 45,
        sortable: true,
        groupable: false,
        searchable: false
    },
    {
        header: 'Product Enabled',
        dataKey: 'productIsEnabled',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Membership Status',
        dataKey: 'membershipStatus',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    },
    {
        header: 'Has Document Access',
        dataKey: 'hasDocumentAccess',
        percWidth: 15,
        sortable: true,
        groupable: true,
        searchable: true,
    }
];