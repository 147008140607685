import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';



@Component({
  selector: 'app-json-viewer',
  templateUrl: './json-viewer.component.html',
  styleUrls: ['./json-viewer.component.scss'],
})
export class JsonViewerComponent implements OnInit {
  @Input() public jsonData:string;

  darkMode$: Observable<boolean>;
  
  constructor(private store: Store<{ darkMode: boolean }>) {
    this.darkMode$ = store.select('darkMode');
  }

  ngOnInit(): void {
    this.jsonData= JSON.parse(this.jsonData)
  }
}
