import { Component, Input, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';
import { ProjectService } from 'src/app/core/services/project.service';
import moment from 'moment';
import { Layout } from 'src/app/core/classes/layout';
import { Project } from 'src/app/core/classes/project';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { CreateOrganisationForm, CreateSubmissionForm, DataUploadForm, DataUploadTableColumns, FileHistoryTableColumns, KeyInformationFromFileTableColumns, UpdateProjectIDForm } from './project-data-upload-layouts';
import { ILayout } from 'src/app/core/models/layout.vm';
import { IAlert } from 'src/app/core/models/alert';

@Component({
  selector: 'app-project-data-upload',
  templateUrl: './project-data-upload.component.html',
  styleUrls: ['./project-data-upload.component.scss'],
})
export class ProjectDataUploadComponent implements OnChanges {
  @Input() currentProject: Project;
  @Input() currentYear: number;

  public dataUploadTableColumns: Array<IDynamicTableColumn> = DataUploadTableColumns;
  public dataUploadForm: Array<IDynamicFormBlock> = DataUploadForm;
  public keyInformationFromFileTableColumns: Array<IDynamicTableColumn> = KeyInformationFromFileTableColumns;
  public fileHistoryTableColumns: Array<IDynamicTableColumn> = FileHistoryTableColumns;
  public createOrganisationForm: Array<IDynamicFormBlock> = CreateOrganisationForm;
  public updateProjectIDForm: Array<IDynamicFormBlock> = UpdateProjectIDForm;
  public createSubmissionForm: Array<IDynamicFormBlock> = CreateSubmissionForm;
  public fileData: Array<{columnA: null, columnB: null}>;
  public titleButton: Object = {title: null, link: null, icon: null}
  public loadingError: string;
  public currentQueryParams: any;
  public dataUploadLayout: Layout;
  public displayedData: any;
  public selectSubmission: any;
  public sharePointUrl: string = null;
  public createOrgLayout: ILayout;
  public createSubLayout: ILayout;
  public createChangeProjectLayout: ILayout;
  public modalInfo: Object = null;
  public primaryKey: number;
  public selectOrganisation: string;
  public selectOrganisationId: number;
  public fileHistory: any;
  public ulidLink: string;
  public updatedProjectId: number = null;
  public alert: IAlert;
  public wrongProjectSubmission: boolean = false;
  public fileProjectId: number;

  constructor(
    public loaderService: LoaderService,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private router: Router,
  ) {}

  ngOnChanges(): void {
    // Check for open slider
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });
    if (!this.currentProject.dataUpload) {
      this.getDataUploads(this.currentProject.projectId, this.currentYear);
    }
  }

  public buttonAction(action) {
    if(action == 'createOrganisation') {
      this.createOrgSlider();
    }
    if(action == 'createSubmission') {
      this.onTableRowClick(this.currentQueryParams.id);
      this.createSubSlider()
    }
    if(action == 'renameSubmission'){
      this.router.navigate(['/project', this.currentYear, this.currentProject.projectId,'submissions']);
    }
  }

  public getDataUploads(projectId: number, year: number, organisationCreated?: boolean) {
    this.ulidLink = "https://submit.wfbenchmarking.nhs.uk/";
    this.projectService.getDataUpload(projectId, year).subscribe(
      (success) => {
        success.uploadedFiles.uploadedFiles.forEach((value) => {
          if(['Error', 'Intray', 'Rejected', 'Imported'].includes(value.status_name)) {
            value.viewOnly = true;
          }
        });
        this.currentProject.dataUpload = success.uploadedFiles.uploadedFiles;
        this.ulidLink = this.ulidLink + success.uploadedFiles.projectUlid + '-' + this.currentYear;
        // Open slider, if in params
        if (this.currentQueryParams.id || organisationCreated) {
          this.onTableRowClick(this.currentQueryParams.id);
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public moveFileToIntray(fileTrackingId: number, projectId: number) {
    this.projectService.moveFileToIntray(fileTrackingId, projectId).subscribe(
      (success) => {
        this.getDataUploads(this.currentProject.projectId, this.currentYear);
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public getFileStatusHistory(fileTrackingId: number) {
    this.projectService.getFileStatusHistory(fileTrackingId).subscribe(
      (success) => {
        success.fileData.forEach((value) => {
          value['submission_date_time'] = moment( value.submission_date_time ).format('MMM D, h:mm A');
        });
        this.fileHistory = success.fileData.reverse();
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  createOrgSlider() {
    this.createOrgLayout = new Layout(
      "Create Organisation", [' - All Fields Are Required To Be Filled - '], null
    );
  }

  createSubSlider() {
    this.createSubLayout = new Layout(
      "Create Submission", [' - All Fields Are Required To Be Filled - '], null
    );
  }

  public closeSubSlider = (): void => {
    this.createSubLayout = null;
  };

  public closeOrgSlider = (): void => {
    this.createOrgLayout = null;
  };

  public rejectUploadedFile(fileTrackingId: number) {
    let object = {
      fileTrackingId: fileTrackingId,
      trackingNote: "Organisation Rejected",
    };
    this.projectService.rejectUploadedFile(object).subscribe(
      (success) => {
        this.getDataUploads(this.currentProject.projectId, this.currentYear);
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public changeProjectSlider() {
    this.createChangeProjectLayout = new Layout(
      "Change Project ID", ['File will automatically move to the selected project'], null
    );
  }

  public closeChangeProjectSlider = (): void => {
    this.createChangeProjectLayout = null;
  };

  public changeProject(selected: any) {
    this.updatedProjectId = selected;
    let object = {
      organisationName: this.selectSubmission.organisation_name,
      projectId: this.updatedProjectId,
      organisationId: this.selectSubmission.organisation_id,
      submissionId: null,
      serviceId: null,
      year: this.currentYear,
      trackingNote: `Project ID updated:  ${this.currentProject.projectId} -> ${this.updatedProjectId}`
    };
    this.updateOrganisation(this.selectSubmission, null, null, object)
  }

  public updateOrganisation(data, organisation, trackingNote: string, projectChange?) {
    let object;
    if(projectChange) {
      object = projectChange;
    } else {
      object = {
        organisationName: organisation || this.selectOrganisation,
        projectId: this.updatedProjectId || this.currentProject.projectId,
        organisationId: data.organisation_id,
        submissionId: data.submission_id || null,
        serviceId: data.service_item_id,
        year: this.currentYear,
        trackingNote: trackingNote || data.tracking_note,
      };
    }

    this.projectService.updateOrganisation(data.file_tracking_id, object).subscribe(
      (success) => {
        if(projectChange) {
          this.updatedProjectId = null;
          this.closeQuestionSlider();
        } else {
          this.getDataUploads(this.currentProject.projectId, this.currentYear, true);
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  updateProjectId(value) {
    if(value.project_id) {
      this.changeProject(value.project_id)
      this.closeChangeProjectSlider();
      this.alert = { message: `Project ID change to ${value.project_id}`, alertClass: 'success', fadeOut: true };
    } else {
      this.alert = { message: "Project ID change failed", alertClass: 'danger', fadeOut: true };
    }
  }

  dataUpdate(data) {
    if(data.row?.postalAddress1) {
      this.createOrganisation(data.row);
    } else if(data.row?.SubmissionName) {
      this.createSubmission(data.row);
    } else if(data.organisation) {
      this.updateOrganisation(data.row, data.organisation, 'Organisation updated')
      this.getSubmissions(data.row.file_tracking_id, data.organisation)
    } else {
      this.updateOrganisation(data.row, data.organisation, null)
      this.closeQuestionSlider();
    }
  }

  public createSubmission(data) {
    let object = {
      submissionName: data.SubmissionName,
      submissionYear: this.currentYear,
      projectId: this.currentProject.projectId,
      organisationId: data.organisation_id
    };
    this.projectService.createSubmission(object).subscribe(
      (success) => {
        this.getDataUploads(this.currentProject.projectId, this.currentYear);
        this.getSubmissions(null, data.organisation_id)
        this.closeSubSlider();
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public createOrganisation(data) {
    let object = {
      organisationName : data.organisationName,
      organisationUrl : data.organisationUrl,
      postalAddress1: data.postalAddress1,
      postalAddress2: data.postalAddress2,
      postalAddress3: data.postalAddress3,
      postalAddress4: data.postalAddress4,
      postalAddressPc: data.postalAddressPc,
      organisationTypeId: Number(data.organisationTypeId),
      organisationTypeOther: data.organisationTypeOther,
      poRequired: data.poRequired,
      vatCharge: data.vatCharge,
      trackingNote: "Organisation Created"
    };
    this.projectService.createOrganisation(object).subscribe(
      (success) => {
        this.getDataUploads(this.currentProject.projectId, this.currentYear, true);
      },
      (error) => {
        console.log('Error: ', error);
      },
      () => {
        this.closeOrgSlider();
      }
    );
  }

  public onTableTdClick = (fileTrackingId: any, action: string): void => {

    if(action == 'edit') {
      this.onTableRowClick(fileTrackingId);
    }

    if(action == 'accept') {
      this.moveFileToIntray(fileTrackingId, this.currentProject.projectId)
    }

    if(action == 'reject') {
      this.rejectUploadedFile(fileTrackingId)
    }

    if(action == 'undo') {
      this.moveFileToSubmitted(fileTrackingId)
    }
  }

  public moveFileToSubmitted(fileTrackingId: number) {
    this.projectService.moveFileToSubmitted(fileTrackingId).subscribe(
      (success) => {
        this.getDataUploads(this.currentProject.projectId, this.currentYear);
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public getEditLink(fileTrackingId: number) {
    this.projectService.getEditLink(fileTrackingId).subscribe(
      (success) => {
        this.sharePointUrl = success.sharepointData.data[0]?.webUrl;
        this.titleButton = {
          title: 'View file',
          link: this.sharePointUrl,
          icon: 'far fa-file-spreadsheet mr-2'
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public getKeyInformationFromFile(id) {
    this.projectService.getKeyInformationFromFile(id).subscribe(
      (success) => {
        this.fileData = success.fileData.data;
        this.fileProjectId = this.fileData.find(e => e.columnA === 'Project ID').columnB
        if (this.fileProjectId && this.currentProject.projectId !== this.fileProjectId) {
          this.wrongProjectSubmission = true
        }
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public getSubmissions(primaryKey, organisationId) {
    let object = {
      year: Number(this.currentYear),
      projectId: this.currentProject.projectId,
      organisationId: organisationId
    }
    this.projectService.getSubmissions(object.year, object.projectId, object.organisationId).subscribe(
      (success) => {
        this.displayedData.submissions = success.submissions;
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  public onTableRowClick = (primaryKey: number): void => {
    this.fileData = null;
    this.sharePointUrl = null;
    this.primaryKey = primaryKey;
    this.getFileStatusHistory(this.primaryKey);
    this.displayedData = this.currentProject.dataUpload;
    this.selectSubmission = this.displayedData.filter(data => data.file_tracking_id == primaryKey)[0];
    this.selectOrganisation = this.selectSubmission.organisation_name;
    if(['Submitted', 'Updated', 'Imported', 'Error', 'Intray', 'Rejected'].includes(this.selectSubmission.status_name)) {
      this.getKeyInformationFromFile(this.primaryKey);
    }
    this.selectOrganisationId = this.selectSubmission.organisation_id;
    this.getEditLink(this.primaryKey);
    this.getSubmissions(primaryKey, this.selectOrganisationId);
    this.displayedData = this.displayedData.find(
      (submission) => submission.file_tracking_id == +primaryKey
    );
    this.dataUploadLayout = new Layout(
      this.displayedData.filename,
      [ `Submitted by: ${this.displayedData.submitter_name}`, `Email: ${this.displayedData.submitter_email}` ], null
    );
    this.router.navigate([], { queryParams: { id: primaryKey }, queryParamsHandling: 'merge' });
  };

  public closeQuestionSlider = (): void => {
    this.fileProjectId = null;
    this.wrongProjectSubmission = false;
    this.getDataUploads(this.currentProject.projectId, this.currentYear);
    this.dataUploadLayout = null;
    this.router.navigate([], { queryParams: { id: null }, queryParamsHandling: 'merge',
    });
  };
}
