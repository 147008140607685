<!-- Navbar -->
<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
  <nav class="navbar navbar-expand-lg py-3" [ngClass]="{'navbar-light bg-light': !ui.darkMode, 'navbar-dark bg-dark': ui.darkMode}" *ngIf="auth.authenticated()">
    <div class="container-fluid">
      <a class="navbar-brand font-weight-bold" [ngClass]="{'text-primary': !ui.darkMode, 'text-primary-light': ui.darkMode}" [routerLink]="['project']">
        Admin
      </a>
      <span class="navbar-toggler" (click)="navbarCollapsed = !navbarCollapsed">
          <i [class]="navbarCollapsed ? 'far fa-bars' : 'far fa-times'"></i>
      </span>

      <div class="collapse navbar-collapse" [class.show]="!navbarCollapsed" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto" (click)="navbarCollapsed = true">
          <li class="nav-item" [class.active]="router.isActive('/project', false)">
              <a class="nav-link" [routerLink]="['project']">Projects</a>
          </li>
          <!-- Future areas, disabled until live -->
          <li class="nav-item" [class.active]="router.isActive('/organisation', false)">
            <a class="nav-link" [routerLink]="['organisation']">Organisations</a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/users', false)">
            <a class="nav-link" [routerLink]="['users']">Users</a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/roles', false)">
            <a class="nav-link" [routerLink]="['roles']">Roles</a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/event', false)">
            <a class="nav-link" [routerLink]="['event']">Events</a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/tool', false)">
            <a class="nav-link" [routerLink]="['tool']">Tools</a>
          </li>
          <!-- end -->
        </ul>

        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" (click)="switch_theme()" *ngIf="ui.darkMode" ngbTooltip="Light Theme">
              <i class="fas fa-sun"></i>
            </a>
            <a class="nav-link" (click)="switch_theme()" *ngIf="!ui.darkMode" ngbTooltip="Dark Theme">
              <i class="fas fa-moon"></i>
            </a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/desk', false)">
            <a class="nav-link" [routerLink]="['desk']" ngbTooltip="Reserve Desk">
              <i class="fad fa-desktop"></i>
            </a>
          </li>
          <li class="nav-item" [class.active]="router.isActive('/profile', false)">
            <a class="nav-link" [routerLink]="['profile']" ngbTooltip="Profile">
              <i class="fas fa-user"></i>
            </a>
          </li>
          <li class="nav-item">
              <span role="button" class="nav-link" (click)="logout()" ngbTooltip="Logout">
                <i class="fas fa-sign-out"></i>
              </span>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <!-- Content -->
  <main style="min-height: 100vh" [ngClass]="{'bg-dark-900': ui.darkMode}">
    <router-outlet></router-outlet>
  </main>
</ng-container>

<!-- Help -->
<app-teamwork-form></app-teamwork-form>

