import { Component, Input, OnInit } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { INavItem } from 'src/app/core/models/nav-item.vm';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-area-nav',
  templateUrl: './area-nav.component.html',
  styleUrls: ['./area-nav.component.scss'],
  providers: [ NgbDropdown ]
})
export class AreaNavComponent implements OnInit {

  @Input() public navItems: Array<INavItem>;
  darkMode$: Observable<boolean>;

  constructor(
    private store: Store<{ darkMode: boolean }>
  ) {
    this.darkMode$ = store.select('darkMode')
   }

  ngOnInit(): void { }

}
