<!-- Loading and error -->
<app-loading-status *ngIf="loaderService.isLoading | async; else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>

    <!-- Table -->
    <app-dynamic-table 
        *ngIf="currentOrganisation.registrations" 
        [data]="currentOrganisation.registrations" 
        [columns]="organisationRegistrationTableColumns" 
        [currentYear]="currentYear"
        [tableTitle]="'registrationList'"
        [primaryKey]="'registrationId'"
        [onRowClick]="onRegistrationClick">
    </app-dynamic-table>

    <!-- Selected Registration -->
    <app-slider-panel *ngIf="selectedRegistration" [onClose]="closeRegistrationSlider">
        <!-- Heading -->
        <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
        <!-- Form -->
        <app-dynamic-form [data]="selectedRegistration" [blocks]="organisationRegistrationDetailsForm"></app-dynamic-form>
    </app-slider-panel>

</ng-template>