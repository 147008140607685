import { Constants } from "src/app/core/constants/constants";
import { IDynamicFormBlock } from "src/app/core/models/dynamic-form-block.vm";
import { IDynamicTableColumn } from "../core/models/dynamic-table-column.vm";
import { INavItem } from "../core/models/nav-item.vm";

export const ProfileNavItems: INavItem[] = [
    {
        label: 'Basics',
        link: '../profile',
        scope: 'basics'
    },
    {
        label: 'Roles',
        link: '/profile',
        scope: 'roles'
    }
];

export const ProfileRolesDetailsForm: IDynamicFormBlock[] = [
    {
        blockType: 'divider'
    },
    {
        label: 'Role Details',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Role Name',
        dataKey: 'roleName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Type',
        dataKey: 'roleType',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation Name',
        dataKey: 'organisationName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Product Name',
        dataKey: 'productName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Organisation Id',
        dataKey: 'organisationId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'product Id',
        dataKey: 'productId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Region ID',
        dataKey: 'regionId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project ID',
        dataKey: 'projectId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Project Name',
        dataKey: 'projectName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Role Year',
        dataKey: 'roleYear',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service Item ID',
        dataKey: 'serviceItemId',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Service Item Name',
        dataKey: 'serviceItemName',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Is Defunct',
        dataKey: 'isDefunct',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    },
    {
        label: 'Enabled',
        dataKey: 'isEnabled',
        blockType: 'input',
        colWidth: 6,
        disabled: true
    } 
];

export const ProfileBasics: IDynamicFormBlock[] = [
    {
        label: 'Basics',
        blockType: 'header',
        colWidth: 12
    },
    {
        label: 'Name',
        dataKey: 'fullName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Role',
        dataKey: 'jobTitle',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        blockType: 'breaker'
    },
    {
        label: 'Email',
        dataKey: 'logonName',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    },
    {
        label: 'Phone Number',
        dataKey: 'phoneNo',
        blockType: 'input',
        inputType: 'text',
        colWidth: 6
    }
];

export const ProfileRolesTableColumns: IDynamicTableColumn[] = [
    {
        header: 'Organisation',
        dataKey: 'organisationName',
        percWidth: 30,
        sortable: true,
        groupable: true,
        searchable: true
    },
    {
        header: 'Product',
        dataKey: 'productName',
        percWidth: 30,
        sortable: true,
        groupable: false,
        searchable: true
    },
    {
        header: 'Role Name',
        dataKey: 'roleName',
        percWidth: 20,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Year',
        dataKey: 'roleYear',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    },
    {
        header: 'Service Item',
        dataKey: 'serviceItemName',
        percWidth: 10,
        sortable: true,
        groupable: true,
        searchable: false
    }
];