<app-loading-status *ngIf="((loaderService.isLoading | async) && !userList); else notLoading"></app-loading-status>
<app-alert-status *ngIf="loadingError" [loadingError]="loadingError"></app-alert-status>

<ng-template #notLoading>
  <ng-container *ngIf="!currentUser">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-11 col-12">
          <div class="row mt-5 mb-3">
            <div class="col-md-7 col-12" *ngIf="layout">
              <app-area-heading [heading]="layout.heading" [subheading]="layout.subheading" [backRoute]="layout.backRoute"></app-area-heading>
            </div>
            <div class="col-md-5 col-12 d-flex">
              <div class="ml-auto">
              </div>
            </div>
          </div>
          <app-area-nav [navItems]="topLevelUserNavItems"></app-area-nav>

          <app-dynamic-table
            [data]="userList"
            [columns]="usersTableColumns"
            [tableTitle]="'userList'"
            [primaryKey]="'ID'"
            [onRowClick]="onUserClick"
            [userTableUpdate]=""
            [changeUsersList]="">
          </app-dynamic-table>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<app-slider-panel *ngIf="detailslayout" [onClose]="closePendingUserSlider">
  <!-- Heading -->
  <app-area-heading [heading]="detailslayout.heading" [subheading]="detailslayout.subheading" [backRoute]="detailslayout.backRoute"></app-area-heading> 
  <!-- Form -->
  <app-dynamic-form [parentSection]="section" [data]="currentPendingUser" [tableData]="tableData" [blocks]="UserPendingForm" (dataUpdate)="dataUpdate($event)" (onRolesClick)="onRolesClick()" [roles]="roles" [products]="products"></app-dynamic-form>
  <app-popup-modal *ngIf="modalInfo" [modalInfo]="modalInfo" (action)="closeModal($event)" (commentText)="commentText($event)"></app-popup-modal>     
</app-slider-panel>

<app-slider-panel *ngIf="isRoleAndProductsSliderOpened" [onClose]="closeRolesSlider" [layer]="1">
  <!-- Heading -->
  <app-area-heading [heading]="detailslayout.heading" [subheading]="detailslayout.subheading" [backRoute]="detailslayout.backRoute"></app-area-heading>
  <!-- Form -->
  <app-users-roles-products-shared [tableData]="tableData" (tableDataFromUsersRolesAndProductsToUsersPending)="addTableData($event)" (assignNullValueToTableData)="removeTable()" [roles]="roles" [products]="products"></app-users-roles-products-shared>
</app-slider-panel>