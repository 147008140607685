import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Event } from 'src/app/core/classes/event';
import { Layout } from 'src/app/core/classes/layout';
import { IDynamicFormBlock } from 'src/app/core/models/dynamic-form-block.vm';
import { IDynamicTableColumn } from 'src/app/core/models/dynamic-table-column.vm';
import { IEventRegistration } from 'src/app/core/models/event-registration.vm';
import { ILayout } from 'src/app/core/models/layout.vm';
import { EventsService } from 'src/app/core/services/events.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { EventRegistrationDetailsForm, EventRegistrationTableColumns, RegistrationTableUpdate } from './event-registration-layouts';
import { CreateOrganisationForm, NonMemberEventRegistrationDetailsForm, NonMemberEventRegistrationTableColumns } from './non-memeber-event-registration-layouts'
import { DatePipe } from '@angular/common';
import { IDynamicChangeList } from 'src/app/core/models/dynamic-table-change-list.vm';
import { ProjectService } from 'src/app/core/services/project.service';
import { DynamicFormComponent } from 'src/app/shared/dynamic-form/dynamic-form.component';

@Component({
  selector: 'app-event-registration',
  templateUrl: './event-registration.component.html',
  styleUrls: ['./event-registration.component.scss']
})
export class EventRegistrationComponent implements OnInit {

  @Input() public currentEvent: Event;
  @Input() currentYear: number;
  @ViewChild(DynamicFormComponent) dynamicFormComponent: DynamicFormComponent;

  public selectedRegistration: IEventRegistration = null;
  public layout: ILayout;
  public currentQueryParams: any;
  public loadingError: string;
  public eventRegistrationTableColumns: Array<IDynamicTableColumn> = EventRegistrationTableColumns;
  public eventRegistrationDetailsForm: Array<IDynamicFormBlock> = EventRegistrationDetailsForm;
  public registrationTableUpdate: Array<IDynamicChangeList> = RegistrationTableUpdate;
  public createOrganisationForm: Array<IDynamicFormBlock> = CreateOrganisationForm;
  public tablePrimaryKey: string = 'registrationId'
  public modalInfo: Object = null; 
  public commentValue: string;
  public acceptOrRejectError: string = null;
  public currentSection: string = "eventNonMemberRegistrationRequest"
  public createOrgLayout: ILayout;
  public organisationDetails
  public newOrgCreated: boolean = false;
  public reloadTrigger: boolean = false;
  public acceptAndAmendButtonText: string = 'Accept as a Member'

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    private eventsService: EventsService,
    private projectService: ProjectService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.currentQueryParams = params;
    });  
    // Get registrations
    if (this.currentQueryParams.registration) {
      this.getEventRegistrations(this.currentEvent.eventId, this.currentYear);
    }
    if (this.currentQueryParams.registrationRequest) {
      this.getNonMemberEventRegistrations(this.currentEvent.eventId);
    }
    if (!this.currentQueryParams.registration && !this.currentQueryParams.registrationRequest) {
      this.getEventRegistrations(this.currentEvent.eventId, this.currentYear);
    }
  }

  public changeEventsList = (name: string): void => {
    this.currentEvent.registrations = [];
    if(name == "PENDING") {      
      this.getNonMemberEventRegistrations(this.currentEvent.eventId);
    } else {        
      this.getEventRegistrations(this.currentEvent.eventId, this.currentYear);
    }
  };

  private getNonMemberEventRegistrations(eventId: number): void {
    this.registrationTableUpdate[0].hideValue = true;
    this.registrationTableUpdate[1].hideValue = false;
    this.tablePrimaryKey = 'id';
    this.eventRegistrationDetailsForm = NonMemberEventRegistrationDetailsForm;
    this.eventRegistrationTableColumns = NonMemberEventRegistrationTableColumns;
    this.eventsService.getNonMemberEventRegistrations(eventId).subscribe(
        success => {
          this.currentEvent.registrations = success.data;
          // Open slider, if in params
          if (this.currentQueryParams.registrationRequest) {
            this.onRegistrationClick(this.currentQueryParams.registrationRequest);
          }
        },
        error => {
          console.log(error);
          this.loadingError = error.error;
        }
      );
  }

  private getEventRegistrations(eventId: number, currentYear: number): void {
    this.registrationTableUpdate[0].hideValue = false;
    this.registrationTableUpdate[1].hideValue = true;
    this.tablePrimaryKey = 'registrationId';
    this.eventRegistrationDetailsForm = EventRegistrationDetailsForm;
    this.eventRegistrationTableColumns = EventRegistrationTableColumns;
    this.eventsService.getEventRegistration(eventId, currentYear).subscribe(
        success => {
          if (success.data.registrationList) {
            success.data.registrationList.forEach(value => {
              value["registrationDt"] = this.datePipe.transform(value.registrationDt, 'medium');
              this.mapRegistrants(value)             
            });
            this.currentEvent.registrations = success.data.registrationList;
          } else {
            this.currentEvent.registrations = [];
          }
          // Open slider, if in params
          if (this.currentQueryParams.registration) {
            this.onRegistrationClick(this.currentQueryParams.registration);
          }
        },
        error => {
          console.log('Error: ', error);
          this.loadingError = error.error.error.message;
        }
      );
  }

  public mapRegistrants(registrant) {    
    if(registrant['isMember'] == null) {
      registrant['isMember'] = 'N'
    }
  }
  

  public onRegistrationClick = (primaryKey: string): void => {
    this.acceptOrRejectError = null
    if (this.tablePrimaryKey == 'registrationId') {
      this.selectedRegistration = this.currentEvent.registrations.find((reg) => reg.registrationId == +primaryKey);
      this.router.navigate([], { queryParams: { registration: primaryKey }, queryParamsHandling: 'merge', });
    }
    if (this.tablePrimaryKey == 'id') {
      this.selectedRegistration = this.currentEvent.registrations.find((reg) => reg.id == +primaryKey);
      this.selectedRegistration['registrationId'] = null;
      this.router.navigate([], { queryParams: { registrationRequest: primaryKey }, queryParamsHandling: 'merge', });
    }
    this.layout = new Layout(
      this.selectedRegistration.fullName,
      [`ID: ${this.selectedRegistration.eventId}`],
      null
    );
  };

  public closeRegistrationSlider = (): void => {
    this.selectedRegistration = null;
    if (this.currentQueryParams.registration) {
      this.router.navigate([], { queryParams: { registration: null }, queryParamsHandling: 'merge', });
    } 
    if (this.currentQueryParams.registrationRequest) {
      this.router.navigate([], { queryParams: { registrationRequest: null }, queryParamsHandling: 'merge', });
    }
  };

  public dataUpdate(formData){
    if(formData.action == 'accept') {
      this.acceptEventRegistrationRequest(formData.form)
    }
    if(formData.action == 'amend-accept') {
      this.acceptEventRegistrationRequest(formData.form, true)
    }
    if(formData.action == 'reject') {
      this.openRejectModal();
    }  
    if(formData.action == 'add' && formData.row.organisationName) {
      this.createOrganisation(formData);
    }  
  }

  public acceptEventRegistrationRequest(form: IEventRegistration, isMember?: boolean) {
    const eventRegistrationDetails = {
      organisationId: form.organisationId,
      delegateEmail: form.emailAddress,
      delegateName: form.fullName,
      delegateJobTitle: form.jobTitle,
      delegateContactNo: "",
      twitterHandle: "",
      dietaryRequirements: form.dietaryRequirements,
      dietaryDetails: form.dietaryDetails,
      delegateBadge: "",
      delegateList: form.delegateList,
      accessRequirements: form.accessRequirements,
      eventId: this.currentEvent.eventId,
      isMember: isMember ? 'Y' : 'N'
    }
    this.eventsService.acceptNonMemberEventRegistrationRequest(this.selectedRegistration.id, eventRegistrationDetails).subscribe((success) => {
      if(success){
        this.selectedRegistration = null
        this.getNonMemberEventRegistrations(this.currentEvent.eventId);
        this.router.navigate([], { queryParams: { registrationRequest: null }, queryParamsHandling: 'merge', });
      }
    },
    (error) => {
      console.log('Error: ', error);
      if (error.error.error.message == "Compulsory parameters missing: organisationId") {
        this.acceptOrRejectError = "Please confirm the Organisation by selecting one from the Organisation dropdown";
      } else {
        this.acceptOrRejectError = error.error.error.message;
      }
    }
  )
  }

  public openRejectModal() {
    this.modalInfo = {
      item: null, 
      subText: "Please provide a reason why you would like to reject this request", 
      title: "Reject",
      action: "Event Registration Request",
      request: "Reject",
      comment: true
    }    
  }

  public closeModal(event){
    this.newOrgCreated = false;
    if(event == true){
      this.rejectEventRegistrationRequest()
      this.modalInfo = null
    }
    this.modalInfo = null
  }

  public commentText(event){
    this.commentValue = event
  }

  public rejectEventRegistrationRequest() {
    const rejectionDetails = { message: this.commentValue }
    this.eventsService.rejectNonMemberEventRegistrationRequest(this.selectedRegistration.id, rejectionDetails).subscribe((success) => {
      if(success){
        this.selectedRegistration = null
        this.getNonMemberEventRegistrations(this.currentEvent.eventId);
        this.router.navigate([], { queryParams: { registrationRequest: null }, queryParamsHandling: 'merge', });
      }
    },
    (error) => {
      console.log('Error: ', error);
      this.acceptOrRejectError = error.message
    }
  )
  }

  public buttonAction(action) {
    if(action == 'createOrganisation') {
      this.createOrgSlider();
    }
  }

  createOrgSlider() {
    this.organisationDetails = {
      organisationName: '',
      organisationUrl: '',
      postalAddress1: '',
      postalAddress2: '',
      postalAddress3: '',
      postalAddress4: '',
      postalAddressPc: '',
      organisationTypeId: null,
      organisationTypeOther: '',
      poRequired: null,
      vatCharge: null,
      trackingNote: "Organisation Created and assigned"
    }  
    this.createOrgLayout = new Layout(
      "Create Organisation", [], null
    ); 
  }

  public closeOrgSlider = (): void => {
    this.createOrgLayout = null;   
  };

  public createOrganisation(formData) {
    let organisation = {
      organisationName: formData.row.organisationName,
      organisationUrl: formData.row.organisationUrl,
      postalAddress1: formData.row.postalAddress1,
      postalAddress2: formData.row.postalAddress2,
      postalAddress3: formData.row.postalAddress3,
      postalAddress4: formData.row.postalAddress4,
      postalAddressPc: formData.row.postalAddressPc,
      organisationTypeId: formData.row.organisationTypeId,
      organisationTypeOther: formData.row.organisationTypeOther,
      poRequired: formData.row.poRequired,
      vatCharge: formData.row.vatCharge,
      trackingNote: "Organisation Created"
    }
    for (let key in organisation) {
      if (organisation[key] === null) {
        organisation[key] = "";
      }
    }    
    this.projectService.createOrganisation(organisation).subscribe(
      (success) => {
        this.newOrgCreated = true;
        this.reloadTrigger = !this.reloadTrigger;
        this.reloadOrganisationsList();       
        this.closeOrgSlider()       
      },
      (error) => {
        console.log('Error: ', error);
      }
    );
  }

  reloadOrganisationsList() {
    if (this.dynamicFormComponent) {
      this.dynamicFormComponent.reloadOrganisationsList();
    }
  }

}
