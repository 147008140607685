<ng-container *ngIf="{ darkMode: darkMode$ | async } as ui">
  <!-- HEADER -->
  <div class="row mb-4">

    <div class="col-md-4 col-sm-12">
      <!-- Preset filters -->
      <ng-container *ngIf="presetFilters">
        <button class="btn btn-outline-info mr-2 mb-2" *ngFor="let filter of presetFilters"
          (click)="togglePresetFilter(filter)">
          <i class="fas mr-2" [class.fa-eye-slash]="filter.hideValue" [class.fa-eye]="!filter.hideValue"></i>
          <b> {{ filter.name }} </b>
        </button>
      </ng-container>
      <ng-container *ngIf="userTableUpdate">
        <button class="btn btn-outline-info mr-2 mb-2" *ngFor="let user of userTableUpdate"
          (click)="changeUsersList(user.currentList)" [disabled]="!user.hideValue">
          <i class="fas mr-2" [class.fa-eye-slash]="user.hideValue" [class.fa-eye]="!user.hideValue"></i>
          <b>{{ user.name }} </b>
        </button>
        <ng-container *ngFor="let user of userTableUpdate">
          <div class="d-block text-info mt-2 mb-4" *ngIf="!user.hideValue && user.displayHelpText">
            Showing rows where <b>{{ user.header }}</b> are <b>{{ user.currentList }}</b></div>
        </ng-container>
      </ng-container>
      <!-- Table filters -->
      <app-table-filters style="display: table-cell"
        *ngIf="groupableColumns.length > 0"
        (resetFilterClickChange)="resetFilterClickChange($event)"
        (filterColumnValue)="filterColumn($event)"
        (groupByColumnValue)="groupByColumn($event)" 
        (datePickerValue)="recieveDatePickerValue($event)"
        (removeDateFilter)="removeDateFilter($event)"
        [groupableColumns]="groupableColumns" 
        [columns]="columns"
        [groupedColumn]="groupedColumn"
        [defaultGroupedColumn]="defaultGroupedColumn"
        [resetFilterClicked]="resetFilterClickedGrouped">
      </app-table-filters>
    </div>
    
    <div class="col-md-8 col-sm-12 align-content-end">
        <div class="d-flex justify-content-end">
        <button *ngIf="dynamicButton" (click)="dynamicButtonClick()" style="margin-bottom: 10px;" [class]="dynamicButton.class">{{dynamicButton.name}}</button>
      <!-- Export and search -->
      <app-table-export-search 
        (searchValue)="searchValue($event)" 
        [searchableColumns]="searchableColumns"
        [columns]="columns" 
        [data]="data" 
        [tableTitle]="tableTitle" 
        [hideExportButton]="hideExportButton"
        [hideSearch]="hideSearch" 
        [data]="exportData" 
        [tableTitle]="tableTitle">
      </app-table-export-search>
      </div>
    </div>
  </div>

  <!-- TABLE -->
  <div class="row mb-4">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-hover" [ngClass]="{ 'table-dark': ui.darkMode }">
          <!-- Table headers -->
          <thead>
            <tr>
              <ng-container *ngFor="let col of columns">
                <ng-template #headerTooltipContent>{{ col.header }}</ng-template>
                <th class="c-pointer" *ngIf="col.sortable" 
                  [style.display]="col.percWidth === 0 ? 'none' : ''"
                  [style.width]="col.percWidth + '%'" 
                  [style.color]=" searchText !== undefined ? col.searchable ? '#17a2b8' : '' : ''" 
                  (click)="sortColumn(col.dataKey)">
                  <span [ngbTooltip]="headerTooltipContent">{{ col.header }}</span>
                  <i *ngIf="col.insight" class="far fa-info-circle" (click)="openModal($event, col.insight)" placement="right"></i>
                  <i class="fas fa-long-arrow-down ml-1" *ngIf="checkSort(col.dataKey) == 'asc'"></i>
                  <i class="fas fa-long-arrow-up ml-1" *ngIf="checkSort(col.dataKey) == 'desc'"></i>
                </th>
                <th *ngIf="!col.sortable" 
                  [style.display]="col.percWidth === 0 ? 'none' : ''" 
                  [style.width]="col.percWidth + '%'" 
                  [style.color]=" searchText !== undefined ? col.searchable ? '#17a2b8' : '' : ''">
                  <span [ngbTooltip]="headerTooltipContent">{{ col.header }}</span>
                </th>
              </ng-container>

              <th *ngIf="!disableRowClick" style="width: 5%"></th>
            </tr>
            <tr *ngIf="data.length > 0">
              <th [attr.colspan]="columns.length + 1" [ngClass]="{ 'bg-light': !ui.darkMode, 'bg-dark': ui.darkMode }">
                {{ rowDisplay() }}
                {{ "row" | makePluralString : pager.totalItems }}
              </th>
            </tr>
          </thead>
          <!-- Ungrouped table body -->
          <ng-container *ngIf="!groupedColumn">
            <tbody>
              <app-table-row style="display: contents" 
                [disableRowClick]="disableRowClick" 
                [columns]="columns"
                [data]="pageOfItems" 
                [searchText]="searchText" 
                [onRowClick]="onRowClick" 
                [primaryKey]="primaryKey"
                [onTdClick]="onTdClick">
              </app-table-row>
            </tbody>
          </ng-container>

          <!-- Grouped table body -->
          <ng-container *ngIf="groupedColumn">
            <tbody *ngFor="let group of pageOfItems | groupBy : groupedColumn | pairs">
              <tr (click)="group.isVisible = !group.isVisible" class="font-weight-bold c-pointer" 
                [ngClass]="{ 'bg-light': !ui.darkMode, 'bg-dark-700': ui.darkMode }">
                <th [attr.colspan]="columns.length + 1">
                  {{ group[0] }} ({{ group[1].length }})
                  <i class="ml-1 fas" [class]="group.isVisible ? 'fa-angle-right' : 'fa-angle-down'"></i>
                </th>
              </tr>
              <ng-container *ngIf="!group.isVisible">
                <app-table-row style="display: contents" 
                  [disableRowClick]="disableRowClick" 
                  [columns]="columns"
                  [data]="group[1]" 
                  [searchText]="searchText" 
                  [onRowClick]="onRowClick" 
                  [primaryKey]="primaryKey"
                  [onTdClick]="onTdClick">
                </app-table-row>
              </ng-container>
            </tbody>
          </ng-container>

          <!-- No results -->
          <ng-container *ngIf="(data | filterBy : searchableColumns : searchText).length == 0 && searchableColumns.length > 0">
            <tbody>
              <tr>
                <th [attr.colspan]="columns.length + 1" class="text-center text-muted p-4">
                  There are no results for this search
                </th>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
      <app-table-pagination [items]="data" [initialPage]="selectedPage" [paginationReset]="paginationReset" [pageSize]="pageSize" (changePage)="onChangePage($event)"></app-table-pagination>
    </div>
  </div>
</ng-container>

<ng-template #modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{ status.title }}</h4>
    <i
      class="fal fa-2x fa-window-close c-pointer"
      (click)="d('Cross click')"
    ></i>
  </div>
    <table class="table table-striped w-auto">
      <thead>
        <tr *ngIf="!status.noHeader">
          <th>{{ status.header1 }}</th>
          <th>{{ status.header2 }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let state of status.body">
          <td><span [style.background-color]="state.badge" [style.color]="state.textColor">{{ state.column1 }}</span></td>
          <td>{{ state.column2 }}</td>
        </tr>
      </tbody>
    </table>
</ng-template>
